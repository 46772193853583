import { styled } from "@mui/material";
import classnames from "classnames";
import React from 'react'
import { isValentine } from './SiteTheme'
import cupido_small from './cupido-small.png';

const PREFIX = 'HomePageTheming';
const classes = {
	cupido: `${PREFIX}-cupido`,
}

// Date specific home page theming
const HomePageTheming = styled(({ className }) => {
    if(isValentine()) return (
        <div className={classnames(className, classes.cupido)} />
    );

    return null;
})(({ theme }) => ({
	[`&.${classes.cupido}`]: {
		position: 'absolute',
		right: '0',
		top: '50px',
		width: '175px',
		height: '200px',
		backgroundImage: `url(${cupido_small})`,
		backgroundSize: '175px auto',

		[theme.breakpoints.down('lg')]: {
			display: 'none',
		},
	},
}));

export default HomePageTheming
