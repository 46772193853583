import { Box } from "@mui/material";
import React, { Component} from 'react'
import PropTypes from 'prop-types'

class Faq extends Component {
	state = {
		active: null
	};

	render(){
		return (
			<Box sx={{ margin: '-20px', fontSize: '16px' }}>
				{this.props.children}
			</Box>
		);
	}

	getChildContext() {
		return {
			faqToggle: this.toggleItem,
			faqActive: this.state.active
		};
	}

	toggleItem = (key) => {
		this.setState({
			active: this.state.active == key ? null: key
		});
	};
}

Faq.propTypes = {
	children: PropTypes.node.isRequired
};

Faq.childContextTypes = {
	faqToggle: PropTypes.func,
	faqActive: PropTypes.number
};

export default Faq
