import { styled, Button, TextField, Typography, Autocomplete, createFilterOptions } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { updateLastScopePathStartAt } from '../../actions/lastPath';
import { fetchSubScopes, fetchSubSubScopes } from '../../actions/scopes'
import { gtmEvent } from '../../helpers/gtm';
import { useLoadingDispatch } from '../../hoc/hooks/useLoadingDispatch'
import useRootScopeProperties from '../../hoc/hooks/useRootScopeProperties'
import { rootScopeSelector, subScopeSelector, subSubScopeSelector } from '../../selectors/scope';
import { lastScopePathSelector } from '../../selectors/lastPath';
import { path, routes } from '../../routes';
import { scopeUrlSegment } from '../../helpers/scope';
import PickerTabs from './PickerTabs';

const PREFIX = 'Picker';

const classes = {
    root: `${PREFIX}-root`,
    wrapper: `${PREFIX}-wrapper`,
    selector: `${PREFIX}-selector`,
    select: `${PREFIX}-select`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		background: 'white',
		borderRadius: '4px',
	},

    [`& .${classes.selector}`]: {
		display: 'flex',
		alignContent: 'center',
		padding: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
			flexDirection: 'column',
		},
	},

    [`& .${classes.select}`]: {
		position: 'relative',
		marginRight: theme.spacing(2),
		flex: 1,
		'& .MuiInputBase-root': {
			borderRadius: '4px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			backgroundColor: 'rgba(0,0,0,0.075)',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
		},
	}
}));

const StyledButton = styled(Button)(({ theme }) => ({
	fontSize: '1.1rem',
	borderRadius: '4px',
	color: 'white',
	minWidth: 'unset',
	whiteSpace: 'nowrap',
	boxShadow: 'none',
	'&:hover, &:focus': {
		boxShadow: 'none',
	},
	[theme.breakpoints.down('md')]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

// TODO: might need better/cleaner loading state

const Picker = (props) => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [loadingDispatch, loading] = useLoadingDispatch();

	const rootScopeProperties = useRootScopeProperties();
	const lastScopePath = useSelector(lastScopePathSelector);
	const rootScope = useSelector(rootScopeSelector);
	const institutes = useSelector(state => subScopeSelector(state, rootScope));
	const faculties = useSelector(state => lastScopePath[0] ? subScopeSelector(state, lastScopePath[0]) : null);
	const studies = useSelector(state => rootScopeProperties.config.showFacultyScope ? (
		lastScopePath[1] ? subScopeSelector(state, lastScopePath[1]) : null
	) : (
		lastScopePath[0] ? subSubScopeSelector(state, lastScopePath[0]) : null
	));

	useEffect(() => {
		if(!institutes){
			loadingDispatch(fetchSubScopes(rootScope.id));
		}
	}, [rootScope, institutes]);

	const institute = lastScopePath[0];
	const alreadyFetchingRef = useRef(false); // Nasty solution for now to prevent double fetching in some cases
	useEffect(() => {
		if(institute && !studies && !alreadyFetchingRef.current){
			alreadyFetchingRef.current = true;
			loadingDispatch(fetchSubSubScopes(institute?.id || institute)).then(() => {
				if(alreadyFetchingRef) alreadyFetchingRef.current = false;
			});
		}
	}, [institute, studies]);

	const navigateToStudy = () => {
		if(!lastScopePath[0] && !lastScopePath[1] && !lastScopePath[2]){
			gtmEvent('homePickerNavigate', { homePickerNavigateTo: 'course' });
			navigate(path(i18n, routes.COURSES));
		} else if (!lastScopePath[1] && !lastScopePath[2]){
			navigate(path(i18n, routes.INSTITUTE, scopeUrlSegment(lastScopePath[0])));
			gtmEvent('homePickerNavigate', { homePickerNavigateTo: 'institute' });
		} else if (!lastScopePath[2]){
			navigate(path(i18n, routes.FACULTY, scopeUrlSegment(lastScopePath[0]), scopeUrlSegment(lastScopePath[1])));
			gtmEvent('homePickerNavigate', { homePickerNavigateTo: 'faculty' });
		} else{
			navigate(path(i18n, routes.STUDY, scopeUrlSegment(lastScopePath[0]), scopeUrlSegment(lastScopePath[1]), scopeUrlSegment(lastScopePath[2])));
			gtmEvent('homePickerNavigate', { homePickerNavigateTo: 'study' });
		}
	};

	const { scopePickerTabs } = rootScope.properties;
	const studySelectInput = useRef(null);
	const facultySelectInput = useRef(null);

	const handleChangeInstitute = (event, value) => {
		const redirect = event.target.properties && event.target.properties.redirect;
		// If there is a redirect, that is all there is to do
		if(redirect){
			document.location.href = redirect;
			return;
		}

		dispatch(updateLastScopePathStartAt(value?.id ? [value.id] : [], 0));

		if(value) setTimeout(() => {
			if(rootScopeProperties.config.showFacultyScope){
				facultySelectInput.current?.focus?.();
			}else{
				studySelectInput.current?.focus?.();
			}
		}, 200);
	}

	const handleChangeFaculty = (event, value) => {
		dispatch(updateLastScopePathStartAt(value?.id ? [value.id] : [], 1));

		if(value) setTimeout(() => studySelectInput.current?.focus?.(), 200);
	}

	const goToStudyBtn = useRef(null);
	const handleChangeStudy = (event, value) => {
		if(rootScopeProperties.config.showFacultyScope){
			dispatch(updateLastScopePathStartAt(value?.id ? [value.id] : [], 2));
		}else{
			dispatch(updateLastScopePathStartAt(value?.id ? [value?.parent || false, value.id] : [], 1));
		}

		if (value) setTimeout(() => goToStudyBtn.current?.focus?.(), 200);
	}

	const filterOptions = createFilterOptions({
		stringify: option => `${option.name} ${option.abbreviation} ${option.city}`,
	});

	const handleInputChangeGTM = useCallback((e, value, reason) => {
		if(reason === 'input'){
			gtmEvent('homePickerKeyboard', {
				homePickerSelect: e.target.id,
			});
		}
	}, []);

	const handleOpenGTM = useCallback((e) => {
		gtmEvent('homePickerOpen', {
			homePickerSelect: e.target.id,
		});
	}, []);

	return (
        <Root className={classes.wrapper} {...props}>
			{ scopePickerTabs?.length > 1 && (
				<PickerTabs scopePickerTabs={scopePickerTabs} />
			)}
			<div className={classes.selector}>
				<Autocomplete
					className={classes.select}
					disableClearable
					fullWidth
					id="picker_uni_select"
					options={institutes || []}
					filterOptions={filterOptions}
					getOptionLabel={(option) => option.name || ''}
					isOptionEqualToValue={(option) => option.id === lastScopePath[0]?.id}
					onChange={handleChangeInstitute}
					onInputChange={handleInputChangeGTM}
					onOpen={handleOpenGTM}
					value={lastScopePath[0] || null}
					autoSelect
					openOnFocus
					loading={!institutes && loading}
					renderOption={(props, option) => (
						<li {...props} key={option.name}>
							<Typography>{`${option.name} (${option.abbreviation})`}</Typography>
						</li>
					)}
					renderInput={(params) => (
						<TextField
							label={t('home.picker.uni.select')}
							variant="outlined"
							size="medium"
							{...params}
						/>
					)}
				/>
				{rootScopeProperties.config.showFacultyScope && (
					<Autocomplete
						className={classes.select}
						fullWidth
						id="picker_faculty_select"
						options={faculties || []}
						filterOptions={filterOptions}
						getOptionLabel={(option) => option.name || ''}
						isOptionEqualToValue={(option) => option.id === lastScopePath[1]?.id}
						onChange={handleChangeFaculty}
						onInputChange={handleInputChangeGTM}
						onOpen={handleOpenGTM}
						disabled={!lastScopePath[0]}
						value={lastScopePath[1] || null}
						autoSelect
						openOnFocus
						loading={!faculties && loading}
						noOptionsText={t('home.picker.faculty.not_found')}
						renderInput={(params) => (
							<TextField
								inputRef={facultySelectInput}
								label={lastScopePath[0] ? t('home.picker.faculty.select') : t('home.picker.faculty.select_uni_first')}
								variant="outlined"
								size="medium"
								{...params}
							/>
						)}
					/>
				)}
				<Autocomplete
					className={classes.select}
					fullWidth
					id="picker_study_select"
					options={studies || []}
					getOptionLabel={(option) => option.name}
					isOptionEqualToValue={(option) => option.id === lastScopePath[2]?.id}
					onChange={handleChangeStudy}
					onInputChange={handleInputChangeGTM}
					onOpen={handleOpenGTM}
					disabled={!lastScopePath[0] || (rootScopeProperties.config.showFacultyScope && !lastScopePath[1])}
					value={lastScopePath[2] || null}
					autoSelect
					openOnFocus
					loading={!studies && loading}
					noOptionsText={t('home.picker.study.not_found')}
					renderInput={(params) => (
						<TextField
							inputRef={studySelectInput}
							label={rootScopeProperties.config.showFacultyScope ? (
								lastScopePath[1] ? t('home.picker.study.select') : t('home.picker.study.select_faculty_first')
							) : (
								lastScopePath[0] ? t('home.picker.study.select') : t('home.picker.study.select_uni_first')
							)}
							variant="outlined"
							size="medium"
							{...params}
						/>
					)}
				/>
				<StyledButton
                    variant="contained"
                    color="cta"
                    onClick={navigateToStudy}
                    ref={goToStudyBtn}
                    endIcon={<ArrowForward />}
                 >
					{t('home.picker.pick_courses')}
				</StyledButton>
			</div>
		</Root>
    );
}

export default Picker;
