import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import { CheckSharp } from "@mui/icons-material";
import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomLocaleMessage } from "../../helpers/locale";

const PREFIX = 'HeaderUspList';

const classes = {
    container: `${PREFIX}-container`,
    list: `${PREFIX}-list`,
    listItem: `${PREFIX}-listItem`
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.container}`]: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},

    [`& .${classes.list}`]: {
		display: 'flex',
		listStyle: 'none',
		gap: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: 0,
		padding: theme.spacing(0.5, 0),
		fontSize: '0.85em',
		width: '100%',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8em',
		},
	},

    [`& .${classes.listItem}`]: {
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			marginRight: theme.spacing(1),
			fontSize: '1.25em',
			color: theme.palette.info.main,
		}
	}
}));

function HeaderUspList({ items }) {

	const { i18n } = useTranslation();
	const slicedItems = items?.slice(0, 4);

	if (slicedItems.length === 0) {
		return null;
	}

	return (
        <StyledContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
			<ul className={classes.list}>
				{slicedItems.map((item, index) => (
					<li className={classes.listItem} key={`usp-item_${index}`}>
						<CheckSharp />
						{getCustomLocaleMessage(item.usp, i18n)}
					</li>
				))}
			</ul>
		</StyledContainer>
    );
}

HeaderUspList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		usp: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
		]).isRequired,
	})),
}

export default HeaderUspList;
