import { mapObjIndexed, pick, values } from 'ramda'
import { BasketCookieDays } from '../reducers/init'

const getEntryCourseName = (entry) => {
	if(!entry.product.parent) return '';

	return entry.product.parent.name;
}

export const nameFromBasketEntry = (entry) => {
	return `${getEntryCourseName(entry)} - ${entry.product.name}`;
};

const validUtmNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
const BasketCookieUtmName = 'ATHENASTUDIES-BASKET-UTM';

/**
 * Reads and validates the UTM parameters and stores them
 *
 * @param {object} query The object of query parameters
 * @param {function} setCookie setCookie function from react-cookie hook
 * @returns {boolean} True if some UTM query parameters were present
 */
export function storeUtmQueryString(query, setCookie){
	query = query || {};

	const utm = values(mapObjIndexed((val, key) => {
		return `${key}=${val}`;
	}, pick(validUtmNames, query))).join('&');

	if(utm.length > 0){
		setCookie(BasketCookieUtmName, utm, BasketCookieDays);
		return true;
	}

	return false;
}

/**
 * Get the ready to use query string with UTM parameters, removes the cookie so it is only used once
 *
 * @param {Cookies} cookies
 * @returns {string} The ready to use query string with or empty string if no UTM parameters present
 */
export function getUtmQueryString(cookies){
	const utm = cookies.get(BasketCookieUtmName);

	// Directly remove the cookie so it is only used once
	cookies.remove(BasketCookieUtmName);

	return utm && utm.length > 0 ? utm : '';
}
