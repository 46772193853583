import { concat, without } from 'ramda'

export function fetchDataReducerFinal(state = {}, action) {
	if(!action.fetchDataId) return state;

	switch(action.type){
		case 'FETCH_DATA_LOADING':
			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					error: false,
					loading: true
				}
			};

		case 'FETCH_DATA_ERROR':
			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					error: action.error,
					success: false,
					loading: false
				}
			};

		case 'FETCH_DATA_SUCCESS':
			let pagination = null;
			if(action.pagination){
				pagination = {
					page: action.pagination.page,
					pageSize: action.pagination.pageSize,
					total: action.pagination.total
				}
			}

			return {
				...state,
				[action.fetchDataId]: {
					error: false,
					success: action.result || true,
					loading: false,
					pagination: pagination
				}
			};

		case 'FETCH_DATA_CLEAR':
			return {
				...state,
				[action.fetchDataId]: undefined
			};

		case 'FETCH_DATA_APPEND':
			if(!state[action.fetchDataId]) return state;

			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					success: state[action.fetchDataId].success
						? concat(state[action.fetchDataId].success, [action.result])
						: [action.result]
				}
			};

		case 'FETCH_DATA_CONCAT':
			if(!state[action.fetchDataId]) return state;

			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					success: state[action.fetchDataId].success
						? concat(state[action.fetchDataId].success, action.result)
						: action.result
				}
			};

		case 'FETCH_DATA_DELETE':
			if(!state[action.fetchDataId]) return state;

			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					success: state[action.fetchDataId].success
						? without([action.result], state[action.fetchDataId].success)
						: []
				}
			};

		case 'FETCH_DATA_PAGE':
			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					pagination: {
						...state[action.fetchDataId].pagination || {},
						page: action.page
					}
				}
			};

		case 'FETCH_DATA_PAGE_SIZE':
			return {
				...state,
				[action.fetchDataId]: {
					...state[action.fetchDataId],
					pagination: {
						...state[action.fetchDataId].pagination || {},
						page: 0,
						pageSize: action.pageSize
					}
				}
			};
	}

	return state;
}

const fetchDataReducer = {
	fetchData: fetchDataReducerFinal,
};

export default fetchDataReducer
