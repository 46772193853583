import { Grid } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { values } from 'ramda'
import { moneyComposer, percentageComposer } from '../../helpers/composers'
import CheckoutMethodTitle from './CheckoutMethodTitle';
import StyledCheckoutMethodsSection, { checkoutClasses } from './StyledCheckoutMethodsSection';

export const methodImgUrl = (method) =>
	method.methodImgUrl || `https://static.pay.nl/payment_profiles/50x50/${method.id}.png`;
export const methodSubImgUrl = (method) =>
	`https://cdn.athenastudies.nl/psp/ideal/banks/${method.id}.png`;

const CheckoutMethodsSection = ({ isMain, method, onMethodClick }) => {
	const subs = values(method.paymentOptionSubList);
	const hasSubs = subs && subs.length > 0;

	if(!isMain) return (
		<Grid item key={method.id} xs={6} sm={4} md={2}>
			<div className={checkoutClasses.item} onClick={() => onMethodClick(method)}>
				<img src={methodImgUrl(method)} alt={method.visibleName} />
				<div>{method.visibleName}</div>
			</div>
			{method.transactionCosts && (
				<div>
					{`${moneyComposer(method.transactionCosts.unitPrice)} + ${percentageComposer(method.transactionCosts.percentage)}`}
				</div>
			)}
		</Grid>
	)

    return (
        <StyledCheckoutMethodsSection>
			<CheckoutMethodTitle method={method} />
			{hasSubs ? (
				<Grid container spacing={3}>
					{subs.map(sub => (
						<Grid item key={sub.id} xs={6} sm={4} md={2}>
							<div className={checkoutClasses.item} onClick={() => onMethodClick(method, sub)}>
								<img src={methodSubImgUrl(sub)} alt={sub.visibleName} />
								<div>{sub.visibleName}</div>
							</div>
						</Grid>
					))}
				</Grid>
			) : (
				<div className={checkoutClasses.item} onClick={() => onMethodClick(method)}>
					<img src={methodImgUrl(method)} alt={method.visibleName} />
					<span>{method.visibleName}</span>
					{method.transactionCosts && (
						<div>
							{`${moneyComposer(method.transactionCosts.unitPrice)} + ${percentageComposer(method.transactionCosts.percentage)}`}
						</div>
					)}
				</div>
			)}
		</StyledCheckoutMethodsSection>
    );
};

CheckoutMethodsSection.propTypes = {
	isMain: PropTypes.bool.isRequired,
	method: PropTypes.object.isRequired,
	onMethodClick: PropTypes.func.isRequired,
};

export default CheckoutMethodsSection
