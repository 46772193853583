import { MenuItem, Select, styled, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { flatten } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import withFetchData from '../../modules/fetchData/withFetchData';
import { CALL_API } from '../../setup/api';
import logoKransCut from "../../style/images/logo_krans_cut.png";
import Loader from '../loader';
import CourseBundleSelectDialog from './CourseBundleSelectDialog';
import CourseListItem from './CourseListItem';
import CourseNone from './CourseNone';
import { UNGROUPED } from "./Courses";

const PREFIX = 'CourseList';

const classes = {
    topBar: `${PREFIX}-topBar`,
    topBarRight: `${PREFIX}-topBarRight`,
    select: `${PREFIX}-select`,
    courses: `${PREFIX}-courses`,
    resetFilter: `${PREFIX}-resetFilter`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.topBar}`]: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(2),
	},

    [`& .${classes.topBarRight}`]: {
		display: 'none', // TODO: Decide sorting options
		alignItems: 'center',
	},

    [`& .${classes.select}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(2),
		minWidth: '12rem',
		'& .MuiSelect-root': {
			padding: theme.spacing(1),
		},
	},

    [`& .${classes.courses}`]: {
		'& .item-enter': {
			opacity: 0,
		},
		'& .item-enter-active': {
			opacity: 1,
			transition: 'all 150ms ease-in',
		},
		'& .item-exit': {
			opacity: 1,
		},
		'& .item-exit-active': {
			opacity: 0,
			transition: 'all 150ms ease-in',
			transform: 'translateX(1rem)',
		}
	},

    [`& .${classes.resetFilter}`]: {
		cursor: 'pointer',
	}
}));

const apiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'BUNDLES',
		endpoint: `athena-course-bundles/${scopeId}`,
	},
});

const StyledGroupBar = styled(({ children, ...props }) => (
	<div {...props}>
		{children}
		<div className="wreath" />
	</div>
))(({ theme }) => ({
	position: 'relative',
	color: theme.palette.common.white,
	fontSize: '1.2rem',
	backgroundColor: theme.palette.primary.main,
	backgroundImage: `linear-gradient(270deg, ${theme.palette.primaryGradient1} 0.5%, ${theme.palette.primaryGradient2} 100%)`,
	borderRadius: theme.shape.borderRadiusCards,
	padding: theme.spacing(1, 2),
	margin: theme.spacing(2, 0),
	'& .wreath': {
		position: 'absolute',
		zIndex: 1,
		right: 0,
		top: 0,
		bottom: 0,
		width: '40%',
		background: `url(${logoKransCut}) no-repeat center right`,
		backgroundSize: 'contain',
	},
}))

const CourseList = ({ filteredAndGroupedScopes, coursePathCreator, study, totalCourses, resetFilter, data: bundles, loading, load }) => {

	const { t } = useTranslation();

	useEffect(() => {
		if(!bundles) load(apiAction(study.id));
	}, [bundles, study.id]);

	const handleChangeSort = (event) => {
		console.log(event);
	}

	const [activeBundle, setActiveBundle] = useState(null);

	const handleSelectBundle = useCallback((bundle) => {
		setActiveBundle(bundle);
	}, [setActiveBundle]);

	const handleCloseBundle = useCallback(() => {
		setActiveBundle(null);
	}, [setActiveBundle]);

	if (!filteredAndGroupedScopes?.grouped || !bundles || loading) {
		return <Loader />
	}

	return (
        <>
            {activeBundle && (
				<CourseBundleSelectDialog study={study} bundle={activeBundle} onClose={handleCloseBundle} />
			)}
            <StyledGrid item xs={12} md={9}>
				<div className={classes.topBar}>
					<div>
						<strong>
							{t('course.results', {count: filteredAndGroupedScopes.count})}
						</strong>
					</div>
					<div className={classes.topBarRight}>
						<span>{t('course.sortBy.label')}</span>
						<Select
							value="name"
							disabled
							onChange={handleChangeSort}
							variant="outlined"
							className={classes.select}
						>
							<MenuItem value="name">{t('course.sortBy.name')}</MenuItem>
						</Select>
					</div>
				</div>
				{filteredAndGroupedScopes.count === 0 && (
					totalCourses === 0 ? (
						<CourseNone scope={study} />
					) : (
						<Trans i18nKey="course.noResults">
							Geen resultaten binnen de opgegeven zoekcriteria. <a onClick={resetFilter} className={classes.resetFilter}>Klik om de filter te herstellen</a>.
						</Trans>
					)
				)}
				{filteredAndGroupedScopes.count > 0 && (
					<TransitionGroup className={classes.courses}>
						{flatten(filteredAndGroupedScopes.grouped.map((group) => {
							const groupTitle = group[0];
							const groupCourses = group[1];
							// If the only found group is titled UNGROUPED, do not display the group title bar
							const singleGroup = filteredAndGroupedScopes.grouped.length === 1 && filteredAndGroupedScopes.grouped[0][0] === UNGROUPED;
							return (
								(!singleGroup ? [
									<CSSTransition
										timeout={150}
										classNames="item"
										key={groupTitle}
									>
										<StyledGroupBar>
											{groupTitle === UNGROUPED ? t('course.group.others') : groupTitle}
										</StyledGroupBar>
									</CSSTransition>
								] : []).concat(groupCourses.map(course => (
									<CSSTransition
										timeout={150}
										classNames="item"
										key={course.id}
									>
										<CourseListItem
											course={course}
											filteredCourses={groupCourses}
											study={study}
											coursePathCreator={coursePathCreator}
											bundles={bundles}
											onSelectBundle={handleSelectBundle}
											mode='list'
										/>
									</CSSTransition>
								)))
							);
						}))}
					</TransitionGroup>
				)}
				{totalCourses > 0 && <CourseNone scope={study} noWarning />}
			</StyledGrid>
        </>
    );
}

CourseList.propTypes = {
	filteredAndGroupedScopes: PropTypes.object,
	study: PropTypes.object.isRequired,
	coursePathCreator: PropTypes.func.isRequired,
	totalCourses: PropTypes.number.isRequired,
	resetFilter: PropTypes.func.isRequired,
};

export default withFetchData(undefined, {
	customId: (props) => `bundles-${props.study.id}`,
	keepData: true,
})(CourseList);
