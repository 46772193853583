import { Grid, styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { moneyComposer } from './../../helpers/composers'
import BasketDiscount from './BasketDiscount'
import StyledBox from "./StyledBox";

const classes = {
	discount: 'BasketTotal-discount',
}

const StyledTotalPrice = styled('div')(({ theme }) => ({
	textAlign: 'right',
	fontSize: '18px',
	color: theme.palette.grey[900],
	paddingTop: '6px',

	[`& .${classes.discount}`]: {
		fontSize: '85%',
		textDecoration: 'line-through',
	},

	'& span': {
		marginLeft: '20px',
		fontWeight: '600',
	},

	[theme.breakpoints.down('md')]: {
		marginBottom: '20px',
		textAlign: 'left',

		[`& .${classes.discount}`]: {
			textAlign: 'right',
		},

		'& span': {
			float: 'right',
		}
	}
}));


const BasketTotal = ({ basket, removeDiscount }) => {
	const { t } = useTranslation();
	return (
		<StyledBox>
			<Grid container columnSpacing={2} sx={{ flexDirection: { sm: 'row', xs: 'column-reverse' } }}>
				<Grid item xs={12} sm={7} md={6}>
					<BasketDiscount basket={basket} removeDiscount={removeDiscount} />
				</Grid>
				<Grid item xs={12} sm={5} md={6}>
					<StyledTotalPrice>
						{basket.totalPrice.amount !== basket.priceWithoutDiscount.amount && (
							<div className={classes.discount}>
								{moneyComposer(basket.priceWithoutDiscount)}
							</div>
						)}
						{t('checkout.basket.total')} <span>{moneyComposer(basket.totalPrice)}</span>
					</StyledTotalPrice>
				</Grid>
			</Grid>
		</StyledBox>
	);
};

BasketTotal.propTypes = {
	basket: PropTypes.object.isRequired,
	removeDiscount: PropTypes.func.isRequired,
};

export default BasketTotal
