import { Button, InputLabel, styled, TextField, Typography, Grid, Alert } from "@mui/material";
import React from 'react'
import { withTranslation } from 'react-i18next'
import FormData, { errorRenderer, errorRendererField } from './../../hoc/FormData'
import SimpleState from './../../hoc/SimpleState'
import { sendResetPassword } from './../../actions/auth'
import Loader from './../loader'

const sendResetPasswordEnhanced = (data) => sendResetPassword(data.email);

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

const PasswordForgotForm = ({ fields, onChangeField, saving, error, success, handleSubmit, t }) => {
	if (success) return (
		<Alert severity="success" icon={false}>
			{t('password.forgot.form.success_text')}
		</Alert>
	);

    return (
        <form onSubmit={handleSubmit(() => fields, sendResetPasswordEnhanced)}>
			<Typography paragraph>{t('password.forgot.form.text')}</Typography>
			{
				error && (
					error.errors && error.errors.email ?
						<Alert severity="warning" icon={false}>{errorRendererField(error, 'email')}</Alert>
						:
						errorRenderer(error.errorCode === 400 ? {
							error: t('password.forgot.error_email_not_exist')
						} : error)
				)
			}
			{saving && <Loader>
				{t('password.forgot.form.loading')}
			</Loader>}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<StyledInputLabel htmlFor="forgot-email">
						{t('password.forgot.form.email')}
					</StyledInputLabel>
					<TextField
						id="forgot-email"
						name="email"
						placeholder={t('password.forgot.form.email')}
						fullWidth
						value={fields.email}
						onChange={onChangeField}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						type="submit"
						color="info"
						variant="contained"
						disabled={saving}
						fullWidth
					>
						{t('password.forgot.form.button_request')}
					</Button>
				</Grid>
			</Grid>
		</form>
    );
};

PasswordForgotForm.propTypes = {

};

export default withTranslation()(FormData(SimpleState(PasswordForgotForm, {
	fields: ['email']
}), 'passwordForgotForm', {

}));
