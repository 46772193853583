import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { path as objPath } from 'ramda';

import { useDispatch, useSelector } from 'react-redux';
import HeaderUspList from './components/header/HeaderUspList';

import { routes as appRoutes, path } from './routes';
import { LocaleGetLanguage, getCustomLocaleMessage } from './helpers/locale';
import { dateIso } from './helpers/datetime';
import { getRootScopeTitle } from './helpers/scope';
import AuthProvider from './hoc/AuthProvider';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/general/ScrollToTop';
import PartnerStyle from './components/partner/PartnerStyle';
import ErrorModal from './components/error/ErrorModal';
import SiteTheme from './components/general/theme/SiteTheme';
import { locales } from './selectors/i18n';

import og8 from './style/images/og-8.png';
import og8Square from './style/images/og-8-square.png';
import msOg0 from './style/images/ms-og-0.png';
import msOg0Square from './style/images/ms-og-0-square.png';
import gmatOg0 from './style/images/gmat-og-0.png';
import gmatOg0Square from './style/images/gmat-og-0-square.png';
import { rootScopeAlternateSitesSelector, rootScopeSelector } from './selectors/scope';
import QueryHandler from './hoc/QueryHandler';
import PartnerHandler from './hoc/PartnerHandler';
import { getLangAndPath, getRouterPath } from './helpers/path';
import { changeLocale } from './reducers/i18n';

const buildDateTime = process.env.BUDDY_EXECUTION_START_DATE;

const defaultKeywords = 'athenastudies,athena studies,athena tentamentraining,athena bijles,athena cursus,athena studie,athena,athene studies,pallas athena group,athena samenvatting,athena summary,tentamentraining uva,athena study,bijles ,cursus,tentamentraining,universiteit,studenten,CapitaSelecta,Capita Selecta,studeersnel,studeer snel,slimstuderen,slim studeren,bijlespartner,dominus,tentamentraining rechten,tentamentraining geneeskunde,tentamentraining international business ,tentamentraining bedrijfskunde,tentamentraining economie,tentamentraining psychologie,tentamentraining rechtsgeleerdheid,tentamentraining premaster,amsterdam,rotterdam,delft,tilburg,utrecht,eindhoven,groningen,maastricht,leiden,nijmegen,wageningen,zwolle,rechten,bedrijfskunde,premaster,international business,werktuigbouwkunde,international business administration,geneeskunde,european studies,economie,economics,studie (gehele aanbod),athenastudies rotterdam,athenastudies amsterdam,athenstudies leiden,athenastudies nijmegen,athenastudies groningen,athenastudies maastricht,athenastudies tilburg,athenastudies eindhoven,athenastudies delft,athenastudies zwolle,athenastudies wageningen,athenastudies utrecht,bijles rechten,bijles  bedrijfskunde,bijles  premaster,bijles  international business,bijles  werktuigbouwkunde,bijles  international business administration,bijles  geneeskunde,bijles  european studies,bijles  economie,bijles  economics,erasmus universiteit rotterdam,haagse hogeschool,hanzehogeschool,hogeschool leiden,hogeschool rotterdam,hogeschool utrecht,hogeschool van amsterdam,radboud universiteit,rijksuniversiteit groningen,technische universiteit delft,technische universiteit eindhoven,the new school,tilburg university,tmo fashion business school,universiteit leiden,universiteit maastricht,universiteit twente,universiteit utrecht,universiteit van amsterdam,vrije universiteit amsterdam,wageningen university,windesheim zwolle';

function makeFullyQualified(domain) {
    return domain.indexOf('http') === 0 ? domain : `https://${domain}`;
}

// Require some old OG images to keep them in the build data at clean deploys
import('./style/images/og-1.png');
import('./style/images/og-1-square.png');

import('./style/images/og-7.png');
import('./style/images/og-7-square.png');

// Studies default
import('./style/images/og-8.png');
import('./style/images/og-8-square.png');

// MS default
import('./style/images/ms-og-0.png');
import('./style/images/ms-og-0-square.png');

// GMAT default
import('./style/images/gmat-og-0.png');
import('./style/images/gmat-og-0-square.png');

const ogImages = {
    default: [og8, og8Square],
    ms: [msOg0, msOg0Square],
    gmat: [gmatOg0, gmatOg0Square],
};

function getOgImage(rootScope, index) {
    const custom = objPath(['properties', 'config', 'customLogo'], rootScope);

    return ogImages[custom] ? ogImages[custom][index] : ogImages.default[index];
}

const StyledPageRoot = styled(Box)(({ theme }) => ({
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
}));

const StyledPageWrap = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});

const initSelector = state => state.init;

function Root({ user }) {
    const location = useLocation();
    const rootScope = useSelector(rootScopeSelector);
    const { partner, rootDomain } = useSelector(initSelector);
    const alternateSites = useSelector(rootScopeAlternateSitesSelector);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    // TODO: try to find a better way for this, used to do this through route definition in router v3
    const current = {
        noHeader: matchPath({
            path: appRoutes.EVALUATION,
            end: true,
        }, location.pathname) !== null,
    };

    const meta = {
        email: objPath(['properties', 'meta', 'email'], rootScope) || 'info@athenastudies.nl',
        description: objPath(['properties', 'meta', 'description'], rootScope),
        ogDescription: objPath(['properties', 'meta', 'ogDescription'], rootScope),
        defaultTitleAddon: objPath(['properties', 'meta', 'defaultTitleAddon'], rootScope),
        defaultOgTitleAddon: objPath(['properties', 'meta', 'defaultOgTitleAddon'], rootScope),
        keywords: objPath(['properties', 'meta', 'keywords'], rootScope) || objPath(['properties', 'keywords'], rootScope),
    };

    const domain = rootDomain.indexOf('localhost') === 0
        ? 'http://localhost'
        : objPath(['properties', 'config', 'baseUrl'], rootScope) || 'https://athenastudies.nl';

    const linkTags = useMemo(() => locales.map(item => {
        const countryTag = rootScope.locale?.substring(3) || 'NL';
        const routerPath = getRouterPath(i18n, location.pathname, item.locale);
        const href = `${domain}${routerPath}`;
        return [
            <meta property="og:locale:alternate" content={`${item.locale}_${countryTag}`} key={`${item.locale}_${countryTag}`} />,
            <link rel="alternate" href={href} hrefLang={`${item.locale}-${countryTag}`} key={`${item.locale}-${countryTag}`} />,
        ];
    }), [i18n, location.pathname]);

    const rootScopeTitle = getRootScopeTitle(rootScope);
    const { headerUspList = [] } = rootScope.properties;

    useEffect(() => {
        if (i18n.language) {
            moment.locale(i18n.language);
        }
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.athena !== 'undefined') {
            // Trigger a user update for the public api defined in AppEntryClient
            window.athena.dispatchCurrentUserUpdate();
        }
    }, [user]);

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.athena !== 'undefined') {
            // Trigger a locale update
            window.athena.dispatchLocaleUpdate();
        }
    }, [i18n.language]);

    useEffect(() => {
        const { lang } = getLangAndPath(i18n, location.pathname);
        if (i18n.language !== lang) {
            const skipSave = user?.locale?.substring(0, 2) === lang;
            dispatch(changeLocale(lang, skipSave));
        }
    }, [location.pathname, i18n]);

    useEffect(() => {
        if (!user) return;
        const { lang, path: relPath } = getLangAndPath(i18n, location.pathname);
        const userLocalePreference = user.locale ? user.locale.substring(0, 2) : undefined;
        if (relPath === '/' && userLocalePreference && userLocalePreference !== lang) {
            const routerPath = getRouterPath(i18n, appRoutes.HOME, userLocalePreference, true, params);
            navigate(`${routerPath}?${location.search}`);
        }
    }, []);

    return (
        <div>
            {!location && (
                <>
                    <QueryHandler />
                    <PartnerHandler />
                </>
            )}
            <Helmet
                defaultTitle={`${rootScopeTitle} - ${meta.defaultTitleAddon ? getCustomLocaleMessage(meta.defaultTitleAddon, i18n) : t('root.title')}`}
                titleTemplate={`${rootScopeTitle} - %s`}
                htmlAttributes={{ lang: i18n.language || LocaleGetLanguage(rootScope.locale) }}
            >
                <meta name="description" content={meta.description ? getCustomLocaleMessage(meta.description, i18n) : t('root.seo_description')} />
                <meta name="keywords" content={meta.keywords || defaultKeywords} />

                {alternateSites.filter(rootSite => rootSite.id !== rootScope.id).map(rootSite => (
                    <link rel="alternate" hrefLang={rootSite.locale} href={makeFullyQualified(rootSite.domains[0])} key={rootSite.locale} />
                ))}
                <link rel="alternate" hrefLang="x-default" href="https://athenastudies.nl" />

                {(domain.indexOf('localhost') > -1 || domain.indexOf('staging.') > -1 || domain.indexOf('-stage.') > -1) && (
                    <meta name="robots" content="noindex" />
                )}

                <meta property="og:title" content={`${rootScopeTitle} - ${meta.defaultOgTitleAddon ? getCustomLocaleMessage(meta.defaultOgTitleAddon, i18n) : t('root.og_title')}`} />
                <meta property="og:description" content={meta.ogDescription ? getCustomLocaleMessage(meta.ogDescription, i18n) : t('root.og_description')} />
                <meta property="og:type" content="website" />

                {Boolean(buildDateTime) && (
                    <meta property="og:updated_time" content={dateIso(buildDateTime)} />
                )}

                <link rel="alternate" hrefLang={LocaleGetLanguage(rootScope.locale)} href={domain} />
                {linkTags}

                <meta property="og:image" content={domain + getOgImage(rootScope, 0)} />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                <meta property="og:image" content={domain + getOgImage(rootScope, 1)} />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="400" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'http://schema.org',
                        '@type': 'Organization',
                        url: domain || 'https://athenastudies.nl/',
                        name: rootScopeTitle,
                        logo: domain + getOgImage(rootScope, 1),
                        email: meta.email,
                        sameAs: [
                            'https://www.facebook.com/AthenaStudiesStudiehulp/',
                        ],
                        contactPoint: {
                            '@type': 'ContactPoint',
                            contactType: 'customer service',
                            email: meta.email,
                            url: domain + path(i18n, appRoutes.STATIC.CONTACT),
                        },
                    })}
                </script>
            </Helmet>
            <PartnerStyle partner={partner} />
            <ScrollToTop />
            <StyledPageRoot>
                {headerUspList.length > 0 && (
                    <HeaderUspList items={headerUspList} />
                )}
                <StyledPageWrap>
                    {!current.noHeader && (
                        <Header />
                    )}
                    <Outlet />
                </StyledPageWrap>
                <Footer />
            </StyledPageRoot>
            <SiteTheme />
            <ErrorModal />
        </div>
    );
}

export default AuthProvider(Root);
