import { ExpandLess, ExpandMore, Schedule, List } from "@mui/icons-material";
import { Button, Container, Grid, Collapse, Box, styled, Typography } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { append } from 'ramda'
import { useTranslation } from 'react-i18next'
import routes, { path } from '../../routes'
import SectionTitle from './../general/section-title'
import { dateNormal, time } from '../../helpers/datetime'
import { Link } from 'react-router-dom'

const getParentArray = (scope) => {
	if(!scope || scope.class === 'Company') return [];
	return append(scope.name, getParentArray(scope.parent));
};

const getParentString = (scope) => {
	return getParentArray(scope).join(' > ');
};

const StyledCourseProperty = styled(Box)(({theme}) => ({
	padding: theme.spacing(0.25, 0, 0.5),
	display: 'flex',
	alignItems: 'center',
}));

const StyledSlotTeacherBox = styled(Box)(({ theme }) => ({
	fontStyle: 'italic',
	fontSize: theme.typography.fontSize,
	lineHeight: 'normal',
	color: theme.palette.grey[700],
	marginBottom: theme.spacing(0.75),
}));

const AccountCourse = ({ index, course, open, onClick }) => {
	const { t, i18n } = useTranslation();
	const courseDescriptionId = `${index}_course`;
    return (
        <Container sx={{ mb: 2.5 }}>
			<Button
				onClick={() => onClick(index)}
				sx={{
					fontSize: '1rem',
					'& span:first-of-type': {
						flex: 1, textAlign: 'left',
					}
				}}
				endIcon={open ? <ExpandLess /> : <ExpandMore />}
				fullWidth
				color="primary"
				variant="contained"
				aria-expanded={open}
				aria-controls={courseDescriptionId}
			>
				<span>{course.parent.name}</span>
			</Button>
			<Collapse in={open} sx={{ backgroundColor: 'grey.200' }} id={courseDescriptionId}>
				<Grid container columnSpacing={2} padding={2}>
					<Grid item xs={12} sm={7} md={8}>
						<Box sx={{ color: 'grey.500' }}>
							{getParentString(course.parent.parent)}
						</Box>
						<Box
							sx={{ my: 1.25 }}
							className="editor"
							dangerouslySetInnerHTML={{__html: course.parent.description}}
						/>
					</Grid>
					<Grid item xs={12} sm={5} md={4}>
						<StyledCourseProperty>
							<List fontSize="small" sx={{ mr: 1, color: 'grey.600', mt: '-2px' }} />
							{course.name}
						</StyledCourseProperty>
						{course.courseStart && (
							<StyledCourseProperty>
								<Schedule fontSize="small" sx={{ mr: 1, color: 'grey.600', mt: '-2px' }} />
								{dateNormal(course.courseStart)} {t('account.courses.slot.at')} {time(course.courseStart)}
							</StyledCourseProperty>
						)}
						{course.evaluationFormToken && (
							<Button
								component={Link}
								to={`${path(i18n, routes.EVALUATION)}?token=${course.evaluationFormToken}`}
								color="cta"
								variant="contained"
								sx={{ mb: 2 }}
							>
								{t('account.courses.evaluate_button')}
							</Button>
						)}
					</Grid>
				</Grid>
				<Grid container padding={2} justifyContent="center">
					<Grid item>
						<SectionTitle>{t('account.courses.course_dates')}</SectionTitle>
						{course.numTimeSlots === 0 && (
							<em>{t('account.courses.no_course_dates')}</em>
						)}
					</Grid>
				</Grid>
				{course.timeSlots.map(slot => (
					<Box sx={{ mt: 2 }} key={slot.id}>
						<Grid container padding={2}>
							<Grid item xs={12} sm={6} md={4}>
								<Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}>
									{dateNormal(slot.startDateTime)} {t('account.courses.slot.at')} {time(slot.startDateTime)} - {time(slot.endDateTime)}
								</Typography>
								{slot.teacher && (
									<StyledSlotTeacherBox>
										{t('account.courses.slot.given_by')} {slot.teacher}
									</StyledSlotTeacherBox>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={8}>
								{(slot.hasZoomMeeting || slot.zoomMeetingForwardUrl) ? (
									slot.zoomMeetingForwardUrl ? (
										<Button
											component="a"
											href={slot.zoomMeetingForwardUrl}
											target="_blank"
											referrerPolicy="no-referrer"
											color="cta"
											size="small"
											variant="contained"
										>
											{t('account.courses.slot.goto_online_classroom')}
										</Button>
									) : (
										t('account.courses.slot.online_classroom_no_url')
									)
								) : (
									slot.location
								)}
							</Grid>
						</Grid>
					</Box>
				))}
			</Collapse>
		</Container>
    );
};

AccountCourse.propTypes = {
	index: PropTypes.number.isRequired,
	course: PropTypes.object.isRequired,
	open: PropTypes.bool,
	onClick: PropTypes.func.isRequired
};

export default AccountCourse;
