// These are the route definitions as passed through the application
// The path helpers might translate them to another company language
// NOTE: every path is assumed to be unique
// NOTE: optional parameters are currently not supported. getCurrentPathPattern() creates the path pattern based on
// useParams, but useParams does not provide info if these params are optional and add the required question mark in the url: `/:param?`
export const baseRoutes = {
    HOME: '/',
    // TODO: Probably introduce one route to rule them all
    // COURSES: '/cursus/:institute?/:study?/:courseId?',
    PARTNER: '/partner',

    COURSES: '/training',
    INSTITUTE: '/training/:institute',
    FACULTY: '/training/:institute/:faculty',
    STUDY: '/training/:institute/:faculty/:study',
    COURSE: '/training/:institute/:faculty/:study/:courseId',

    COURSES_OLD: '/cursus',
    INSTITUTE_OLD: '/cursus/:institute',
    STUDY_OLD: '/cursus/:institute/:study',

    CHECKOUT: '/afrekenen',
    RETURN: '/afronden/:orderId',

    PAY: '/betalen',
    PAY_METHOD_BASE: '/betalen/:methodId',
    PAY_METHOD_SUB: '/betalen/:methodId/:methodSubId',

    ACCOUNT: '/mijn',
    ACCOUNT_EDIT: '/mijn/bewerken',
    ACCOUNT_EDIT_ADDRESS: '/mijn/bewerken-adres',
    LOGIN: '/inloggen',
    LOGOUT: '/uitloggen',
    REGISTER: '/registreren',
    PASSWORD_FORGOT: '/wachtwoord',
    PASSWORD_RESET: '/wachtwoordvergeten/:token',

    FAQ: '/faq',
    STATIC: {
        TERMS: '/voorwaarden',
        PRIVACY: '/privacy',
        COOKIES: '/cookies',
        ABOUT: '/over',
        VACANCIES: '/vacatures',
        VACANCIES_MANAGER: '/vacatures/fm',
        VACANCIES_TEACHER: '/vacatures/docent',
        VACANCIES_BUSINESS: '/vacatures/business',
        VACANCIES_GROWTH: '/vacatures/growth-hacker',
        VACANCIES_FRONT: '/vacatures/front-end',
        VACANCIES_JAVA: '/vacatures/medior-java',
        VACANCIES_FULL: '/vacatures/full-stack',
        SCHOOL_EXAM: '/onze-examentraining',
        SCHOOL_ABOUT: '/visie',
        SCHOOL_BIJLES: '/bijles',
        SCHOOL_HUISWERKBEGELEIDING: '/huiswerkbegeleiding',
        CONTACT: '/contact',
    },

    EVALUATION: '/evaluation',
};

export const languageRoutes = {
    nl: baseRoutes,
    en: {
        ...baseRoutes,

        COURSES_OLD: '/courses',
        INSTITUTE_OLD: '/courses/:institute',
        STUDY_OLD: '/courses/:institute/:study',

        CHECKOUT: '/checkout',
        RETURN: '/return/:orderId',
        PAY: '/pay',
        PAY_METHOD_BASE: '/pay/:methodId',
        PAY_METHOD_SUB: '/pay/:methodId/:methodSubId',

        ACCOUNT: '/my',
        ACCOUNT_EDIT: '/my/edit',
        ACCOUNT_EDIT_ADDRESS: '/my/edit-address',
        LOGIN: '/login',
        LOGOUT: '/logout',
        REGISTER: '/register',
        PASSWORD_FORGOT: '/password',
        PASSWORD_RESET: '/forgotpassword/:token',

        STATIC: {
            ...baseRoutes.STATIC,
            TERMS: '/terms',
            PRIVACY: '/privacy',
            COOKIES: '/cookies',
            DISCLAIMER: '/disclaimer',
            ABOUT: '/about',
            CONTACT: '/contact',
        },
    },
    de: {
        ...baseRoutes,

        COURSES_OLD: '/kurse',
        INSTITUTE_OLD: '/kurse/:institute',
        STUDY_OLD: '/kurse/:institute/:study',

        CHECKOUT: '/kasse',
        RETURN: '/return/:orderId',
        PAY: '/zahlen',
        PAY_METHOD_BASE: '/zahlen/:methodId',
        PAY_METHOD_SUB: '/zahlen/:methodId/:methodSubId',

        ACCOUNT: '/mein',
        ACCOUNT_EDIT: '/mein/bearbeiten',
        ACCOUNT_EDIT_ADDRESS: '/mein/bearbeiten-adresse',
        LOGIN: '/einloggen',
        LOGOUT: '/ausloggen',
        REGISTER: '/registrieren',
        PASSWORD_FORGOT: '/passwort',
        PASSWORD_RESET: '/passwortvergessen/:token',

        STATIC: {
            ...baseRoutes.STATIC,
            TERMS: '/bedingungen',
            PRIVACY: '/datenschutzerklarung',
            COOKIES: '/cookies',
            DISCLAIMER: '/haftungsausschluss',
            ABOUT: '/uber',
            CONTACT: '/contact',
        },
    },
};
