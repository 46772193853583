import { styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'

const ScopeInfo = styled(({ children, ...props }) => {
	return (
		<div {...props}>
			{children}
		</div>
	);
})(({ theme }) => ({
	fontSize: '16px',
	color: theme.palette.grey[700],
	[theme.breakpoints.up('sm')]: {
		padding: '24px 20px 10px',
	},
	[theme.breakpoints.up('md')]: {
		padding: '24px 50px 10px',
	},
}));

ScopeInfo.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ScopeInfo
