import { useSelector } from 'react-redux'
import { rootScopeSelector } from '../../selectors/scope'

/**
 * @return {rootScopeProperties}
 */
const useRootScopeProperties = () => {
	return useSelector(rootScopeSelector)?.properties || {};
};

export default useRootScopeProperties
