import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from 'react'
import { useTranslation } from 'react-i18next'
import Content from '../components/content/Content'
import HomePageTheming from '../components/general/theme/HomePageTheming'
import HomePageWidget from '../components/home/widgets/HomePageWidget'
import { getCustomLocaleMessage } from "../helpers/locale";
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties'

import Block from './../components/home/block'
import Picker from '../components/home/Picker'
import Percentage  from './../components/home/percentage'

const StyledHeaderWrapper = styled('div')({
	width: '100%',
	maxWidth: '850px',
	margin: '0 auto',
});

const StyledWidgetWrapper = styled('div')(({ theme }) => ({
	background: theme.palette.common.white,
	paddingTop: theme.spacing(6),
	boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.09)',
	border: 'solid 1px rgba(151, 151, 151, 0.13)',
}));

const HomePage = () => {
	const { t, i18n } = useTranslation();

	const { widgets, config } = useRootScopeProperties();

	const hasWidgets = Array.isArray(widgets) && widgets.length > 0;

	return (
		<>
            <Content noContainer noSheet>
				<Content.Header>
					<StyledHeaderWrapper>
						<Block title={t('home.block.title')}>
							{t('home.block.text')}
						</Block>
						{(!config || !config.hideScopePicker) && (
							<Picker />
						)}
						{(!config || !config.hideSuccessRate) && (
							<Percentage>{t('home.percentage')}</Percentage>
						)}
					</StyledHeaderWrapper>
				</Content.Header>
				{hasWidgets && (
					<StyledWidgetWrapper>
						<Container>
							{Array.isArray(widgets) && widgets.map((widget, i) => (
								<HomePageWidget
									{...widget}
									key={`${i}-${getCustomLocaleMessage(widget.title, i18n)}`}
								/>
							))}
						</Container>
					</StyledWidgetWrapper>
				)}
			</Content>
            <HomePageTheming />
        </>
    );
};

export default HomePage
