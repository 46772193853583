import React, { createContext, useContext } from "react";

export const RedirectContext = createContext(null);

export function RedirectContextProvider({ children, context }) {
	return (
		<RedirectContext.Provider value={context}>{children}</RedirectContext.Provider>
	);
}

/**
 * @typedef {Object} RedirectContext
 * @property {string} url - url to direct to
 * @returns {RedirectContext}
 */
export const useRedirectContext = () => {
	return useContext(RedirectContext);
};
