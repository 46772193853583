import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from 'react-redux'
import AppEntry from './AppEntry'
import { localeSelector } from './selectors/i18n'
import { userMeIdSelector, userMeSelector } from './selectors/user'
import ClientRouter from './ClientRouter';

function createPublicApi(store){
	const EVENTS = {
		userUpdate: 'userUpdate',
		localeUpdate: 'localeUpdate',
	};

	const userUpdateEvent = new Event(EVENTS.userUpdate);
	const localeUpdateEvent = new Event(EVENTS.localeUpdate);

	return {
		// User related APIs
		getCurrentUserId: () => {
			return userMeIdSelector(store.getState()) || null;
		},
		getCurrentUser: () => {
			const user = userMeSelector(store.getState()) || null;
			if(!user) return null;

			return {
				id: user.id,
				email: user.email,
				phone: user.mobilePhone,
				name: user.fullName,
				firstName: user.name,
				lastName: user.surname,
			};
		},
		dispatchCurrentUserUpdate: () => {
			document.dispatchEvent(userUpdateEvent);
		},
		onCurrentUserUpdate: (cb) => {
			document.addEventListener(EVENTS.userUpdate, cb);
		},

		// Language related APIs
		getCurrentLocale: () => {
			const state = store.getState();
			return localeSelector(state) || state.init.rootLocale || 'en';
		},
		dispatchLocaleUpdate: () => {
			document.dispatchEvent(localeUpdateEvent);
		},
		onLocaleUpdate: (cb) => {
			document.addEventListener(EVENTS.localeUpdate, cb);
		},
	};
}

const AppEntryClient = ({ store, deviceType }) => {
	useEffect(() => {
		window.athena = createPublicApi(store);
	}, []);

	return (
		<ReduxProvider store={store}>
			<HelmetProvider>
				<AppEntry deviceType={deviceType}>
					<ClientRouter />
				</AppEntry>
			</HelmetProvider>
		</ReduxProvider>
	);
}

AppEntryClient.propTypes = {
	store: PropTypes.any.isRequired,
};

export default AppEntryClient
