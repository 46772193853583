import { chain, fromPairs, map, toPairs } from 'ramda';
import { fallbackRootLocale } from '../setup/config';

export const LocaleGetLanguage = (locale) => {
    // eslint-disable-next-line no-param-reassign
    if (!locale) locale = fallbackRootLocale; // This should never happen

    return locale.replace('_', '-').split('-')[0].toLowerCase();
};

export const GetAccountLocaleFromLanguage = (language, rootLocale) => `${language}_${rootLocale.substr(3)}`;

/**
 * Resolve a custom string input from the server to the correct text for the given locale
 *
 * @param {translatableString} message - the message string, or object with strings for various locales
 * @param i18n - the i18n object
 * @returns {string}
 */
export function getCustomLocaleMessage(message, i18n) {
    if (!message) return '';
    if (typeof message === 'string') return message;
    return message[i18n.language] || message[i18n.options.fallbackLng[0]] || Object.values(message)[0] || '';
}

/**
 * Loops through an object and replaces the locale message object (i.e. { en: 'title', nl: 'titel' })
 * with the string of the current language
 *
 * @param { Object } obj - the message string, or object with strings for various locales
 * @param i18n - the i18n object
 * @param props - (nested) props to be translated
 * @returns { Object }
 */
export function flattenLanguageProps(obj, i18n, props = []) {
    const iterate = obj_ => chain(([k, v]) => {
        if (props.includes(k)) {
            return [[k, getCustomLocaleMessage(v, i18n)]];
        }
        if (Array.isArray(v)) {
            return [[k, map(v_ => fromPairs(iterate(v_)), v)]];
        }
        if (typeof v === 'object') {
            return [[k, fromPairs(iterate(v))]];
        }
        return [[k, v]];
    }, toPairs(obj_));
    return fromPairs(iterate(obj));
}
