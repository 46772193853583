import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @deprecated
 * This is a replacement for react-router-dom's deprecated withRouter to keep older (HOC) component functioning.
 * This should eventually be phased out, so don't use this HOC in new components.
 */
function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		return (
			<Component
				location={location}
				navigate={navigate}
				params={params}
				{...props}
			/>
		);
	}

	return ComponentWithRouterProp;
}

export default withRouter;
