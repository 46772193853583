import moment from 'moment';
import { LocaleGetLanguage } from '../helpers/locale';

export const defaultLanguage = 'nl';

const locales = {
    nl: import('../../locales/translation_nl.json'),
    en: import('../../locales/translation_en.json'),
    de: import('../../locales/translation_de.json'),
};

const LanguageCookieName = 'language';
const StudyLocaleAnswerCookieName = 'studyLocaleAnswer';

const I18N_CHANGE_LOCALE = 'I18N_CHANGE_LOCALE';
const I18N_CHANGE_LOCALE_AUTO = 'I18N_CHANGE_LOCALE_AUTO';
const I18N_HIDE_SAVE_LOCALE_DIALOG = 'I18N_HIDE_SAVE_LOCALE_DIALOG';
const I18N_TOGGLE_STUDY_LOCALE_PROMPT = 'I18N_TOGGLE_STUDY_LOCALE_PROMPT';

const I18N_SOURCE_ROOT = 'root';
const I18N_SOURCE_ROUTE = 'route';
const I18N_SOURCE_COOKIE = 'cookie';
const I18N_SOURCE_CHANGE = 'change';
export const I18N_SOURCE_ACCOUNT_LOGIN = 'account_login';
export const I18N_SOURCE_ACCOUNT_ME = 'account_me';
export const I18N_SOURCE_STUDY = 'study';
export const I18N_SOURCE_EVALUATION = 'evaluation';

export const changeLocale = (locale, skipSave) => ({
    type: I18N_CHANGE_LOCALE,
    locale,
    skipSave,
});

export const changeLocaleAuto = (locale, source) => ({
    type: I18N_CHANGE_LOCALE_AUTO,
    locale,
    source,
});

export const hideLocaleSaveDialog = () => ({
    type: I18N_HIDE_SAVE_LOCALE_DIALOG,
});

export const toggleStudyLocalePrompt = (showPrompt, answer = undefined) => ({
    type: I18N_TOGGLE_STUDY_LOCALE_PROMPT,
    showPrompt,
    answer,
});

export default function i18n(state = null, action, parentState, cookies) {
    if (state === null) {
        // Wait for the rootLocale
        if (!parentState.rootLocale) return state;

        let language = parentState.reqLang || LocaleGetLanguage(parentState.rootLocale);
        let source = I18N_SOURCE_ROOT;

        // See if there is a saved language to set
        const savedLanguage = cookies.get(LanguageCookieName);

        if (parentState.reqLang) {
            language = parentState.reqLang;
            source = I18N_SOURCE_ROUTE;
        } else if (savedLanguage && locales[savedLanguage]) {
            language = savedLanguage;
            source = I18N_SOURCE_COOKIE;
        }

        moment.locale(language);

        state = {
            language,
            fallbackLng: LocaleGetLanguage(parentState.rootLocale),
            availableLocales: Object.keys(locales),
            source,
            showLocaleSaveDialog: false,
            showStudyLocalePrompt: false,
            studyLocaleAnswer: cookies.get(StudyLocaleAnswerCookieName) || undefined,
        };
    }

    if (action.type === I18N_CHANGE_LOCALE) {
        const language = LocaleGetLanguage(action.locale);

        // Save the language in a cookie for short term
        cookies.set(LanguageCookieName, language, {
            maxAge: 3600,
        });

        if (locales[language]) {
            moment.locale(language);

            return {
                ...state,
                language,
                fallbackLng: LocaleGetLanguage(parentState.rootLocale),
                source: I18N_SOURCE_CHANGE,
                showLocaleSaveDialog: !action.skipSave,
                showStudyLocalePrompt: false,
            };
        }
    }

    if (action.type === I18N_CHANGE_LOCALE_AUTO) {
        const { source } = action;

        // Auto should provide source
        if (!source) return state;

        // Auto cannot overwrite when set from cookie
        // Except at login
        if (state.source === I18N_SOURCE_COOKIE && source !== I18N_SOURCE_ACCOUNT_LOGIN) return state;

        // Auto cannot overwrite an active change done by the user
        if (state.source === I18N_SOURCE_CHANGE) return state;

        // Auto cannot overwrite when set from account
        // Except when it is the account requesting it
        if (state.source === I18N_SOURCE_ACCOUNT_ME
			&& source !== I18N_SOURCE_ACCOUNT_ME
			&& source !== I18N_SOURCE_ACCOUNT_LOGIN) return state;

        if (state.source === I18N_SOURCE_ACCOUNT_LOGIN
			&& source !== I18N_SOURCE_ACCOUNT_ME
			&& source !== I18N_SOURCE_ACCOUNT_LOGIN) return state;

        const language = LocaleGetLanguage(action.locale || parentState.rootLocale);

        // Save the language in a cookie for short term
        // Don't set if source is account loading
        if (source !== I18N_SOURCE_ACCOUNT_ME) {
            cookies.set(LanguageCookieName, language, {
                maxAge: 3600,
            });
        }

        if (locales[language]) {
            moment.locale(language);

            return {
                ...state,
                language,
                fallbackLng: LocaleGetLanguage(parentState.rootLocale),
                source,
            };
        }
    }

    if (action.type === I18N_HIDE_SAVE_LOCALE_DIALOG) {
        return {
            ...state,
            showLocaleSaveDialog: false,
        };
    }

    if (action.type === I18N_TOGGLE_STUDY_LOCALE_PROMPT) {
        // Do not ask twice for the language change
        if (state.studyLocaleAnswer !== undefined) return state;

        // Do not ask for logged-in users, we assume they decided on their language already
        if (parentState.me > 0) return state;

        if (action.answer !== undefined) {
            cookies.set(StudyLocaleAnswerCookieName, action.answer, {
                maxAge: 3600,
            });
        }

        return {
            ...state,
            showStudyLocalePrompt: action.showPrompt,
            studyLocaleAnswer: action.answer || undefined,
        };
    }

    return state;
}
