import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScopeProperties } from "../../actions/properties";
import { fetchDataInternalActions } from "../../modules/fetchData/fetchDataActions";

const actionSelector = (actionId) => {
	switch (actionId) {
		case 'ScopeProperties':
			return getScopeProperties;
		default:
			return null;
	}
};

export const useFetchData = (actionId) => {
	const action = actionSelector(actionId);
	const dispatch = useDispatch();
	const dataSelector = useCallback(({ fetchData: { [actionId]: data } }) => data, [actionId]);
	const fetchData = useSelector(dataSelector) || {};
	const { loading, success } = fetchData;

	useEffect(() => {
		function dispatchFetchDataActions() {
			dispatch(fetchDataInternalActions.fetchDataLoading(actionId));
			const promise = dispatch(action());
			promise.then((res) => {
				if (res && res.errorCode) {
					// Exclude 401 error and let other systems handle it
					if (res.errorCode !== 401) {
						return dispatch(fetchDataInternalActions.fetchDataError(actionId, res));
					}
					return false;
				}
				const result = res && res.response && res.response.result;
				return dispatch(fetchDataInternalActions.fetchDataSuccess(actionId, result || true));
			});
		}
		if (!success && !loading && action) dispatchFetchDataActions();
	}, [Array.isArray(success)]);

	return { loading, data: Array.isArray(success) ? success : [] };
};
