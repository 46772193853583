import React from 'react'
import PropTypes from 'prop-types'
import BasketEntry from './BasketEntry'
import BasketTotal from './BasketTotal'
import BasketInfo from './BasketInfo'

const Basket = ({ basket, partner, removeBasketItem, removeDiscount }) => (
	<>
		{basket.basketContents.map((entry) => (
			<BasketEntry
				key={entry.id}
				entry={entry}
				removeBasketItem={removeBasketItem}
				basket={basket}
			/>
		))}
		<BasketTotal
			basket={basket}
			removeDiscount={removeDiscount}
		/>
		<BasketInfo partner={partner} />
	</>
);

Basket.propTypes = {
	basket: PropTypes.object.isRequired,
	partner: PropTypes.object,
	removeBasketItem: PropTypes.func.isRequired,
	removeDiscount: PropTypes.func.isRequired
};

export default Basket;
