import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { VideoWrapper, videoWrapperClasses } from '@athenagroup/components';

const getVideoId = (videoId, i18n) => {
    if (typeof videoId === 'string') return videoId;
    return videoId[i18n.language] || videoId[i18n.options.fallbackLng[0]];
};

function HomePageWidgetVideo({ videoId }) {
    const { i18n } = useTranslation();

    return (
        <VideoWrapper
            renderVideo={(params => (<YouTube {...params} videoId={getVideoId(videoId, i18n)} />))}
            sx={{
                div: { height: '100%' },
                [`.${videoWrapperClasses.playerWrapper}`]: {
                    aspectRatio: '16/9.005',
                },
            }}
        />
    );
}

HomePageWidgetVideo.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default HomePageWidgetVideo;
