import { Box, Grid, Alert } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { filter } from 'ramda'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { fetchSubSubScopes } from '../actions/scopes'
import Loader from '../components/loader'
import ScopeInstituteInfo from '../components/scope/ScopeInstituteInfo'
import { useLoadingDispatch } from '../hoc/hooks/useLoadingDispatch'
import { path, routes } from '../routes'
import { subScopeSelector, rootScopeSelector } from '../selectors/scope'
import Content from './../components/content/Content'
import ScopeList from './../components/scope/ScopeList'
import ScopeSearch from './../components/scope/scope-search'
import {SchemaBreadcrumb} from '../helpers/schema'
import { getRootScopeTitle, scopeUrlSegment } from '../helpers/scope'

const filterScopes = (scopes, q) => {
	if(!q || q === '') return scopes;

	q = q.toLowerCase();

	return filter(scope => {
		const keywords = scope.properties && scope.properties.keywords || [];
		return scope.name.toLowerCase().indexOf(q) > -1 ||
			scope.abbreviation.toLowerCase().indexOf(q) > -1 ||
			keywords.filter(k => k.toLowerCase().indexOf(q) > -1).length > 0;
	}, scopes);
};

export const scopeCompanyPageLoader = store => {
	const state = store.getState();
	const rootScope = rootScopeSelector(state);
	return store.dispatch(fetchSubSubScopes(rootScope.id));
}

const ScopeCompanyPage = () => {
	const [dispatch, loading] = useLoadingDispatch();
	const { t, i18n } = useTranslation();

	const rootScope = useSelector(rootScopeSelector);
	const scopes = useSelector((state) => rootScope && subScopeSelector(state, rootScope));
	const partner = useSelector(state => state.init.partner);

	const [q, setQ] = useState('');
	const filteredScopes = useMemo(() => filterScopes(scopes, q), [scopes, q]);

	const description = t('select.institute.seo_description');

	useEffect(() => {
		if (!scopes) {
			dispatch(fetchSubSubScopes(rootScope.id));
		}
	}, [rootScope, scopes]);

	const handleFilterChange = useCallback((value) => {
		setQ(value);
	}, []);

	const pathCreator = useCallback((scope) => {
		return path(i18n, routes.INSTITUTE, scopeUrlSegment(scope));
	}, [i18n]);

	if (!scopes || loading) {
		return <Content><Loader/></Content>
	}

	return (
		<Content>
			<Content.Header>
				<Content.Partner partnerGlobal={!!partner} />
				<h1>{t('select.institute.title')}</h1>
			</Content.Header>
			<Helmet>
				<title>{t('select.institute.courses')}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={`${getRootScopeTitle(rootScope)} - ${t('select.institute.og_title')}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: 'Cursussen', path: path(i18n, routes.COURSES)}
					])}
				</script>
			</Helmet>
			<Grid container>
				{Boolean(rootScope.properties && rootScope.properties.info) && (
					<Grid item xs={12}>
						<ScopeInstituteInfo scope={rootScope} />
					</Grid>
				)}
				<Grid item xs={12} sm={6} md={4}>
					<ScopeSearch q={q} onChange={handleFilterChange} />
				</Grid>
			</Grid>
			<Box>
				{(q !== '' && filteredScopes && filteredScopes.length === 0) ? (
					<Alert severity="info" icon={false}>
						{t('select.institute.none_found')}
					</Alert>
				) : (
					<ScopeList
						scopes={filteredScopes}
						pathCreator={pathCreator}
						group={true}
						filterUnlisted
					/>
				)}
			</Box>
			<Box>
				<br/>
				<hr/>
				<em className="text-muted">
					{description}
				</em>
			</Box>
		</Content>
	);
}

export default ScopeCompanyPage
