import { useCallback } from 'react'
import TagManager from 'react-gtm-module'
import { gtmEvent } from '../../helpers/gtm'
import { EnvRunTagManager } from './useTagManager'

export function useDataLayer(){
	const sendDataLayer = useCallback((dataLayer) => {
		if(typeof window === 'undefined' || !EnvRunTagManager) return;

		TagManager.dataLayer({
			dataLayer: dataLayer,
		});
	}, []);

	return {
		sendDataLayer,
		sendEvent: gtmEvent,
	};
}
