import { Button, buttonClasses, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { gtmEvent } from '../../helpers/gtm';
import { choiceLabel, propertyLabel } from '../../helpers/properties';

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.grey[300]}`,
	margin: theme.spacing(2, 0, -0.5),
	display: 'flex',
	justifyContent: 'space-between',
	paddingTop: `${theme.spacing(2)} !important`,
	paddingLeft: '0 !important',
	paddingRight: '0 !important',
	lineHeight: '32px',
	background: 'none !important',
	fontWeight: theme.typography.fontWeightMedium,
	color: theme.palette.common.black,

    [`& .${buttonClasses.root}`]: {
		fontWeight: theme.typography.fontWeightLight,
		'&[disabled]': {
			visibility: 'hidden',
		}
	},
}));

const PropertySubHeader = ({property, propertyKey, propertyValue = [], handleProperty}) => {
	const { i18n, t } = useTranslation();

	const disabled = propertyValue.length === 0;
	return (
		<StyledListSubheader disableSticky>
			{propertyLabel(property, i18n.language)}
			<Button variant="text" onClick={() => handleProperty(propertyKey, undefined)} disabled={disabled}>
				{t('property.reset')}
			</Button>
		</StyledListSubheader>
	)
}

const PropertyFilter = ({ propertyKey, propertyValue, allProperties, handleProperty }) => {
	const property = allProperties?.find(prop => prop.key === propertyKey) || {};
	const { i18n, t } = useTranslation();


	const handlePropertyChange = (prop, value) => () => {
		let array = propertyValue || [];
		const index = array.indexOf(value);
		if (index > -1) {
			array.splice(index, 1);
		} else {
			array.push(value);

			gtmEvent('filter', {
				propertyKey: prop,
				propertyValue: value,
			});
		}
		array = array.length !== 0 ? array : undefined;

		handleProperty(prop, array);
	};

	if (property.type === 'boolean') {
		return (
            <>
                <PropertySubHeader property={property} propertyKey={propertyKey} propertyValue={propertyValue} handleProperty={handleProperty} />
                <ListItem disablePadding>
					<ListItemButton onClick={handlePropertyChange(property.key, true)}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={(propertyValue || []).indexOf(true) > -1}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': property.key }}
								size="small"
								sx={{ p: 0.75, pl: 0 }}
							/>
						</ListItemIcon>
						<ListItemText id={property.key} primary={t('course.properties.yes')} />
					</ListItemButton>
				</ListItem>
                <ListItem disablePadding>
					<ListItemButton onClick={handlePropertyChange(property.key, false)}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={(propertyValue || []).indexOf(false) > -1}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': property.key }}
								size="small"
								sx={{ p: 0.75, pl: 0 }}
							/>
						</ListItemIcon>
						<ListItemText id={property.key} primary={t('course.properties.no')} />
					</ListItemButton>
				</ListItem>
            </>
        );
	}

	if (property.type === 'select' || property.type === 'multi-select' || property.type === 'text') {
		// When there are no choices available, we hide the whole property
		if(!property.options?.choices?.length) return null;

		return (
			<>
				<PropertySubHeader property={property} propertyKey={propertyKey} propertyValue={propertyValue} handleProperty={handleProperty}/>
				{property.options?.choices?.map(choice => {
					const key = choice?.key || choice;
					return (
						<ListItem disablePadding key={key}>
							<ListItemButton
								onClick={handlePropertyChange(property.key, key)}
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={(propertyValue || []).indexOf(key) > -1}
										tabIndex={-1}
										disableRipple
										inputProps={{'aria-labelledby': key}}
										size="small"
										sx={{ p: 0.75, pl: 0 }}
									/>
								</ListItemIcon>
								<ListItemText id={key} primary={choiceLabel(choice, i18n.language)}/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</>
		);
	}

	return null;
};

PropertyFilter.propTypes = {
	propertyKey: PropTypes.string.isRequired,
	propertyValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.string]),
	allProperties: PropTypes.array.isRequired,
	handleProperty: PropTypes.func.isRequired,
};

export default PropertyFilter;
