import { styled, useMediaQuery } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useLayoutEffectClient from '../../hoc/hooks/useLayoutEffectClient';

const RootDiv = styled('div')(({ theme }) => ({
	position: 'relative',
	overflow: 'hidden',
	marginTop: theme.spacing(1.5),
	'&.gradient': {
		maskImage: 'linear-gradient(180deg, #000 70%, transparent)',
	},
}));

const DEFAULT_TRUNCATION_HEIGHT = 210;

function CourseListItemDescription({ course }) {
	const { description } = course;
	const descContentRef = useRef();
	const [truncated, setTruncated] = useState(false);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const maxHeight = useMemo(() => (
		DEFAULT_TRUNCATION_HEIGHT + (isMobile ? 50 : 0)
	), [isMobile]);

    useLayoutEffectClient(() => {
        if (description && descContentRef.current) {
            setTruncated(descContentRef.current.getBoundingClientRect().height > maxHeight);
        }
    }, [description, maxHeight]);

	if (!course.description) {
		return null;
	}

	return (
		<RootDiv className={truncated ? 'gradient' : ''} sx={{ maxHeight }}>
			<div className="editor" ref={descContentRef} dangerouslySetInnerHTML={{ __html: course.description}} />
		</RootDiv>
	)
}

CourseListItemDescription.propTypes = {
	course: PropTypes.object.isRequired,
}

export default CourseListItemDescription;
