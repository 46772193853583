import { Close } from "@mui/icons-material";
import { Grid, IconButton, Chip, styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { moneyComposer } from '../../helpers/composers'
import { nameFromBasketEntry } from '../../helpers/basket'
import StyledBox from "./StyledBox";

const PREFIX = 'BasketEntry';
const classes = {
	entry: `${PREFIX}-entry`,
	price: `${PREFIX}-price`,
	quantity: `${PREFIX}-quantity`,
}

const StyledBasketEntry = styled(StyledBox)(({ theme }) => ({
	[`&.${classes.entry}`]: {
		borderBottom: `1px solid ${theme.palette.grey[200]}`,
		color: theme.palette.grey[900],

		[`& .${classes.price}`]: {
			fontWeight: theme.typography.fontWeightMedium,
			fontSize: '16px',
		},

		[`& .${classes.price}, & .${classes.quantity}`]: {
			marginRight: '26px',
		},

		'&:hover': {
			backgroundColor: theme.palette.grey[50],
		},

		[theme.breakpoints.down('md')]: {
			[`& .${classes.price}, & .${classes.quantity}`]: {
				marginRight: '10px',
			},
		},
	},
}))

const BasketEntry = ({ entry, removeBasketItem, basket }) => {
	const { t } = useTranslation();
	const handleRemove = () => {
		removeBasketItem(entry.product.id);
	};

	const hasDiscountFromBundle = basket.appliedDiscountCodes.filter(discount => {
		return discount.applicableInCourses?.filter?.(course => (
			course.id === entry.product.parent?.id
		)).length > 0;
	}).length > 0;

	return (
		<StyledBasketEntry className={classes.entry}>
			<Grid container columnSpacing={2}>
				<Grid item xs={7} md={8}>
					{nameFromBasketEntry(entry)}
					{hasDiscountFromBundle && (
						<Chip
							label={(
								<Trans
									i18nKey="checkout.basket.entry.bundle_discount"
									defaults="bundelkorting"
								/>
							)}
							color="info"
							sx={{ ml: 1, mt: -0.5 }}
							size="small"
						/>
					)}
				</Grid>
				<Grid item xs={5} md={4} sx={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
					<span className={classes.quantity}>
						{entry.quantity}x
					</span>
					<span className={classes.price}>
						{moneyComposer(entry.product.price)}
					</span>
					<IconButton onClick={handleRemove} sx={{ m: -1.5 }} label={t('checkout.basket.remove')}>
						<Close sx={{ color: 'grey.600' }} />
					</IconButton>
				</Grid>
			</Grid>
		</StyledBasketEntry>
	);
};

BasketEntry.propTypes = {
	entry: PropTypes.object.isRequired,
	removeBasketItem: PropTypes.func.isRequired,
	basket: PropTypes.object.isRequired,
};

export default BasketEntry
