/**
 * Helper which wraps the setter with default options
 *
 * @param {Cookies} cookies
 * @returns {Cookies}
 */
const extendCookies = (cookies) => {
	const originalSet = cookies.set.bind(cookies);
	cookies.set = ((name, value, options) => {
		options = {
			path: '/',
			secure: true,
			sameSite: 'lax',
			...options,
		}

		if(options.days){
			options.maxAge = options.days * 24 * 60 * 60;
		}

		return originalSet(name, value, options);
	}).bind(cookies);

	const originalRemove = cookies.remove.bind(cookies);
	cookies.remove = ((name, options) => {
		options = {
			path: '/',
			secure: true,
			sameSite: 'lax',
			...options,
		}

		return originalRemove(name, options);
	}).bind(cookies);

	return cookies;
}

export default extendCookies
