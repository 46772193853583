import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getCustomLocaleMessage } from '../../helpers/locale'

const CustomAltImage = ({ alt, ...rest }) => {
	const { i18n } = useTranslation();
    return (
        <img {...rest} alt={getCustomLocaleMessage(alt, i18n)} />
    );
};

CustomAltImage.propTypes = {
	alt: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
};

export default CustomAltImage
