import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { TextImage, textImageClasses } from '@athenagroup/components';
import { useTranslation } from 'react-i18next';
import Markdown from '../../markdown/Markdown';
import staticPages from '../../../pages/static/staticPages';
import { getPagePath, getRouterPath } from '../../../helpers/path';
import { LinkComponent } from '../../general/LinkComponent';

function HomePageWidgetTextImage({ imgSide, description, button, imgSrc, title, animation }) {
    const { i18n } = useTranslation();
    const to = useMemo(() => {
        if (button?.to.indexOf('key:') === 0) {
            const pages = staticPages(i18n);
            return getPagePath(pages, button.to.substring(4));
        }

        if (button?.to.indexOf('route:') === 0) {
            return getRouterPath(i18n, button.to.substring(6), i18n.language, true);
        }

        return undefined;
    }, [button?.to, i18n]);

    return (
        <TextImage
            title={title}
            description={<Markdown>{description}</Markdown>}
            imgSrc={imgSrc}
            imgAlt=""
            imgSide={imgSide}
            variant="backdrop"
            alignImage="center"
            justifyContent="center"
            animation={animation}
            buttonLink={!to ? { href: button?.to } : { to }}
            buttonLabel={button?.label}
            ButtonLinkComponent={LinkComponent}
            sx={{ [`& .${textImageClasses.titleDescriptionButton} a`]: { borderRadius: '2px' } }}
            applyBorderRadius
        />
    );
}

HomePageWidgetTextImage.disableTitle = true;

HomePageWidgetTextImage.propTypes = {
    imgSide: PropTypes.oneOf(['left', 'right']),
    description: PropTypes.string.isRequired,
    button: PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    animation: PropTypes.string,
};

export default HomePageWidgetTextImage;
