import { ContactBlock } from '@athenagroup/components';
import React from 'react';
import PropTypes from 'prop-types';
import { LinkComponent } from '../../general/LinkComponent';

function HomePageWidgetContactBlock({ title, description, buttonLabel, buttonLink, imgSrc, imgAlt, phoneNumber, phoneText }) {
    return (
        <ContactBlock
            title={title}
            description={description}
            buttonLabel={buttonLabel}
            buttonLink={buttonLink}
            imgSrc={imgSrc}
            imgAlt={imgAlt}
            phoneNumber={phoneNumber || ''}
            phoneText={phoneText || ''}
            LinkComponent={LinkComponent}
        />
    );
}

HomePageWidgetContactBlock.disableTitle = true;

HomePageWidgetContactBlock.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    buttonLink: PropTypes.object.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    phoneText: PropTypes.string,
};

export default HomePageWidgetContactBlock;
