
export function formatNumber(number, fractionDigits = 2){
	if(!number || typeof number !== 'number') return number;

	return number.toLocaleString(undefined, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
}

export function percentageComposer(factor){
	return Math.round(factor * 100) + '%';
}

export function moneyComposer(price){
	return moneyComposerDirect(price.amount, price.currency);
}

export function moneyComposerDirect(amount, currency){
	return `${getMoneyCurrencySymbol(currency)}${formatNumber(amount, 2)}`;
}

export function getMoneyCurrencySymbol(currency){
	switch(currency){
		case 'EUR':
			return '€';
		case 'GBP':
			return '£';
		default:
			return currency || '€';
	}
}
