import { Cancel } from "@mui/icons-material";
import { Box, Chip, IconButton } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { percentageComposer } from '../../helpers/composers'

const BasketDiscountList = ({ codes, removeDiscount }) => {
	if(!codes || codes.length === 0) return <div></div>;

	return (
		<Box sx={{ '& > div': { padding: '10px 0' } }}>
			{codes.map(entry => (
				<Box sx={{ display: 'flex', alignItems: 'center' }} key={entry.id}>
					<Chip label={`-${percentageComposer(entry.discountPercentage)}`} color="success" sx={{ mr: 2, mt: '-2px' }} />
					{entry.code}
					{entry.partner && `Via partner ${entry.partner.name}`}
					{entry.class === 'AthenaCourseBundleDiscountCode' && entry.description && (
						<Trans
							i18nKey="checkout.basket.discount.bundle"
							defaults="Bundel: {{bundleTitle}}"
							values={{
								bundleTitle: entry.description,
							}}
						/>
					)}
					{entry.code && (
						<IconButton onClick={() => removeDiscount(entry.code)} sx={{ mt: '-2px' }}>
							<Cancel sx={{ color: 'error.main' }} />
						</IconButton>
					)}
				</Box>
			))}
		</Box>
	);
};

BasketDiscountList.propTypes = {
	codes: PropTypes.array,
	removeDiscount: PropTypes.func.isRequired,
};

export default BasketDiscountList
