import { Box, Container, styled } from "@mui/material";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'
import useRootScopeProperties from "../../hoc/hooks/useRootScopeProperties";
import background from "../../style/images/background.jpg";

import ContentHeader from './ContentHeader'
import ContentLine from './ContentLine'
import ContentPartner from './ContentPartner'
import ContentBack from './ContentBack'

const StyledHeaderWrap = styled('div')(({ theme }) => ({
	paddingTop: '88px', // This corrects for the default 'header height'
	backgroundColor: theme.palette.primary.main,
	backgroundPosition: 'center center',
	backgroundSize: 'cover',
	backgroundAttachment: 'scroll',

	'& > div': {
		position: 'relative',
		zIndex: '50',
	},

	'.no-webp &': {
		backgroundImage: `url('${background}')`,
	},

	'.webp &': {
		backgroundImage: `url('${background}')`,
	}
}))

const PREFIX = 'Content';
export const contentClasses = {
	withoutHeader: `${PREFIX}-withoutHeader`,
	body: `${PREFIX}-body`,
	sheet: `${PREFIX}-sheet`,
	padding: `${PREFIX}-padding`,
	line: `${PREFIX}-line`,
}

const StyledContentDiv = styled('div')(({ theme }) => ({
	flex: 1,
	backgroundColor: theme.palette.common.white,
	zIndex: 50,

	[`&.${contentClasses.withoutHeader}`]: {
		marginTop: '176px',
	},

	[`& .${contentClasses.body}`]: {
		marginTop: 0,
		[`&.${contentClasses.sheet}`]: {
			marginBottom: '30px',
			padding: '40px 0',
			minHeight: '320px',
		},
		[`&.${contentClasses.padding}`]: {
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(3, 1),
				[`& .${contentClasses.line}`]: {
					marginLeft: '-30px',
					marginRight: '-30px',
				}
			},

			[theme.breakpoints.up('md')]: {
				padding: '20px 50px',

				[`& .${contentClasses.line}`]: {
					marginLeft: '-50px',
					marginRight: '-50px',
				},
			},
		},
	},
}))

const Content = React.forwardRef(({ children, noContainer, noSheet, padding }, ref) => {
	let Header = null;
	let childrenNew = [];
	const { customBackgroundUrl } = useRootScopeProperties();

	if(Array.isArray(children)){
		children.map((child, i) => {
			if(child && child.type && (child.type.name == 'ContentHeader' || child.type == ContentHeader)){
				Header = children[i];
			}else{
				childrenNew.push(children[i]);
			}
		});
	}else{
		childrenNew = children;
	}
	const customBackground = customBackgroundUrl && ({
		backgroundImage: `url(${customBackgroundUrl})`,
	});

	return (
		<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }} ref={ref}>
			<StyledHeaderWrap style={customBackground}>
				{Header}
			</StyledHeaderWrap>
			<StyledContentDiv className={!!Header ? '' : contentClasses.withoutHeader}>
				{noContainer ? (
					<div className={classnames(contentClasses.body, {
						[contentClasses.sheet]: !noSheet,
						[contentClasses.padding]: padding,
					})}>
						{childrenNew}
					</div>
				) : (
					<Container>
						<div className={classnames(contentClasses.body, {
							[contentClasses.sheet]: !noSheet,
							[contentClasses.padding]: padding,
						})}>
							{childrenNew}
						</div>
					</Container>
				)}
			</StyledContentDiv>
		</Box>
	);
});

Content.Header = ContentHeader;
Content.Line = ContentLine;
Content.Partner = ContentPartner;
Content.Back = ContentBack;

Content.propTypes = {
	children: PropTypes.node.isRequired,
	noContainer: PropTypes.bool,
	noSheet: PropTypes.bool,
	padding: PropTypes.bool
};

Content.defaultProps = {
	noContainer: false,
	noSheet: false,
	padding: false,
};

export default Content
