import { path as objPath } from 'ramda'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import config from '../../setup/config'
import useAuth from './useAuth'

export const EnvRunTagManager = process.env.NODE_ENV === 'production';

export function useTagManager(rootScope){
	if(typeof window === 'undefined' || !EnvRunTagManager) return;

	const { user } = useAuth();
	const gtmId = objPath(['properties', 'config', 'gtmId'], rootScope) || config.gtmId;

	useEffect(() => {
		TagManager.initialize({
			gtmId: gtmId,
			dataLayer: {
				company: `${process.env.NODE_ENV === 'production' ? '' : 'DEV-'}${rootScope.abbreviation}`,
				...(user ? {
					userId: user.id,
				} : null),
			},
		});
	}, [gtmId]); // Assume rootScope does never change (if it does, something is wrong with the whole application)

	useEffect(() => {
		user && TagManager.dataLayer({
			dataLayer: {
				userId: user.id,
			},
		});
	}, [user]);
}
