import { createSelector } from 'reselect'
import { dropLastWhile, pickBy, reverse, tail, unnest } from 'ramda'
import { SortScopesOnName } from '../helpers/lists'

const scopesSelector = state => state.entities.scopes;
const rootScopeIdSelector = state => state.init.rootScope;

const scopeSelector = (state, props) => scopesSelector(state)[props.id];

const propsAbbrPathSelector = (state, props) => props.abbrPath;
const paramsInstituteSelector = (state, props) => props.params?.institute?.toUpperCase?.();
const paramsFacultySelector = (state, props) => props.params?.faculty?.toUpperCase?.();
const paramsStudySelector = (state, props) => props.params?.study?.toUpperCase?.();

export const abbrPathSelector = createSelector(
	propsAbbrPathSelector,
	paramsInstituteSelector,
	paramsFacultySelector,
	paramsStudySelector,
	(abbrPath, institute, faculty, study) => {
		if(abbrPath) return abbrPath;

		const path = dropLastWhile((abbr) => !Boolean(abbr), [
			institute, faculty || false, study,
		]);

		return path.length === 0 ? null : path;
	}
);

export const rootScopeSelector = createSelector(
	rootScopeIdSelector,
	scopesSelector,
	(id, scopes) => {
		if(!id) return null;
		return scopes[id];
	}
);

export const rootScopeAlternateSitesSelector = createSelector(
	rootScopeSelector,
	scopesSelector,
	(rootScope, scopes) => {
		if(!rootScope) return [];
		if(!rootScope.properties || !rootScope.properties.config || !rootScope.properties.config.alternateSiteScopeIds) return [];

		return rootScope.properties.config.alternateSiteScopeIds.map(id => {
			const scope = scopes[id];
			if(!scope || !scope.properties || !scope.properties.config || !scope.properties.config.domains) return false;

			return {
				id: scope.id,
				locale: scope.locale.replace('_', '-'),
				domains: scope.properties.config.domains,
			};
		}).filter(Boolean);
	}
);

/**
 * @deprecated
 */
export const subScopeSelector = createSelector(
	scopeSelector,
	scopesSelector,
	(scope, scopes) => {
		if(!scope) return null;
		if(!scope.subScopes) return null;
		return SortScopesOnName(
			scope.subScopes.map(id => scopes[id])
		);
	}
);

/**
 * @deprecated
 */
export const subSubScopeSelector = createSelector(
	scopeSelector,
	scopesSelector,
	(scope, scopes) => {
		if(!scope) return null;
		if(!scope.subScopes) return null;

		const nestedScopes = scope.subScopes.map(id => {
			if(!scopes[id] || !scopes[id].subScopes){
				return false;
			}
			return scopes[id].subScopes.map(subId => scopes[subId]);
		});
		// Some subScopes of the subScopes were not loaded yet
		if(nestedScopes.includes(false)) return null;

		return SortScopesOnName(unnest(nestedScopes));
	}
);

/**
 * @deprecated
 */
export const subSubScopeNestedSelector = createSelector(
	scopeSelector,
	scopesSelector,
	(scope, scopes) => {
		if(!scope) return null;
		if(!scope.subScopes) return null;

		return SortScopesOnName(
			scope.subScopes.map(id => {
				// TODO: split courses page and let course load the subScopes (groups)... saves this rewrite hazzle
				return {
					...scopes[id],
					subScopes: SortScopesOnName((scopes[id].subScopes || []).map(
						id => scopes[id]
					)),
				};
			})
		);
	}
);

export const scopeByIdSelector = createSelector(
	scopeSelector,
	(scope) => {
		return scope || null;
	},
);

export const scopeByIdAndSubSubScopesSelector = createSelector(
	scopeSelector,
	scopesSelector,
	(scope, scopes) => {
		if(!scope) return null;
		if(!scope.subScopes) return null;

		return {
			...scope,
			subScopes: SortScopesOnName(
				scope.subScopes.map(id => ({
					...scopes[id],
					subScopes: scopes[id].subScopes ? SortScopesOnName((scopes[id].subScopes).map(
						id => scopes[id]
					)) : scopes[id].subScopes,
				})),
			),
		};
	}
);

export const scopeByReverseAbbrPathSelector = createSelector(
	rootScopeSelector,
	abbrPathSelector,
	scopesSelector,
	(rootScope, abbrPath, scopes) => {
		if(!rootScope || !abbrPath) return null;

		function hasAbbrAndParents(scope, path){
			if(path.length === 0) return scope.id === rootScope.id;
			if(!scope.parent) return false;

			// Account for missing faculty, just ignore the abbr match
			if(path[0] === false){
				return hasAbbrAndParents(scopes[scope.parent], tail(path))
			}

			return path[0].toLowerCase() === scope.abbreviation.toLowerCase() && hasAbbrAndParents(scopes[scope.parent], tail(path));
		}

		const path = reverse(abbrPath);
		const candidates = Object.values(pickBy((scope, id) => hasAbbrAndParents(scope, path), scopes));

		return candidates?.[0] || null;
	},
);

const scopeNames = {
	'Company': 'company',
	'Institute': 'institute',
	'Faculty': 'faculty',
	'Course': 'study',
	'AthenaStudiesCourse': 'course',
	'AthenaStudiesCourseGroup': 'group',
};

export const scopeAndParentsSelector = createSelector(
	scopeSelector,
	scopesSelector,
	(scope, scopes) => {
		return (function createParentScopesObject(scope) {
			if(!scope) return {};

			return {
				...createParentScopesObject(scopes[scope.parent]),
				[scopeNames[scope.class]]: scope,
			}
		})(scope)
	}
)
