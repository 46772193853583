import { flatten, uniq } from 'ramda';
import { useMemo } from 'react';

export function cleanFilterPropValue(value) {
    if (typeof value === 'string') return value.trim().toLowerCase();
    if (Array.isArray(value)) return value.map(v => cleanFilterPropValue(v));

    return value;
}

const validFilterPropertyTypes = ['boolean', 'select', 'multi-select', 'text'];

/**
 * Filter the given properties based on what is used in the given courses
 * Also derives used text type properties and enhances the choices with those to allow them to be filtered
 *
 * @param {array} allProperties Array of all properties as given by the API
 * @param {array} courses Array of all the courses to look into
 * @returns {array|*} Array of active properties and derived choices after filtering
 */
export function useActiveFilterPropertiesAndChoices(allProperties, courses){
	return useMemo(() => {
		if(!allProperties || !courses) return allProperties;

		const coursesProperties = courses.map(course => course.properties).filter(Boolean);

		return allProperties.map(property => {
			if(property.options?.flags?.hiddenInFacetFilter) return property;

			const props = coursesProperties
				.map(props => props[property.key])
				.filter(prop => prop !== null && prop !== undefined && prop !== '');

			// For this property, make a list of unique keys used by the courses
			const propertiesInCourses = property.type.indexOf('multi') > -1 ? uniq(flatten(props)) : uniq(props);

			// For text types we simply add all the used keys/values as choices
			if(property.type === 'text') return {
				...property,
				options: {
					...property.options,
					choices: propertiesInCourses.map(key => ({
						key: key,
						label: key,
					})),
				},
			};

			// For selects we filter the original choices based on what is in the courses
			if(property.type === 'select' || property.type === 'multi-select') return {
				...property,
				options: {
					...property.options,
					choices: property.options.choices.filter(choice => (
						propertiesInCourses.includes(choice.key || choice)
					)),
				},
			};

			// Boolean properties which are not used in the courses are simply removed from the properties list
			if(property.type === 'boolean' && propertiesInCourses.length === 0) return null;

			// Rest we leave untouched
			return property;
		}).filter(property => {
			if(!property) return false;
			if(!validFilterPropertyTypes.includes(property.type)) return false;
			if(property.options?.flags?.hiddenInFacetFilter) return false;
			if(property.options?.choices && !property.options?.choices?.length) return false;

			return true;
		});
	}, [allProperties, courses]);
}
