import { Button, darken, Grid, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Tune } from '@mui/icons-material'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { countBy, compose, groupBy, omit, sortBy, toPairs } from 'ramda'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import useRootScopeProperties from "../../hoc/hooks/useRootScopeProperties";
import CoursesSearchAndFilterWrapper from './CoursesSearchAndFilter'
import { subSubScopeNestedSelector } from '../../selectors/scope'
import CourseList from './CourseList'
import CoursesSearchField from './CoursesSearchField'
import { cleanFilterPropValue } from './useActiveFilterPropertiesAndChoices';

const StyledDiv = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(2),
	marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.info.main,
	'&:hover, &:focus': {
		backgroundColor: darken(theme.palette.info.main, 0.3),
	}
}));

const GROUP_THRESHOLD_DEFAULT = 3;
export const UNGROUPED = 'UNGROUPED';

const parseSearch = (str) => qs.parse(str, {
	arrayFormat: 'bracket-separator',
	arrayFormatSeparator: '|',
});

const encodeSearch = (obj) => `?${qs.stringify(obj, {
	arrayFormat: 'bracket-separator',
	arrayFormatSeparator: '|',
})}`

// TODO: eventually add sub-sub-scopes loading here with react-query
const Courses = ({ study, coursePathCreator }) => {
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const { config } = useRootScopeProperties();
	const { groupingProps, threshold = GROUP_THRESHOLD_DEFAULT } = config.courseListGrouping || {};

	const [opened, setOpen] = useState(false);

	const query = parseSearch(location.search);

	const setSearch = useCallback((q) => {
		navigate({
			search: encodeSearch({
				...query,
				search: q || undefined,
			}),
		}, { replace: true });
	}, [navigate, query]);

	const updateFilter = useCallback((obj) => {
		navigate({
			search: encodeSearch({
				// Checking hasOwnProperty to make sure search and filter are clearable
				...(obj.hasOwnProperty('filter') ? obj.filter : query),
				search: obj.hasOwnProperty('search') ? obj.search : query.search,
			}),
		}, { replace: true });
	}, [navigate, query]);

	const resetFilter = useCallback(() => {
		navigate({
			search: encodeSearch({}),
		}, { replace: true });
	}, [navigate]);

	const search = typeof query.search === 'string' ? query.search : ''; // Ignore any wrong (user)input
	const properties = useMemo(() => (
		omit(['search'], query)
	), [query]);

	const scopes = useSelector((state) => study && subSubScopeNestedSelector(state, study));

	const useDrawer = useMediaQuery(theme.breakpoints.down('md'));
	const propertiesCount = useMemo(() => Object.keys(properties).length, [properties]);

	const filteredAndGroupedScopes = useMemo(() => {
		const filtered = scopes?.filter?.(scope => {
			const propertyMatch = Object.keys(properties || {}).length === 0 || Object.keys(properties).every(propKey => {
				const selectedValues = properties[propKey];
				const scopeValues = cleanFilterPropValue(scope.properties?.[propKey]);

				if(!Array.isArray(selectedValues)) return true; // Ignore this invalid filter (user)input

                // The basic === comparison is for simple types like boolean
                return selectedValues?.some?.(value => {
                    const valueC = cleanFilterPropValue(value);
                    return valueC === scopeValues || scopeValues?.includes?.(valueC);
                });
			});

            if (!search) return propertyMatch;

            return (
                scope.name.toLowerCase().includes(search.toLowerCase())
                || scope.description?.toLowerCase?.().includes(search.toLowerCase())
            ) && propertyMatch;
		}) || [];

		const shouldGroup = scopes && groupingProps
			? countBy(scope => groupingProps.every(prop => !!scope.properties?.[prop]), scopes).false < threshold
			: false;

		const groupByProp = groupBy((scope) => {
			const { properties } = scope;
			if (shouldGroup && groupingProps.every(prop => !!properties?.[prop])) {
				return groupingProps.map(prop => properties[prop]).join(' - ');
			}
			return UNGROUPED;
		});

		const sortGroups = sortBy((group) => group[0] === UNGROUPED);

		const grouped = compose(sortGroups, toPairs, groupByProp)(filtered);

		return {
			grouped,
			count: filtered.length,
		};
	}, [scopes, search, properties, groupingProps, threshold]);

	return (
        <>
            {useDrawer && (
				<StyledDiv>
					<CoursesSearchField
						search={search}
						setSearch={setSearch}
						opened={opened}
						setOpen={setOpen}
					/>
					<StyledButton
						variant="contained"
						color="primary"
						onClick={() => setOpen(true)}
						fullWidth
						startIcon={<Tune />}
					>
						Filters {!!propertiesCount && ` (${propertiesCount})`}
					</StyledButton>
				</StyledDiv>
			)}
            <Grid container columnSpacing={2}>
				<CoursesSearchAndFilterWrapper
					search={search}
					updateFilter={updateFilter}
					properties={properties}
					opened={opened}
					setOpen={setOpen}
					resultCount={filteredAndGroupedScopes?.count || 0}
					courses={scopes}
				/>
				<CourseList
					filteredAndGroupedScopes={filteredAndGroupedScopes}
					study={study}
					coursePathCreator={coursePathCreator}
					totalCourses={scopes?.length || 0}
					resetFilter={resetFilter}
				/>
			</Grid>
        </>
    );
};

Courses.propTypes = {
	study: PropTypes.object.isRequired,
	coursePathCreator: PropTypes.func.isRequired,
};

export default Courses
