import { styled } from "@mui/material/styles";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'
import config from './../../setup/config'

const PREFIX = 'ContentPartner';
const classes = {
	scopeLogo: `${PREFIX}-scopeLogo`,
	globalLogo: `${PREFIX}-globalLogo`,
}

const StyledScopeLogo = styled('div')(({ theme }) => ({
	[`&.${classes.scopeLogo}`]: {
		display: 'inline-block',
		height: '70px',
		width: '100px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right center',
		backgroundSize: 'contain',

		[`+.${classes.globalLogo}`]: {
			marginLeft: '-200px',
			marginRight: '100px',
		},
		[theme.breakpoints.down('md')]: {
			backgroundPosition: 'center center',
			display: 'inline-block',

			[`+.${classes.globalLogo}`]: {
				margin: '-70px auto 0 auto',
			}
		}
	}
}))

const StyledGlobalLogo = styled('div')(({ theme }) => ({
	float: 'right',
	height: '70px',
	width: '100px',
	margin: '4px 0 -20px -100px',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'right center',
	backgroundSize: 'contain',

	[theme.breakpoints.down('md')]: {
		display: 'inline-block',
		float: 'none',
		margin: '-12px auto 0 auto',
		backgroundPosition: 'center center',
	}
}))

const ContentPartner = ({ partner, partnerGlobal }) => (
    <div>
		{partner && partner.visuals && partner.visuals.styleUrl && (
			<link rel="stylesheet" type="text/css" href={config.staticPartnerUrl + partner.visuals.styleUrl} />
		)}
		{partner && (
			<StyledScopeLogo
				className={classnames(classes.scopeLogo, 'partner-scope-logo')}
			/>
		)}
		{partnerGlobal && (
			<StyledGlobalLogo
				className={classnames(classes.globalLogo, 'partner-global-logo')}
			/>
		)}
	</div>
);

ContentPartner.propTypes = {
	partner: PropTypes.object,
	partnerGlobal: PropTypes.bool
};

export default ContentPartner
