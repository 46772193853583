import { Alert, Button, styled } from "@mui/material";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { mapObjIndexed, omit, keys, pick, values } from 'ramda'
import { withTranslation } from 'react-i18next'
import FormData from '../../hoc/FormData'
import { saveEvaluationForm } from '../../actions/evaluation'
import Loader from '../loader'
import EvaluationFormQuestion from './EvaluationFormQuestion'

const questionKeyOrdering = [
	'teacherConveyedSubject',
	'goodFeelingAboutCourse',
	'knowledgeForExam',
	'teacherSeenAsTop',
	'courseOrganisationExcellence',
	'comments',
];

export const questionOpenFieldKeys = [
	'comments',
];

const StyledForm = styled('form')(({ theme }) => ({
	'& > h3': {
		margin: 0,
		fontWeight: theme.typography.fontWeightMedium,
	},
	'& > p': {
		marginBottom: '24px',
	}
}));

class EvaluationForm extends Component {
	constructor(props){
		super(props);

		this.state = {
			...pick(keys(props.questions), props.savedAnswers || {})
		};
	}

	render(){
		const { questions, name, saving, error, success, t } = this.props;

		if(success) return (
			<Alert severity="success" icon={false}>
				{t('evaluation.thank_you_message', {
					name: name,
				})}
			</Alert>
		)

		const questionObjects = questionKeyOrdering
			.map(key => ({
				key: key,
				question: questions[key],
			}))
			.filter(item => item.question)
			.concat(values(mapObjIndexed(
				(question, key) => ({
					key: key,
					question: question,
				}),
				omit(questionKeyOrdering, questions)
			)));

		return (
			<StyledForm onSubmit={this.handleSubmit}>
				<h3>{t('evaluation.evaluation_form')}</h3>
				<p>{t('evaluation.text')}</p>
				{saving && (
					<Loader sheet />
				)}
				{error && (
					<Alert severity="warning" icon={false}>
						{error.errorCode === 400
							? t('evaluation.fill_all_required_fields')
							: error.error
						}
					</Alert>
				)}
				{questionObjects.map((questionItem, i) => (
					<EvaluationFormQuestion
						key={questionItem.key}
						number={i + 1}
						questionItem={questionItem}
						error={error && error.errors && error.errors[questionItem.key]}
						value={this.state[questionItem.key]}
						onChange={this.handleChange(questionItem.key)}
					/>
				))}
				<hr />
				<Button
					type="submit"
					color="cta"
					variant="contained"
					disabled={saving}
				>
					{t('evaluation.send_evaluation')}
				</Button>
			</StyledForm>
		);
	}

	handleChange = (key) => (value) => {
		this.setState({
			[key]: value,
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const { watchSubmit, token } = this.props;

		watchSubmit(saveEvaluationForm(token, this.state), true);
		window.scrollTo(0, 0);
	}
}

EvaluationForm.propTypes = {
	token: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	questions: PropTypes.object.isRequired,
	savedAnswers: PropTypes.object,
}

export default withTranslation()(FormData(EvaluationForm, 'EvaluationForm'))
