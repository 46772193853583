import { Box, Divider, Alert, Grid } from "@mui/material";
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { intersperse } from 'ramda'
import { useParams } from "react-router-dom";
import { fetchScopeByAbbrPath } from '../actions/scopes'
import ScopeInstituteInfo from '../components/scope/ScopeInstituteInfo'
import { getCustomLocaleMessage } from '../helpers/locale'
import { useLoadingDispatch } from '../hoc/hooks/useLoadingDispatch'
import { useSetLastPath } from '../hoc/hooks/useSetLastPath'
import { path, routes } from '../routes'
import {
	abbrPathSelector,
	scopeAndParentsSelector,
	scopeByReverseAbbrPathSelector,
	subScopeSelector
} from '../selectors/scope'
import Content from './../components/content/Content'
import Loader from './../components/loader'
import SectionTitle from './../components/general/section-title'
import ScopeList from './../components/scope/ScopeList'
import { SchemaBreadcrumb } from '../helpers/schema'
import { getRootScopeTitle, scopeHideAbbr, scopeUrlSegment } from '../helpers/scope'
import { useRedirectContext } from '../hoc/RedirectContextProvider'

export const scopeFacultyPageLoader = (store, params) => {
	const state = store.getState();
	const abbrPath = abbrPathSelector(state, { params });
	return store.dispatch(fetchScopeByAbbrPath(abbrPath, 1));
};

const ScopeFacultyPage = () => {
	const [loadingDispatch, loading, error] = useLoadingDispatch();
	const { t, i18n } = useTranslation();
	const params = useParams();

	const faculty = useSelector((state) => scopeByReverseAbbrPathSelector(state, { params }));
	const abbrPath = useSelector((state) => abbrPathSelector(state, { params }));
	const scopes = useSelector((state) => faculty && subScopeSelector(state, faculty));
	const partner = useSelector(state => state.init.partner);
	const { company, institute } = useSelector((state) => scopeAndParentsSelector(state, { id: faculty?.id }));
	// If static rendered, put the redirect in the context
	const redirectContext = useRedirectContext()
	const redirect = faculty?.properties?.redirect;
	if (redirectContext && redirect) {
		redirectContext.url = redirect;
	}

	useEffect(() => {
		if(!faculty || !faculty.subScopes){
			loadingDispatch(fetchScopeByAbbrPath(abbrPath, 1));
		}else{
			// TODO: do setLastPath action (with id list)
		}
	}, [faculty, abbrPath]);

	useSetLastPath(faculty);

	const pathCreator = useCallback((scope) => {
		return path(i18n, routes.STUDY, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(scope))
	}, [i18n, faculty]);

	useEffect(() => {
		// Prevent doing this in SSR, staticContext above is handling that
		if(typeof window === 'undefined' || !redirect) return;

		window.location.replace(redirect);
	}, [redirect]);

	if((error && error.errorCode === 400) || (faculty === undefined && !loading)) return (
		<Content><Alert severity="info" icon={false}>{t('select.faculty.faculty_not_found')}</Alert></Content>
	);

	if(!faculty || loading) return (
		<Content><Loader/></Content>
	);

	const description = t('select.faculty.seo_description', {
		faculty: faculty.name
	});

	return (
		<Content>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} alignItems="center">
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))} />
					</Grid>
					<Grid item xs={12} md={4} lg={6}>
						<h1>{faculty.name}{!scopeHideAbbr(faculty) && ` (${faculty.abbreviation})`}</h1>
						<h2>{institute.name}</h2>
					</Grid>
					<Grid item xs={!!partner ? 12 : 0} md={4} lg={3} sx={{ textAlign: { md: 'right' } }}>
						<Content.Partner partnerGlobal={!!partner} />
					</Grid>
				</Grid>
			</Content.Header>
			<Helmet>
				<title>{faculty.name}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={`${getRootScopeTitle(company)} - ${faculty.name}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: 'Cursussen', path: path(i18n, routes.COURSES)},
						{name: institute.name, path: path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))},
						{name: faculty.name, path: path(i18n, routes.FACULTY, scopeUrlSegment(institute), scopeUrlSegment(faculty))},
					])}
				</script>
			</Helmet>
			<Box>
				<ScopeInstituteInfo scope={faculty} />
				<SectionTitle>
					{faculty.properties?.customSectionTitle
						? getCustomLocaleMessage(faculty.properties.customSectionTitle, i18n)
						: t('select.faculty.select_study')
					}
				</SectionTitle>
				<ScopeList
					scopes={scopes}
					pathCreator={pathCreator}
					group={scopes && scopes.length > 8}
					noScopesMessage={t('select.faculty.no_studies_found')}
					filterUnlisted
				/>
			</Box>
			<Box>
				<Divider sx={{ my: 2 }} />
				<em className="text-muted">
					{description}
				</em>
				<ul className="keyword-list">
					{intersperse(' ', (faculty.properties?.keywords || []).map(k => (
						<li key={k}>{k}</li>
					)))}
				</ul>
			</Box>
		</Content>
	);
}

export default ScopeFacultyPage
