import { Schemas, CALL_API } from './../schemas'

export const MAILING = 'mailing';

export const addToMailing = ({scopeId, email}) => ({
	[CALL_API]: {
		type: MAILING,
		endpoint: `mailinglist/${scopeId}`,
		body: {
			email: email
		}
	}
});
