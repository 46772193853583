import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { routes } from '../routes'
import { doLogout } from '../actions/auth'
import Content from './../components/content/Content'
import Loader from './../components/loader'
import { getRouterPath } from '../helpers/path';

const LogoutPage = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();

    useEffect(() => {
        dispatch(doLogout());
        navigate(getRouterPath(i18n, routes.HOME, i18n.language, true), { replace: true });
    }, []);

	return (
		<Content>
			<Content.Header>
				<h1>{t('logout.title')}</h1>
			</Content.Header>
			<Loader/>
		</Content>
	);
}

export default LogoutPage;
