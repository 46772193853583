
export default function loading(state = {}, action) {
	const { type } = action;

	if(type.indexOf('_REQUEST') > -1){
		return { ...state,
			[type.replace('_REQUEST', '').toLowerCase()]: true,
		};
	}

	if(type.indexOf('_SUCCESS') > -1){
		return { ...state,
			[type.replace('_SUCCESS', '').toLowerCase()]: false,
		};
	}

	if(type.indexOf('_FAILURE') > -1){
		return { ...state,
			[type.replace('_FAILURE', '').toLowerCase()]: false,
		};
	}

	return state;
}
