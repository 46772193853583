import { assocPath, mapObjIndexed, mergeDeepRight, split } from "ramda";
import translationDe from '../../locales/translation_de.json';
import translationEn from '../../locales/translation_en.json';
import translationNl from '../../locales/translation_nl.json';

const i18nServices = {
	generateResources(rootTranslations) {
		const overrides = {};
		// Add extra translations
		if (rootTranslations){
			mapObjIndexed((translations, key) => {
				Object.keys(translations).forEach(lang => {
					if(!overrides[lang]) overrides[lang] = {};
					const object = assocPath(split('.', key), translations[lang], {});
					overrides[lang] = mergeDeepRight(overrides[lang], object);
				});
			}, rootTranslations);
		}
		return {
			en: {
				translation: mergeDeepRight(translationEn, overrides.en || {}),
			},
			nl: {
				translation: mergeDeepRight(translationNl, overrides.nl || {}),
			},
			de: {
				translation: mergeDeepRight(translationDe, overrides.de || {}),
			}
		};
	},
};

export default i18nServices;
