import PropTypes from 'prop-types';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTagManager } from './hoc/hooks/useTagManager';
import RootLoader from './hoc/RootLoader';
import I18nProvider from './setup/i18nProvider';
import ThemeProvider from './style/ThemeProvider';

function AppEntry({ root, helmetContext, deviceType, children }) {
    useTagManager(root);

    return (
        <I18nProvider>
            <HelmetProvider context={helmetContext}>
                <ThemeProvider deviceType={deviceType}>
                    {children}
                </ThemeProvider>
            </HelmetProvider>
        </I18nProvider>
    );
}

AppEntry.propTypes = {
    helmetContext: PropTypes.object,
};

export default RootLoader(AppEntry);
