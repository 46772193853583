export default {
	primary: {
		main: '#5E2163',
	},
	error: {
		main: '#d04c3d',
	},
	info: {
		main: '#4165ff',
	},
	white: {
		main: '#fff',
		contrastText: '#000',
	},
	primarySubDark: '#7e4292',
	primarySubLight: '#9e79a1',
	primaryGradient1: '#8a5092',
	primaryGradient2: '#77467e',
};
