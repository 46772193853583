import React, { Component, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const defaultOptions = {
	failureRedirectPath: undefined,
	FailureComponent: undefined,
	wrapperDisplayName: 'AuthCheck',
	allowHistoryBack: false,
	predicate: () => true
};

/**
 *
 * @param {Object} options
 * @param {function} [options.failureRedirectPath=undefined] - when unauthorized redirect to path
 * @param {Component} [options.FailureComponent=undefined] - when unauthorized render this Component
 * @param {string} [options.wrapperDisplayName=AuthCheck] - nice display name for the wrapper
 * @param {bool} [options.allowHistoryBack=false] - allow user to navigate back after redirect
 * @param {function} [options.predicate=() => true] - predicate function receiving auth context
 * @returns {function(Component)} function which accepts a DecoratedComponent to wrap with
 */
export default options => {
	const {
		failureRedirectPath, FailureComponent, allowHistoryBack, predicate
	} = {...defaultOptions, ...options};

	const isAuthorized = (auth) => {
		return auth?.loggedIn && predicate(auth);
	};

	return DecoratedComponent => () => {
		const { i18n } = useTranslation();
		const navigate = useNavigate();
		const auth = useAuth();

		useEffect(() => {
			if (isAuthorized(auth)) return;

			if (failureRedirectPath) {
				const path = failureRedirectPath(i18n);
				if (!allowHistoryBack) {
					navigate(path, { replace: true })
				} else {
					navigate(path);
				}
			}
		}, [])

		if (isAuthorized(auth)) {
			return <DecoratedComponent/>;
		}

		return FailureComponent && <FailureComponent/>;
	};
}
