import { Grid } from "@mui/material";
import React from 'react'
import { Trans } from 'react-i18next'
import Content from './../components/content/Content'
import PasswordForgotForm from './../components/password/PasswordForgotForm'

const PasswordForgotPage = () => (
	<Content>
		<Content.Header>
			<h1>
				<Trans i18nKey="password.forgot.title"
								  defaults="Wachtwoord vergeten" />
			</h1>
		</Content.Header>
		<Grid container justifyContent="center">
			<Grid item xs={10} md={6}>
				<PasswordForgotForm />
			</Grid>
		</Grid>
	</Content>
);

export default PasswordForgotPage
