import { Button, Dialog, DialogContent, dialogContentClasses, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowBack, ArrowForward, Check, PriorityHigh } from "@mui/icons-material";
import classnames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { addBasketItem } from "../../actions/basket";
import FormData from "../../hoc/FormData";
import useRootScopeProperties from '../../hoc/hooks/useRootScopeProperties';
import { path, routes } from "../../routes";
import Loader from "../loader";
import CourseForwardSelling from "./CourseForwardSelling";

const PREFIX = 'CourseAddToBasketModal';

const classes = {
	paper: `${PREFIX}-paper`,
	paperFullWidth: `${PREFIX}-paperFullWidth`,
	header: `${PREFIX}-header`,
	icon: `${PREFIX}-icon`,
	actions: `${PREFIX}-actions`,
	actionsFlex: `${PREFIX}-actionsFlex`,
	buttonBack: `${PREFIX}-buttonBack`,
};

const StyledDialog = styled(Dialog)(({ theme}) => ({
	[`& .${classes.header}`]: {
		display: 'flex',
		alignItems: 'center',
		minHeight: '6rem',
		gap: theme.spacing(2),
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText,
		padding: theme.spacing(2),
		'& p': {
			marginBottom: 0,
			color: theme.palette.grey[200],
		},
		'&.error': {
			backgroundColor: theme.palette.error.main,
			[`& .${classes.icon}`]: {
				background: theme.palette.error.dark,
			},
		},
	},
	[`& .${classes.icon}`]: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.primary.dark,
		borderRadius: '50%',
		width: '2.8rem',
		height: '2.8rem',
		flex: '0 0 2.8rem',
		animation: 'Basket-Icon 700ms ease-out',
		animationFillMode: 'both',
		animationDelay: '200ms',
		'& svg': {
			color: theme.palette.primary.contrastText,
			stroke: theme.palette.primary.contrastText,
			animation: 'Basket-Icon-Svg 500ms ease-out',
			animationDelay: '500ms',
			animationFillMode: 'forwards',
			opacity: 0,
		},
	},
	[`& .${classes.actions}`]: {
		display: 'flex',
		padding: theme.spacing(3),
		flexDirection: 'column-reverse',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 2, 1),
			[`& .${classes.actionsFlex}`]: {
				marginBottom: theme.spacing(1),
			},
		},
		[theme.breakpoints.up('sm')]: {
			alignItems: 'center',
			flexDirection: 'row',
			[`& .${classes.actionsFlex}`]: {
				flex: 1,
				textAlign: 'right',
			},
		},
	},
	[`& .${classes.buttonBack}`]: {
		marginLeft: theme.spacing(-1),
		'& svg': {
			marginRight: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
	'@keyframes Basket-Icon': {
		'0%': { transform: 'scale(1,1) translateY(-100px)',},
		'10%':  { transform: 'scale(1.1,.9) translateY(5px)' },
		'30%':  { transform: 'scale(.9,1.1) translateY(-10px)' },
		'50%':  { transform: 'scale(1.05,.95) translateY(0)' },
		'57%':  { transform: 'scale(1,1) translateY(-5px)' },
		'64%':  { transform: 'scale(1,1) translateY(0)' },
		'100%': { transform: 'scale(1,1) translateY(0)' },
	},
	'@keyframes Basket-Icon-Svg': {
		'0%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
	'@keyframes Basket-Button': {
		'10%, 90%': {
			transform: 'translate3d(-1px, 0, 0)',
		},
		'20%, 80%': {
			transform: 'translate3d(2px, 0, 0)',
		},
		'30%, 50%, 70%': {
			transform: 'translate3d(-4px, 0, 0)',
		},
		'40%, 60%': {
			transform: 'translate3d(4px, 0, 0)',
		},
	},

	[`&.mobile .${classes.paper}`]: {
		margin: theme.spacing(2),
	},
	[`&.mobile .${classes.paperFullWidth}`]: {
		width: `calc(100% - ${theme.spacing(4)})`,
	},
	[`&.saving .${classes.paper}`]: {
		background: 'none',
		boxShadow: 'none'
	}
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	[`&.${dialogContentClasses.root}`]: {
		paddingTop: 0,
		paddingBottom: theme.spacing(0.5),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		'&.gradient': {
			maskImage: 'linear-gradient(180deg, #000 80%, transparent)',
		},
	},
}));

const StyledButton = styled(Button)(({ theme }) => ({
	animation: 'Basket-Button 0.82s cubic-bezier(.36,.07,.19,.97) both',
	animationDelay: '2s',
	fontSize: '1.1rem',
	color: theme.palette.common.white,
	[theme.breakpoints.down('md')]: {
		marginTop: theme.spacing(1),
	},
	'& svg': {
		marginLeft: theme.spacing(1),
	},
}));

const CourseAddToBasketModal = ({ course, group, onClose, addBasketItem, watchSubmit, saving, error }) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [gradient, setGradient] = useState(false);
	const { config: { forwardSelling } } = useRootScopeProperties();

	useEffect(() => {
		watchSubmit(addBasketItem(group.id));
	}, [])

	const icon = useMemo(() => {
		if (error) return <PriorityHigh />
		return <Check />
	}, [error]);

	const message = useMemo(() => {
		if (error) {
			if (error.error === 'Product is not available anymore') {
				return t('course.product_not_available');
			}
			return t('course.add_to_basket_error');
		}
		return t('course.added_to_basket');
	}, [t, error]);

	return (
		<StyledDialog
			open={true}
			className={classnames({
				mobile: isMobile,
				saving: saving,
			})}
			maxWidth="md"
			fullWidth
		>
			{saving ? (
				<Loader />
			) : (
				<>
					<div className={classnames(classes.header, {
						error: !!error,
					})}>
						<div className={classes.icon}>
							{icon}
						</div>
						<div>
							<Typography variant="h6">{message}</Typography>
							<Typography variant="body2">{course.name} - {group.name}</Typography>
						</div>
					</div>
					{!!forwardSelling && (
						<StyledDialogContent className={classnames({
							gradient,
						})}>
							<CourseForwardSelling group={group} setGradient={setGradient} />
						</StyledDialogContent>
					)}
					<div className={classes.actions}>
						<Button
							onClick={onClose}
							variant="text"
							fullWidth={isMobile}
							className={classes.buttonBack}
						>
							<ArrowBack />
							{t('courses.close_basket_modal')}
						</Button>
						{!error && (
							<div className={classes.actionsFlex}>
								<StyledButton
									to={path(i18n, routes.CHECKOUT)}
									onClick={onClose}
									component={Link}
									variant="contained"
                                    color="cta"
									fullWidth={isMobile}
								>
									{t('courses.go_to_basket')}
									<ArrowForward />
								</StyledButton>
							</div>
						)}
					</div>
				</>
			)}
		</StyledDialog>
	);
}

CourseAddToBasketModal.propTypes = {
	course: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default connect(null, {
	addBasketItem,
})(FormData(CourseAddToBasketModal, 'add-basket-item'));
