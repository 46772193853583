import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import fetch from 'isomorphic-fetch';
import { gtmApiError } from '../helpers/gtm';
import { createApiTypes } from '../helpers/types';
import { LOGOUT } from '../actions/auth';

// For the browser, especially in local development, access through the proxy by default
const browserRoot = process.env.API_DIRECT === 'true' ? process.env.API_URL : '/api/';

// On SSR the proxy might not be accessible, so directly access the URL
const API_ROOT = !import.meta.env.SSR ? browserRoot : process.env.API_URL;

const auth_header_token = 'X-ACCESS-TOKEN';
export const AuthCookieName = 'ATHENASTUDIES-AUTH';

function readAuthData(cookies, body){
	if((body.tokenValue && body.expires) || (body.token && body.token.tokenValue && body.token.expires)){
		const token = body.tokenValue || body.token.tokenValue;
		const expires = body.expires || body.token.expires;

		cookies.set(AuthCookieName, token, {
			expires: new Date(expires),
		});
	}
}

function callApi(cookies, endpoint, schema, method = 'get', body = null){
	const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
	const options = {
		method: method.toLowerCase(),
		headers: {}
	};

	// if(IS_SERVER){
	// 	options.headers['Host'] = `${hostname()}`;
	// 	options.headers['User-Agent'] = 'React SSR';
	// }

	if(body){
		if (options.method === undefined || options.method === 'get') {
			options.method = 'post';
		}

		options.body = JSON.stringify(body);//decamelizeKeys(body)); // TODO: decamelizeKeys fucks bodies
		options.headers['Content-Type'] = 'application/json; charset=utf-8';
	}

	const authToken = cookies.get(AuthCookieName);

	if(authToken){
		options.headers[auth_header_token] = authToken;
	}

	return fetch(fullUrl, options)
		.then(response => {
			// Try to parse in json
			return response.json()
				.then(json => ({ json, response }))
				.catch(error => {
					return ({ json: null, response })
				});
		})
		.then(({ json, response }) => {
			// Response checks
			if (!response.ok) {
				return Promise.reject({
					...json,
					errorCode: response.status,
				});
			}

			if(!json) return {};

			// Authentication
			readAuthData(cookies, json);

			// Create final data
			let data = camelizeKeys(json);

			// Normalizing with and without scheme
			if(schema){
				data = Object.assign({},
					normalize(data, schema)
				);
			}else{
				data = Object.assign({}, {
					result: data
				});
			}

			return data;
		})
		.catch((error) => {
			return Promise.reject(error);
		});
}

export const CALL_API = Symbol('Call API');

export default cookies => store => next => action => {
	// Catch logout
	if(action.type === LOGOUT){
		cookies.remove(AuthCookieName);
		return next(action);
	}

	// Get the api request data
	const callAPI = action[CALL_API];
	if (typeof callAPI === 'undefined') {
		return next(action)
	}

	const { endpoint, schema, type, method, body } = callAPI;

	function nextWith(data) {
		const finalAction = Object.assign({
			originalType: type,
		}, action, data);
		delete finalAction[CALL_API];
		return finalAction;
	}

	const [ requestType, successType, failureType ] = createApiTypes(type);
	next(nextWith({ type: requestType }));

	return callApi(cookies, endpoint, schema, method, body).then(
		response => next(nextWith({
			response,
			type: successType
		})),
		error => {
			// Dispatch logout success if status is 401 to let the store know
			if(error && error.errorCode === 401){
				cookies.remove(AuthCookieName);
				next({
					type: LOGOUT,
					autoLogout: true
				});
			}

			gtmApiError(error.errorCode, error);

			return next(nextWith({
				type: failureType,
				error: error.error,
				errors: error.requestErrors,
				errorCode: error.errorCode,
				uuid: error.uuid
			}))
		});
}

export function createFetchRequest(req) {
	let origin = `${req.protocol}://${req.get("host")}`;
	// Note: This had to take originalUrl into account for presumably vite's proxying
	let url = new URL(req.originalUrl || req.url, origin);

	let controller = new AbortController();
	req.on("close", () => controller.abort());

	let headers = new Headers();

	for (let [key, values] of Object.entries(req.headers)) {
		if (values) {
			if (Array.isArray(values)) {
				for (let value of values) {
					headers.append(key, value);
				}
			} else {
				headers.set(key, values);
			}
		}
	}

	let init = {
		method: req.method,
		headers,
		signal: controller.signal,
	};

	if (req.method !== "GET" && req.method !== "HEAD") {
		init.body = req.body;
	}

	return new Request(url.href, init);
}
