import { styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { insert, range } from 'ramda';

const min = 1;
const max = 10;
const ratings = insert(5, 'spacer', range(min, max + 1));

const PREFIX = 'EvaluationRatingQuestion';
const classes = {
    spacer: `${PREFIX}-spacer`,
    active: `${PREFIX}-active`,
};

const StyledRatingQuestion = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',

    '& > span': {
        display: 'inline-block',
        flexGrow: 0,

        '&.spacer': {
            display: 'none',
        },

        '& > span': {
            display: 'inline-block',
            background: '#ccc',
            cursor: 'pointer',

            width: '2.5em',
            height: '2.5em',
            lineHeight: '2.5em',
            borderRadius: '50%',
            textAlign: 'center',
            paddingTop: '1px',
            marginRight: '8px',
            marginBottom: '8px',

            [`&.${classes.active}`]: {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',

        '& > span': {
            [`&.${classes.spacer}`]: {
                display: 'inline-block',
                flex: '1 0 100%',
            },
        },
    },
}));

function EvaluationRatingQuestion({ value, onChange }) {
    return (
        <StyledRatingQuestion>
            {ratings.map(rating => rating === 'spacer' ? (
                <span className={classes.spacer} key={rating} />
            ) : (
                <span key={rating}>
                    <span onClick={() => onChange(rating)} className={value === rating ? classes.active : ''}>
                        {rating}
                    </span>
                </span>
            ))}
        </StyledRatingQuestion>
    );
}

EvaluationRatingQuestion.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};

export default EvaluationRatingQuestion;
