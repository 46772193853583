import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createRootReducers from './../reducers'
import api from './api'
import { initialSchemasState } from '../schemas'

export default function configureStore(initialState = {}, cookies) {
	const finalCreateStore = compose(
		applyMiddleware(
			thunk.withExtraArgument(cookies),
			api(cookies),
		),
		typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
	)(createStore);

	return finalCreateStore(createRootReducers(cookies), {
		...initialSchemasState,
		...initialState,
	})
}
