import { styled, TextField } from "@mui/material";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { questionOpenFieldKeys } from './EvaluationForm'
import EvaluationRatingQuestion from './EvaluationRatingQuestion'

const PREFIX = 'EvaluationFormQuestion';
const classes = {
	error: `${PREFIX}-error`,
}

const StyledEvaluationQuestion = styled('div')(({ theme }) => ({
	marginBottom: '24px',

	'& > h4': {
		marginBottom: 0,
		fontWeight: '600',
		fontSize: '1.2em',
	},

	[`& .${classes.error}`]: {
		display: 'inline-block',
		marginLeft: '10px',
		fontSize: '0.8em',
		color: theme.palette.error.main,
		fontStyle: 'italic',
	},
}))

class EvaluationFormQuestion extends Component {
	render(){
		const { number, questionItem, error, value, onChange } = this.props;
		const isOpenField = questionOpenFieldKeys.indexOf(questionItem.key) > -1;

		return (
			<StyledEvaluationQuestion>
				<h4>
					{isOpenField
						? questionItem.question
						: (
							<Trans
								i18nKey="evaluation.question"
								defaults="Vraag {{number}}"
								values={{
									number: number,
								}}
							/>
						)
					}
					{Boolean(error) && (
						<span className={classes.error}>* {error}</span>
					)}
				</h4>
				{!isOpenField && (
					<p>{questionItem.question}</p>
				)}
				{isOpenField ? (
					<TextField
						multiline
						onChange={this.handleChangeOpenField}
						value={value || ''}
						fullWidth
						size="medium"
					/>
				) : (
					<EvaluationRatingQuestion value={value} onChange={onChange} />
				)}
			</StyledEvaluationQuestion>
		)
	}

	handleChangeOpenField = (e) => {
		this.props.onChange(e.target.value);
	}
}

EvaluationFormQuestion.propTypes = {
	number: PropTypes.number.isRequired,
	questionItem: PropTypes.object.isRequired,
	error: PropTypes.any,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
}

export default EvaluationFormQuestion
