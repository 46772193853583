import { Done } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import React, { Component } from 'react';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withRouter from '../helpers/withRouter';
import { routes, path } from '../routes';
import { BASKET, removeBasketItem, removeDiscount, orderBasket, clearActiveOrder } from '../actions/basket';
import { getLoading } from '../selectors/loading';
import { userMeSelector } from '../selectors/user';
import Content from '../components/content/Content';
import Basket from '../components/basket/Basket';
import BackgroundSheet from '../components/general/BackgroundSheet';
import Checkout from '../components/checkout/Checkout';
import CheckoutTimer from '../components/checkout/CheckoutTimer';
import Loader from '../components/loader';

class CheckoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            methodId: null,
            methodSubId: null,
            ordering: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { methodId, methodSubId } = this.state;
        const { order, navigate, i18n } = this.props;

        // Received a new order and paying intention, redirect
        if ((!prevProps.order || prevProps.order.positive) && order && methodId) {
            if (methodId === 9999) {
                navigate(path(i18n, routes.PAY));
            } else if (!methodSubId) {
                navigate(path(i18n, routes.PAY_METHOD_BASE, methodId));
            } else {
                navigate(path(i18n, routes.PAY_METHOD_SUB, methodId, methodSubId));
            }
        }
    }

    handlePayRequest = (methodId, methodSubId) => {
        const { orderBasket } = this.props;

        this.setState({
            methodId: methodId || 9999,
            methodSubId,
            ordering: true,
        }, () => {
            orderBasket();
        });
    };

    renderContent() {
        const { basket, partner, loading, removeBasketItem, removeDiscount, order, activeOrderId, clearActiveOrder, t, i18n } = this.props;
        const { ordering } = this.state;

        if (activeOrderId || (order && !order.positive && order.status !== 'FULL_DISCOUNT' && order.status !== 'FREE')) return (
            <Alert severity="info" icon={false}>
                <p>{t('checkout.open_order.text')}</p>
                <div style={{ marginTop: 8 }}>
                    <Button
                        component={Link}
                        to={path(i18n, routes.PAY)}
                        color="success"
                        variant="contained"
                        startIcon={<Done />}
                    >
                        {t('checkout.open_order.see_and_pay')}
                    </Button>
                    {' '}
                    <Button onClick={() => clearActiveOrder()} variant="contained" color="error">
                        {t('checkout.open_order.cancel')}
                    </Button>
                </div>
            </Alert>
        );

        if (!basket && loading) return <Loader />;
        if (!basket || !basket.basketContents || basket.basketContents.length === 0) return (
            <Alert severity="info" icon={false}>{t('checkout.empty_basket')}</Alert>
        );

        return (
            <div>
                { (loading || ordering) && <Loader sheet /> }
                <Basket
                    basket={basket}
                    partner={partner}
                    removeBasketItem={removeBasketItem}
                    removeDiscount={removeDiscount}
                />
                <BackgroundSheet isCheckoutWrapper>
                    <Checkout onPayRequest={this.handlePayRequest} basket={basket} />
                </BackgroundSheet>
            </div>
        );
    }

    render() {
        const { t, firstAdded } = this.props;

        return (
            <Content>
                <Content.Header>
                    <h1>{t('checkout.shopping_basket')}</h1>
                    <CheckoutTimer startDateTime={firstAdded} durationMinutes={15} />
                </Content.Header>
                {this.renderContent()}
            </Content>
        );
    }
}

CheckoutPage.propTypes = {
    basket: PropTypes.object,
    partner: PropTypes.object,
    loading: PropTypes.bool,
    order: PropTypes.object,
    activeOrderId: PropTypes.number,
    removeBasketItem: PropTypes.func,
    removeDiscount: PropTypes.func,
    orderBasket: PropTypes.func,
    clearActiveOrder: PropTypes.func,
};

export default compose(
    withTranslation(),
    withRouter,
    connect((state) => ({
        basket: state.init.shoppingBasket,
        firstAdded: state.init.shoppingBasketFirstAdded,
        partner: state.init.partner,
        loading: getLoading(state, BASKET),
        order: state.pay.order,
        activeOrderId: state.pay.activeOrderId,
        me: userMeSelector(state), // Nasty trick to force re-render after login
    }), {
        removeBasketItem,
        removeDiscount,
        orderBasket,
        clearActiveOrder,
    }),
)(CheckoutPage);
