import { Grid } from "@mui/material";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPaymentMethods } from '../../actions/auth'
import { rootScopeSelector } from '../../selectors/scope'
import Loader from '../loader'
import CheckoutMethodsSection from './CheckoutMethodsSection'
import CheckoutMethodTitle from './CheckoutMethodTitle'
import StyledCheckoutMethodsSection from './StyledCheckoutMethodsSection';

class CheckoutMethods extends Component {
    render() {
    	const { methods, rootScope } = this.props;

    	const country = ((rootScope.properties || {}).config || {}).pspCountry || 'nl';

		const mainMethods = methods && methods.filter(method => method.country === country);
		const restMethods = methods && methods.filter(method => method.country !== country);

        return (
            <div>
				{methods && (
					<div>
						{mainMethods.map(method => (
							<CheckoutMethodsSection
								key={method.id}
								isMain={true}
								method={method}
								onMethodClick={this.handleMethodClick}
							/>
						))}
						{restMethods.length > 0 && (
							<StyledCheckoutMethodsSection>
								<CheckoutMethodTitle />
								<Grid container spacing={3}>
									{restMethods.map(method => (
										<CheckoutMethodsSection
											key={method.id}
											isMain={false}
											method={method}
											onMethodClick={this.handleMethodClick}
										/>
									))}
								</Grid>
							</StyledCheckoutMethodsSection>
						)}
					</div>
				) || <Loader />}
			</div>
        );
    }

    componentDidMount(){
    	this.props.getPaymentMethods();
	}

	handleMethodClick = (method, sub) => {
    	if(method.id === 0){
    		method = sub;
			sub = undefined;
		}

    	return this.props.onPayRequest(method.id, sub && sub.id);
	};
}

CheckoutMethods.propTypes = {
	onPayRequest: PropTypes.func.isRequired,
	methods: PropTypes.array,
	getPaymentMethods: PropTypes.func,
};

export default connect((state) => {
	return {
		methods: state.paymentMethods,
		rootScope: rootScopeSelector(state),
	};
}, {
	getPaymentMethods
})(CheckoutMethods)
