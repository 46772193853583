import { styled } from "@mui/material";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'HomeBlock';
const classes = {
	block: `${PREFIX}-block`,
}

const Block = styled(({ title, children, className, ...props }) => (
	<div className={classnames(className, classes.block)} {...props}>
		<h1>{title}</h1>
		<div>
			{children}
		</div>
	</div>
))(({ theme }) => ({
	[`&.${PREFIX}-block`]: {
		textAlign: 'center',
		color: theme.palette.common.white,
		margin: theme.spacing(0, 0, 4, 0),

		'& h1': {
			fontSize: '34px',
			fontWeight: '500',
			margin: '0 0 17px 0',
			lineHeight: '38px',
		},

		'& > div': {
			fontSize: '16px',
			fontWeight: '100',
			whiteSpace: 'pre-wrap',
		},

		[theme.breakpoints.down('sm')]: {
			marginTop: '20px',
		},
	},
}));

Block.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default Block
