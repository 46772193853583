import { Chip, chipClasses, Divider, dividerClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { lightGreen, orange, red } from "@mui/material/colors";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCourseStockStatus, getStockStatusClass } from "../../helpers/course";
import { sortAlphabet } from "../../helpers/lists";

const PREFIX = 'CourseStockStatusChips';

const classes = {
    root: `${PREFIX}-root`,
    avatar: `${PREFIX}-avatar`,
    label: `${PREFIX}-label`,
    statusChip: `${PREFIX}-statusChip`,
    circle: `${PREFIX}-circle`,
    divider: `${PREFIX}-divider`
};

const StyledChip = styled(Chip)(({ theme }) => ({
	height: theme.spacing(3),
	position: 'relative',
	border: '1px solid',
	marginRight: theme.spacing(1),
	marginBottom: theme.spacing(0.5),
	'&.success': {
		color: lightGreen[800],
		borderColor: lightGreen[800],
		backgroundColor: lightGreen[50],
		[`& .${chipClasses.avatar}:after, & .${dividerClasses.root}`]: {
			background: lightGreen[800],
		},
	},
	'&.warning': {
		color: orange[800],
		borderColor: orange[800],
		backgroundColor: orange[50],
		[`& .${chipClasses.avatar}:after, & .${dividerClasses.root}`]: {
			background: orange[700],
		},
	},
	'&.danger': {
		color: red[800],
		borderColor: red[800],
		backgroundColor: red[50],
		[`& .${chipClasses.avatar}:after, & .${dividerClasses.root}`]: {
			background: red[800],
		},
	},

    [`& .${chipClasses.avatar}`]: {
		position: "relative",
		marginLeft: 1,
		'&::after': {
			position: 'absolute',
			content: '""',
			top: theme.spacing(1),
			left: theme.spacing(1),
			display: 'inline-block',
			borderRadius: '50%',
			height: `calc(100% - ${theme.spacing(2)})`,
			width: `calc(100% - ${theme.spacing(2)})`,
			background: theme.palette.grey[500],
		}
	},

	[`& .${chipClasses.label}`]: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		padding: theme.spacing(0, 1.5, 0, 0.75),
	}
}));

const createStockStatusCounts = (course) => {
	if (!course.subScopes) return;
	const counts = {};
	course.subScopes
		.sort(sortAlphabet('courseStockStatus'))
		.forEach(group => {
			switch (group.courseStockStatus) {
				case 'FINISHED':
				case 'FULL':
					counts.FULL = counts.FULL ? counts.FULL + 1 : 1;
					break;
				case 'ALMOST_FULL':
				case 'ONGOING':
					counts.ALMOST_FULL = counts.ALMOST_FULL ? counts.ALMOST_FULL + 1 : 1;
					break;
				case 'SUFFICIENT_PLACES':
					counts.SUFFICIENT_PLACES = counts.SUFFICIENT_PLACES ? counts.SUFFICIENT_PLACES + 1 : 1;
					break;
				default:
					break;
			}
		})

	return counts;
}

function CourseStockStatusChips({course}) {
	const { t } = useTranslation();

	const counts = useMemo(() => createStockStatusCounts(course), [course]);

	return (
        <div>
			{Object.entries(counts).map((status) => (
				<StyledChip
                    className={getStockStatusClass(status[0])}
                    avatar={<div />}
                    label={(
						<>
							{getCourseStockStatus(status[0], t)}
							<Divider sx={{ mx: 0.75, height: '60%' }} orientation="vertical" />
							{t('courses.status.courses', {
								count: status[1] || 0,
							})}
						</>
					)}
                    key={status[0]}
                />
			))}
		</div>
    );
}

export default CourseStockStatusChips;
