import { NavigateBefore } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'

const ContentBack = ({ to, message }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	if(to) return (
		<Button startIcon={<NavigateBefore />} component={Link} to={to} variant="outlined" color="white">
			{message || t('content.back')}
		</Button>
	);

	return (
        <Button startIcon={<NavigateBefore />} component={Link} onClick={() => navigate(-1)} variant="outlined" color="white">
			{message || t('content.back')}
		</Button>
    );
};

ContentBack.propTypes = {
	to: PropTypes.string,
	message: PropTypes.string,
};

export default ContentBack
