import { styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { moneyComposer } from './../../helpers/composers'
import { dateNormal, time } from './../../helpers/datetime'

const StyledTable = styled('table')(({ theme }) => ({
	'tr': {
		'th, td': {
			padding: '4px 6px',
		}
	},

	'tr + tr': {
		'th, td': {
			borderTop: `1px solid ${theme.palette.grey[100]}`
		}
	},
}));

const PayOrderInfo = ({ order }) => {
	const { t } = useTranslation();
	return (
		<StyledTable>
			<tbody>
			<tr>
				<th>{t('pay.order_info.order_number')}</th>
				<td>{order.id}</td>
			</tr>
			<tr>
				<th>{t('pay.order_info.price')}</th>
				<td>{moneyComposer(order.totalPrice)}</td>
			</tr>
			<tr>
				<th>{t('pay.order_info.items')}</th>
				<td>{order.orderEntries.length}</td>
			</tr>
			<tr>
				<th>{t('pay.order_info.date')}</th>
				<td>{dateNormal(order.orderDateTime)} {t('pay.order_info.date_at')} {time(order.orderDateTime)}</td>
			</tr>
			{
				order.client &&
				<tr>
					<th>{t('pay.order_info.email')}</th>
					<td>{order.client.email}</td>
				</tr>
			}
			</tbody>
		</StyledTable>
	);
}

PayOrderInfo.propTypes = {
	order: PropTypes.object.isRequired
};

export default PayOrderInfo
