import { Box, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
	marginLeft: '-20px',
	marginRight: '-20px',
	padding: '33px 80px',
	[theme.breakpoints.down('md')]: {
		padding: '26px 20px',
	},
}))

export default StyledBox;
