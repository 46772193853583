// ===========================
// Main internal actions
// ===========================
const fetchDataLoading = (id) => ({
	type: 'FETCH_DATA_LOADING',
	fetchDataId: id
});

const fetchDataError = (id, error) => ({
	type: 'FETCH_DATA_ERROR',
	fetchDataId: id,
	error: error
});

const fetchDataSuccess = (id, res, pagination) => ({
	type: 'FETCH_DATA_SUCCESS',
	fetchDataId: id,
	result: res,
	pagination
});

const fetchDataClear = (id) => ({
	type: 'FETCH_DATA_CLEAR',
	fetchDataId: id
});

// ===========================
// Helper actions which can manipulate the
// ... results for a given fetchDataId
// ===========================
export const fetchDataAppend = (id, res) => ({
	type: 'FETCH_DATA_APPEND',
	fetchDataId: id,
	result: res
});

export const fetchDataConcat = (id, res) => ({
	type: 'FETCH_DATA_CONCAT',
	fetchDataId: id,
	result: res
});

export const fetchDataDelete = (id, res) => ({
	type: 'FETCH_DATA_DELETE',
	fetchDataId: id,
	result: res
});

// ===========================
// Internal page related actions
// ===========================
const fetchDataPage = (id, page) => ({
	type: 'FETCH_DATA_PAGE',
	fetchDataId: id,
	page: page
});

const fetchDataPageSize = (id, pageSize) => ({
	type: 'FETCH_DATA_PAGE_SIZE',
	fetchDataId: id,
	pageSize: pageSize
});

export const fetchDataInternalActions = {
	fetchDataLoading,
	fetchDataError,
	fetchDataSuccess,
	fetchDataClear,
	fetchDataPage,
	fetchDataPageSize,
}
