import moment from "moment";
import { max, reduce } from "ramda";

export const getCourseStockStatus = (stockStatus, t) => {
	switch(stockStatus){
		case 'SUFFICIENT_PLACES': return t('course.status.sufficient_places');
		case 'ALMOST_FULL': return t('course.status.almost_full');
		case 'FULL': return t('course.status.full');
	}
}

export const getStockStatus = (stockStatus, t) => {
	switch(stockStatus){
		case 'SUFFICIENT_PLACES': return t('course.group.status.sufficient_places');
		case 'ALMOST_FULL': return t('course.group.status.almost_full');
		case 'FULL': return t('course.group.status.full');
		case 'ONGOING': return t('course.group.status.ongoing');
		case 'FINISHED': return t('course.group.status.finished');
	}
};

export const getStockStatusButton = (stockStatus, t) => {
	switch(stockStatus){
		case 'SUFFICIENT_PLACES': return t('course.group.pick_course');
		case 'ALMOST_FULL': return t('course.group.pick_course');
		case 'FULL': return t('course.group.not_available');
		case 'ONGOING': return t('course.group.pick_course');
		case 'FINISHED': return t('course.group.pick_course');
	}
};

export const getStockStatusClass = (stockStatus) => {
	switch(stockStatus){
		case 'SUFFICIENT_PLACES': return 'success';
		case 'ALMOST_FULL': return 'warning';
		case 'FULL': return 'danger';
		case 'ONGOING': return 'warning';
		case 'FINISHED': return 'danger';
	}
};

export const getStockStatusHiddenClass = (stockStatus) => {
	switch(stockStatus){
		case 'FULL': return 'hidden';
		case 'FINISHED': return 'hidden';
	}

	return '';
};

export const isUnavailableByStockStatus = (stockStatus) => {
	return stockStatus === 'FULL' || stockStatus === 'FINISHED';
};

export const getCourseType = (courseType, t) => {
	switch(courseType){
		case 'Stoomcursus': return t('course.type.quick');
		case 'Basiscursus': return t('course.type.base');
		default: return t('course.type.base');
	}
};

const getMaximumMinutes = (groups) => reduce(max, 0, groups.map(group => {
	return reduce(max, 0, groups.filter(group => !isUnavailableByStockStatus(group.courseStockStatus)).map(group => {
		return reduce((acc, timeSlot) => {
			return acc + moment(timeSlot.endDateTime).diff(timeSlot.startDateTime, 'minutes');
		}, 0, group?.timeslots);
	}))
}));

export const getHourPrice = (price, groups) => {
	if(price.amount === 0) return false;

	const minutes = getMaximumMinutes(groups);

	if(minutes === 0) return false;

	return {
		amount: price.amount / (minutes / 60),
		currency: price.currency,
	};
}

export const getMaxHours = (groups) => {
	const minutes = getMaximumMinutes(groups);

	if(minutes === 0) return false;
	return Math.round(minutes / 60 * 100) / 100;
}
