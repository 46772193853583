import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles';
import { splitEvery } from 'ramda'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'MarkdownListTable';

const classes = {
    root: `${PREFIX}-root`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${classes.root}`]: {
		fontSize: '1.4rem',
		border: `1px solid ${theme.palette.divider}`,
	},
}))

const MarkdownListTable = ({ items, columns, separator }) => {
	const columnCount = parseInt(columns) || 3;

	const rows = useMemo(() => (
		splitEvery(columnCount, items?.split?.(separator) || [])
	), [items]);

	if(rows.length === 0) return null;

	return (
        <Table size="small" sx={{ mx: 0, my: 3 }}>
			<TableBody>
				{rows.map((row, i) => (
					<TableRow key={`${i}-${row.join('-')}`}>
						{row.map((item, j) => (
							<StyledTableCell
                                key={`${j}-${item}`}
                                width={`${100 / columnCount}%`}
                                classes={{
                                    root: classes.root
                                }}>
								{item}
							</StyledTableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
    );
};

MarkdownListTable.propTypes = {
	items: PropTypes.string.isRequired,
	columns: PropTypes.string,
	separator: PropTypes.string,
};

MarkdownListTable.defaultProps = {
	separator: ',',
};

export default MarkdownListTable
