import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import React from 'react';

const StyledScopeListItem = styled(({ component, children, ...props }) => {
	const ComposedComponent = component || Link;
	return (
		<ComposedComponent {...props}>
			{children}
		</ComposedComponent>
	)
})(({ theme }) => ({
	display: 'block',
	padding: '24px 15px 24px 24px',
	borderTop: `solid 1px ${theme.palette.grey[200]}`,
	color: theme.palette.common.black,

	'&:hover': {
		backgroundColor: theme.palette.grey[50],
		textDecoration: 'none',
		color: theme.palette.common.black,
	},

	[theme.breakpoints.up('sm')]: {
		padding: '24px 40px',
	},

	[theme.breakpoints.up('md')]: {
		padding: '24px 70px',
	},

	'.scope-list-item-letter': {
		display: 'inline-block',
		fontSize: '14px',
		color: theme.palette.grey[500], // Replacement for $warm-grey
		textTransform: 'uppercase',
	},

	'.scope-list-item-name': {
		display: 'inline-block',
		fontSize: '18px',
		fontWeight: '600',
		lineHeight: 'normal',
		verticalAlign: '-1px',

		'.scope-list-item-abbreviation': {
			fontSize: '15px',
			color: theme.palette.grey[500],
			fontWeight: 'normal',
			marginLeft: '10px',
		},
	},
}));

export default StyledScopeListItem;
