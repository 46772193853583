import { styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'CheckoutAddressBlock';
const classes = {
	title: `${PREFIX}-title`,
	content: `${PREFIX}-content`,
}

const CheckoutAddressBlock = styled(({ title, children, ...props }) => {
    return (
		<div {...props}>
			<div className={classes.title}>
				{title}
			</div>
			<div className={classes.content}>
				{children}
			</div>
		</div>
    );
})(({ theme }) => ({
	position: 'relative',
	border: `1px solid ${theme.palette.primary.main}`,
	borderRadius: '6px',
	marginTop: '30px',
	marginBottom: '30px',

	[`& .${classes.title}`]: {
		 position: 'absolute',
		 top: '0',
		 left: '10px',
		 backgroundColor: theme.palette.common.white,
		 color: theme.palette.primary.main,
		 fontWeight: '600',
		 padding: '10px',
		 transform: 'translateY(-50%)',
	},

	[`& .${classes.content}`]: {
		marginTop: '10px',
		padding: '20px 20px 10px 20px',
		background: 'transparent',
	}
}));

CheckoutAddressBlock.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default CheckoutAddressBlock
