import { combineReducers } from 'redux'
import { formDataReducer } from '../hoc/FormData'
import fetchDataReducer from '../modules/fetchData/fetchDataReducer'
import entities from './entities'
import error from './error'
import lastPathReducer from './lastPath'
import lists from './lists'
import init from './init'
import loading from './loading'
import paymentMethods from './payment-methods'
import pay from './pay'
import actionLogger from './action-logger'

export default function createRootReducers(cookies){
	let reducers = {
		entities,
		error,
		lists,
		init: init(cookies),
		...lastPathReducer,
		loading,
		paymentMethods,
		pay: pay(cookies),
		formData: formDataReducer,
		...fetchDataReducer,
	};

	if(process.env.NODE_ENV !== 'production'){
		reducers = { ...reducers,
			actionLogger,
		};
	}

	return combineReducers(reducers);
}
