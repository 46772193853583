import { CALL_API } from "../setup/api";

export const CONTACT_FORM_SUBMIT = 'contact_form_submit';
export const submitContactForm = (data) => {
	return {
		[CALL_API]: {
			type: CONTACT_FORM_SUBMIT,
			endpoint: 'contact',
			body: data,
			method: 'POST',
		},
	};
};
