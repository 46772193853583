
export default function lists(state = {}, action) {
	if(action.response && action.response.result && action.forList){
		state = { ...state,
			[action.forList]: action.response.result,
		};
	}

	return state;
}
