import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getCustomLocaleMessage } from '../../helpers/locale'
import Markdown from '../markdown/Markdown'

const CustomMessage = ({ message, markdown, i18n }) => {
	const finalMessage = getCustomLocaleMessage(message, i18n);

	return markdown ? (
		<Markdown>
			{finalMessage}
		</Markdown>
	) : finalMessage;
};

CustomMessage.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	markdown: PropTypes.bool,
};

CustomMessage.defaultProps = {
	markdown: false,
};

export default withTranslation()(CustomMessage)
