import { Create, Logout } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../hoc/hooks/useAuth'
import { routes, path } from '../routes'
import { Trans, useTranslation } from 'react-i18next'
import IsAuthenticated from '../auth'
import Content from './../components/content/Content'
import AccountSimpleOverview from './../components/account/AccountSimpleOverview'
import AccountCourses from './../components/account/AccountCourses'
import Loader from './../components/loader'

const AccountPage = () => {
	const auth = useAuth();
	const { t, i18n } = useTranslation();

	return (
		<Content padding>
			<Content.Header>
				<Grid container rowSpacing={2}>
					<Grid item xs={0} md={4} lg={3} />
					<Grid item xs={12} md={4} lg={6}>
						<h1>
							<Trans i18nKey="account.my_athenastudies"
											  defaults="Mijn AthenaStudies" />
						</h1>
					</Grid>
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'right' } }}>
						<Button
							component={Link}
							startIcon={<Create />}
							to={path(i18n, routes.ACCOUNT_EDIT)}
							color="white"
							variant="outlined"
							sx={{ mr: 2 }}
						>
							<Trans i18nKey="account.edit.title"
											  defaults="Bewerken" />
						</Button>
						<Button
							component={Link}
							startIcon={<Logout />}
							to={path(i18n, routes.LOGOUT)}
							variant="contained"
							color="error"
							sx={{ boxShadow: 0 }}
						>
							{t('header.my.logout')}
						</Button>
					</Grid>
				</Grid>
			</Content.Header>
			{auth.user ? (
				<div>
					<AccountSimpleOverview user={auth.user} />
					<Content.Line />
					<AccountCourses />
				</div>
			) : (
				<Loader/>
			)}
		</Content>
	);
}

export default IsAuthenticated(AccountPage)
