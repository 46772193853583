import { Alert, styled } from "@mui/material";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getEvaluationForm } from '../../actions/evaluation'
import withFetchData from '../../modules/fetchData/withFetchData'
import { changeLocaleAuto, I18N_SOURCE_EVALUATION } from '../../reducers/i18n'
import Loader from '../loader'
import EvaluationForm from './EvaluationForm'

const classes = {
	title: 'Evaluation-title',
}

const StyledEvaluationDiv = styled('div')(({ theme }) => ({
	padding: '0 20px',
	marginBottom: '20px',
	[`& .${classes.title}`]: {
		color: theme.palette.common.white,
		backgroundColor: '$purple',
		fontSize: '24px',
		marginLeft: '-41px',
		marginRight: '-41px',
		marginBottom: '16px',
		padding: '14px 41px 12px 41px',
	},

	[theme.breakpoints.down('md')]: {
		padding: 0,
		[`& .${classes.title}`]: {
			marginLeft: '-21px',
			marginRight: '-21px',
			padding: '14px 21px 12px 21px',
		},
	},
}))

class Evaluation extends Component {
	componentDidUpdate(prevProps){
		const { data, changeLocaleAuto } = this.props;

		if(data && data.locale && !prevProps.data){
			changeLocaleAuto(data.locale, I18N_SOURCE_EVALUATION);
		}
	}

	render(){
		const { token, loading, error, data, i18n } = this.props;

		if(error) return (
			<Alert severity="warning" icon={false}>
				{error.error}
			</Alert>
		);

		if(loading || !data) return (
			<Loader />
		);

		// Find the correct question set
		let questions = data.questions;
		if(data.questionLocale && i18n.language && data.questionLocale[i18n.language]){
			questions = data.questionLocale[i18n.language];
		}

		return (
			<StyledEvaluationDiv>
				<div className={classes.title}>
					{data.group.parent.name} - {data.group.name}
				</div>
				<EvaluationForm
					token={token}
					name={data.name}
					questions={questions}
					savedAnswers={data.evaluationForm}
				/>
			</StyledEvaluationDiv>
		)
	}
}

Evaluation.propTypes = {
	token: PropTypes.string.isRequired,
}

export default connect(null, {
	changeLocaleAuto
})(
	withTranslation()(withFetchData((props) => getEvaluationForm(props.token))(Evaluation))
)
