import { Grid } from "@mui/material";
import React from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from "react-router-dom";
import { parseSearch } from '../helpers/query'
import Content from './../components/content/Content'
import RegisterForm from './../components/register/register-form'

const RegisterPage = () => {
	const location = useLocation();
	const query = parseSearch(location.search);

	return (
		<Content>
			<Content.Header>
				<h1>
					<Trans i18nKey="register.title"
									  defaults="Registreren" />
				</h1>
			</Content.Header>
			<Grid container justifyContent="center">
				<Grid item xs={12} sm={10} lg={8}>
					<RegisterForm redirectPath={query && query.return} />
				</Grid>
			</Grid>
		</Content>
	);
}

export default RegisterPage
