import { CacheProvider } from '@emotion/react';
import React from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { createRoot, hydrateRoot } from 'react-dom/client';
import UAParser from 'ua-parser-js';
import AppEntryClient from './AppEntryClient';
import createEmotionCache from './createEmotionCache';
import extendCookies from './helpers/extendCookies';
import configureStore from './setup/store';

const cookies = extendCookies(new Cookies());
const parser = new UAParser(navigator);
const deviceType = parser.getResult().device.type || 'desktop';

// Prepare the properties for the providers
const rootCompProps = {
    store: configureStore(window.__PRE_LOADED_STATE__ || {

        // This runs on client only, so take it from window in server.mjs it provides another value
        init: {
            context: {
                url: window.location.href,
                forceRootScopeId: process.env.FORCE_ROOT_SCOPE_ID || undefined,
            },
            initialCookies: cookies.getAll(),
        },
    }, cookies),
    deviceType,
};

// Delete possible pre-loaded state
delete window.__PRE_LOADED_STATE__;

const emotionCache = createEmotionCache();

const container = document.getElementById('root');

function render(finalContainer, nodes) {
    const isSSR = import.meta.env.MODE !== 'nossr';
    if (isSSR) {
        hydrateRoot(finalContainer, nodes);
    } else {
        createRoot(finalContainer).render(nodes);
    }
}

render(
    container, (
        <CacheProvider value={emotionCache}>
            <CookiesProvider cookies={cookies}>
                <AppEntryClient {...rootCompProps} />
            </CookiesProvider>
        </CacheProvider>
    ),
);
