import { mapObjIndexed, uniq } from 'ramda'
import TagManager from 'react-gtm-module'
import { EnvRunTagManager } from '../hoc/hooks/useTagManager'

function dataLayerPush(dataLayer){
	TagManager.dataLayer({
		dataLayer: dataLayer,
	});
}

export function gtmEvent(event, dataLayer){
	if(typeof window === 'undefined' || !EnvRunTagManager || !event) return;

	dataLayerPush({
		event: event,
		...dataLayer,
	});

	// Reset event data
	if(Object.keys(dataLayer || {}).length > 0){
		dataLayerPush(mapObjIndexed(() => undefined, dataLayer));
	}
}

export function gtmApiError(statusCode, errorData){
	if(errorData && errorData.error) errorData = errorData.error;
	if(typeof errorData === 'object') errorData = JSON.stringify(errorData);

	gtmEvent('apiError', {
		apiErrorCode: statusCode,
		apiErrorData: errorData,
	});
}

export function gtmOrder(order){
	if(typeof window === 'undefined' || !EnvRunTagManager || !order) return;

	// Clear any previous ecommerce data first
	dataLayerPush({
		ecommerce: null,
	});

	function getProductCompany(scope){
		return !scope.parent ? scope.name : getProductCompany(scope.parent);
	}

	function getProductName(scope){
		return `${scope.parent ? scope.parent.name : scope.id} - ${scope.name}`;
	}

	function getProductCategories(scope){
		if(!scope) return [];

		return getProductCategories(scope.parent).concat([scope.abbreviation]);
	}

	const entries = (order.orderEntries || []).filter(entry => Boolean(entry.product));
	const companies = uniq((entries).map(getProductCompany)).join('+');

	gtmEvent('purchase', {
		ecommerce: {
			transaction_id: `${order.id}`,
			affiliation: companies,
			value: order.totalPrice.amount,
			tax: 0,
			shipping: 0,
			currency: `${order.totalPrice.currency}`,
			coupon: null,
			items: entries.map((entry, index) => ({
				item_id: `${entry.product.id}`,
				item_name: getProductName(entry.product),
				affiliation: getProductCompany(entry.product),
				currency: entry.product.price.currency,
				price: entry.product.price.amount,
				quantity: entry.quantity,
				coupon: entry.discountCode || null,
				discount: entry.receivedDiscount?.amount || 0,
				index: index,
				...getProductCategories(
					// We start at the parent of the parent, because a product is a group, of which the parent is the course, which is not a category
					entry.product.parent ? entry.product.parent.parent : false
				).reduce((obj, category, index) => {
					obj[`item_category${index > 0 ? index + 1 : ''}`] = category;
					return obj;
				}, {}),
			})),
		},
	});
}
