import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {connect} from 'react-redux';
import {dismissError} from '../../actions/error';

const ErrorModal = ({ show, error, dismissError }) => {
	const { t } = useTranslation();
	if(!show || !error) return null;

	return (
		<Dialog
			open={true}
			onClose={dismissError}
			aria-labelledby="error-dialog-title"
			aria-describedby="error-dialog-description"
			disablePortal
		 >
			<DialogTitle id="error-dialog-title">
				{t('error.title')}
			</DialogTitle>
			<DialogContent id="error-dialog-description">
				{ error.offline ? (
					<p>{t('error.offline')}</p>
				) : (
					<>
						<p>{t('error.serverError')}</p>
						<p>{error.errorCode}: {error.error || 'unknown'}</p>
						{error.uuid && <p>{error.uuid}</p>}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={dismissError}>{t('error.close')}</Button>
			</DialogActions>
		</Dialog>
	);
};

ErrorModal.propTypes = {
	show: PropTypes.bool,
	error: PropTypes.object,
	dismissError: PropTypes.func
};

export default connect((state) => {
	return {
		show: state.error.show,
		error: state.error.error
	}
}, {
	dismissError
})(ErrorModal)
