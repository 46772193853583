import { buttonClasses, Container, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router-dom';
import useRootScopeProperties from '../../hoc/hooks/useRootScopeProperties';
// eslint-disable-next-line import/no-cycle
import Picker from '../home/Picker';

const PREFIX = 'ContentHeader';

const classes = {
    header: `${PREFIX}-header`,
};

const useMatchPaths = (patterns) => {
    const { pathname } = useLocation();

    if (!patterns || !Array.isArray(patterns)) return null;

    return patterns.find(pattern => matchPath(pattern, pathname));
};

const ContentHeader = styled(({ children, className }) => {
    const { config } = useRootScopeProperties();
    const matchPickerPath = useMatchPaths(config.scopePickerPagePatterns);

    return (
        <Container className={className}>
            <div className={classes.header}>
                {children}
                {!!matchPickerPath && (
                    <Picker sx={{ mt: 6, mx: 'auto', maxWidth: '850px' }} />
                )}
            </div>
        </Container>
    );
})(({ theme }) => ({
    [`& .${classes.header}`]: {
        padding: '60px 0',
        marginBottom: 0,
        marginTop: '-24px', // Correct for header whitespace visually
        textAlign: 'center',

        '& h1': {
            margin: '6px 0',
            color: theme.palette.common.white,
        },

        '& h2': {
            textAlign: 'center',
            marginTop: '0',
            color: theme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '40px',
        },

        '& > a, & > * > a': {
            '&.right': {
                float: 'right',
            },
            [theme.breakpoints.down('sm')]: {
                float: 'none',
                margin: '10px auto -28px auto',
            },

            [`&:not(.${buttonClasses.root})`]: {
                float: 'left',
                marginTop: '-55px',
                backgroundColor: 'transparent',
                borderColor: theme.palette.common.white,
                color: theme.palette.common.white,

                '&:hover': {
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.common.white,
                },
            },
        },
    },
}));

ContentHeader.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ContentHeader;
