import {
	Button, buttonBaseClasses, buttonClasses, darken,
	Divider, Grid,
	List, listItemIconClasses, listSubheaderClasses,
	SwipeableDrawer,
	Typography,
	useMediaQuery, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CoursesSearchField from './CoursesSearchField';
import Loader from '../loader';
import PropertyFilter from '../properties/PropertyFilter';
import { useFetchData } from '../../hoc/hooks/useFetchData';
import { useActiveFilterPropertiesAndChoices } from './useActiveFilterPropertiesAndChoices';

const StyledGrid = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(0, 2, 2),
	[theme.breakpoints.down('md')]: {
		minWidth: '320px',
	},
}));

const StyledSearchHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	margin: theme.spacing(2, 0, -0.5),
	'& > span': {
		fontSize: '0.9em',
		fontStyle: 'italic',
	},
}))

const StyledFilterHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(2, 0, 0, 0),
	},
}));

const StyledList = styled(List)(({ theme}) => ({
	paddingTop: 0,
	[`& .${buttonBaseClasses.root}`]: {
		padding: theme.spacing(0, 1, 0, 0.5),
	},
	[`& .${listItemIconClasses.root}`]: {
		minWidth: 'unset',
	},
	[`& > .${listSubheaderClasses.root}:first-of-type`]: {
		marginTop: theme.spacing(0.5),
	},
}));

const StyledResultsButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.info.main,
	color: theme.palette.common.white,
	'&:hover, &:focus': {
		backgroundColor: darken(theme.palette.info.main, 0.3),
	},
}))

// TODO: To be split and moved to components

const CoursesSearchAndFilter = ({ search, setOpen, properties, updateFilter, resultCount, courses }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	// TODO: clean up useDrawer logic, as it seems to never reach this component when drawer should be used
	const useDrawer = useMediaQuery(theme.breakpoints.down('md'));
	const { loading, data: allProperties } = useFetchData('ScopeProperties');
	const disableResetBtn = Object.keys(properties).length === 0 && !search;

	const allActiveProperties = useActiveFilterPropertiesAndChoices(allProperties, courses);

	const handleResetAll = useCallback(() => {
		updateFilter({
			filter: undefined,
			search: undefined,
		});
	}, [updateFilter]);

	const setSearch = useCallback((q) => {
		updateFilter({
			search: q || undefined,
		});
	}, [updateFilter])

	const handleProperty = (prop, value) => {
		let clone = { ...properties };
		if (!value) {
			delete clone[prop];
		} else {
			clone = { ...properties, [prop]: value };
		}
		updateFilter({
			filter: Object.keys(clone).length === 0 ? undefined : clone,
		});
	}

	return (
		<StyledGrid item xs={12} md={useDrawer ? 12 : 3}>
			{allActiveProperties?.length > 0 && (
				<>
					<StyledFilterHeader>
						<Typography variant="h6">{t('course.filters')}</Typography>
						<Button
							onClick={handleResetAll}
							variant="text"
							sx={{[`&.${buttonClasses.disabled}`]: { visibility: 'hidden' }}}
							disabled={disableResetBtn}
						>
							{t('course.resetAll')}
						</Button>
					</StyledFilterHeader>
					<StyledList dense>
						{loading ? (
							<Loader />
						) : allActiveProperties?.map(prop => (
							<PropertyFilter
								propertyKey={prop.key}
								propertyValue={properties[prop.key]}
								handleProperty={handleProperty}
								allProperties={allActiveProperties}
								key={prop.key}
							/>
						))}
					</StyledList>
				</>
			)}
			{useDrawer
				? (
					<StyledResultsButton variant="contained" onClick={() => setOpen(false)} fullWidth>
						{t('course.showResults', {
							count: resultCount,
						})}
					</StyledResultsButton>
				)
				: (
					<>
						<StyledSearchHeader>
							<Typography variant="h6">{t('course.search')}</Typography>
							{!!search && (
								<span>
									{t('course.results', {
										count: resultCount,
									})}
								</span>
							)}
						</StyledSearchHeader>
						<Divider sx={{ mt: 1, mb: 2 }} />
						<CoursesSearchField search={search} setSearch={setSearch} />
					</>
				)
			}
		</StyledGrid>
	)
}

const CoursesSearchAndFilterWrapper = ({ opened, setOpen, children, ...rest }) => {
	const theme = useTheme();
	const useDrawer = useMediaQuery(theme.breakpoints.down('md'));

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setOpen(open);
	};

	if (useDrawer) {
		return (
            <SwipeableDrawer
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				open={opened}
			>
				<CoursesSearchAndFilter setOpen={setOpen} {...rest} />
			</SwipeableDrawer>
        );
	}

	return (
		<CoursesSearchAndFilter {...rest} />
	)
}

CoursesSearchAndFilterWrapper.propTypes = {
	search: PropTypes.string.isRequired,
	updateFilter: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
	properties: PropTypes.object.isRequired,
	resultCount: PropTypes.number.isRequired,
	courses: PropTypes.array,
}

export default CoursesSearchAndFilterWrapper;
