import { useDidUpdateEffect } from '@athenagroup/utils';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Close, Search } from "@mui/icons-material";
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { gtmEvent } from '../../helpers/gtm';

const PREFIX = 'CoursesSearchField';

const classes = {
    buttonReset: `${PREFIX}-buttonReset`
};

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    [`& .${classes.buttonReset}`]: {
		marginLeft: theme.spacing(-1.5),
		marginRight: theme.spacing(-1.5),
	}
}));

const debounce = (func) => {
	let timer;

	return function (...args) {
		const context = this;
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			func.apply(context, args);
		}, 500);
	};
};

const debouncedGtmSearch = debounce((val) => {
	gtmEvent('search', {
		searchValue: val,
	});
});

const CoursesSearchField = ({ search, setSearch }) => {
    const [value, setValue] = useState(search || '');

    const debouncedSetSearch = useMemo(() => debounce((val) => setSearch(val)), [setSearch]);

	const { t } = useTranslation();

    const handleReset = useCallback(() => {
        setValue('');
        setSearch('');
    }, [setSearch]);

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
        debouncedSetSearch(e.target.value);
        debouncedGtmSearch(e.target.value);
    }, [debouncedSetSearch]);

    // Make sure the extern reset function works
    useDidUpdateEffect(() => {
        if (search === '') setValue('');
    }, [search]);

	return (
        <TextField
			onChange={handleChange}
			value={value}
			variant="outlined"
			fullWidth
			placeholder={t('course.searchPlaceholder')}
			InputProps={{
				endAdornment: (
					<StyledInputAdornment position="end">
						{!!search ? (
							<IconButton onClick={handleReset} className={classes.buttonReset} size="large">
								<Close />
							</IconButton>
						) : (
							<Search />
						)}
					</StyledInputAdornment>
				),
			}}
			size="small"
		/>
    );
}

CoursesSearchField.propTypes = {
	search: PropTypes.string.isRequired,
	setSearch: PropTypes.func.isRequired,
};

export default CoursesSearchField;
