import { makeTheme } from '@athenagroup/styles';
import { mergeDeepRight, assocPath } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
    createTheme,
    CssBaseline, darken,
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import mediaQuery from 'css-mediaquery';
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties';
import getGlobalStyling from './getGlobalStyling';
import DefaultPalette from './palettes/default';
import AtotZPalette from './palettes/atotz';
import HSAPalette from './palettes/hsa';

const defaultTheme = createTheme();

const paletteOverrides = {
    AtotZ: AtotZPalette,
    HSA: HSAPalette,
};

function createCustomTheme(customTheme) {
    return makeTheme(
        {
            typography: {
                h1: {
                    fontSize: '1.6rem',
                },
                h2: {
                    fontSize: '1rem',
                },
                h3: {
                    fontSize: '22px',
                },
                h4: {
                    fontSize: '1rem',
                },
                h5: {
                    fontSize: '1.5rem',
                },
            },
            palette: mergeDeepRight({
                default: {
                    main: defaultTheme.palette.grey[300],
                },
                ...DefaultPalette,
            }, paletteOverrides[customTheme] || {}),
            shape: {
                borderRadiusCards: 3,
            },
        },
        (themeBase) => ({
            MuiCssBaseline: {
                styleOverrides: getGlobalStyling(themeBase),
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: 24,
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        marginBottom: themeBase.spacing(2),
                        fontSize: '1rem',
                        lineHeight: '26px',
                    },
                    message: {
                        '& > *:last-child': {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        textTransform: 'none',
                    },
                },
                variants: [
                    {
                        props: {
                            variant: 'contained',
                            color: 'default',
                        },
                        style: {
                            color: themeBase.palette.getContrastText(themeBase.palette.grey[300]),
                            backgroundColor: themeBase.palette.grey[300],
                            boxShadow: themeBase.shadows[1],
                            '&.Mui-disabled': {
                                color: themeBase.palette.grey[600],
                            },
                            '&:hover, &:focus': {
                                color: themeBase.palette.common.black,
                                outline: 'none',
                                textDecoration: 'none',
                                backgroundColor: themeBase.palette.grey[400],
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'contained',
                            color: 'cta',
                        },
                        style: {
                            '&:hover, &:focus': {
                                backgroundColor: darken(themeBase.palette.cta.main, 0.3),
                                color: themeBase.palette.common.white,
                            },
                        },
                    },
                ],
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        marginBottom: themeBase.spacing(0.5),
                    },
                },
            },
            MuiGrid: {
                defaultProps: {
                    spacing: undefined,
                },
            },
        }),
    );
}

function ThemeProvider({ deviceType, children }) {
    const { customTheme } = useRootScopeProperties();
    let finalTheme = createCustomTheme(customTheme);

    const ssrMatchMedia = (query) => ({
        matches: mediaQuery.match(query, {
            // The estimated CSS width of the browser.
            width: deviceType === 'mobile' ? '0px' : `${finalTheme.breakpoints.values.md}px`,
        }),
    });

    finalTheme = assocPath(
        ['components', 'MuiUseMediaQuery', 'defaultProps', 'ssrMatchMedia'],
        ssrMatchMedia,
        finalTheme,
    );

    return (
        <MuiThemeProvider theme={finalTheme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                {children}
            </StyledEngineProvider>
        </MuiThemeProvider>
    );
}

ThemeProvider.propTypes = {
    children: PropTypes.node,
};

export default ThemeProvider;
