import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'
import circlePercentage from './circle-percentage.png';

const PREFIX = 'Percentage';
const classes = {
	percentage: `${PREFIX}-percentage`,
	title: `${PREFIX}-title`,
	circle: `${PREFIX}-circle`,
}

const Percentage = styled(({ children, className, ...props }) => (
	<Box className={classnames(className, classes.percentage)} {...props}>
		<div className={classes.title}>
			{children}
		</div>
		<div className={classes.circle} />
	</Box>
))(({ theme }) => ({
	[`&.${classes.percentage}`]: {
		fontSize: '18px',
		color: theme.palette.common.white,
		textAlign: 'center',
		margin: '50px 0 -30px',

		[`& .${classes.title}`]: {
			opacity: 0.56,
		},

		[`& .${classes.circle}`]: {
			margin: '10px auto 0 auto',
			width: '64px',
			height: '50px',
			background: `url('${circlePercentage}') no-repeat top center`,
			backgroundSize: 'contain',
		},
	}
}));

Percentage.propTypes = {
	children: PropTypes.node.isRequired
};

export default Percentage
