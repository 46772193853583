import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Grid, Button, styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import CustomAltImage from '../general/CustomAltImage'
import CustomMessage from '../general/CustomMessage'
import SectionTitle from '../general/section-title'

const Block = ({ info, full }) => (
	<Grid item xs={12} sm={full ? 12 : 6} className="institute-info-block">
		{info.image && (
			<CustomAltImage src={info.image} alt={info.title} />
		)}
		<div className="institute-info-title">
			<CustomMessage message={info.title} />
		</div>
		<div className="institute-info-text">
			<CustomMessage message={info.text} markdown={Boolean(info.markdown)} />{' '}
			{info.more && (
				<Button
					variant="text"
					endIcon={<KeyboardDoubleArrowRight />}
					component={Link}
					to={info.more}
					sx={{ fontSize: '1rem', mt: '-1px' }}
				>
					<Trans
						i18nKey="scope.institute.info.read_more"
						defaults="Lees meer"
					/>
				</Button>
			)}
		</div>
	</Grid>
);

const StyledDiv = styled('div')(({ theme }) => ({
	margin: '16px -20px',
	padding: '0 24px 24px 24px',
	fontSize: '16px',
	borderBottom: `solid 1px ${theme.palette.grey[200]}`,

	[theme.breakpoints.up('sm')]: {
		padding: '0 40px 24px 40px',
	},

	[theme.breakpoints.up('md')]: {
		padding: '0 70px 24px 70px',
	},

	'.section-title': {
		marginBottom: '16px',
	},

	'.institute-info-block': {
		marginTop: '24px',
		'& img': {
			width: '100%',
			marginBottom: '16px',
		},

		'.institute-info-title': {
			fontWeight: 600,
			fontSize: '1.1em',
		},

		'.institute-info-text': {
			color: theme.palette.grey[700], // $brownish-grey

			['& > .markdown-new']: {
				marginTop: 0,
			},

			'a, a:link, a:visited': {
				color: '#777',
				fontWeight: 600,
			}
		}
	},
}))

const ScopeInstituteInfo = ({ scope }) => {
	if(!scope.properties || !scope.properties.info) return null;

	const info = scope.properties.info;

    return (
    	<StyledDiv>
			{info.title && (
				<SectionTitle>
					<CustomMessage message={info.title} />
				</SectionTitle>
			)}
			<Grid container columnSpacing={2}>
				<Block info={info.left} full={!info.right} />
				{Boolean(info.right) && (
					<Block info={info.right} />
				)}
			</Grid>
		</StyledDiv>
    );
};

ScopeInstituteInfo.propTypes = {
	scope: PropTypes.object.isRequired,
};

export default ScopeInstituteInfo
