import { LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fetchSubScopes } from "../../actions/scopes";
import { scopeUrlSegment } from "../../helpers/scope";
import useLayoutEffectClient from '../../hoc/hooks/useLayoutEffectClient';
import { useLoadingDispatch } from "../../hoc/hooks/useLoadingDispatch";
import useRootScopeProperties from "../../hoc/hooks/useRootScopeProperties";
import { path, routes } from "../../routes";
import { scopeAndParentsSelector, subSubScopeNestedSelector } from "../../selectors/scope";
import CourseListItem from "./CourseListItem";
import { cleanFilterPropValue } from './useActiveFilterPropertiesAndChoices';

const RootDiv = styled(({ setGradient, ...rest }) => {
	const ref = useRef(null);
    useLayoutEffectClient(() => {
        if (!ref.current) return;

        setGradient(
            ref.current.getBoundingClientRect().height > ref.current.parentNode.getBoundingClientRect().height,
        );
    }, []);

	return <div ref={ref} {...rest} />
})(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(8),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: theme.spacing(0.5),
	margin: theme.spacing(0, -3, -0.5),
}))

function CourseForwardSelling({ group, setGradient }) {
	const { t, i18n } = useTranslation();
	const [dispatch, loading] = useLoadingDispatch();
	const { config: { forwardSelling } } = useRootScopeProperties();
	const { institute, faculty, study, course } = useSelector((state) => scopeAndParentsSelector(state, { id: group?.parent }));
	const scopes = useSelector((state) => study && subSubScopeNestedSelector(state, study));
	const { basketContents = [] } = useSelector(state => state.init.shoppingBasket || {});

	const filteredCourses = useMemo(() => {
		// Config should have at least the match prop to enable any results (to prevent rendering all possible courses)
		if(!forwardSelling?.shouldMatchWithProps) return [];

		const { hideFree, shouldMatchWithProps = [], shouldDifferFromProps = [] } = forwardSelling || {};

        const c = cleanFilterPropValue;

		return (scopes || []).filter(scope => {
			return (
				!basketContents.some(item => item.product.parent.id === scope.id)
				&& (!hideFree || scope.price.amount !== 0)
			);
		}).filter(scope => {
			// Suggested courses should include those that match all properties set in includeProperties
			return shouldMatchWithProps.every(prop => !!scope.properties?.[prop] && c(scope.properties[prop]) === c(course.properties?.[prop]));
		}).filter(scope => {
			// Suggested courses should exclude those with properties set in excludeProperties
			return shouldDifferFromProps.every(prop => !scope.properties?.[prop] || c(scope.properties[prop]) !== c(course.properties?.[prop]));
		});
	}, [study, scopes, basketContents, forwardSelling]);

	const coursePathCreator = useCallback((courseId) => (
		path(i18n, routes.COURSE, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study), courseId)
	), [institute, study]);

	useEffect(() => {
		if (study && !scopes) {
			dispatch(fetchSubScopes(study.id));
		}
	}, [scopes, study])

	if (loading) {
		return (
			<StyledLinearProgress
				aria-label={t('courses.forwardSelling.loading')}
				color="primary"
			/>
		)
	}

	if (filteredCourses.length === 0) {
		return null;
	}

	return (
		<RootDiv setGradient={setGradient}>
			<Typography paragraph sx={{ marginBottom: '1rem' }}><strong>{t('courses.forwardSelling.relatedCourses')}</strong></Typography>
			{filteredCourses.map(course => (
				<CourseListItem
					course={course}
					filteredCourses={filteredCourses}
					study={study}
					coursePathCreator={coursePathCreator}
					key={course.id}
				/>
			))}
		</RootDiv>
	)
}

CourseForwardSelling.propTypes = {
	group: PropTypes.object.isRequired,
	setGradient: PropTypes.func.isRequired,
}

export default CourseForwardSelling;
