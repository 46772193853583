import {
	alpha,
	Button,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import { styled } from '@mui/material/styles';
import classnames from "classnames";
import { orange } from "@mui/material/colors";
import { AddShoppingCart, Check } from "@mui/icons-material";
import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from 'react-i18next';
import { moneyComposer } from "../../helpers/composers";
import { getHourPrice, isUnavailableByStockStatus } from "../../helpers/course";
import { shortDateAbr, time } from "../../helpers/datetime";
import CourseAddToBasketModal from "./CourseAddToBasketModal";
import StockStatusBanner from "./StockStatusBanner";
import logoKransCut from '../../style/images/logo_krans_cut.png';

const PREFIX = 'CourseGroupCard';

const classes = {
    card: `${PREFIX}-card`,
    highlight: `${PREFIX}-highlight`,
    header: `${PREFIX}-header`,
    headerWreath: `${PREFIX}-headerWreath`,
    body: `${PREFIX}-body`,
    footer: `${PREFIX}-footer`,
    footerPrice: `${PREFIX}-footerPrice`,
    addToBasketBtn: `${PREFIX}-addToBasketBtn`,
    timeslotCell: `${PREFIX}-timeslotCell`
};

const StyledDiv = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
	borderRadius: theme.shape.borderRadiusCards,

    [`&.${classes.highlight}`]: {
		boxShadow: `${alpha(orange[700], 0.7)} 0 0 10px`,
		[`& .${classes.body}, & .${classes.footer}`]: {
			borderColor: orange[700],
			borderStyle: 'solid',
		},
		[`& .${classes.body}`]: {
			borderWidth: '0 1px 0 1px',
		},
		[`& .${classes.footer}`]: {
			borderWidth: '0 1px 1px 1px',
		}
	},

    [`& .${classes.header}`]: {
		position: 'relative',
		background: `linear-gradient(270deg, ${theme.palette.primaryGradient1} 0.5%, ${theme.palette.primaryGradient2} 100%)`,
		color: theme.palette.common.white,
		padding: theme.spacing(1.5, 7, 1.5, 2),
		borderRadius: theme.shape.borderRadiusCards,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		'& h6': {
			fontSize: '1.05rem',
			position: 'relative',
			zIndex: 2,
		},
	},

    [`& .${classes.headerWreath}`]: {
		position: 'absolute',
		zIndex: 1,
		right: 0,
		top: 0,
		bottom: 0,
		width: '40%',
		background: `url(${logoKransCut}) no-repeat center right`,
		backgroundSize: 'contain',
	},

    [`& .${classes.body}`]: {
		background: theme.palette.grey[100],
		padding: theme.spacing(2),
		flex: 1,
		'& > em': {
			fontStyle: 'italic',
			color: theme.palette.text.secondary,
		},
	},

    [`& .${classes.footer}`]: {
		background: theme.palette.grey[100],
		padding: theme.spacing(0, 2, 2, 2),
	},

    [`& .${classes.footerPrice}`]: {
		fontSize: '0.95rem',
		'& > strong': {
			fontSize: '1.1rem',
		},
	},

    [`& .${classes.addToBasketBtn}`]: {
		marginTop: theme.spacing(1),
	},

    [`& .${classes.timeslotCell}`]: {
		border: 0,
		padding: theme.spacing(0.5, 0),
		'&:last-child': {
			paddingRight: 0,
			textAlign: 'right',
		},
	}
}));

function CourseGroupCard({ group, course, basketContents }) {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(false);

	const hourPrice = useMemo(() => getHourPrice(course.price, [group]), [course, group]);
	const isUnavailable = useMemo(() => isUnavailableByStockStatus(group.courseStockStatus), [group]);

	const isInBasket = useMemo(() => basketContents.some((basketItem) => basketItem.product.id === group.id), [basketContents]);
	const addToBasketStatusIcon = useMemo(() => {
		if (isInBasket) {
			return <Check />
		}
		return <AddShoppingCart />
	}, [isInBasket]);

	const handleClose = useCallback(() => {
		setSelected(false);
	}, []);

	const handleAddBasketItem = () => {
		setSelected(true)
	}

	return (
        <>
            <StyledDiv className={classnames({
				[classes.highlight]: group.courseStockStatus === 'ALMOST_FULL'
			})}>
				<div className={classes.header}>
					<Typography variant="h6">
						{group.name}
					</Typography>
					<div className={classes.headerWreath} />
				</div>
				<StockStatusBanner
					group={group}
				/>
				<div className={classes.body}>
					{isUnavailable ? (
						<em>
							<Trans
								i18nKey="course.group.slots.not_available_anymore"
								defaults="Niet meer verkrijgbaar"
							/>
						</em>
					) : (group.timeslots.length === 0 ? (
						<em>
							<Trans
								i18nKey="course.group.slots.not_available_yet"
								defaults="Nog geen tijden bekend"
							/>
						</em>
					) : (
						<Table size="small">
							<TableBody>
								{group.timeslots.map(slot => (
									<TableRow key={slot.id}>
										<TableCell className={classes.timeslotCell}>
											{shortDateAbr(slot.startDateTime)}
										</TableCell>
										<TableCell className={classes.timeslotCell}>
											{time(slot.startDateTime)} - {time(slot.endDateTime)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					))}
				</div>
				<div className={classes.footer}>
					<div className={classes.footerPrice}>
						<strong>{moneyComposer(course.price)}</strong>
						{Boolean(hourPrice) && (
							` / ${t('course.per_hour', {
								hourPrice: moneyComposer(hourPrice)
							})}`
						)}
					</div>
					<Button
						variant="contained"
						color="cta"
						onClick={handleAddBasketItem}
						endIcon={addToBasketStatusIcon}
						fullWidth
						className={classes.addToBasketBtn}
						disabled={isUnavailable}
					>
						{t('course.in_cart')}
					</Button>
				</div>
			</StyledDiv>
            {course && selected && (
				<CourseAddToBasketModal course={course} group={group} onClose={handleClose} />
			)}
        </>
    );
}

// TODO: move back to parent or initialize for every group
export default connect((state) => {
	return {
		basketContents: state.init.shoppingBasket?.basketContents || [],
	}
})(CourseGroupCard);
