import AuthCheck from './hoc/AuthCheck';
import { routes } from './routes';
import { getRouterPath } from './helpers/path';

const IsAuthenticated = AuthCheck({
    failureRedirectPath: (i18n) => getRouterPath(i18n, routes.LOGIN, i18n.language, true),
    wrapperDisplayName: 'IsAuthenticated',
});

export default IsAuthenticated;
