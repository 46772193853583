import { Card, CardHeader, CardContent } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import StyledBox from "./StyledBox";

const BasketInfo = ({ partner }) => {
	const { t } = useTranslation();
	return (
		<StyledBox sx={{ pt: '10px', pb: '20px' }}>
			{partner?.visuals?.information && (
				<Card>
					<CardHeader
						title={`${t('basket.info.partner')} ${partner.name}`}
						sx={{ background: '#efefef', borderBottom: '1px solid #ccc', fontSize: '1.2rem', p: 1.5 }}
						disableTypography
					/>
					<CardContent sx={{ p: 1.5, '&:last-child': { p: 1.5 } }}>
						{partner.visuals.information}
					</CardContent>
				</Card>
			)}
		</StyledBox>
	);
}

BasketInfo.propTypes = {
	partner: PropTypes.object
};

export default BasketInfo
