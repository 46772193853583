import { Button, Alert, styled } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import FormData from '../../hoc/FormData';
import { hideLocaleSaveDialog } from '../../reducers/i18n';
import { editClient } from '../../actions/auth';
import { GetAccountLocaleFromLanguage } from '../../helpers/locale';
import { localeSelector } from '../../selectors/i18n';
import Loader from '../loader';

const PREFIX = 'HeaderLocaleSave';

const classes = {
    body: `${PREFIX}-body`,
    explanation: `${PREFIX}-explanation`,
    footer: `${PREFIX}-footer`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.body}`]: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },

    [`& .${classes.footer}`]: {
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            color: 'white',
            '& button': {
                fontSize: '1.1rem',
            },
        },
    },
}));

const rootLocaleSelector = (state) => state.init.rootLocale;

function HeaderLocaleSave({ saving, success, watchSubmit }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const language = useSelector(localeSelector);
    const rootLocale = useSelector(rootLocaleSelector);

    const saveLocale = () => {
        watchSubmit(editClient({
            locale: GetAccountLocaleFromLanguage(language, rootLocale),
        }), true);
    };

    const handleClick = () => {
        dispatch(hideLocaleSaveDialog());
    };

    return (
        <Root className={classes.body}>
            <p className={classes.explanation}><Trans i18nKey="header.locale.save.title" /></p>
            {success
                ? (
                    <Alert severity="success" sx={{ mb: 0 }}>
                        {t('header.locale.save.saved')}
                    </Alert>
                )
                : (
                    <div className={classes.footer}>
                        {saving
                            ? <Loader />
                            : (
                                <>
                                    <Button onClick={handleClick} variant="outlined" size="small" sx={{ mr: 2 }}>
                                        {t('header.locale.save.no')}
                                    </Button>
                                    <Button onClick={saveLocale} variant="contained" color="primary" size="small">
                                        {t('header.locale.save.save')}
                                    </Button>
                                </>
                            )}
                    </div>
                )}
        </Root>
    );
}

export default FormData(HeaderLocaleSave, 'localeSaveForm');
