import { createSelector } from 'reselect'

const scopesSelector = state => state.entities.scopes;
export const lastPathSelector = state => state.lastPath;

export const lastScopePathSelector = createSelector(
	lastPathSelector,
	scopesSelector,
	(path, scopes) => {
		if(!path) return [];

		return path.map(id => scopes[id] || id); // TODO: is this logical?
	}
);
