import {alpha} from "@mui/material";

export default {
	primary: {
		main: '#E96C35',
	},
	error: {
		main: '#d04c3d',
	},
	info: {
		main: '#E96C35',
	},
	primarySubDark: '#E96C35',
	primarySubLight: '#E96C35',
	primaryGradient1: '#5C903E',
	primaryGradient2: alpha('#5C903E', 0.6),
}
