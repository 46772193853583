import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { WarningAmber } from '@mui/icons-material'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from "react-i18next";
import { readableDuration } from "../../helpers/duration";

const StyledRoot = styled(({ as }) => as || 'li')(({ theme }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	lineHeight: '1rem',
	[theme.breakpoints.up('sm')]: {
		order: -1,
		margin: theme.spacing(0.5, 0, 'auto'),
	}
}));

const StyledWarningAmber = styled(WarningAmber)(({ theme }) => ({
	color: theme.palette.error.main,
	fontSize: '1.25rem',
	marginRight: theme.spacing(0.5),
	transform: 'translateY(-1px)',
}))

function sortedStartDates(groups){
	return groups
		.filter(group => group.courseStart)
		.map(group => moment(group.courseStart).diff(moment(), 'hours'))
		.sort((a, b) => a - b);
}

function hoursTillNext(dates){
	return dates.filter(date => date > 0)[0];
}

function CourseTimeTillStartTag({ groups, as }){
	if(!groups) return null;

	const dates = sortedStartDates(groups);
	const hours = hoursTillNext(dates);
	const ended = !Boolean(hours);

	if(ended) return null;
	if(hours > (7 * 24)) return null;

	return (
        <StyledRoot as={as}>
			<StyledWarningAmber />
			<Box component="span" sx={{color: 'error.main'}}>
				<Trans
					i18nKey="course.time_till_start"
					values={{
						time: readableDuration(hours),
					}}
				>
					Eerstvolgende over <strong>{readableDuration(hours)}</strong>
				</Trans>
			</Box>
		</StyledRoot>
    );
}

CourseTimeTillStartTag.propTypes = {
	groups: PropTypes.arrayOf(PropTypes.object).isRequired,
	as: PropTypes.node,
};

export default CourseTimeTillStartTag
