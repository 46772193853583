import { styled } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = styled(({ children, left, ...props }) => (
	<div className={left ? ' left' : ''} {...props}>
		{children}
		<div className="section-title-line">
			<span />
		</div>
	</div>
))(({ theme }) => ({
	textAlign: 'center',
	fontSize: '14px',
	fontWeight: 600,
	margin: '30px 0',

	'& .section-title-line': {
		lineHeight: '10px',

		'& > span': {
			display: 'inline-block',
			width: '33px',
			height: '2px',
			borderBottom: `solid 2px ${theme.palette.primary.main}`,
		}
	},

	'&.left': {
		textAlign: 'left',

		'.section-title-line': {
			lineHeight: '2px',
			'& > span': {
				width: '100px',
			}
		}
	}
}));

SectionTitle.propTypes = {
	children: PropTypes.node.isRequired,
	left: PropTypes.bool
};

export default SectionTitle
