import { styled } from "@mui/material";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'FaqGroup';
const classes = {
	group: `${PREFIX}-group`,
	groupTitle: `${PREFIX}-groupTitle`,
}

const FaqGroup = styled(({ title, children, className }) => (
	<div className={classnames(classes.group, className)}>
		<div className={classes.groupTitle}>{title}</div>
		{children}
	</div>
))({
	[`&.${classes.group}`]: {
		[`& .${classes.groupTitle}`]: {
			padding: '6px 0',
			margin: '20px 20px 0 20px',
		},
		[`& + .${classes.group}`]: {
			marginTop: '20px',
		},
	},
});

FaqGroup.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default FaqGroup
