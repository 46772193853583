
export const SchemaBreadcrumb = (list) => {
	const finalList = [{
		name: 'AthenaStudies',
		path: '/'
	}].concat(list);

	return JSON.stringify({
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: finalList.map((item, i) => ({
			"@type": "ListItem",
			position: (i + 1),
			item: {
				"@id": 'https://athenastudies.nl' + item.path,
				name: item.name
			}
		}))
	});
};
