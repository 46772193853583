import { darken, Grid, lighten, styled } from "@mui/material";
import React from 'react'
import { useTranslation } from 'react-i18next'
import glitter from './glitter.gif';

const StyledGrid = styled(Grid)(({ theme }) => ({
	textAlign: 'center',
	justifyContent: 'center',

	'h3': {
		margin: '10px 0 16px 0',
		fontSize: '3em',
		fontWeight: 700,
		color: theme.palette.success.main,
	},

	'p': {
		fontSize: '1.2em',
	}
}));

const StyledImageDiv = styled('div')(({ theme }) => ({
	display: 'inline-block',
	width: '270px',
	height: '270px',
	padding: '10px',
	borderRadius: '50%',
	background: `linear-gradient(to top, ${lighten(theme.palette.primary.main, 0.1)}, ${darken(theme.palette.primary.main, 0.1)})`,

	'& > div': {
		display: 'inline-block',
		width: '250px',
		height: '250px',
		background: `url(${glitter}) center`,
		backgroundSize: 'auto 250px',
		borderRadius: '50%',
	},
}));

function PaySuccessOrder() {
	const { t } = useTranslation();
	return (
		<StyledGrid container>
			<Grid item xs={12} sm={8}>
				<StyledImageDiv>
					<div />
				</StyledImageDiv>
				<h3>{t('pay.success_order_title')}</h3>
				<p>{t('pay.success_order_text')}</p>
			</Grid>
		</StyledGrid>
	)
}

export default PaySuccessOrder
