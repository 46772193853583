import { Card, CardHeader, CardContent, checkmarkListStyled } from '@athenagroup/components';
import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const CustomCardContent = styled(checkmarkListStyled(CardContent))(({ theme }) => ({
    '&.specificity > ul > li': {
        background: 'none',
    },
    '&.specificity > ul': {
        paddingLeft: theme.spacing(4),
    },
}));

function MarkdownCard({ items, title, separator }) {
    const rows = useMemo(() => (
        items?.split?.(separator) || []
    ), [items, separator]);

    if (rows.length === 0) return null;

    return (
        <Card>
            <CardHeader
                title={title}
                sx={{ '& h3': { fontSize: '1.4rem' } }}
            />
            <CustomCardContent className="specificity">
                <ul>
                    {rows.map((row, i) => (
                        <li key={i}>{row}</li>
                    ))}
                </ul>
            </CustomCardContent>
        </Card>
    );
}

MarkdownCard.propTypes = {
    items: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    separator: PropTypes.string,
};

MarkdownCard.defaultProps = {
    separator: ',',
};

export default MarkdownCard;
