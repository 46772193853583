import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material'
import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'

const StyledDialogTitle = styled(DialogTitle)({
	backgroundColor: 'transparent',
	display: 'flex',
	alignItems: 'center',
});

const StyledTitleText = styled('div')(({ theme }) => ({
	...theme.typography.h5,
	fontWeight: theme.typography.fontWeightBold,
	flex: 1,
}));

const SimpleDialog = forwardRef(({ open, onClose, title, children, maxWidth, ...rest }, ref) => {
	const handleClose = useCallback((e, reason) => {
		onClose?.(reason);
	}, [onClose]);

	const handleCloseTitle = useCallback((e) => {
		handleClose(e, 'button');
	}, [handleClose]);

	return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth} scroll="body" {...rest} ref={ref}>
			{Boolean(title) && (
				<StyledDialogTitle component="div">
					<StyledTitleText>{title}</StyledTitleText>
					<IconButton onClick={handleCloseTitle} sx={{ mr: -1, color: 'error.main' }} size="large">
						<Close sx={{ fontSize: '1.6rem' }} />
					</IconButton>
				</StyledDialogTitle>
			)}
			<DialogContent sx={{ mb: 1 }}>
				{children}
			</DialogContent>
		</Dialog>
    );
});

SimpleDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	title: PropTypes.node,
	children: PropTypes.node,
	maxWidth: PropTypes.string,
};

export default SimpleDialog
