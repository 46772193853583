import { keyframes, styled } from "@mui/material";
import { mergeDeepRight } from "ramda";
import React from 'react';
import PropTypes from 'prop-types';
import loader_gold from './loader_gold.gif';

const PREFIX = 'Loader';
const classes = {
	image: `${PREFIX}-image`,
	text: `${PREFIX}-text`,
	sheet: `${PREFIX}-sheet`,
}

const loaderSheet = keyframes`
	from { opacity: 0; }
	to { opacity: 0.5; }
`;

const StyledDiv = styled('div')(({ theme, sheet, takeSpace }) => {
	let styles = {
		margin: '0 10px 30px 10px',
		[`.${classes.image}`]: {
			display: 'block',
			margin: '0 auto',
			height: '70px',
		},

		[`.${classes.text}`]: {
			textAlign: 'center',
			fontStyle: 'italic',
			fontSize: '95%',
			color: theme.palette.grey[700],
		}
	};

	if (takeSpace) {
		styles = mergeDeepRight(styles, {
			marginTop: '200px',
			marginBottom: '300px',

			[`.${classes.image}`]: {
				height: '150px',
			}
		})
	}

	if (sheet) {
		styles = mergeDeepRight(styles, {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: 0,

			[`.${classes.sheet}`]: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: theme.palette.grey[500],
				zIndex: 100,
				animation: `${loaderSheet} 0.7s ease-in-out forwards`,
			},

			[`.${classes.image}`]: {
				marginTop: '100px',
				position: 'relative',
				zIndex: 200,
			},

			[`.${classes.text}`]: {
				marginTop: '10px',
				position: 'relative',
				zIndex: 200,

				'span': {
					display: 'inline-block',
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
					padding: '8px 14px',
					borderRadius: theme.shape.borderRadiusCards,
				}
			}
		})
	}

	return styles;
});

const Loader = ({ children, ...props }) => (
	<StyledDiv {...props}>
		<img src={loader_gold} alt='Loading' className={classes.image} />
		{
			children &&
			<div className={classes.text} aria-live="polite" aria-busy="true">
				<span>{children}</span>
			</div>
		}
		<div className={classes.sheet} />
	</StyledDiv>
);

Loader.propTypes = {
	children: PropTypes.node,
	takeSpace: PropTypes.bool,
	sheet: PropTypes.bool
};

export default Loader
