import { Button, styled, buttonClasses } from '@mui/material'
import { ContactSupport, Email, Feedback, Forum, Info, LiveHelp } from '@mui/icons-material'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getTransformedUri } from './Markdown'

const StyledButton = styled(Button)(({ theme }) => ({
	[`&.${buttonClasses.outlinedPrimary}`]: {
		borderWidth: 2,
		boxShadow: theme.shadows[1],
		'&:hover, &:focus': {
			borderWidth: 2,
			color: theme.palette.primary.dark,
		},
	},
	[`&.${buttonClasses.containedPrimary}`]: {
		'&:hover, &:focus': {
			color: theme.palette.primary.contrastText,
		},
	},
}));

const icons = {
	forum: Forum,
	liveHelp: LiveHelp,
	info: Info,
	feedback: Feedback,
	contactSupport: ContactSupport,
	email: Email,
};

/**
 *
 * @param to {string} The children of the directive
 * @param title {string} Title option
 * @param [external] {boolean} External
 * @param [icon] {'forum'|'liveHelp'|'info'|'feedback'|'contactSupport'|'email'} Optional icon
 * @param [variant] {'outlined'|'contained'|'text'} Optional variant, default is text
 */
const MarkdownButton = ({ to, title, external, icon, variant }) => {
	const { i18n } = useTranslation();

	const path = getTransformedUri(to, i18n);
	const IconComponent = icons[icon] || null;

	return (
        <StyledButton
            component={external ? 'a' : Link}
            to={external ? undefined : path}
            href={external ? to : undefined}
            target={external ? '_blank' : undefined}
            variant={variant || 'text'}
            color="primary"
        >
			{IconComponent && (
				<IconComponent sx={{ mr: 1 }} />
			)}
			{title || path}
		</StyledButton>
    );
};

MarkdownButton.propTypes = {
	to: PropTypes.string.isRequired,
	title: PropTypes.string,
};

export default MarkdownButton
