import { styled } from "@mui/material";
import classnames from "classnames";
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocale } from '../../reducers/i18n'

import flagNl from './../header/flags/nl.png';
import flagEn from './../header/flags/en.png';
import flagDe from './../header/flags/de.png';

import { locales, localeSelector } from '../../selectors/i18n'

const PREFIX = 'BasicLocaleSwitcher';
const classes = {
	flag: `${PREFIX}-flag`,
	active: `${PREFIX}-active`,
}

export const StyledLocaleFlag = styled('div')({
	display: 'inline-block',
	width: '30px',
	height: '30px',
	backgroundSize: '30px 30px',
	cursor: 'pointer',
	filter: 'brightness(70%)',
	transform: 'scale(0.8, 0.8)',

	'&.nl': {
		backgroundImage: `url('${flagNl}')`,
	},
	'&.en': {
		backgroundImage: `url('${flagEn}')`,
	},
	'&.de': {
		backgroundImage: `url('${flagDe}')`,
	},

	[`& + .${classes.flag}`]: {
		marginLeft: '10px',
	},

	'&:hover': {
		filter: 'brightness(100%)',
		transform: 'scale(0.9, 0.9)',
	},

	[`&.${classes.active}`]: {
		filter: 'brightness(100%)',
		transform: 'scale(1, 1)',
	},
});

function BasicLocaleSwitcher() {
	const dispatch = useDispatch();
	const locale = useSelector(localeSelector);

	const handlePickLocale = useCallback((e) => {
		dispatch(changeLocale(e.target.getAttribute('data-locale')));

		// Attempt to make sure the current system does not open the "save" dialog in case the client goes back to the site
	}, [changeLocale]);

    return locales.map(option => (
		<StyledLocaleFlag
			key={option.locale}
			data-locale={option.locale}
			className={classnames(classes.flag, option.locale, {
				[classes.active]: option.locale === locale,
			})}
			onClick={handlePickLocale}
		/>
	));
}

export default BasicLocaleSwitcher;
