import { styled } from '@mui/material/styles';
import { Schedule } from '@mui/icons-material'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { getMaxHours } from '../../helpers/course'

const StyledScheduleIcon = styled(Schedule)(({ theme }) => ({
	color: theme.palette.info.main,
	fontSize: '1.25rem',
	marginRight: theme.spacing(0.5),
	transform: 'translateY(-1px)',
}));

function CourseHoursTag({ course, as }){

	const { t } = useTranslation();
	const maxHours = getMaxHours(course.subScopes);

	if(isNaN(maxHours) || maxHours <= 0) return null;

	const Root = as || 'li';

	return (
		<Root>
			<StyledScheduleIcon />
			{t('course.hours.total', { count: maxHours })}
		</Root>
	);
}

CourseHoursTag.propTypes = {
	course: PropTypes.object.isRequired,
	as: PropTypes.node,
};

export default CourseHoursTag
