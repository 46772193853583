import { LAST_PATH } from '../actions/lastPath'

const defaultState = [];

function lastPathReducerFinal(state = defaultState, action){
	if(action.type === LAST_PATH && action.path){
		return [...action.path];
	}

	return state;
}

const lastPathReducer = {
	lastPath: lastPathReducerFinal,
};

export default lastPathReducer;
