const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?/g;
export function getDescription(description){
	if(!description) return [null, null];

	let match = description.match(youtubeRegex) || [];

	// Now extract the keys
	const keys = match.map(url => {
		const sp1 = url.split('=');
		const last = sp1[sp1.length - 1];
		const sp2 = last.split('/');

		return sp2[sp2.length -1];
	});

	return [description, keys[0]];
}

export const DEFAULT_DESC_STRING = '<div><br></div>';
