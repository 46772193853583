import { subtitleClasses } from '@athenagroup/components';
import dot2 from './images/dot2.png';

const getGlobalStyling = (theme) => ({
    [`& .${subtitleClasses.title}`]: {
        fontSize: '1.65rem',
        fontWeight: 400,
    },

    '& h4': {
        marginBottom: theme.spacing(2.5),
    },

    html: {
        fontSize: 15,
        WebkitFontSmoothing: 'auto',
        height: '100%',
    },

    body: {
        fontSize: '15px',
        lineHeight: '26px',
        color: theme.palette.common.black,
        overflowY: 'scroll',
    },

    table: {
        borderCollapse: 'collapse',
    },

    p: {
        marginTop: 0,
    },

    'h1, h2, h3, h4, h5, h6': {
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: 1.1,
        color: 'inherit',
    },

    'h1, h2, h3': {
        marginTop: '21px',
        marginBottom: '10.5px',
    },

    h1: {
        fontWeight: '600',
        lineHeight: '28px',
        textAlign: 'center',
    },

    a: {
        color: '#337ab7',
        textDecoration: 'none',
    },

    'a, a:link, a:visited, a:hover': {
        textDecoration: 'none',
    },

    'button, input, optgroup, select, textarea': {
        color: 'inherit',
        font: 'inherit',
    },

    '.text-muted': {
        color: '#777',
    },

    hr: {
        margin: theme.spacing(2.5, 0),
        border: 0,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },

    blockquote: {
        fontWeight: '300',
        fontStyle: 'italic',
        color: theme.palette.grey[900],
        lineHeight: 'normal',
        paddingRight: '0',
        marginTop: '32px',
        marginBottom: '32px',

        '& > div, & > span > div': {
            marginLeft: '30px',
        },
    },

    ul: {
        marginTop: 0,
        '&.keyword-list': {
            margin: theme.spacing(1, 0, 0, 0),
            padding: 0,
            listStyle: 'none',

            '& > li': {
                display: 'inline-block',
                fontSize: '80%',
                lineHeight: 'normal',
                marginRight: '2px',
                marginBottom: '4px',
                padding: '3px 6px',
                color: '#666666',
                border: '1px solid #999999',
                borderRadius: '6px',
                background: 'none',
            },
        },
    },

    'ul.purple-bullets, .editor ul, .markdown ul': {
        paddingLeft: '2px',
        listStyle: 'none',
        marginBottom: '20px',

        '& li': {
            padding: '3px 0',
        },

        '& > li': {
            background: `url('${dot2}') no-repeat left center`,
            backgroundSize: '7px 7px',
            paddingLeft: '18px',
        },

        '& > ul': {
            paddingLeft: '38px',
            listStyle: 'square',

            '& > li': {
                background: 'none',
                paddingLeft: 0,
            },
        },
    },

    '.editor': {
        // Make the list appear a bit more coupled with the paragraph above
        '& p + ul': {
            marginTop: '-6px',
        },

        '& ul': {
            marginBottom: '8px',

            '& > li': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },

        '& p': {
            margin: '0 0 10px',
        },
    },

    '.markdown': {
        marginTop: '16px',

        '& h3': {
            fontSize: '26px',
        },

        '& h4': {
            marginTop: '30px',
        },

        '& p + ul': {
            marginTop: '-10px',
        },

        '& ul, & ul:not(.nav)': {
            marginLeft: '14px',

            '& > li': {
                paddingTop: '0',
                paddingBottom: '0',
            },
        },

        '&.markdown-new': {
            fontSize: '16px',

            '& ol': {
                paddingLeft: '8px',
                marginLeft: '20px',

                '& > li': {
                    padding: '3px 0 3px 12px',
                },
            },

            '& .listIcon-checkmark + ul': {
                position: 'relative',
                listStyle: 'none',
                marginLeft: '10px',

                '& > li': {
                    background: 'none',
                    paddingLeft: '24px',

                    '&::before': {
                        content: '""',
                        // eslint-disable-next-line max-len
                        backgroundImage: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIGZpbGw9IiMyNWMxMjgiPjxwYXRoIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjY1MiwzLjIxMWMtMC4yOTMtMC4yOTUtMC43Ny0wLjI5NS0xLjA2MSwwTDkuNDEsMTQuMzQgIGMtMC4yOTMsMC4yOTctMC43NzEsMC4yOTctMS4wNjIsMEwzLjQ0OSw5LjM1MUMzLjMwNCw5LjIwMywzLjExNCw5LjEzLDIuOTIzLDkuMTI5QzIuNzMsOS4xMjgsMi41MzQsOS4yMDEsMi4zODcsOS4zNTEgIGwtMi4xNjUsMS45NDZDMC4wNzgsMTEuNDQ1LDAsMTEuNjMsMCwxMS44MjNjMCwwLjE5NCwwLjA3OCwwLjM5NywwLjIyMywwLjU0NGw0Ljk0LDUuMTg0YzAuMjkyLDAuMjk2LDAuNzcxLDAuNzc2LDEuMDYyLDEuMDcgIGwyLjEyNCwyLjE0MWMwLjI5MiwwLjI5MywwLjc2OSwwLjI5MywxLjA2MiwwbDE0LjM2Ni0xNC4zNGMwLjI5My0wLjI5NCwwLjI5My0wLjc3NywwLTEuMDcxTDIxLjY1MiwzLjIxMXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        width: '1.1em',
                        height: '1.1em',
                        marginTop: '1px',
                        position: 'absolute',
                        left: 0,
                    },
                },
            },

            '& .listIconColor-green + ul > li::before': {
                color: '#25c128 !important',
            },
        },
    },
});

export default getGlobalStyling;
