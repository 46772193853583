import { Close } from '@mui/icons-material';
import { styled, Button, Dialog, Typography, IconButton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toggleStudyLocalePrompt } from '../../reducers/i18n';
import { scopeByReverseAbbrPathSelector } from '../../selectors/scope';
import { getRouterPath } from '../../helpers/path';
import { StyledLocaleFlag } from '../general/BasicLocaleSwitcher';

const PREFIX = 'HeaderStudyLocaleDialog';

const classes = {
    footer: `${PREFIX}-footer`,
    header: `${PREFIX}-header`,
    icon: `${PREFIX}-icon`,
    close: `${PREFIX}-close`,
};

const StyledDialog = styled(Dialog)(({ theme}) => ({
    [`& .${classes.header}`]: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: '6rem',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    },
    [`& .${classes.icon}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'Basket-Icon 700ms ease-out',
        animationFillMode: 'both',
        animationDelay: '200ms',
        '& > div': {
            cursor: 'unset',
            filter: 'none',
            transform: 'none',
            '&:hover': {
                filter: 'none',
                transform: 'none',
            },
        },
    },
    [`& .${classes.close}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.common.white,
        opacity: 0.6,
        '&:hover': {
            opacity: 1,
        },
    },
}));

const RootDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    [`& .${classes.footer}`]: {
        textAlign: 'center',
    },
}));

function HeaderStudyLocaleDialog() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const study = useSelector((state) => scopeByReverseAbbrPathSelector(state, { params }));

    const studyLanguage = study?.locale?.substring(0, 2);

    if (!studyLanguage) {
        return null;
    }

    const handleClickClose = () => {
        dispatch(toggleStudyLocalePrompt(false, false));
    };

    const handleChangeLocale = () => {
        dispatch(toggleStudyLocalePrompt(false, true));
        navigate(getRouterPath(i18n, location.pathname, studyLanguage), { replace: true });
    };

    return (
        <StyledDialog open={true} maxWidth="xs" fullWidth onClose={handleClickClose}>
            <>
                <div className={classes.header}>
                    <div className={classes.icon}>
                        <StyledLocaleFlag className={studyLanguage} />
                    </div>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        {t('header.locale.studyLocale.title', { lng: studyLanguage })}
                    </Typography>
                    <IconButton onClick={handleClickClose} className={classes.close} size="large">
                        <Close sx={{ fontSize: '1.6rem' }} />
                    </IconButton>
                </div>
                <RootDiv>
                    <p>{t('header.locale.studyLocale.prompt', { lng: studyLanguage })}</p>
                    <div className={classes.footer}>
                        <Button onClick={handleClickClose} variant="text" color="error" sx={{ mr: 2 }}>
                            {`${t('common.no')} / ${t('common.no', { lng: studyLanguage })}`}
                        </Button>
                        <Button onClick={handleChangeLocale} variant="contained" color="primary">
                            {`${t('header.locale.studyLocale.switch')} / ${t('header.locale.studyLocale.switch', { lng: studyLanguage })}`}
                        </Button>
                    </div>
                </RootDiv>
            </>
        </StyledDialog>
    );
}

export default HeaderStudyLocaleDialog;
