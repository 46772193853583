import { Button, darken, lighten, LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Redeem } from '@mui/icons-material'
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { percentageComposer } from '../../helpers/composers'
import { scopeUrlSegment } from '../../helpers/scope'
import withFetchData from '../../modules/fetchData/withFetchData'
import { path, routes } from '../../routes'
import { CALL_API } from '../../setup/api'

const PREFIX = 'CourseBundle';

const MODES = {
	LIST: 'list',
	COURSE: 'course',
}

const classes = {
	list: `${PREFIX}-${MODES.LIST}`,
	course: `${PREFIX}-${MODES.COURSE}`,
    bundles: `${PREFIX}-bundles`,
    bundle: `${PREFIX}-bundle`,
    bundleIcon: `${PREFIX}-bundleIcon`,
    bundleContent: `${PREFIX}-bundleContent`,
    bundleButton: `${PREFIX}-bundleButton`,
    bundleTitle: `${PREFIX}-bundleTitle`,
    bundleLink: `${PREFIX}-bundleLink`,
    icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.list}`]: {
		margin: theme.spacing(0, 0.5),
		boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
		marginTop: '1px',
		borderRadius: '0 0 3px 3px',
		[`& .${classes.bundle}`]: {
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
			padding: theme.spacing(1),
			color: theme.palette.primary.dark,
			'&:last-of-type': {
				borderBottom: 0,
			},
			[`& .${classes.bundleButton}`]: {
				padding: theme.spacing(1, 1),
				lineHeight: '0.75rem',
			},
			[`& .${classes.bundleContent}`]: {
				lineHeight: '1.1rem',
				fontSize: theme.typography.fontSize,
			},
			[`& .${classes.bundleLink} a`]: {
				fontWeight: theme.typography.fontWeightRegular,
				textDecoration: 'underline',
				color: theme.palette.primary.dark,
			},
		}
	},
	[`&.${classes.course}`]: {
		margin: theme.spacing(0, 0, 1, 0),
		[`& .${classes.bundle}`]: {
			margin: theme.spacing(0, 0, 2, 0),
			padding: theme.spacing(2),
			[`& .${classes.icon}`]: {
				fontSize: '1.4rem',
			},
			[`& .${classes.bundleTitle}`]: {
				fontSize: '1.15rem',
				fontWeight: theme.typography.fontWeightMedium,
			},
			[`& .${classes.bundleLink} a`]: {
				fontWeight: theme.typography.fontWeightBold,
				color: theme.palette.common.black,
			},
		}
	},

    [`& .${classes.bundle}`]: {
		backgroundColor: lighten(theme.palette.info.main, 0.9),
		display: 'flex',
		alignItems: 'center',
		zIndex: 1,
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			[`& .${classes.bundleButton}`]: {
				marginTop: theme.spacing(1),
				flexBasis: '100%',
			},
		},
	},

    [`& .${classes.bundleIcon}`]: {
		padding: theme.spacing(0, 1),
		color: theme.palette.primary.dark,
	},

    [`& .${classes.bundleContent}`]: {
		flex: 1,
		margin: theme.spacing(0, 1),
	},

    [`& .${classes.bundleButton}`]: {
		alignSelf: 'center',
		margin: theme.spacing(0, 1),
		color: theme.palette.common.white,
		borderRadius: theme.shape.borderRadiusCards,
		backgroundColor: theme.palette.info.main,
		'&:hover, &:focus': {
			backgroundColor: darken(theme.palette.info.main, 0.2),
		},
		boxShadow: 'none',
	},

    [`& .${classes.bundleTitle}`]: {
		color: theme.palette.primary.dark,
		fontWeight: theme.typography.fontWeightBold,
	},
}));

const apiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'BUNDLES',
		endpoint: `athena-course-bundles/${scopeId}`,
	},
});

const CourseBundle = ({ courseId, bundles, onSelectBundle, mode, loading, data }) => {

	const finalBundles = bundles || data;
	const { i18n, t } = useTranslation();

	if (loading && !finalBundles) {
		return (
			<LinearProgress
				aria-label={t('course.bundles.loading')}
				color="primary"
				sx={(theme) => ({
					height: theme.spacing(0.25),
					marginTop: theme.spacing(-2),
					marginBottom: theme.spacing(1.75),
				})}
			/>
		);
	}

	if(!Array.isArray(finalBundles)) return null;

	const applicableBundles = finalBundles.filter(bundle => (
		Boolean(bundle.applicableInCourses.find(course => course.id === courseId))
	));

	if(applicableBundles.length === 0) return null;

	return (
        <Root className={classnames({
			[classes.list]: mode === MODES.LIST,
			[classes.course]: mode === MODES.COURSE,
		})}>
			{applicableBundles.map(bundle => (
				<div className={classes.bundle} key={bundle.id}>
					<div className={classes.bundleIcon}>
						<Redeem sx={{ fontSize: 'inherit' }} className={classes.icon} />
					</div>
					<div className={classes.bundleContent}>
						<div className={classes.bundleTitle}>
							{t('course.bundle.title', {
								bundleTitle: bundle.description,
								percentage: percentageComposer(bundle.discountPercentage)
							})}
						</div>
						{t('course.bundle.text')}
						{bundle.applicableInCourses.filter(course => course.id !== courseId).map(course => (
							<span className={classes.bundleLink} key={course.id}>
								{mode === 'list' ? (
									<a href={`#${course.id}`}>{course.name}</a>
								) : (
									<Link to={path(i18n, routes.COURSE, scopeUrlSegment(course.parent.parent.parent), scopeUrlSegment(course.parent.parent), scopeUrlSegment(course.parent), course.id)}>{course.name}</Link>
								)}
							</span>
						))}
					</div>
					<Button variant="contained" className={classes.bundleButton} onClick={() => onSelectBundle(bundle)}>
						{t('course.bundle.button')}
					</Button>
				</div>
			))}
		</Root>
    );
};

CourseBundle.propTypes = {
	courseId: PropTypes.number.isRequired,
	onSelectBundle: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(['list', 'course']).isRequired,
	bundles: PropTypes.array,
};

export default withFetchData((props) => props.bundles ? undefined : apiAction(props.courseId), {
	keepData: true,
	customId: (props) => `CourseBundle-${props.bundles ? '' : props.courseId}`,
})(CourseBundle)
