import { styled } from "@mui/material";
import React from 'react'

const BackgroundSheet = styled(({ isCheckoutWrapper, children, ...props }) => (
	<div {...props}>
		{children}
	</div>
))(({ theme, isCheckoutWrapper }) => ({
	margin: '10px -20px -20px',
	padding: '30px 20px',
	backgroundColor: theme.palette.common.white,
	...(isCheckoutWrapper && {
		borderTop: `1px solid ${theme.palette.grey[200]}`,
		padding: '33px 80px',
		[theme.breakpoints.down('sm')]: {
			padding: '26px 20px'
		}
	})
}));

export default BackgroundSheet
