import { Alert, Grid, styled } from "@mui/material";
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BasicLocaleSwitcher from '../components/general/BasicLocaleSwitcher'
import { parseSearch } from '../helpers/query'
import { path, routes } from '../routes'
import Evaluation from '../components/evaluation/Evaluation'
import Content from '../components/content/Content'

import logo from '../components/header/logo.png';

const StyledH1 = styled('h1')(({ theme }) => ({
	textAlign: 'center',

	'& a': {
		display: 'inline-block',
		width: '136px',
		padding: 0,
		float: 'none',
		marginBottom: '-20px',

		'& img': {
			width: '100%',
			height: 'auto',
		},

		'&:hover': {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
		},

		[theme.breakpoints.down('md')]: {
			marginBottom: 0,
		},
	},

	[theme.breakpoints.down('md')]: {
		marginTop: 0,
	},
}))

const EvaluationPage = () => {
	const location = useLocation();
	const { t, i18n } = useTranslation();

	const query = parseSearch(location.search);

	return (
		<Content>
			<Content.Header>
				<Grid container rowSpacing={2} alignItems="center">
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }} order={{ xs: 2, md: 1 }}>
						<Content.Back message={t('evaluation.to_the_site')} to={path(i18n, routes.HOME)} />
					</Grid>
					<Grid item xs={12} md={4} lg={6} order={{ xs: 1, md: 2 }}>
						<StyledH1>
							<Link to={path(i18n, routes.HOME)}>
								<img src={logo} alt="AthenaStudies" />
							</Link>
						</StyledH1>
					</Grid>
					<Grid item xs={12} md={4} lg={3} order={3} sx={{ textAlign: { md: 'right' } }}>
						<BasicLocaleSwitcher />
					</Grid>
				</Grid>
			</Content.Header>
			<Helmet>
				<title>{t('evaluation.page_title')}</title>
			</Helmet>

			{query && query.token ? (
				<Evaluation token={query.token} />
			) : (
				<Alert severity="warning" icon={false}>
					{t('evaluation.link_error')}
				</Alert>
			)}
		</Content>
	);
}

export default EvaluationPage
