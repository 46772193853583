import { Alert, Box } from "@mui/material";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getCourses } from '../../actions/auth'
import SectionTitle from '../general/section-title'
import Loader from '../loader'
import AccountCourse from './AccountCourse'

class AccountCourses extends Component {
	state = {
		open: 0
	};

	render(){
		const { t } = this.props;

		return (
			<Box sx={{ mt: 0.75, mb: 2.5 }}>
				<SectionTitle>{t('account.courses.my_courses')}</SectionTitle>
				{this.renderContent()}
			</Box>
		)
	}

	renderContent(){
		const { courses, t } = this.props;
		const { open } = this.state;

		if(!courses) return <Loader/>;
		if(courses.length === 0) return (
			<Alert severity="info" icon={false}>{t('account.courses.no_courses')}</Alert>
		);

		return courses.map((course, index) => (
			<AccountCourse
				key={index}
				index={index}
				course={course}
				open={index === open}
				onClick={this.handleOpenChange}
			/>
		));
	}

	componentDidMount(){
		this.props.getCourses();
	}

	handleOpenChange = (id) =>{
		this.setState({
			open: this.state.open === id ? null : id
		});
	};
}

AccountCourses.propTypes = {
	courses: PropTypes.array,
	getCourses: PropTypes.func
};

export default withTranslation()(connect(state => ({
	courses: state.lists.courses
}), {
	getCourses
})(AccountCourses))
