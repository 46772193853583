import { KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import StyledScopeList from '../../components/scope/StyledScopeList';
import StyledScopeListItem from '../../components/scope/StyledScopeListItem';
import { getRootScopeTitle } from '../../helpers/scope';
import useRootScope from '../../hoc/hooks/useRootScope';
import { routes, path } from '../../routes';
import Content from '../../components/content/Content';

function VacanciesStaticPage({ children }) {
    const rootScope = useRootScope();
    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('vacancies.seo_title')}</title>
                <meta name="description" content={t('vacancies.seo_description')} />
                <meta property="og:title" content={`${getRootScopeTitle(rootScope)} - ${t('vacancies.seo_title')}`} />
                <meta property="og:description" content={t('vacancies.seo_description')} />
            </Helmet>
            {children || (
                <Content>
                    <Content.Header>
                        <h1>{t('vacancies.title')}</h1>
                    </Content.Header>
                    <StyledScopeList>
                        {/* TODO: This could be a MUI <ListItemButton component="Link" ..> */}
                        <StyledScopeListItem to={path(i18n, routes.STATIC.VACANCIES_TEACHER)} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="scope-list-item-name" style={{ flex: 1 }}>
                                {t('vacancies.teacher')}
                            </div>
                            <KeyboardArrowRight fontSize="large" sx={{ color: 'info.main' }} />
                        </StyledScopeListItem>
                        <StyledScopeListItem to="/vacatures/athenacheck" style={{ display: 'flex' }}>
                            <div className="scope-list-item-name" style={{ flex: 1 }}>
                                {t('vacancies.editorAthenaCheck')}
                            </div>
                            <KeyboardArrowRight fontSize="large" sx={{ color: 'info.main' }} />
                        </StyledScopeListItem>
                    </StyledScopeList>
                </Content>
            )}
        </>
    );
}

export default VacanciesStaticPage;
