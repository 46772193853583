import { lastPathSelector } from '../selectors/lastPath'

export const LAST_PATH = 'last_path';

const replaceLastScopePath = (scopesOrIds) => (dispatch) => {
	const ids = scopesOrIds?.map?.(scope => typeof scope === 'object' ? scope.id : scope) || [];

	return dispatch({
		type: LAST_PATH,
		path: ids,
	});
}

export const updateLastScopePath = (scopesOrIds) => (dispatch, getState) => {
	const path = [...lastPathSelector(getState())];
	const ids = scopesOrIds.map(scope => typeof scope === 'object' ? scope.id : scope);

	ids.forEach(id => (
		id === '..' ? path.pop() : path.push(id)
	));

	return dispatch(replaceLastScopePath(path));
}

export const updateLastScopePathStartAt = (scopesOrIds, startAtIndex) => (dispatch, getState) => {
	const path = [...lastPathSelector(getState())].slice(0, startAtIndex);
	const ids = scopesOrIds.map(scope => typeof scope === 'object' ? scope.id : scope);

	ids.forEach(id => (
		id === '..' ? path.pop() : path.push(id)
	));

	return dispatch(replaceLastScopePath(path));
}
