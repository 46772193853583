import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import createLangRoutes from './router';
import { rootScopeSelector } from './selectors/scope';

const selectInitState = (state) => state.init;

function ClientRouter() {
    const store = useStore();
    const init = useSelector(selectInitState);
    const root = useSelector(rootScopeSelector);

    const router = useMemo(() => {
        if (!init || !root) return false;
        return createBrowserRouter(createLangRoutes(store, init.i18n, root), { basename: init.rootPath });
    }, [init?.rootScope]); // eslint-disable-line react-hooks/exhaustive-deps -- `root` is not stable and intentionally left out of the dependencies

    if (!router) return null;

    return (
        <RouterProvider
            router={router}
            fallbackElement={null}
        />
    );
}

export default ClientRouter;
