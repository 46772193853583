import { lightGreen, orange, red } from "@mui/material/colors";
import { styled } from "@mui/material";
import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { getStockStatus, getStockStatusClass } from "../../helpers/course";
import { readableDuration } from "../../helpers/duration";

const PREFIX = 'StockStatusBanner';

const classes = {
	statusBanner: `${PREFIX}-statusBanner`,
	highlighted: `${PREFIX}-highlighted`,
	circle: `${PREFIX}-circle`,
	textWrapper: `${PREFIX}-textWrapper`,
	stockStatusText: `${PREFIX}-stockStatusText`
};

const StyledRoot = styled('div')(({ theme }) => ({
	[`&.${classes.statusBanner}`]: {
		display: 'flex',
		alignItems: 'center',
		height: '2.75rem',
		'&.success': {
			color: lightGreen[800],
			backgroundColor: lightGreen[100],
			[`& .${classes.circle}:after`]: {
				background: lightGreen[800],
			},
		},
		'&.warning': {
			color: orange[800],
			backgroundColor: orange[50],
			[`& .${classes.circle}:after`]: {
				background: orange[700],
			},
			[`&.${classes.highlighted}`]: {
				background: orange[700],
				color: theme.palette.common.white,
				'& .${classes.circle}:after': {
					background: theme.palette.common.white,
				},
			}
		},
		'&.danger': {
			color: red[800],
			backgroundColor: red[50],
			[`& .${classes.circle}:after`]: {
				background: red[800],
			},
		},
		'& svg': {
			fontSize: '1.8rem',
			margin: theme.spacing(0, 0.5, 0, 0),
		},
	},
	[`& .${classes.circle}`]: {
		position: "relative",
		margin: theme.spacing(0, 0.5, 0, 2),
		display: 'flex',
		justifyContent: "center",
		alignItems: "center",
		height: theme.spacing(1.25),
		width: theme.spacing(1.25),
		'&::after': {
			content: '""',
			display: 'block',
			borderRadius: '50%',
			height: "100%",
			width: "100%",
			background: theme.palette.grey[500],
			transform: "translateY(-1px)",
		},
		'&.pulse::after': {
			animation: `$pulse 1500ms infinite`,
		},
	},
	"@keyframes pulse": {
		"0%": {
			opacity: 0.4,
		},
		"50%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0.4,
		},
	},
	[`& .${classes.textWrapper}`]: {
		padding: theme.spacing(0.5, 1),
		fontSize: '0.95rem',
		lineHeight: '1.1em',
	},
	[`& .${classes.stockStatusText}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

function hoursTillNext(group) {
	return moment(group.courseStart).diff(moment(), 'hours');
}

const StockStatusBanner = ({ group }) => {
	const { t } = useTranslation();
	const hours = hoursTillNext(group);
	const ended = !isNaN(hours) && hours < 0;
	const showCountdown = hours > 0 && hours < (7 * 24);

	return (
		<StyledRoot className={classnames(classes.statusBanner, getStockStatusClass(group.courseStockStatus), {
			[classes.highlighted]: group.courseStockStatus === 'ALMOST_FULL',
		})}>
			<div className={`${classes.circle} ${showCountdown ? 'pulse' : ''}`} />
			<div className={classes.textWrapper}>
				<div className={classes.stockStatusText}>
					{getStockStatus(group.courseStockStatus, t)}
				</div>
				{(!ended && showCountdown) && (
					<Trans
						i18nKey="course.group.starts_in"
						values={{
							time: readableDuration(hours),
						}}
					>
						Start over <strong>1 uur</strong>
					</Trans>
				)}
			</div>
		</StyledRoot>
	)
}

StockStatusBanner.propTypes = {
	group: PropTypes.shape({
		courseStockStatus: PropTypes.string.isRequired,
		courseStart: PropTypes.string,
	})
}
export default StockStatusBanner;
