import { gtmEvent, gtmOrder } from '../helpers/gtm'
import { getApiTypeSuccess } from '../helpers/types'
import { ORDER_BASKET, CLEAR_ACTIVE_ORDER } from '../actions/basket'
import { ORDER, PAY, ORDER_RETURN } from '../actions/auth'

const OrderCookieName = 'ATHENASTUDIES-ACTIVE-ORDER';
const OrderCookieSeconds = 1800; // half a hour

/**
 * @param {Cookies} cookies
 */
export default (cookies) => (state = {
	activeOrderId: parseInt(cookies.get(OrderCookieName))
}, action) => {
	if(action.type === getApiTypeSuccess(ORDER_BASKET) && action.response && action.response.result) {
		const free = (action.response.result.status === 'FULL_DISCOUNT' || action.response.result.status === 'FREE');

		// Store a cookie for unexpected page requests or history.back actions
		if(!free){
			cookies.set(OrderCookieName, action.response.result.id, {
				maxAge: OrderCookieSeconds,
			});
		}

		state = { ...state,
			order: action.response.result,
			activeOrderId: free ? null : action.response.result.id,
			orderReturn: null,
		};

		const orderStatus = state.order.status && state.order.status.toLowerCase().replace('_', ' ');
		gtmEvent('order', {
			orderAction: 'place',
			orderStatus: orderStatus,
			orderValue: state.order.totalPrice.amount,
		});

		if(free){
			// ORDER SUCCESS - FREE ORDERS
			gtmEvent('order', {
				orderAction: 'success',
				orderStatus: 'free',
				orderValue: 0,
			});
			gtmOrder(state.order);
		}
	}

	if(action.type === getApiTypeSuccess(ORDER) && action.response && action.response.result && action.response.result.id === state.activeOrderId) {
		state = { ...state,
			order: action.response.result,
		};
	}

	if(action.type === getApiTypeSuccess(PAY) && action.response && action.response.result) {
		state = { ...state,
			payLink: action.response.result.transactionLink,
		};

		gtmEvent('order', {
			orderAction: 'payStart',
			orderStatus: null,
			orderValue: null,
		});
	}

	if(action.type === getApiTypeSuccess(ORDER_RETURN) && action.response && action.response.result) {
		state = { ...state,
			orderReturn: action.response.result,
		};

		if(parseInt(cookies.get(OrderCookieName)) === state.orderReturn.id){
			if(state.orderReturn.positive){
				// ORDER SUCCESS - PAID ORDERS
				gtmEvent('order', {
					orderAction: 'success',
					orderStatus: 'paid',
					orderValue: state.orderReturn.totalPrice.amount,
				});
				gtmOrder(state.orderReturn);
			}else{
				gtmEvent('order', {
					orderAction: 'failure',
					orderStatus: state.orderReturn.status,
					orderValue: state.orderReturn?.totalPrice?.amount,
				});
			}
		}

		if(state.orderReturn.positive){
			cookies.remove(OrderCookieName);
			state = { ...state,
				order: null,
				activeOrderId: null,
			};
		}
	}

	if(action.type === CLEAR_ACTIVE_ORDER) {
		cookies.remove(OrderCookieName);
		state = {};
	}

	return state;
}
