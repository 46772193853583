import { Schemas, CALL_API } from '../schemas'
import { getApiTypeSuccess } from '../helpers/types'
import { BasketCookieName} from '../reducers/init'
import { getBasket } from './basket'

export const INIT = 'init';

const getInit = (url) => ({
	[CALL_API]: {
		type: INIT,
		endpoint: url,
		schema: Schemas.INIT
	}
});

export const fetchInit = (partner, initCode) => (dispatch, getState, cookies) => {
	let query = [];

	if(partner && partner.length > 0) query.push('partner=' + partner);
	if(initCode && initCode.length > 0) query.push('initCode=' + initCode);

	const url = 'init' + (query.length > 0 ? '?' + query.join('&') : '');

	return dispatch(getInit(url)).then(res => {
		// Fetch basket if needed
		if(res.type === getApiTypeSuccess(INIT)) {
			const basketId = cookies.get(BasketCookieName);
			if(!res.response.result.shoppingBasket && basketId){
				return dispatch(getBasket(basketId));
			}
		}

		return res;
	});
};
