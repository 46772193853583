import { sort } from 'ramda'

export const SortScopesOnName = (scopes) => {
	if(!scopes) return scopes;

	return sort((a, b) => a.name.localeCompare(b.name, undefined, {
		numeric: true,
		sensitivity: 'base',
	}), scopes);
};

export const sortAlphabet = key => (a, b) => a[key].localeCompare(b[key])
