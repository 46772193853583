import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const style = {
	width: '100%',
	border: 'none',
	backgroundColor: 'transparent',
};

const MarkdownIframe = ({ src, title, height, listenStudyspace }) => {
	const ref = useRef();

	useEffect(() => {
		function listener(event){
			if(listenStudyspace !== 'true') return;

			if (event.origin.indexOf('https://athenaschool.studyspace.nl') !== -1) {
				if (event.data === 'scroll_top') {
					window.scrollTo(0, window.scrollY + ref.current?.getBoundingClientRect?.()?.top - 120);
				} else {
					if(ref.current){
						ref.current.height = event.data;
					}
				}
			}
		}

		window.addEventListener('message', listener);

		return () => {
			window.removeEventListener('message', listener);
		};
	}, [listenStudyspace]);

	const handleLoad = useCallback(() => {
		// TODO: Impossible at the moment due to cors on the external src
		// console.log('loaded', ref.current.contentWindow.document.body.scrollHeight);
	}, []);

	return (
		<iframe
			ref={ref}
			src={src}
			title={title}
			scrolling={height ? 'no' : 'yes'}
			height={height ? `${height}px` : '600px'}
			frameBorder={0}
			allowFullScreen={false}
			style={style}
			onLoad={handleLoad}
		/>
	);
};

MarkdownIframe.propTypes = {
	src: PropTypes.string.isRequired,
	title: PropTypes.string,
	height: PropTypes.string,
	listenStudyspace: PropTypes.string,
};

export default MarkdownIframe
