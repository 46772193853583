import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { mergeAll, clone } from 'ramda'

const defaultOptions = {
	fields: [],
	cleanOnSuccess: false
};

export default (ComposedComponent, extraOptions) => {
	const options = { ...defaultOptions, ...extraOptions };
	const defaultFields = mergeAll(
		options.fields.map(
			field => ({[field]: ''})
		)
	);

	return class extends Component {
		displayName = 'SimpleState(' + (ComposedComponent.displayName || 'Unknown') + ')';

		constructor(props){
			super(props);

			this.state = { ...defaultFields, ...(props.defaultFields || {}), };
		}

		render() {
			return <ComposedComponent fields={this.state}
									  onChangeField={this.handleChange}
									  onChangeFieldBind={this.handleChangeBind}
									  onChangeFieldCheckbox={this.handleChangeCheckbox}
									  onChangeFieldDirect={this.handleChangeDirect}
									  {...this.props} />;
		}

		componentDidUpdate(prevProps){
			if(options.cleanOnSuccess && !prevProps.success && this.props.success){
				this.setState(clone(defaultFields));
			}
		}

		handleChange = (e) => {
			this.setState({
				[e.target.name]: e.target.value
			})
		}

		handleChangeBind = field => (e) => {
			this.setState({
				[field]: e.target.value
			})
		}

		handleChangeCheckbox = (e) => {
			const name = e.target.name;
			this.setState(state => ({
				[name]: !state[name]
			}))
		}

		handleChangeDirect = (field, value) => {
			this.setState({
				[field]: value
			})
		}
	}
};
