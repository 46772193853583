import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { styled } from "@mui/material";
import classnames from "classnames";
import React from 'react'
import PropTypes from 'prop-types'
import { useDataLayer } from '../../hoc/hooks/useDataLayer'

// Some java hash function to generate ids
const createId = (title) => {
	let hash = 0;
	if (title.length == 0) return hash;
	for (let i = 0; i < title.length; i++) {
		let char = title.charCodeAt(i);
		hash = ((hash<<5)-hash)+char;
		hash = hash & hash;
	}
	return hash;
};

const PREFIX = 'FaqItem';
const classes = {
	item: `${PREFIX}-item`,
	title: `${PREFIX}-title`,
	body: `${PREFIX}-body`,
	open: `${PREFIX}-open`,
}

const StyledFaqItem = styled('div')(({ theme }) => ({
	[`&.${classes.item}`]: {
		padding: '0 20px',
		[`& + .${classes.item}`]: {
			borderTop: `1px solid ${theme.palette.grey[200]}`,
		},

		[`& .${classes.title}`]: {
			padding: '20px 0',
			fontSize: '1.1em',
			color: theme.palette.grey[500],
			cursor: 'pointer',
		},

		[`& .${classes.body}`]: {
			display: 'none',
			paddingBottom: '20px',
			color: theme.palette.grey[700],
		},

		[`&.${classes.open}`]: {
			backgroundColor: theme.palette.grey[50],

			[`& .${classes.title}`]: {
				color: theme.palette.common.black,
			},

			[`& .${classes.body}`]: {
				display: 'block',
			},
		},
	},
}));

const FaqItem = ({title, children}, {faqToggle, faqActive}) => {
	const { sendEvent } = useDataLayer();

	const id = createId(title);
	const open = faqActive == id;
	const handleClick = () => {
		faqToggle(id);
		if(!open){
			sendEvent('faq', {
				faqTitle: title,
			});
		}
	};

	return (
		<StyledFaqItem className={classnames(classes.item, {
			[classes.open]: open,
		})}>
			<div className={classes.title} onClick={handleClick}>
				{open ? (
					<KeyboardArrowUp sx={{ float: 'right', ml: 1 }} />
				) : (
					<KeyboardArrowDown sx={{ float: 'right', ml: 1 }} />
				)}
				{title}
			</div>
			<div className={classes.body}>
				{children}
			</div>
		</StyledFaqItem>
	);
};

FaqItem.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

FaqItem.contextTypes = {
	faqToggle: PropTypes.func.isRequired,
	faqActive: PropTypes.number
};

export default FaqItem
