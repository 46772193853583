import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { getStockStatusClass, isUnavailableByStockStatus } from '../../helpers/course'
import { shortDateAbr, time } from '../../helpers/datetime'

const PREFIX = 'CourseBundleSelectGroup';

const classes = {
    root: `${PREFIX}-root`,
    handle: `${PREFIX}-handle`,
    content: `${PREFIX}-content`,
    title: `${PREFIX}-title`,
    checkbox: `${PREFIX}-checkbox`,
    slots: `${PREFIX}-slots`,
    slotsNone: `${PREFIX}-slotsNone`,
    slotsRow: `${PREFIX}-slotsRow`,
};

const Root = styled('div', {
	shouldForwardProp: prop => prop !== 'selected',
})(({ theme, selected }) => ({
	display: 'flex',
	justifyContent: 'stretch',
	cursor: 'pointer',
	borderRadius: 4,
	'&:not(.unavailable):hover': {
		boxShadow: theme.shadows[1],
		[`& .${classes.content}`]: {
			backgroundColor: theme.palette.grey[300],
		},
	},
	'&.unavailable': {
		cursor: 'unset',
		[`& .${classes.content}`]: {
			backgroundColor: 'transparent',
		},
	},
	'&.danger': {
		[`& .${classes.handle}`]: {
			backgroundColor: theme.palette.error.main,
		},
	},
	'&.warning': {
		[`& .${classes.handle}`]: {
			backgroundColor: theme.palette.warning.main,
		},
	},

    [`& .${classes.handle}`]: {
		borderRadius: '4px 0 0 4px',
		backgroundColor: theme.palette.success.main,
		width: 6,
	},

    [`& .${classes.content}`]: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: selected ? theme.palette.grey[200] : theme.palette.grey[100],
		borderRadius: '0 4px 4px 0',
		flex: 1,
		padding: theme.spacing(0.5, 1, 1, 1),
		borderColor: selected ? theme.palette.primary.main : theme.palette.grey[100],
		borderWidth: '1px 1px 1px 0',
		borderStyle: 'solid',
	},

    [`& .${classes.title}`]: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: theme.typography.fontWeightBold,
		'& > span': {
			flex: 1,
		},
	},

    [`& .${classes.checkbox}`]: {
		fontSize: '1.4em',
		color: selected ? theme.palette.primary.main : 'inherit',
	},

    [`& .${classes.slots}`]: {
		marginTop: theme.spacing(1),
		flex: 1,
		fontSize: '0.95em',
		lineHeight: '1.6em',
		color: theme.palette.grey[600],
	},

    [`& .${classes.slotsNone}`]: {
		fontStyle: 'italic',
		textAlign: 'center',
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}));

const CourseBundleSelectGroup = ({ group, selected, onSelect }) => {
	const unavailable = isUnavailableByStockStatus(group.courseStockStatus);

	const handleSelect = useCallback(() => {
		!unavailable && onSelect(group.id);
	}, [unavailable, group, onSelect]);


	return (
        <Root
			className={classnames({
				unavailable: unavailable,
				[getStockStatusClass(group.courseStockStatus)]: true,
			})}
			onClick={handleSelect}
			selected={selected}
		>
			<div className={classes.handle} />
			<div className={classes.content}>
				<div className={classes.title}>
					<span>{group.name}</span>
					{!unavailable && (selected ? (
						<CheckBoxOutlined className={classes.checkbox} />
					) : (
						<CheckBoxOutlineBlank className={classes.checkbox} />
					))}
				</div>
				<div className={classes.slots}>
					{unavailable ? (
						<div className={classes.slotsNone}>
							<Trans
								i18nKey="course.group.slots.not_available_anymore"
								defaults="Niet meer verkrijgbaar"
							/>
						</div>
					) : (
						group.timeslots.length === 0 ? (
							<div className={classes.slotsNone}>
								<Trans
									i18nKey="course.group.slots.not_available_yet"
									defaults="Nog geen tijden bekend"
								/>
							</div>
						) : group.timeslots.map(slot => (
							<div key={slot.id} className={classes.slotsRow}>
								<Box sx={{ float: 'right' }}>
									{time(slot.startDateTime)} - {time(slot.endDateTime)}
								</Box>
								{shortDateAbr(slot.startDateTime)}
							</div>
						))
					)}
				</div>
			</div>
		</Root>
    );
};

CourseBundleSelectGroup.propTypes = {
	group: PropTypes.object.isRequired,
	selected: PropTypes.bool,
	onSelect: PropTypes.func.isRequired,
};

export default CourseBundleSelectGroup
