import { useSelector } from 'react-redux'
import { authSelector } from '../../selectors/user'

/**
 * @return {{ user, loggedIn }}
 */
const useAuth = () => {
	return useSelector(authSelector);
};

export default useAuth
