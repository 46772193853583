import { createSelector } from 'reselect'
import { reduce } from 'ramda'

const basketSelector = state => state.init.shoppingBasket;

export const basketCountSelector = createSelector(
	basketSelector,
	(basket) => {
		if(!basket) return null;
		return reduce((t, item) => (
			t + item.quantity
		), 0, basket.basketContents);
	}
);
