const staticUrl = 'https://static.athenastudies.nl/';

export const fallbackRootLocale = 'nl-nl';

export default {
	gtmId: 'GTM-PXCDDJM', // GTM default id

	staticUrl: staticUrl,
	staticPartnerUrl: staticUrl + 'partners/'
};
