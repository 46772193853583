import React from 'react'
import PropTypes from 'prop-types'
import AccountEditAddressForm from '../account/AccountEditAddressForm'

const CheckoutAddressCheck = ({ auth, onAddressChecked }) => (
	<AccountEditAddressForm
		defaultFields={{ ...auth.user }}
		onSuccess={onAddressChecked}
		saveAndConfirmText
	/>
);

CheckoutAddressCheck.propsTypes = {
	auth: PropTypes.object.isRequired,
	onAddressChecked: PropTypes.func.isRequired,
};

export default CheckoutAddressCheck
