import React from 'react'
import { Grid, styled, InputLabel, Button } from '@mui/material'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { routes, path } from './../../routes'
import LoginForm from './../login/login-form'

const StyledGridContainer = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		alignItems: 'normal',
	},
}));

const VerticalSeparator = styled(Grid)(({ theme }) => ({
	alignSelf: 'stretch',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	['& .line']: {
		flex: 1,
		width: 1,
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
	},
	['& .text']: {
		width: '100%',
		padding: '6px 0',
		textAlign: 'center',
		fontStyle: 'italic',
		color: theme.palette.grey[500],
	},
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

const StyledDiv = styled(Grid)(({ theme }) => ({
	paddingTop: 0,
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(3),
		borderTop: `1px solid ${theme.palette.grey[400]}`,
	},
}));

const CheckoutLoginRegister = () => {
    const { i18n, t } = useTranslation();

    return (
    	<StyledGridContainer container spacing={2}>
			<Grid item xs={12} sm={5} md={4}>
				<LoginForm hideRegister={true} preventRedirect={true} />
			</Grid>

			<VerticalSeparator item xs={12} sm={2}>
				<div className="line" />
				<div className="text">{t('checkout.or')}</div>
				<div className="line" />
			</VerticalSeparator>

			<Grid item xs={12} sm={5} md={4}>
				<StyledDiv>
					<InputLabel htmlFor="checkout-button-register" sx={{ color: 'common.black', fontSize: 15 }}>
						{t('checkout.button_register_label')}
					</InputLabel>
					<Button
						component={Link}
						to={`${path(i18n, routes.REGISTER)}?return=${path(i18n, routes.CHECKOUT)}`}
						variant="contained"
						color="cta"
						fullWidth
						id="checkout-button-register"
					>
						{t('checkout.button_register')}
					</Button>
				</StyledDiv>
			</Grid>
		</StyledGridContainer>
    );
}

export default CheckoutLoginRegister
