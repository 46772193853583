import { Alert } from "@mui/material";
import React, { Component } from 'react'
import { mergeAll, pick, mapObjIndexed, values } from 'ramda'
import { connect } from 'react-redux'
import withRouter from '../helpers/withRouter'


const defaultOptions = {
	successRedirect: false,
	successRedirectState: null,
	dataPick: false,
	dataPickKeepId: false,
	mapStateToProps: () => ({})
};

const formError = (form, error) => ({
	type: 'FORM_ERROR',
	form: form,
	error: error
});

const formSuccess = (form, res) => ({
	type: 'FORM_SUCCESS',
	form: form,
	result: res
});

const formSaving = (form) => ({
	type: 'FORM_SAVING',
	form: form
});

const formClear = (form) => ({
	type: 'FORM_CLEAR',
	form: form
});

/**
 * FormData HOC, provides tools to process forms
 *
 * @param ComposedComponent
 * @param form
 * @param [extraOptions] object {successRedirect func, mapStateToProps func}
 * @returns Component
 */
export default (ComposedComponent, form, extraOptions) => {
	const options = Object.assign({}, defaultOptions, extraOptions);

	return withRouter(connect((state) => (Object.assign({},
			options.mapStateToProps(state), {
				formData: state.formData[form],
			})
	))(class extends Component {
		displayName = 'FormData(' + (ComposedComponent.displayName || 'Unknown') + ')';

		render() {
			const { formData } = this.props;

			return <ComposedComponent handleSubmit={this.handleSubmit}
									  watchSubmit={this.watchSubmit}
									  saving={formData && formData.saving}
									  error={formData && formData.error}
									  success={formData && formData.success}
									  {...this.props} />;
		}

		handleSubmit = (getData, action, validation) => {
			const { dispatch } = this.props;

			return (e) => {
				if(e) e.preventDefault();

				// Collect data
				let data = getData();

				// Do optional local validation
				// Expects validation function which returns false or an error object
				// Error object might be have fields and/or global error field, string will be global
				if(validation){
					let error = validation(data);
					if(error){
						if(typeof error == 'string') error = { error: error };
						return dispatch(formError(form, error));
					}
				}

				// Optionally pick from the data
				if(options.dataPick){
					let keep = options.dataPick;
					if(options.dataPickKeepId) keep.push('id');

					data = pick(keep, data);
				}

				// Set saving state
				dispatch(formSaving(form));

				// Dispatch the action with the data
				return dispatch(action(data)).then((res) => {
					// Handle the error
					if(res && res.errorCode){
						// Exclude 401 error and let other systems handle it
						if(res.errorCode != 401){
							return dispatch(formError(form, res));
						}
					}else{
						res = res && res.response && res.response.result;
						return dispatch(formSuccess(form, res || true));
					}
				})
			}
		};

		watchSubmit = (actionPromise, dispatchIt = false) => {
			const { dispatch } = this.props;

			// Set saving state
			dispatch(formSaving(form));

			// Dispatch the action with the data
			return (dispatchIt ? dispatch(actionPromise) : actionPromise).then((res) => {
				// Handle the error
				if (res && (res.errorCode || res.type?.includes('_FAILURE'))) {
					// Exclude 401 error and let other systems handle it
					if(res.errorCode != 401){
						return dispatch(formError(form, res));
					}
				}else{
					res = res && res.response && res.response.result;
					return dispatch(formSuccess(form, res || true));
				}
			})
		};

		componentWillUnmount(){
			// Make sure to clear the form
			this.props.dispatch(formClear(form));
		}

		componentDidUpdate(prevProps){
			const { formData, preventRedirect } = this.props;

			if(!formData) return;

			// Transition to success
			if((!prevProps.formData || !prevProps.formData.success) && formData.success && options.successRedirect && !preventRedirect){
				const path = options.successRedirect(this.props);

				this.props.navigate({
					pathname: path,
					state: Object.assign({}, {
						successResult: formData.success
					}, options.successRedirectState)
				});
			}
		}
	}));
};

export function errorRenderer(error, showFieldErrors = false){
	if(!error) return null;

	return (
		<Alert severity="warning" icon={false}>
			{error.error}
			{
				showFieldErrors && error.errors &&
				values(mapObjIndexed(
					(num, key) => <div key={key}>{key}: {num}</div>,
					error.errors
				))
			}
		</Alert>
	);
}

/**
 * FormData HOC, provides tools to process forms
 *
 * @param error
 * @param field
 * @param [renderer]
 * @param [i18n]
 * @returns Component | string
 */
export function errorRendererField(error, field, renderer, i18n = undefined){
	if(!error || !error.errors) return null;
	if(!error.errors[field]) return null;

	if(renderer){
		return React.createElement(
			renderer, {
				error: error.errors[field],
				i18n: i18n,
			}
		);
	}

	return error.errors[field];
}

export function formDataReducer(state = {}, action) {
	if(!action.form) return state;

	switch(action.type){
		case 'FORM_ERROR':
			return mergeAll([{}, state, {
				[action.form]: {
					error: action.error,
					success: false,
					saving: false
				}
			}]);
		case 'FORM_SUCCESS':
			return mergeAll([{}, state, {
				[action.form]: {
					error: false,
					success: action.result || true,
					saving: false
				}
			}]);
		case 'FORM_SAVING':
			return mergeAll([{}, state, {
				[action.form]: {
					error: false,
					success: false,
					saving: true
				}
			}]);
		case 'FORM_CLEAR':
			return mergeAll([{}, state, {
				[action.form]: undefined
			}])
	}

	return state;
}
