import { CALL_API } from "../setup/api";

export const PROPERTIES = 'PROPERTIES';

export const getScopeProperties = () => ({
	[CALL_API]: {
		type: PROPERTIES,
		endpoint: 'scope/properties',
	},
});

export function validProperties(course, scopeProperties = [], ignoreFlag = undefined) {
	return scopeProperties
		.filter(scopeProp => (
			!!course.properties?.[scopeProp.key] && !scopeProp.options?.flags?.[ignoreFlag]
		))
		.map(scopeProp => ({
			key: scopeProp.key,
			options: scopeProp.options,
			value: course.properties?.[scopeProp.key],
		}));
}
