import { getApiTypeSuccess } from '../helpers/types'
import { rootScopeSelector } from '../selectors/scope'
import { Schemas, getKey, CALL_API } from '../schemas'

export const SUB_SCOPES = 'sub_scopes';

const getSubScopes = (parentId) => {
	return {
		[CALL_API]: {
			type: SUB_SCOPES,
			endpoint: `scope/${parentId}/sub-scopes`,
			schema: Schemas.SCOPE_LIST
		},
		addParent: {
			entity: getKey(Schemas.SCOPE_LIST),
			id: parentId
		}
	}
};

export const fetchSubScopes = (parentId) => (dispatch, getState) => {
	if(!parentId) return null;

	const scope = getState().entities.scopes[parentId];
	if(!scope || scope.subScopes) return null;

	return dispatch(getSubScopes(parentId));
};

export const SUB_SUB_SCOPES = 'sub_sub_scopes';

export const fetchSubSubScopes = (parentId) => {
	if(!parentId) return null;

	return {
		[CALL_API]: {
			type: SUB_SUB_SCOPES,
			endpoint: `scope/${parentId}/sub-sub-scopes`,
			schema: Schemas.SCOPE
		},
	}
};

export const SCOPE_BY_PATH = 'SCOPE_BY_PATH';
export const fetchScopeByAbbrPath = (abbrPath, fetchSubScopesDepth = 0) => (dispatch, getState) => {
	const rootScope = rootScopeSelector(getState());
	if(!rootScope) return null;

	return dispatch({
		[CALL_API]: {
			type: SCOPE_BY_PATH,
			endpoint: `scope/${rootScope.id}/find-by-path?path=${abbrPath.map(abbr => abbr === false ? '*' : abbr).join('/').toUpperCase()}`,
			schema: Schemas.SCOPE,
		},
	}).then((res) => {
		if(getApiTypeSuccess(SCOPE_BY_PATH) !== res.type) return res;

		// TODO: replace with a fetchScope?depth= endpoint or something?
		if(fetchSubScopesDepth === 1) return dispatch(fetchSubScopes(res.response.result));
		if(fetchSubScopesDepth === 2) return dispatch(fetchSubSubScopes(res.response.result));

		return res;
	});
};
