import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomLocaleMessage } from '../../../helpers/locale';

const PREFIX = 'WidgetTitle';

const classes = {
    widgetHeader: `${PREFIX}-widgetHeader`,
};

const Root = styled('h3')(({ theme }) => ({
    [`&.${classes.widgetHeader}`]: {
        textAlign: 'center',
        margin: theme.spacing(0, 0, 5),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.65rem',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
            fontSize: '1.7rem',
        },
    },
}));

function WidgetTitle({ title, className, ...rest }) {
    const { i18n } = useTranslation();

    if (!title) return null;

    return (
        <Root className={classnames(classes.widgetHeader, className)} {...rest}>
            {getCustomLocaleMessage(title, i18n)}
        </Root>
    );
}

WidgetTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
};

export default WidgetTitle;
