import { Box, Divider, Alert, Grid } from "@mui/material";
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { intersperse } from 'ramda'
import { useParams } from "react-router-dom";
import { fetchScopeByAbbrPath } from '../actions/scopes'
import ScopeInstituteInfo from '../components/scope/ScopeInstituteInfo'
import { getCustomLocaleMessage } from '../helpers/locale'
import { useLoadingDispatch } from '../hoc/hooks/useLoadingDispatch'
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties'
import { useSetLastPath } from '../hoc/hooks/useSetLastPath'
import { path, routes } from '../routes'
import {
	abbrPathSelector,
	scopeAndParentsSelector,
	scopeByReverseAbbrPathSelector,
	subScopeSelector,
	subSubScopeSelector
} from '../selectors/scope'
import Content from './../components/content/Content'
import Loader from './../components/loader'
import SectionTitle from './../components/general/section-title'
import ScopeList from './../components/scope/ScopeList'
import { SchemaBreadcrumb } from '../helpers/schema'
import { getRootScopeTitle, scopeAbbr, scopeUrlSegment } from '../helpers/scope'
import { useRedirectContext } from '../hoc/RedirectContextProvider'

export const scopeInstitutePageLoader = (store, params) => {
	const state = store.getState();
	const abbrPath = abbrPathSelector(state, { params });
	return store.dispatch(fetchScopeByAbbrPath(abbrPath, 2));
};

const ScopeInstitutePage = () => {
	const params = useParams();
	const [loadingDispatch, loading, error] = useLoadingDispatch();
	const { t, i18n } = useTranslation();

	const rootScopeProperties = useRootScopeProperties();
	const institute = useSelector((state) => scopeByReverseAbbrPathSelector(state, { params }));
	const abbrPath = useSelector((state) => abbrPathSelector(state, { params }));
	const scopes = useSelector((state) => (
		institute && (rootScopeProperties.config.showFacultyScope ? subScopeSelector(state, institute) : subSubScopeSelector(state, institute))
	));
	const { company } = useSelector((state) => scopeAndParentsSelector(state, { id: institute?.id }));
	const partner = useSelector(state => state.init.partner);

	// If static rendered, put the redirect in the context
	const redirectContext = useRedirectContext();
	const redirect = institute?.properties?.redirect;
	if (redirectContext && redirect) {
		redirectContext.url = institute?.properties?.redirect;
	}

	useEffect(() => {
		if(!institute || !institute.subScopes || !scopes){
			loadingDispatch(fetchScopeByAbbrPath(abbrPath, 2));
		}else{
			// TODO: do setLastPath action (with id list)
		}
	}, []);

	useSetLastPath(institute);

	const pathCreator = useCallback((scope, parent) => {
		if(scope.class === 'Faculty'){
			return path(i18n, routes.FACULTY, scopeUrlSegment(institute), scopeUrlSegment(scope))
		}

		return path(i18n, routes.STUDY, scopeUrlSegment(institute), scopeUrlSegment(parent), scopeUrlSegment(scope))
	}, [i18n, institute]);

	useEffect(() => {
		// Prevent doing this in SSR, staticContext above is handling that
		if(typeof window === 'undefined' || !redirect) return;

		window.location.replace(redirect);
	}, [redirect]);

	if((error && error.errorCode === 400) || (institute === undefined && !loading)) return (
		<Content><Alert severity="info" icon={false}>{t('scope.institute.university_not_found')}</Alert></Content>
	);

	if(!institute || loading) return (
		<Content><Loader/></Content>
	);

	const description = t('scope.institute.seo_description', {
		institute: institute.name
	});

	return (
		<Content>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} alignItems="center">
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={path(i18n, routes.COURSES)} />
					</Grid>
					<Grid item xs={12} md={4} lg={6}>
						<h1>{institute.name}</h1>
						<h2>{t('scope.institute.exam_training')} {scopeAbbr(institute)}</h2>
					</Grid>
					<Grid item xs={!!partner ? 12 : 0} md={4} lg={3} sx={{ textAlign: { md: 'right' } }}>
						<Content.Partner partnerGlobal={!!partner} />
					</Grid>
				</Grid>
			</Content.Header>
			<Helmet>
				<title>{institute.name}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={`${getRootScopeTitle(company)} - ${institute.name}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: 'Cursussen', path: path(i18n, routes.COURSES)},
						{name: institute.name, path: path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))}
					])}
				</script>
			</Helmet>
			<Box>
				<ScopeInstituteInfo scope={institute} />
				<SectionTitle>
					{institute.properties?.customSectionTitle
						? getCustomLocaleMessage(institute.properties.customSectionTitle, i18n)
						: t('scope.institute.select_study')
					}
				</SectionTitle>
				<ScopeList
					scopes={scopes}
					pathCreator={pathCreator}
					group={scopes && scopes.length > 8}
					noScopesMessage={t('scope.institute.no_studies_found')}
					filterUnlisted
				/>
			</Box>
			<Box>
				<Divider sx={{ my: 2 }} />
				<em className="text-muted">
					{description}
				</em>
				<ul className="keyword-list">
					{intersperse(' ', (institute.properties?.keywords || []).map(k => (
						<li key={k}>{k}</li>
					)))}
				</ul>
			</Box>
		</Content>
	);
}

export default ScopeInstitutePage
