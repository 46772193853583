import { orange } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import moment from 'moment'

function timeLeftObject(startDateTime, durationMinutes){
	if(!startDateTime) return null;

	const now = moment();
	const d = moment(startDateTime);
	const durationSeconds = durationMinutes * 60;
	const secondsTotal = durationSeconds - Math.abs(d.diff(now, 'seconds') % durationSeconds);

	if(secondsTotal <= 0) return {
		minutesLeft: null,
		secondsLeft: null,
	}

	return {
		minutesLeft: Math.floor(secondsTotal / 60),
		secondsLeft: secondsTotal % 60,
	}
}

const StyledTime = styled('span')({
	fontFamily: 'monospace',
})

const StyledCheckoutTimer = styled('div')(({ theme  }) => ({
	color: theme.palette.common.white,
	backgroundColor: 'rgba(100, 100, 100, 0.4)',
	display: 'inline-block',
	padding: '2px 8px',
	border: `1px solid ${orange[500]}`,
	borderRadius: '2px',
	marginTop: '10px',
	marginBottom: '-20px',
}));

class CheckoutTimer extends Component {
	state = {
		minutesLeft: null,
		secondsLeft: null,
	}

	constructor(props){
		super(props);

		this.state = {
			...this.state,
			...timeLeftObject(props.startDateTime, props.durationMinutes)
		};
	}

	componentDidMount(){
		this.interval = setInterval(this.handleInterval, 1000);
	}

	componentWillUnmount(){
		if(this.interval){
			clearInterval(this.interval);
		}
	}

	render(){
		const { minutesLeft, secondsLeft } = this.state;

		if(!minutesLeft && minutesLeft !== 0) return null;

		return (
			<StyledCheckoutTimer>
				<Trans
					i18nKey="checkout.reservation_expires_in"
					values={{
						time: `${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`,
					}}
				>
					Je reservering vervalt over <StyledTime>...</StyledTime>
				</Trans>
			</StyledCheckoutTimer>
		)
	}

	handleInterval = () => {
		const { startDateTime, durationMinutes } = this.props;

		this.setState(timeLeftObject(startDateTime, durationMinutes));
	}
}

CheckoutTimer.propTypes = {
	startDateTime: PropTypes.string,
	durationMinutes: PropTypes.number.isRequired,
}

export default CheckoutTimer
