import { createSelector } from 'reselect'

export const userMeIdSelector = state => state.init.me;
const usersSelector = state => state.entities.users;

export const userMeSelector = createSelector(
	userMeIdSelector,
	usersSelector,
	(id, users) => {
		if(!id) return null;
		return users[id];
	}
);

export const authSelector = createSelector(
	userMeIdSelector,
	usersSelector,
	(id, users) => ({
		loggedIn: id ? Boolean(users[id]) : false,
		user: id ? users[id] : null,
	})
);
