import { Person, ShoppingCart } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import { path, routes } from '../../routes';
import { LinkComponent } from '../general/LinkComponent';
import { authSelector } from '../../selectors/user';
import { basketCountSelector } from '../../selectors/basket';

const PREFIX = 'Header';

const classes = {
    rightMenu: `${PREFIX}-rightMenu`,
    iconMargin: `${PREFIX}-iconMargin`,
    basketItems: `${PREFIX}-basketItems`,
    basketItemsBubble: `${PREFIX}-basketItemsBubble`,
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.rightMenu}`]: {
        display: 'flex',
        '& > button, & > a': {
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: '1em',
            color: 'white',
            marginLeft: theme.spacing(0.5),
            padding: theme.spacing(1),
            background: 'none',
            border: 0,
            whiteSpace: 'nowrap',
            '&:hover, &:focus': {
                textDecoration: 'none',
                textShadow: '0 0 1px white',
            },
            '& svg': {
                fontSize: '1.6em',
            },
        },
    },

    [`& .${classes.iconMargin}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.basketItems}`]: {
        position: 'relative',
    },

    [`& .${classes.basketItemsBubble}`]: {
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        right: '5px',
        top: '3px',
        background: 'orange',
        width: '12px',
        height: '12px',
    },
}));

function UserProfileElement() {
    const { t, i18n } = useTranslation();
    const auth = useSelector(authSelector);

    const basketCount = useSelector(basketCountSelector);
    const basketItems = basketCount || 0;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledDiv>
            {isMobile ? (
                <>
                    {
                        auth.loggedIn
                            ? (
                                <LinkComponent to={path(i18n, routes.ACCOUNT)} title={t('header.my.athenastudies')}>
                                    <Person fontSize="inherit" />
                                </LinkComponent>
                            )
                            : (
                                <LinkComponent to={path(i18n, routes.LOGIN)} title={t('header.my.login_register')}>
                                    <Person fontSize="inherit" />
                                </LinkComponent>
                            )
                    }
                    <LinkComponent to={path(i18n, routes.CHECKOUT)} style={{ position: 'relative' }}>
                        <ShoppingCart fontSize="inherit" />
                        {basketItems > 0 && (
                            <span className={classes.basketItemsBubble} />
                        )}
                    </LinkComponent>
                </>
            ) : (
                <div className={classes.rightMenu}>
                    <LinkComponent to={path(i18n, auth.loggedIn ? routes.ACCOUNT : routes.LOGIN)}>
                        <Person fontSize="inherit" className={classes.iconMargin} />
                        {' '}
                        {t('header.my.athenastudies')}
                    </LinkComponent>
                    <LinkComponent to={path(i18n, routes.CHECKOUT)} className={classes.basketItems}>
                        <ShoppingCart />
                        {basketItems > 0 && <span className={classes.basketItemsBubble} />}
                    </LinkComponent>
                </div>
            )}
        </StyledDiv>
    );
}

export default UserProfileElement;
