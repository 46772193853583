import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getApiTypeFailure } from '../../helpers/types'

// Temporary hook to support some states on the dispatch, especially when dispatching API actions
// Should eventually be replaced with react-query and other ways of loading data and state

export const useLoadingDispatch = () => {
	const reduxDispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const mountedRef = useRef(true);

	useEffect(() => () => {
		mountedRef.current = false;
	}, []);

	const dispatch = useCallback((action) => {
		setLoading(true);
		setError(false);

		return reduxDispatch(action).then(res => {
			if(mountedRef.current){
				if(res?.originalType && getApiTypeFailure(res.originalType) === res.type){
					setError(res);
				}

				setLoading(false);
			}

			return res;
		});
	}, [reduxDispatch]);

	return [dispatch, loading, error];
};
