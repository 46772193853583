import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose, uniq } from 'ramda'
import withRouter from "../helpers/withRouter";
import { routes, path } from '../routes'
import { percentageComposer } from '../helpers/composers'
import Loader from './../components/loader'
import Content from './../components/content/Content'
import ScopeList from './../components/scope/ScopeList'
import ScopeInfo from './../components/scope/ScopeInfo'

function getUniversityId(scope){
	if(scope.class === 'Institute') return scope.id;
	if(!scope.parent) return null;

	return getUniversityId(scope.parent);
}

function getUniversityText(scope, depth = 0){
	if(scope.class === 'Institute'){
		if(depth === 0) return scope.abbreviation;

		return scope.name;
	}

	return scope.parent
		? getUniversityText(scope.parent, depth + 1) || scope.abbreviation
		: null;
}

class PartnerPage extends Component {
	render(){
		const { partner } = this.props;

		if(!partner) return <Loader/>;

		// Decide if universities should be shown
		const moreAsOneUni = uniq(partner.scopes.map(scope => getUniversityId(scope))).length > 1;
		const first = partner.scopes && partner.scopes.length > 0
			? getUniversityText(partner.scopes[0]) : null;

		return (
			<Content>
				<Content.Header>
					<Content.Partner partnerGlobal={true} />
					<h1>Samen met {partner.name}</h1>
				</Content.Header>
				<ScopeInfo>
					<p>
						AthenaStudies biedt in samenwerking met {partner.name} cursussen aan voor de volgende universiteiten of studies.
						{partner.discountPercentage && ` Hier geldt een korting van ${percentageComposer(partner.discountPercentage)} mits ingelogd via de site van jouw vereniging of in sommige gevallen met een kortingscode.`}
					</p>
					{
						partner.visuals && partner.visuals.information &&
						<p><em>Extra informatie:</em> {partner.visuals.information}</p>
					}
					{!moreAsOneUni && (
						<p><strong>{first}</strong></p>
					)}
				</ScopeInfo>
				<ScopeList
					scopes={partner.scopes}
					pathCreator={this.pathCreator}
					subTextCreator={moreAsOneUni ? getUniversityText : undefined}
				/>
			</Content>
		);
	}

	componentDidMount(){
		const { partner, navigate, i18n } = this.props;

		if(!partner || !partner.scopes || partner.scopes.length === 0){
			navigate(path(i18n, routes.COURSES), { replace: true });
		}
	}

	pathCreator = (scope) => {
		const { i18n } = this.props;
		const abbr = scope.abbreviation.toLowerCase();

		if(scope.class === 'Course'){
			return path(i18n, routes.STUDY, scope.parent.parent.abbreviation.toLowerCase(), abbr);
		}else if(scope.class === 'Faculty'){
			return path(i18n, routes.INSTITUTE, scope.parent.abbreviation.toLowerCase());
		}else{
			return path(i18n, routes.INSTITUTE, abbr);
		}
	};
}

PartnerPage.propTypes = {
	partner: PropTypes.object
};

export default compose(
	withTranslation(),
	withRouter,
	connect(state => ({ partner: state.init.partner })),
)(PartnerPage)
