import { Alert } from "@mui/material";
import { compose } from "ramda";
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { routes, path } from './../routes'
import { payOrder, getOrder, getOrderReturn } from './../actions/auth'
import { userMeSelector } from './../selectors/user'
import Content from './../components/content/Content'
import BackgroundSheet from './../components/general/BackgroundSheet'
import Checkout from './../components/checkout/Checkout'
import PayOrderInfo from './../components/pay/PayOrderInfo'
import PaySuccessOrder from '../components/pay/PaySuccessOrder'
import Loader from './../components/loader'
import withRouter from '../helpers/withRouter';

const returnUri = (i18n, rootPath, orderId) =>
	typeof window === 'undefined'
		? ''
		: window.location.protocol + '//' + window.location.host + (!rootPath || rootPath === '/' ? '' : rootPath) + path(i18n, routes.RETURN, orderId);

const getTitle = (order, isReturnPage, t) => {
	if(isReturnPage){
		return t('pay.title.welcome_back');
	}

	if(order){
		if(order.status === 'FULL_DISCOUNT' || order.status === 'FREE'){
			return t('pay.title.thanks');
		}

		return t('pay.title.pay_of_order_number') + ' #' + order.id;
	}

	return t('pay.title.pay');
};

class PayPage extends Component {
	state = {
		paying: false,
	};

	render(){
		const { order, returnOrderId, t } = this.props;

		return (
			<Content>
				<Content.Header>
					<h1>{getTitle(order, !!returnOrderId, t)}</h1>
				</Content.Header>
				{this.renderContent()}
			</Content>
		);
	}

	renderContent(){
		const { me, order, orderReturn, activeOrderId, returnOrderId, t } = this.props;

		if(!me) return (
			<Alert severity="warning" icon={false}>{t('pay.error.log_in')}</Alert>
		);

		if(!order && !orderReturn && !returnOrderId && !activeOrderId) return (
			<Alert severity="info" icon={false}>{t('pay.error.no_order')}</Alert>
		);
		if(!order && !orderReturn) return <Loader/>;

		if(order && (order.status === 'FULL_DISCOUNT' || order.status === 'FREE')){
			return (<PaySuccessOrder />);
		}

		if(orderReturn){
			if(orderReturn.positive) return (<PaySuccessOrder />);

			return (
				<div>
					{this.state.paying && (
						<Loader sheet>{t('pay.redirecting')}</Loader>
					)}
					<Alert severity="warning" icon={false}>{t('pay.error.try_again')}</Alert>
					<PayOrderInfo order={orderReturn} />
					<BackgroundSheet>
						<Checkout onPayRequest={this.handlePayRequest} />
					</BackgroundSheet>
				</div>
			);
		}

		return (
			<div>
				{this.state.paying && (
					<Loader sheet>{t('pay.redirecting')}</Loader>
				)}
				<PayOrderInfo order={order} />
				{order.paid ? (
					<Alert severity="warning" icon={false}>{t('pay.error.already_paid')}</Alert>
				):(
					<BackgroundSheet>
						<Checkout onPayRequest={this.handlePayRequest} />
					</BackgroundSheet>
				)}
			</div>
		);
	}

	handlePayRequest = (methodId, methodSubId) => {
        if (!methodSubId) {
            this.props.navigate(path(this.props.i18n, routes.PAY_METHOD_BASE, methodId), { replace: true });
        } else {
            this.props.navigate(path(this.props.i18n, routes.PAY_METHOD_SUB, methodId, methodSubId), { replace: true });
        }
	};

	componentDidMount(){
		const { navigate, methodId, methodSubId, order, activeOrderId, payOrder, i18n, rootPath } = this.props;

		// Pay the order, remove from url to prevent loopholes on history.back
		if(methodId && order){
			payOrder(order.id, methodId, methodSubId, returnUri(i18n, rootPath, order.id));
			navigate(path(i18n, routes.PAY), { replace: true });
			this.setState({
				paying: true,
			});
		}

		// Load order if one still active
		if(!order && activeOrderId){
			this.props.getOrder(activeOrderId);
		}

		// Load return order if one given in url
		if(!this.props.orderReturn && this.props.returnOrderId){
			this.props.getOrderReturn(this.props.returnOrderId);
		}
	}

	componentDidUpdate(prevProps){
		const payDirectOrderLoaded = !prevProps.order && (this.props.order || this.props.orderReturn) && this.props.methodId;
		const payMethodPicked = this.props.order && !prevProps.methodId && this.props.methodId;

		// Pay the order, remove from url to prevent loopholes on history.back
		if(payDirectOrderLoaded || payMethodPicked){
			const order = this.props.orderReturn ? this.props.orderReturn : this.props.order;

			this.props.payOrder(order.id, this.props.methodId, this.props.methodSubId, returnUri(this.props.i18n, this.props.rootPath, order.id));
			this.props.navigate(path(this.props.i18n, routes.PAY), { replace: true });
			this.setState({
				paying: true,
			});
		}

		// Paylink received, redirect the users to the PSP
		if(this.props.payLink){
			window.location.href = this.props.payLink;
		}
	}
}

export default compose(
	withRouter,
	withTranslation(),
	connect((state, { params }) => ({
		methodId: params.methodId && parseInt(params.methodId),
		methodSubId: params.methodSubId && parseInt(params.methodSubId),
		returnOrderId: params.orderId,
		order: state.pay.order,
		activeOrderId: state.pay.activeOrderId,
		payLink: state.pay.payLink,
		orderReturn: state.pay.orderReturn,
		me: userMeSelector(state), // Nasty trick to force re-render after login
		rootPath: state.init.rootPath,
	}), {
		payOrder,
		getOrder,
		getOrderReturn
	}),
)(PayPage);
