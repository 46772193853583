import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// Do change
const startAtTop = true;
const amountFactor = 0.6;
const imageBaseSize = 6;

const debug = false;

// Probably don't change
const maxWeight = 5;
const maxSpeed = 2;

let canvas = null,
	ctx = null,
	windowW = 0,
	windowH = 0,
	numFlakes = 200 * amountFactor,
	flakes = [],
	imageObj = null,
	imagePath = null,
	imageSettings = null,
	needsToStop = false;

function Flake(x, y, aStep, whirl = false) {
	this.x = x;
	this.y = y;
	this.r = randomBetween(0, 1, 3);
	this.a = randomBetween(0, Math.PI, 3);
	this.aStep = aStep;
	this.visible = !startAtTop;

	this.weight = randomBetween(2, maxWeight, 3);
	this.alpha = round(this.weight / maxWeight, 3);
	this.speed = round((this.weight / maxWeight) * maxSpeed, 3);

	this.angle = 0;
	this.angleMax = randomBetween(0, Math.PI / 8, 3);
	this.angleDirection = 1;

	this.update = function() {
		if(!this.visible){
			this.visible = randomBetween(0, 1) < (1 / numFlakes)
			return;
		}

		this.x = round(this.x + (Math.cos(this.a) * this.r), 2);
		this.a = round(this.a + this.aStep, 3);
		this.y = round(this.y + this.speed, 2);

		if(this.x > windowW + 50 || this.x < -50){
			this.x = x;
		}

		if(whirl){
			this.angle = round(this.angle + (0.02 * this.angleDirection), 3);
			if(Math.abs(this.angle) > this.angleMax){
				this.angleDirection = -this.angleDirection;
			}
		}
	}
}

function aStepFromWidth(width){
	// Need value between 0.01 and 0.003
	let step = width / 2000;

	if(step < 0.3) step = 0.3;
	if(step > 1) step = 0.01;

	step = round(0.01 / step, 3);

	if(debug) console.log(`[SNOW] Flake step: ${step}`);

	return step;
}

function amountFactorFromWidth(width){
	// Screens are generally roughly from 350 - 2000
	// 2000 will be factor 1, which is the max

	let factor = width / 2000;

	if(debug) console.log(`[SNOW] Flake factor: ${factor}`);

	if(factor < 0.3) return 0.3;
	if(factor > 1) return 1;

	return factor;
}

function init(image = {}, amountOverwrite = null, whirl = false) {
	if(typeof window === 'undefined') return;

	needsToStop = false;

	windowW = window.innerWidth;
	windowH = window.innerHeight;

	canvas = document.querySelector('.snow');
	ctx = canvas.getContext('2d');

	let i = parseInt(amountFactorFromWidth(windowW) * (amountOverwrite || numFlakes)), flake, x, y;
	const aStep = aStepFromWidth(windowW);

	while (i--) {
		x = randomBetween(0, windowW, true);
		y = startAtTop ? -30 : randomBetween(0, windowH, true);

		flake = new Flake(x, y, aStep, whirl);
		flakes.push(flake);
	}

	scaleCanvas();

	imageSettings = image;

	// Load the image and start the loop
	if(image.url){
		imageObj = new Image();
		imageObj.addEventListener('load', function(){
			if(debug) console.log('[SNOW] Image loaded');

			loop();
		}, false);
		imageObj.src = image.url;
	} else {
		imagePath = new Path2D(image.path);
		loop();
	}
}

function scaleCanvas() {
	canvas.width = windowW;
	canvas.height = windowH;
}

let times = [];
function log(){
	if(times.length % 60 === 0){
		const total = times.reduce((acc, c) => acc + c, 0);
		const avgSeconds = total / times.length / 1000;

		console.log(`[SNOW] Average loop time: ${avgSeconds.toFixed(4)}s`);
		console.log(flakes);
	}
}

function loop() {
	if(needsToStop){
		ctx.clearRect(0, 0, windowW, windowH);
		return;
	}

	let i = flakes.length, flake, start;

	if(debug){
		start = performance.now();
	}

	// Clear canvas first
	ctx.save();
	ctx.setTransform(1, 0, 0, 1, 0, 0);
	ctx.clearRect(0, 0, windowW, windowH);
	ctx.restore();

	if(imagePath){
		ctx.fillStyle = imageSettings.color;
		ctx.lineWidth = 0;
	}

	// Update and render again
	while (i--) {
		flake = flakes[i];
		flake.update();

		const size = imageBaseSize * flake.weight;

		ctx.translate(flake.x, flake.y);
		ctx.rotate(flake.angle);

		if(imagePath){
			ctx.scale(size / 40, size / 40);
			// ctx.stroke(imagePath);
			ctx.fill(imagePath);
		} else {
			ctx.drawImage(imageObj, -size/2, -size/2, size, size);
		}

		ctx.setTransform(1, 0, 0, 1, 0, 0);
		// ctx.rotate(-flake.angle);
		// ctx.translate(-flake.x, -flake.y);

		if (flake.y >= windowH + 20) {
			flake.y = -flake.weight - 20;
		}
	}

	if(debug){
		times.push(performance.now() - start);
		log();
	}

	requestAnimationFrame(loop);
}

function round(num, decimals = 3){
	const f = Math.pow(10, decimals);

	return Math.floor(num * f) / f;
}

function randomBetween(min, max, doRound) {
	const num = Math.random() * (max - min + 1) + min;

	if (doRound) {
		if (doRound === true){
			return Math.floor(num);
		} else {
			return round(num, doRound);
		}
	} else {
		return num;
	}
}


export const createImageHeart = () => {
	return {
		path: 'M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2 c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z',
		color: '#fc2e5a',
	};

	// const svg = `
	// 	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 29.6">
  	// 		<path fill="#fc2e5a" d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2 c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z" />
	// 	</svg>
	// `;
	// const blob = new Blob([svg], {type: 'image/svg+xml'});
	// return URL.createObjectURL(blob);
}

const styles = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
};

class ImageRain extends PureComponent {
	render(){
		return (
			<canvas className="snow" style={styles} />
		);
	}

	componentDidMount(){
		const { image, amountOverwrite, whirl } = this.props;

		init(image, amountOverwrite, whirl);
	}

	componentWillUnmount(){
		needsToStop = true;

		if(ctx){
			ctx.clearRect(0, 0, windowW, windowH);
		}
	}
}

ImageRain.propTypes = {
	image: PropTypes.object.isRequired,
	amountOverwrite: PropTypes.number,
	whirl: PropTypes.bool,
};

export default ImageRain
