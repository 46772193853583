import { Button, InputLabel, TextField, Grid, styled, Box, Typography } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { routes, path } from './../../routes'
import SimpleState from './../../hoc/SimpleState'
import FormData, { errorRenderer } from './../../hoc/FormData'
import { doLogin } from './../../actions/auth'
import Loader from './../../components/loader'

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

const linkStyle = theme => ({
	'& a': {
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
	}
});

const StyledGrid = styled(Grid)(({ theme }) => ({
	textAlign: 'center',
	fontSize: theme.typography.fontSize,
	...linkStyle(theme),
}))

const LoginForm = ({ fields: {username, password}, fields, onChangeField, saving, error, handleSubmit, hideRegister, t, i18n }) => (
	<Box component="form" sx={{ my: 3 }} onSubmit={handleSubmit(() => ({
		...fields,
		username: fields.username.trim(),
	}), doLogin)}>
		{ saving && <Loader>{t('login.form.loading')}</Loader>}
		{ error && errorRenderer(error) }
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<StyledInputLabel htmlFor="login-username">
					{t('login.form.email')}
				</StyledInputLabel>
				<TextField
					id="login-username"
					name="username"
					fullWidth
					value={username}
					onChange={onChangeField}
				/>
			</Grid>
			<Grid item xs={12}>
				<StyledInputLabel htmlFor="login-password">
					{t('login.form.password')}
				</StyledInputLabel>
				<TextField
					id="login-password"
					name="password"
					type="password"
					fullWidth
					value={password}
					onChange={onChangeField}
				/>
				<Typography paragraph sx={(theme) => ({
					textAlign: 'right', fontSize: 13, my: 1,
					...linkStyle(theme)
				})}>
					<Link to={path(i18n, routes.PASSWORD_FORGOT)}>{t('login.form.forgot')}</Link>
				</Typography>
				<Button
					type="submit"
					color="cta"
					variant="contained"
					fullWidth
				>
					{t('login.form.button_login')}
				</Button>
			</Grid>
			{!hideRegister && (
				<StyledGrid item xs={12}>
					{t('login.form.no_account')}
					{' '}
					<Link to={path(i18n, routes.REGISTER)}>{t('login.form.register')}</Link>
				</StyledGrid>
			)}
		</Grid>
	</Box>
);

LoginForm.propTypes = {
	hideRegister: PropTypes.bool
};

export default withTranslation()(FormData(SimpleState(LoginForm, {
	fields: ['username', 'password']
}), 'loginForm', {
	successRedirect: (props) => path(props.i18n, routes.ACCOUNT)
}))
