import { styled } from "@mui/material";
import React from 'react'
import { contentClasses } from "./Content";

const ContentLine = styled('div')(({ theme }) => ({
	marginLeft: '-20px',
	marginRight: '-20px',
	height: '1px',
	borderTop: `1px solid ${theme.palette.grey[200]}`,
	[`.${contentClasses.padding} &`]: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: '-30px',
			marginRight: '-30px',
		},

		[theme.breakpoints.up('md')]: {
			marginLeft: '-50px',
			marginRight: '-50px',
		},
	},
}));

export default ContentLine
