import moment from "moment";
function relativeTimeTranslate(time, key){
	if(time > 1) key += key;
	return moment.localeData().relativeTime(time, false, key, false);
}

export function readableDuration(hours){
	if(hours > 48){
		return relativeTimeTranslate(Math.floor(hours / 24), 'd');
	}

	return relativeTimeTranslate(hours, 'h');
}
