import { Alert, Button, Grid, InputLabel, styled, TextField } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import { routes, path } from './../../routes'
import FormData from './../../hoc/FormData'
import SimpleState from './../../hoc/SimpleState'
import { doResetPassword } from './../../actions/auth'
import Loader from './../loader'

const doResetPasswordEnhanced = (token) => (data) => doResetPassword(token, data.password);

const extraValidation = (t) => (data) => {
	if(data.password !== data.password_repeat) return {
		errors: {password_repeat: t('password.reset.form.password_not_the_same')}
	};

	return false;
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

const PasswordResetForm = ({ token, fields, onChangeField, saving, error, success, handleSubmit, t, i18n }) => {
	if(!token) return (
		<Alert severity="error" icon={false}>
			{t('password.reset.form.no_code_found_text')}
		</Alert>
	);

	if(success) return (
		<Alert severity="success" icon={false}>
			<Trans i18nKey="password.reset.form.success_text">
				Je wachtwoord is opnieuw ingesteld. <Link to={path(i18n, routes.LOGIN)}>Log direct in</Link> om je account te gebruiken.
			</Trans>
		</Alert>
	);

    return (
        <form onSubmit={handleSubmit(() => fields, doResetPasswordEnhanced(token), extraValidation(t))}>
			{error && error.error && (
				<Alert severity="warning" icon={false}>
					{error.error}
				</Alert>
			)}
			{saving && (
				<Loader>
					{t('password.reset.form.loading')}
				</Loader>
			)}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<StyledInputLabel htmlFor="reset-password">
						{t('password.reset.form.password')}
					</StyledInputLabel>
					<TextField
						id="reset-password"
						name="password"
						fullWidth
						value={fields.password}
						onChange={onChangeField}
						placeholder={t('password.reset.form.password')}
						error={!!error?.errors?.['password']}
						helperText={error?.errors?.['password']}
					/>
				</Grid>
				<Grid item xs={12}>
					<StyledInputLabel htmlFor="reset-password-repeat">
						{t('password.reset.form.password_repeat')}
					</StyledInputLabel>
					<TextField
						id="reset-password-repeat"
						name="password_repeat"
						fullWidth
						value={fields.password_repeat}
						onChange={onChangeField}
						placeholder={t('password.reset.form.password_repeat')}
						error={!!error?.errors?.['password_repeat']}
						helperText={error?.errors?.['password_repeat']}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						type="submit"
						color="info"
						variant="contained"
						disabled={saving}
						fullWidth
					>
						{t('password.reset.form.button_set')}
					</Button>
				</Grid>
			</Grid>
		</form>
    );
};

PasswordResetForm.propTypes = {
	token: PropTypes.string
};

export default withTranslation()(FormData(SimpleState(PasswordResetForm, {
	fields: ['password', 'password_repeat']
}), 'passwordResetForm', {

}));
