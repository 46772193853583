import { mapObjIndexed, values, flatten } from 'ramda'
import { getApiTypeSuccess } from './../helpers/types'
import { PAYMENT_METHODS } from './../actions/auth'

export default function paymentMethods(state = null, action){
	if(action.type === getApiTypeSuccess(PAYMENT_METHODS) && action.response) {
		state = flatten(values(
			mapObjIndexed(
				(list, key) => list.map(method => ({
					...method,
					country: key.toLowerCase(),
				})),
				action.response.result
			)
		));
	}

	return state;
}
