import { Grid } from "@mui/material";
import React from 'react'
import { Trans } from 'react-i18next'
import Content from '../../components/content/Content'
import shrug from './shrug.jpg'

// The catch-all route "*" will result in status code 200 in react-router's
// context. This loader sets a 404 status, used as routerContext.statusCode in server.mjs
export function notFoundLoader() {
    return new Response('Not Found', { status: 404 });
}

const NotFoundPage = () => (
	<Content>
		<Content.Header>
			<h1>
				<Trans i18nKey="not_found_page.header"
					   defaults="Pagina niet gevonden" />
			</h1>
		</Content.Header>
		<Grid container justifyContent="center">
			<Grid item xs={12} md={8}>
				<h3>
					<Trans i18nKey="not_found_page.header_sub"
						   defaults="Onze excuses, de pagina die je zocht is niet gevonden" />
				</h3>
				<p>
					<Trans i18nKey="not_found_page.text"
						   defaults="Dit kan een fout zijn, probeer het nog eens opnieuw. Neem bij aanhoudende problemen contact met ons op via een van de onderstaande manieren." />
				</p>
				<ul className="purple-bullets">
					<li>
						<Trans i18nKey="not_found_page.general"
							   defaults="Algemeen" />
						{': '}
						<a href="mailto:info@athenastudies.nl">info@athenastudies.nl</a>
					</li>
					<li>
						{'Facebook: '}
						<a href="https://www.facebook.com/AthenaStudiesStudiehulp/" target="_blank">@AthenaStudiesStudiehulp</a>
					</li>
				</ul>
			</Grid>
			<Grid item xs={12} md={4}>
				<img src={shrug} style={{width: '100%'}} alt="shrug" />
			</Grid>
		</Grid>
	</Content>
);

export default NotFoundPage
