import { schema } from 'normalizr'

// Define all entities and basic relations
const user = new schema.Entity('users');
const scope = new schema.Entity('scopes');

// Define scope circular relation
scope.define({
	subScopes: [scope],
	parent: scope
});

// Export used entities
export const Schemas = {
	USER: user,
	SCOPE: scope,
	SCOPE_LIST: [scope],
	INIT: {
		me: user,
		rootScopes: [scope],
		//shoppingBasket: null // Placeholder to clarify that this will be in the return
	}
};

// Export initial schema, used by the store
export const initialSchemasState = {
	entities: {
		users: {},
		scopes: {}
	}
};

// Utility to get the state key from a schema
export const getKey = (schema) => {
	if(Array.isArray(schema)) return schema[0].key;
	return schema.key;
};

// Re-export api symbol for easier action creation
export { CALL_API } from './setup/api'
