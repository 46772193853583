import {clone} from 'ramda';
import {DISMISS_ERROR} from '../actions/error';

const defaultState = {
	error: null,
	show: false
};

export default function error(state = defaultState, action){
	// Listen to dismiss action
	if(action && action.type === DISMISS_ERROR){
		return clone(defaultState);
	}

    // Reset INIT error if it fixes itself
    if (action.type === 'INIT_SUCCESS' && state.error?.type === 'INIT_FAILURE') {
        return clone(defaultState);
    }

	// If api call failed and no errorCode was returned from the server, we can assume the client is offline
	if (action.type?.includes('_FAILURE') && !action.errorCode) return {
		error: {
			offline: true,
		},
		show: true,
	}

	// If no dismiss, is it an error?
	if(!action || !action.errorCode) return state;

	// Ignore some codes
	if([400, 401, 410].indexOf(action.errorCode) > -1) return state;

	// Ignore basket 404 (aka discount code not found)
	if(action.errorCode === 404 && action.type === 'BASKET_FAILURE') return state;

	// Ignore scope 404 (aka scope code not found)
	if(action.errorCode === 404 && action.type === 'SUB_SUB_SCOPES_FAILURE') return state;

	return {
		error: action,
		show: true
	};
}
