import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo, createElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import { visit } from 'unist-util-visit';
import remarkDirective from 'remark-directive';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from '@mui/icons-material';
import { getPagePath, getRouterPath } from '../../helpers/path';
import staticPages from '../../pages/static/staticPages';
import MarkdownButton from './MarkdownButton';
import MarkdownCard from './MarkdownCard';
import MarkdownIframe from './MarkdownIframe';
import MarkdownListTable from './MarkdownListTable';
import MarkdownQuote from './MarkdownQuote';
import TikTokIcon from '../general/TikTokIcon';
import HomePageWidgetContactForm, { ContactFormComponent } from '../home/widgets/HomePageWidgetContactForm';

export const getTransformedUri = (to, i18n) => {
    const pages = useMemo(() => staticPages(i18n), [i18n]);
    if (to.indexOf('route:') === 0) {
        return getRouterPath(i18n, to.substr(6), i18n.language, true);
    }
    if (to.indexOf('key:') === 0) {
        return getPagePath(pages, to.substr(4));
    }
    return to;
};

const socialMediaSX = { color: 'common.black', transform: 'translateY(4px)', mr: 1 };
const socialMediaIcons = {
    instagram: <Instagram fontSize="small" sx={socialMediaSX} />,
    tiktok: <TikTokIcon fontSize="small" sx={socialMediaSX} />,
    facebook: <Facebook fontSize="small" sx={{ ...socialMediaSX, color: '#3b5998' }} />,
};

const customUriTransformer = (uri) => {
    // Internal key and route linking, will be picked up by custom renderer
    if (uri.indexOf('key:') === 0 || uri.indexOf('route:') === 0) {
        return uri;
    }

    return uriTransformer(uri);
};

function customPlugin() {
    return (tree) => {
        visit(
            tree,
            ['textDirective', 'leafDirective', 'containerDirective'],
            (node) => {
                node.data = {
                    hName: node.name,
                    hProperties: node.attributes,
                    ...node.data,
                };
            },
        );
    };
}

const customComponents = (i18n, pages) => ({
    quote: ({ node, children, ...props }) => (
        <MarkdownQuote {...props}>
            {children}
        </MarkdownQuote>
    ),
    socialMediaIcon: ({ node, children, ...props }) => (
        <Typography paragraph sx={{ mb: 1 }}>
            <a href={props.link} target="_blank" rel="noreferrer">
                {socialMediaIcons[props.type]}
                <span>
                    @
                    {props.user}
                </span>
            </a>
        </Typography>
    ),
    container: ({ node, children, ...props }) => (
        <Grid container columnSpacing={2} {...props}>
            {children}
        </Grid>
    ),
    grid: ({ node, children, ...props }) => (
        <Grid item xs={12} {...props}>
            {children}
        </Grid>
    ),
    iframe: ({ node, children, ...props }) => (
        <MarkdownIframe src={children[0]} {...props} />
    ),
    listTable: ({ node, children, ...props }) => (
        <MarkdownListTable items={children[0]} {...props} />
    ),
    button: ({ node, children, ...props }) => (
        <MarkdownButton to={children[0]} {...props} />
    ),
    listIcon: ({ children, ...props }) => (
        <div className={`listIcon-${children[0]} ${props.color ? `listIconColor-${props.color}` : ''}`} />
    ),
    card: ({ node, children, ...props }) => (
        <MarkdownCard items={children[0]} {...props} />
    ),
    cardWrapper: ({ children }) => (
        <Grid container spacing={2} sx={{ my: 2 }}>
            {React.Children.map(children, (child, i) => (
                <Grid key={i} item md={6} xs={12}>
                    {child}
                </Grid>
            ))}
        </Grid>
    ),
    spacer: ({ spacing }) => (
        <Box my={parseInt(spacing, 10) || 2} />
    ),
    anchor: ({ children }) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
        children[0] ? <a id={children[0]} /> : null
    ),
    a: ({ node, ...props }) => {
        if (props.href.indexOf('key:') === 0) return (
            <Link to={getPagePath(pages, props.href.substr(4))}>{props.children}</Link>
        );

        if (props.href.indexOf('route:') === 0) return (
            <Link to={getRouterPath(i18n, props.href.substr(6), i18n.language, true)}>{props.children}</Link>
        );

        return createElement('a', props);
    },
    contactForm: ({ node, children, ...props }) => (
        <ContactFormComponent
            {...props}
        />
    ),

});

function Markdown({ children }) {
    const { i18n } = useTranslation();
    const pages = useMemo(() => staticPages(i18n), [i18n]);

    const components = useMemo(() => ({
        ...customComponents(i18n, pages),
    }), [i18n, pages]);

    const plugins = useMemo(() => [
        remarkDirective,
        customPlugin,
    ], []);

    return (
        <ReactMarkdown
            className="markdown markdown-new"
            components={components}
            transformLinkUri={customUriTransformer}
            remarkPlugins={plugins}
        >
            {children}
        </ReactMarkdown>
    );
}

Markdown.propTypes = {
    children: PropTypes.node,
};

export default Markdown;
