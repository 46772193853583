import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AuthCookieName } from '../setup/api'
import { getMe } from '../actions/auth'
import { userMeIdSelector, userMeSelector } from '../selectors/user'

export default (ComposedComponent) => {
	return connect((state) => ({
		userId: userMeIdSelector(state),
		user: userMeSelector(state),
		hasAuthToken: Boolean(state.init.initialCookies[AuthCookieName]), // Note: This auth token is only available if the user was already logged in on page load
	}), {
		getMe,
	})(class extends Component {
		displayName = 'AuthProvider(' + (ComposedComponent.displayName || 'Unknown') + ')';

		static propTypes = {
			user: PropTypes.object,
			userid: PropTypes.number,
			getMe: PropTypes.func
		};

		render() {
			// TODO: if me loading, prevent composed from fully rendering?
			return <ComposedComponent {...this.props} />;
		}

		componentDidMount() {
			const { user, getMe, hasAuthToken } = this.props;

			if(hasAuthToken && !user){
				getMe();
			}
		}
	})
};
