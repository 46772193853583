import { Alert, Box, Grid } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Trans } from 'react-i18next'
import Content from '../../components/content/Content'
import shrug from './shrug.jpg'

const RootErrorPage = ({ error }) => (
	<>
		<Helmet defaultTitle={`AthenaStudies - site error`} titleTemplate="AthenaStudies - %s" />
		<Box sx={{
			display: 'flex',
			minHeight: '100vh',
			flexDirection: 'column',
			backgroundColor: 'common.white',
		}}>
			<Box sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}>
				<Content>
					<Content.Header>
						<h1>
							<Trans
								i18nKey="root_error_page.header"
								defaults="Deze website is fout ingesteld"
							/>
						</h1>
					</Content.Header>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={8}>
							<h3>
								<Trans
									i18nKey="root_error_page.header_sub"
									defaults="Onze excuses, er is iets mis met de instellingen van deze site"
								/>
							</h3>
							<p>
								<Trans
									i18nKey="root_error_page.text"
									defaults="Dit is uiteraard een probleem die wij graag willen oplossen. Waarschijnlijk zijn wij er al mee bezig, maar neem alsnog even contact met ons op."
								/>
							</p>
							{error && (
								<Alert severity="error" icon={false}>{error}</Alert>
							)}
							<ul className="purple-bullets">
								<li>
									<Trans
										i18nKey="root_error_page.general"
										defaults="Algemeen"
									/>
									{': '}
									<a href="mailto:info@athenastudies.nl">info@athenastudies.nl</a>
								</li>
								<li>
									<Trans
										i18nKey="root_error_page.ict"
										defaults="ICT"
									/>
									{': '}
									<a href="mailto:ict@athenastudies.nl">ict@athenastudies.nl</a>
								</li>
							</ul>
						</Grid>
						<Grid item xs={12} md={4}>
							<img src={shrug} style={{width: '100%'}} alt="shrug" />
						</Grid>
					</Grid>
				</Content>
			</Box>
		</Box>
	</>
);

RootErrorPage.propTypes = {
	error: PropTypes.string,
};

export default RootErrorPage
