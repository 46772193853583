import { Tab, tabClasses, Tabs, tabsClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCustomLocaleMessage } from "../../helpers/locale";
import { getPagePath, getRouterPath } from "../../helpers/path";
import staticPages from "../../pages/static/staticPages";

const StyledTabs = styled(Tabs)(({ theme }) => ({
	borderBottom: '1px solid #ccc',
	padding: theme.spacing(0, 3),
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(0, 2),
	},
	[`& .${tabsClasses.flexContainer}`]: {
		gap: theme.spacing(4),
	},
}));

const StyledTab = styled(Tab)(({ theme }) => ({
	textTransform: 'none',
	paddingLeft: 0,
	paddingRight: 0,
	minWidth: 'unset',
	fontWeight: theme.typography.fontWeightRegular,
	'&:focus, &:hover': {
		color: 'inherit',
		opacity: 0.85,
		outline: 'none',
		textDecoration: 'none',
	},
	[`&.${tabClasses.selected}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	}
}));

const PickerTabs = ({ scopePickerTabs }) => {
	const { i18n } = useTranslation();
	const pages = useMemo(() => staticPages(i18n), [i18n]);

	const getPath = (tab) => {
		if (!tab.to) {
			return undefined;
		}

		if (tab.to.indexOf('key:') === 0) {
			return getPagePath(pages, tab.to.substr(4));
		}

		if(tab.to.indexOf('route:') === 0) {
			return getRouterPath(i18n, tab.to.substr(6), i18n.language, true);
		}

		return tab.to;
	}

	return (
        <StyledTabs value={0} variant="scrollable" scrollButtons="auto" indicatorColor="primary">
			{scopePickerTabs.map((tab, index) => {
				const properties = tab.href
					? { href: tab.href }
					: { to: tab.to ? getPath(tab) : '/' };

				return (
                    <StyledTab
                        label={getCustomLocaleMessage(tab.title, i18n)}
                        component={tab.href ? 'a' : Link}
                        {...properties}
                        key={`${tab.href || tab.to}_${index}`}
					/>
                );
			})}
		</StyledTabs>
    );
}

PickerTabs.propTypes = {
	scopePickerTabs: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		href: PropTypes.string,
		to: PropTypes.string,
	})).isRequired,
}

export default PickerTabs;
