import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../components/loader'
import RootErrorPage from '../pages/general/RootErrorPage'
import { fetchInit } from '../actions/general'
import { rootScopeAlternateSitesSelector, rootScopeSelector } from '../selectors/scope'
import { parseSearch } from '../helpers/query'
import I18nProvider from '../setup/i18nProvider'
import ThemeProvider from '../style/ThemeProvider'
import {HelmetProvider} from "react-helmet-async";

// For initCode / partner testing, this url can be called (note the ip restriction)
// The JSON used: {"voornaam":"Test","achternaam":"Test","email":"ict@athenastudies.nl","jaar":"2020","volgnummer":1,"extra":"decentrale"}
// The Base64 of the JSON: eyJ2b29ybmFhbSI6IlRlc3QiLCJhY2h0ZXJuYWFtIjoiVGVzdCIsImVtYWlsIjoiaWN0QGF0aGVuYXN0dWRpZXMubmwiLCJqYWFyIjoiMjAyMCIsInZvbGdudW1tZXIiOjEsImV4dHJhIjoiZGVjZW50cmFsZSJ9
// https://hermes-stage.pallasathenagroup.com/partner/dsc?getcode=eyJ2b29ybmFhbSI6IlRlc3QiLCJhY2h0ZXJuYWFtIjoiVGVzdCIsImVtYWlsIjoiaWN0QGF0aGVuYXN0dWRpZXMubmwiLCJqYWFyIjoiMjAyMCIsInZvbGdudW1tZXIiOjEsImV4dHJhIjoiZGVjZW50cmFsZSJ9

const getSubDomain = () => {
	//const hostname = 'lanx.athenastudies.nl'.split('.');
	const hostname = typeof window === 'undefined' ? '' : window.location.hostname.split('.');

	// Only if subdomain, ignore www, ignore full numbers, ignore "webstore-stage"
	if(hostname.length >= 3 && hostname[0] !== 'www' && isNaN(hostname[0]) && hostname[0] !== 'webstore-stage'){
		return hostname[0];
	}else{
		return undefined
	}
};

export default (ComposedComponent) => {
	return connect((state) => ({
		error: state.init.error,
		root: rootScopeSelector(state),
	}), {
		fetchInit,
	})(class extends Component {
		displayName = 'RootLoader(' + (ComposedComponent.displayName || 'Unknown') + ')';

		render() {
			// Show critical error
			if(this.props.error) return (
				<I18nProvider>
					<HelmetProvider>
						<ThemeProvider>
							<RootErrorPage error={this.props.error} />
						</ThemeProvider>
					</HelmetProvider>
				</I18nProvider>
			);

			// We need the root scope for everything, so wait for the root before rendering anything
			if(!this.props.root) return (
				<Loader takeSpace={true} />
			);

			return (
				<ComposedComponent {...this.props} />
			);
		}

		componentDidMount(){
			const { root } = this.props;

			// Load if not loaded yet, if everything work correct in production,
			//  ... the root only needs to be loaded in development without SSR
			if(!root){
				const query = parseSearch(window.location.search);

				this.props.fetchInit(
					getSubDomain(),
					query.initCode
				);
			}
		}
	})
};
