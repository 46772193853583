import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'MarkdownQuote';

const classes = {
    root: `${PREFIX}-root`,
    author: `${PREFIX}-author`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		fontSize: '1.9rem',
		fontWeight: 300,
		fontStyle: 'italic',
		color: theme.palette.text.secondary,
		padding: theme.spacing(1, 2),
		margin: theme.spacing(4, 0),
		borderLeft: `solid 5px ${theme.palette.grey[200]}`,
	},

    [`& .${classes.author}`]: {
		marginLeft: theme.spacing(4),
		fontWeight: 500,
	}
}));

const MarkdownQuote = ({ children, author }) => {


	return (
        <Root className={classes.root}>
			{children}
			{author && (
				<div className={classes.author}>- {author}</div>
			)}
		</Root>
    );
};

MarkdownQuote.propTypes = {
	children: PropTypes.node.isRequired,
	author: PropTypes.string,
};

export default MarkdownQuote
