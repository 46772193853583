import { styled } from "@mui/material";

const PREFIX = 'CheckoutMethods';

export const checkoutClasses = {
	item: `${PREFIX}-item`,
}

const StyledCheckoutMethodsSection = styled('section')(({ theme }) => ({
	paddingTop: '22px',
	marginTop: '30px',
	borderTop: `1px solid ${theme.palette.grey[200]}`,

	[`& .${checkoutClasses.item}`]: {
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.grey[200]}`,
		borderRadius: theme.shape.borderRadiusCards,
		padding: theme.spacing(1),
		textAlign: 'center',
		fontSize: '0.9rem',
		cursor: 'pointer',

		'& > img': {
			height: '40px',
			width: '40px',
			backgroundSize: '32px 32px',
		},

		'& > div': {
			textAlign: 'center',
			lineHeight: '14px',
			height: '28px',
			marginTop: '6px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},

		'& + div': {
			textAlign: 'center',
			fontSize: '13px',
			lineHeight: '13px',
			color: theme.palette.grey[700],
			marginTop: '-24px',
			marginBottom: '11px',
		},

		'&:hover': {
			backgroundColor: theme.palette.grey[50], // $white-two
			borderColor: theme.palette.grey[400], // $pinkish-grey
		}
	},

	[`& > .${checkoutClasses.item}`]: {
		textAlign: 'left',
		paddingLeft: '20px',
		display: 'flex',
		alignItems: 'center',

		'& > img': {
			width: '50px',
			height: '50px',
		},

		'& > span': {
			display: 'inline-block',
			flex: '1',
			marginLeft: '20px',
			fontSize: '1.2em',
		},

		'& > div': {
			display: 'inline-block',
			margin: '0 10px 0 10px',
			fontSize: '13px',
			height: 'auto',
			color: theme.palette.grey[700],
		}
	},
}))

export default StyledCheckoutMethodsSection;
