import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer as BaseFooter } from '@athenagroup/components';
import useRootScope from '../../hoc/hooks/useRootScope';
import { path, routes } from '../../routes';
import facebookSrc from './facebook.svg';
import instagramSrc from './instagram.svg';
import tiktokSrc from './tiktok.svg';
import { LinkComponent } from '../general/LinkComponent';
import { getCustomLocaleMessage } from '../../helpers/locale';

function Footer() {
    const { properties, name } = useRootScope();
    const { menuFooter, meta = {} } = properties;
    const { t, i18n } = useTranslation();

    const items = (Array.isArray(menuFooter) && menuFooter.map((column) => ({
        label: getCustomLocaleMessage(column.title, i18n),
        items: Array.isArray(column.items) && column.items.map((item) => ({
            label: getCustomLocaleMessage(item.title, i18n),
            link: item.href ? { href: item.href } : { to: item.to },
        })),
    }))) || [
        {
            label: name,
            items: [
                {
                    label: t('footer.contact'),
                    link: { to: path(i18n, routes.STATIC.CONTACT) },
                },
                {
                    label: t('footer.about_us'),
                    link: { to: path(i18n, routes.STATIC.ABOUT) },
                },
                {
                    label: t('footer.terms'),
                    link: { to: path(i18n, routes.STATIC.TERMS) },
                },
                {
                    label: t('footer.privacy'),
                    link: { to: path(i18n, routes.STATIC.PRIVACY) },
                },
            ],
        },
    ];

    return (
        <BaseFooter
            id="footer"
            DefaultLinkComponent={LinkComponent}
            items={(!!meta.socialFacebook || !!meta.socialInstagram || !!meta.socialTiktok) ? items.concat({
                label: t('footer.follow_us'),
                items: [
                    !!meta.socialFacebook && {
                        label: `@${meta.socialFacebook}`,
                        link: { href: `https://www.facebook.com/${meta.socialFacebook}/`, target: '_blank', rel: 'noreferrer' },
                        icon: { src: facebookSrc, alt: 'Facebook logo' },
                    },
                    !!meta.socialInstagram && {
                        label: `${meta.socialInstagram}`,
                        link: { href: `https://www.instagram.com/${meta.socialInstagram}/`, target: '_blank', rel: 'noreferrer' },
                        icon: { src: instagramSrc, alt: 'Instagram logo' },
                    },
                    !!meta.socialTiktok && {
                        label: `${meta.socialTiktok}`,
                        link: { href: `https://www.tiktok.com/${meta.socialTiktok}/`, target: '_blank', rel: 'noreferrer' },
                        icon: { src: tiktokSrc, alt: 'TikTok logo' },
                    },
                ].filter(Boolean),
            }) : items}
            sx={{
                zIndex: 45,
            }}
        />
    );
}

export default Footer;
