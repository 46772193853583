import { Alert, Button, styled } from "@mui/material";
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import useAuth from '../../hoc/hooks/useAuth'
import { routes, path } from './../../routes'
import Loader from './../loader'
import CheckoutAddressBlock from './CheckoutAddressBlock'
import CheckoutAddressCheck from './CheckoutAddressCheck'
import CheckoutProfile from './CheckoutProfile'
import CheckoutMethods from './CheckoutMethods'
import CheckoutLoginRegister from './CheckoutLoginRegister'

const getBasketAddressMandatory = (basket) => {
	if(!basket || !basket.basketContents) return false;

	return basket.basketContents.reduce((check, entry) => {
		return check || entry.product.addressMandatory;
	}, false);
};

const PREFIX = 'Checkout';
const classes = {
	title: `${PREFIX}-title`,
	text: `${PREFIX}-text`,
}

const StyledCheckout = styled('div')(({ theme }) => ({
	[`& .${classes.title}`]: {
		fontSize: '24px',
		fontWeight: '600',
		textAlign: 'center',
	},

	[`& .${classes.text}`]: {
		fontStyle: 'italic',
		textAlign: 'center',
		marginBottom: '10px',
		color: theme.palette.grey[500],
	},
}))

const Checkout = ({ onPayRequest, basket }) => {
	const [ addressChecked, setAddressChecked ] = useState(false);
	const { t, i18n } = useTranslation();
	const auth = useAuth();

	const handleAddressChecked = () => {
		setAddressChecked(true);
	};

	const handleAddressEdit = () => {
		setAddressChecked(false);
	};

	const addressMandatory = getBasketAddressMandatory(basket);

	return (
		<StyledCheckout>
			<div className={classes.title}>
				{auth.loggedIn ? t('checkout.pay') : t('checkout.pay_login')}
			</div>
			<div className={classes.text}>
				{auth.loggedIn ? ((addressChecked || !addressMandatory) ? t('checkout.pay_text_pay') : t('checkout.pay_text_address')) : t('checkout.pay_text_login')}
			</div>
			{auth.loggedIn ? (
				auth.user ? (
					(addressChecked || !addressMandatory) ? (
						<div>
							<CheckoutProfile
								user={auth.user}
								showAddress={addressMandatory}
								onAddressEdit={handleAddressEdit}
							/>
							{(basket && basket.totalPrice && basket.totalPrice.amount) === 0 ? (
								<div>
									<Alert severity="info" icon={false}>
										<Trans i18nKey="checkout.free.confirm_text">
											Deze bestelling is gratis. Ga akkoord met onze <Link to={path(i18n, routes.STATIC.TERMS)}>algemene voorwaarden</Link> door de bestelling te bevestigen.
										</Trans>
									</Alert>
									<Button
										fullWidth
										color="cta"
										variant="contained"
										onClick={() => onPayRequest()}
									>
										{t('checkout.free.button_confirm')}
									</Button>
								</div>
							) : (
								<CheckoutMethods onPayRequest={onPayRequest} />
							)}
						</div>
					) : (
						<CheckoutAddressBlock title={t('checkout.block.postal_address')}>
							<CheckoutAddressCheck auth={auth} onAddressChecked={handleAddressChecked} />
						</CheckoutAddressBlock>
					)
				) : (
					<Loader />
				)
			) : (
				<CheckoutLoginRegister />
			)}
		</StyledCheckout>
	);
};

Checkout.propTypes = {
	onPayRequest: PropTypes.func.isRequired,
	basket: PropTypes.object
};

export default Checkout;
