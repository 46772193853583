import React from 'react'
import PropTypes from 'prop-types'
import config from './../../setup/config'

const PartnerStyle = ({ partner }) => {
	if(partner && partner.visuals && partner.visuals.styleUrl) return (
		<link rel="stylesheet" type="text/css" href={config.staticPartnerUrl + partner.visuals.styleUrl} />
	);

	return null;
};

PartnerStyle.propTypes = {
	partner: PropTypes.object
};

export default PartnerStyle
