import { ascend, sortWith, descend, prop, path } from 'ramda'
import { decamelizeKeys } from 'humps'
import { getApiTypeSuccess, getApiTypeFailure } from '../helpers/types'
import { INIT } from '../actions/general'
import { LOGIN, ME, LOGOUT, REGISTER } from '../actions/auth'
import { BASKET, ORDER_BASKET } from '../actions/basket'
import i18n from './i18n'

export const BasketCookieName = 'ATHENASTUDIES-BASKET';
export const BasketCookieDays = 1;

export const BasketFirstAddedCookieName = 'ATHENASTUDIES-BASKET-FIRST-ADDED';

const getActiveRootScope = (appContext, rootScopes) => {
	if(!appContext || !appContext.url) return false;

	// Get the url and remove the unneeded protocol
	const url = appContext.url.replace('http://', '').replace('https://', '');

	if(appContext.forceRootScopeId){
		const scope = rootScopes.find(scope => scope.id === appContext.forceRootScopeId);

		if(!scope) console.error(`Using development forceRootScopeId, but cannot find a root scope with id "${appContext.forceRootScopeId}"`);

		return {
			scope,
			bestMatch: {
				pos: 0,
				length: 10,
				domain: 'localhost:7005',
			},
		};
	}

	// Determine the potential scopes based on the url
	const potentialScopes = rootScopes.map(scope => {
		const domains = scope && scope.properties && scope.properties.config && scope.properties.config.domains;
		if(!domains) return false;

		const sorter = sortWith([
			ascend(prop('pos')), // A more direct match is more important
			descend(prop('length')), // Secondly, longer domain is more important
		]);

		return {
			scope: scope,
			bestMatch: sorter(domains.map(domain => ({
				pos: url.indexOf(domain),
				length: domain.length,
				domain: domain,
			})).filter(match => match.pos !== -1))[0],
		};
	}).filter(potential => potential && potential.bestMatch);

	// Sort the scopes on importance and pick the best first one as best match
	return sortWith([
		ascend(path(['bestMatch', 'pos'])),
		descend(path(['bestMatch', 'length'])),
	])(potentialScopes)[0];
};

/**
 * @param {Cookies} cookies
 */
export default (cookies) => (state = {}, action) => {
	if(action.type === getApiTypeFailure(INIT)){
		// The init action was not a success, there is no recovery here: throw a critical error
		return {
			...state,
			error: 'The root company could not be loaded for this application. Please contact us immediately as this is a critical error.',
            errorCode: 500,
		};
	}

	if(action.type === getApiTypeSuccess(INIT)){
		if(!state.context){
			console.error('Default state needs "init.context" when INIT finishes');
		}

		state = {
			...state,
			error: undefined, // Reset any error
			...action.response.result,
		};

		// Find the root scope based on the scope properties
		const rootScopeOption = getActiveRootScope(state.context, state.rootScopes.map(id => action.response.entities.scopes[id]));

		// If there is no rootScope, stop all further processing and put a critical error in the state
		if(!rootScopeOption || !rootScopeOption.scope) return {
			...state,
			error: 'No root company could be found for this application on this particular URL. Please contact us immediately as this is a critical error.',
		};

		const rootScope = rootScopeOption.scope;

		// Extract relevant information from the rootScope for this applications state
		state.rootScope = rootScope.id;
		state.rootLocale = (rootScope.locale || 'nl-nl').toLowerCase();
		state.rootTranslations = rootScope.properties && rootScope.properties.translations;

		// Fix nasty bug because api does camelize keys
		state.rootTranslations = decamelizeKeys(state.rootTranslations);

		// Determine if there is a specific root path for this site
		// (it has a base because otherwise it might crash on older safari browsers)
		state.rootPath = (new URL(`http://${rootScopeOption.bestMatch.domain}`, 'http://athenastudies.nl')).pathname;
		state.rootDomain = rootScopeOption.bestMatch.domain;

		// Store shopping basket
		if(state.shoppingBasket){
			cookies.set(BasketCookieName, state.shoppingBasket.id, {
				days: BasketCookieDays,
			});
		}
	}

	// Listen to login and set id
	if(action.type === getApiTypeSuccess(LOGIN) && action.response && action.response.result){
		const id = parseInt(action.response.result.userId);

		state = { ...state,
			me: id,
		};
	}

	// Listen to register and set id
	if(action.type === getApiTypeSuccess(REGISTER) && action.response && action.response.result){
		const id = parseInt(action.response.result);

		state = { ...state,
			me: id,
		};
	}

	// Listen to logout and remove data
	if(action.type === LOGOUT){
		state = { ...state,
			me: null,
		};
	}

	// Listen to me and set id if different
	if(action.type === getApiTypeSuccess(ME) && action.response && action.response.result){
		const id = action.response.result;

		if(id !== state.user){
			state = { ...state,
				me: id,
			};
		}
	}

	// Listen to basket
	if(action.type === getApiTypeSuccess(BASKET) && action.response && action.response.result){
		const basket = action.response.result;

		if(!state.shoppingBasket || basket.id !== state.shoppingBasket.id){
			cookies.set(BasketCookieName, basket.id, {
				days: BasketCookieDays,
			});

			state = { ...state,
				shoppingBasket: basket,
				shoppingBasketFirstAdded: cookies.get(BasketFirstAddedCookieName),
			};
		}
	}

	// Listen to order creation to dump basket
	if(action.type === getApiTypeSuccess(ORDER_BASKET) && action.response && action.response.result){
		cookies.remove(BasketCookieName);
		cookies.remove(BasketFirstAddedCookieName);
		state = { ...state,
			shoppingBasket: null,
		};
	}

	if(action.type === 'INIT_ERROR' && action.error){
		state = {
			...state,
			error: action.error,
		};
	}

	// Sub reducer for internationalization
	const i18nState = i18n(state.i18n, action, state, cookies);
	if(i18nState !== state.i18n){
		state = { ...state,
			i18n: i18nState,
		};
	}

	return state;
}
