import { CALL_API } from './../schemas'

export const EVALUATION = 'evaluation';

export const getEvaluationForm = (token) => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `evaluation-form/${token}/evaluation-form-info`,
	}
});

export const saveEvaluationForm = (token, data) => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `evaluation-form/${token}`,
		method: 'PUT',
		body: data
	}
});
