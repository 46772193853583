import { autocompleteClasses, Box, outlinedInputClasses, Paper, paperClasses } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import Countries from '../../constants/Countries';

// Sort for searching, making sure longer found prefixes take presence
const sortedCountries = Countries.sort((a, b) => b.prefix.length - a.prefix.length);

function splitPhone(number) {
    if (!number) return ['', ''];

    const item = sortedCountries.find(i => number.indexOf(i.prefix) === 0);

    return [item?.prefix || '', number.replace(item?.prefix, '')];
}

function mockTargetEvent(name, value) {
    return {
        target: {
            name, value,
        },
    };
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

function getOptionLabel(item) {
    if (typeof item === 'string') return item;

    return item ? `${item.prefix}` : 'prefix';
}

const filterOptions = createFilterOptions({
    stringify: o => `${o.code} ${o.name} ${o.nameLocal} ${o.prefix}`,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${paperClasses.root}`]: {
        width: 200,
    },
}));

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '0 1px 0 0',
    },
});

function PrefixAdornment({ value = '+31', onChange }) {
    const handleChange = useCallback((e, item) => {
        onChange(item.prefix);
    }, [onChange]);

    return (
        <InputAdornment position="start">
            <Autocomplete
                onChange={handleChange}
                value={value || null}
                options={Countries}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, val) => option.prefix === val}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        {...props}
                        key={option.code}
                        sx={{
                            [`&.${autocompleteClasses.option}`]: {
                                p: 0,
                            },
                        }}
                    >
                        <Box sx={{ flex: 1, py: 1, px: 2 }}>
                            <Box component="span" sx={{ mr: 1 }}>{countryToFlag(option.code)}</Box>
                            {' '}
                            {`${option.code} ${option.prefix}`}
                        </Box>
                    </Box>
                )}
                filterOptions={filterOptions}
                disableClearable
                PaperComponent={StyledPaper}
                sx={{ width: 100 }}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        placeholder="prefix"
                    />
                )}
            />
        </InputAdornment>
    );
}

PrefixAdornment.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

const selectInitState = (state) => state.init;

const phonePrefixMap = {
    nl: '+31',
    de: '+49',
    en: '+44',
};

function PhoneField({ name, id, value, onChange, ...rest }) {
    const [prefix, number] = splitPhone(value);
    const { i18n } = useSelector(selectInitState);
    const defaultPrefix = phonePrefixMap[i18n.fallbackLng] || phonePrefixMap.nl;

    const handleChange = useCallback((e) => {
        onChange(mockTargetEvent(name, `${prefix || defaultPrefix}${e.target.value}`));
    }, [onChange, name, prefix]);

    const handleBlur = useCallback((e) => {
        const { value } = e.target;
        const number = value.substring(0, 2) === defaultPrefix.slice(1) ? value.substring(2) : value;
        onChange(mockTargetEvent(name, `${prefix || defaultPrefix}${number}`));
    }, [onChange, name, prefix]);

    const handleChangePrefix = useCallback((code) => {
        onChange(mockTargetEvent(name, `${code}${number}`));
    }, [onChange, name, number]);

    return (
        <NumericFormat
            allowNegative={false}
            decimalScale={0}
            isAllowed={({ value }) => value.length <= 15}
            customInput={TextField}
            {...rest}
            name={name}
            id={id}
            value={number}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
                startAdornment: (
                    <PrefixAdornment value={prefix || defaultPrefix} onChange={handleChangePrefix} />
                ),
                sx: {
                    paddingLeft: 0,
                },
            }}
        />
    );
}

PhoneField.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default PhoneField;
