import { styled } from "@mui/material";
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { routes, path } from './../../routes'

const CheckoutProfile = styled(({ user, showAddress, onAddressEdit, ...props }) => {
	const { t, i18n } = useTranslation();
	return (
		<div {...props}>
			{t('checkout.logged_in_as')} <Link to={path(i18n, routes.ACCOUNT)}>{user.email}</Link>
			{showAddress && (
				<Fragment>
					<br/>
					{t('checkout.postal_address')}: <span>{user.street} {user.houseNumber}{user.houseNumberExtension}, {user.zipcode}, {user.city}</span> (<a onClick={onAddressEdit}>{t('checkout.edit')}</a>)
				</Fragment>
			)}
		</div>
	);
})(({ theme }) => ({
	margin: '5px 0 20px',
	color: theme.palette.grey[500],
	textAlign: 'center',

	'& a': {
		fontWeight: 600,
		color: theme.palette.info.main,
		cursor: 'pointer',
	},

	'& > span': {
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

CheckoutProfile.propTypes = {
	user: PropTypes.object.isRequired,
	showAddress: PropTypes.bool.isRequired,
	onAddressEdit: PropTypes.func.isRequired,
};

export default CheckoutProfile
