import { invertObj, chain, toPairs, fromPairs, type, map, path as keyPath } from 'ramda';
import { generatePath } from 'react-router-dom';
import { baseRoutes, languageRoutes } from '../routeDefinitions';
import { defaultLanguage } from '../reducers/i18n';

const flattenObj = obj => {
    const go = obj_ => chain(([k, v]) => {
        if (type(v) === 'Object' || type(v) === 'Array') {
            return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
        }
        return [[k, v]];
    }, toPairs(obj_));

    return fromPairs(go(obj));
};

/**
 @param pathname {string} i.e. `/wachtwoordvergeten/123`
 @param [params] {object} i.e. `{token: 123}`
 @returns string i.e. `/wachtwoordvergeten/:token`
 */
export const getCurrentPathPattern = (pathname, params) => {
    let pattern = pathname;
    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            pattern = pattern.replace(value, `:${key}`);
        });
    }
    return pattern;
};

/**
 @param pattern {string} i.e. `/wachtwoordvergeten/:token`
 @param [params] {object} i.e. `{token: 123}`
 @returns string i.e. `/wachtwoordvergeten/123`
 */
export const getPathnameWithInterpolatedParams = (pattern, params) => {
    let pathname = pattern;
    if (params && (pattern.match(/:/g) || []).length) {
        Object.entries(params).forEach(([key, value]) => {
            pathname = pathname.replace(`:${key}`, value);
        });
    }
    return pathname;
};

const mirrorFlattenLangRoutes = map(routes => invertObj(flattenObj(routes)), languageRoutes);

export function getLangAndPath(i18n, rootPath) {
    const fallbackLng = i18n.options?.fallbackLng?.[0] || i18n.fallbackLng;

    if (rootPath.match(/^\/[a-z]{2}?\/|^\/[a-z]{2}\/?$/)) {
        const path = rootPath.substring(3);
        return {
            lang: rootPath.substring(1, 3),
            path: path === '' ? baseRoutes.HOME : path,
        };
    }
    return { lang: fallbackLng, path: rootPath };
}

/**
    @param path {string}
    @param sourceLocale {string}
    @param [targetLocale] {nl|en|de}
    @returns string Path string
*/
export function getLanguagePathPattern(path, sourceLocale, targetLocale) {
    // Get the key first, if not exists, fallback to the path itself
    const key = mirrorFlattenLangRoutes[sourceLocale][path];

    if (!key) return path;

    // Use locale param if provided, otherwise use fallbackLng from i18next
    // On route creation the i18n object is provided directly from the state, so the shape of the object can be different

    // Try to find the language path for the current language
    if (!languageRoutes[targetLocale || sourceLocale]) return path;

    // This is the path we need for the new language
    return keyPath(key.split('.'), languageRoutes[targetLocale || sourceLocale]) || path;
}

/**
 * Create router path from given route
 *
 * @param i18n {i18n}
 * @param originalPath {string}
 * @param [targetLocale] {string}}
 * @param [isBaseRoute] {boolean}}
 * @param [params] {object}
 * @returns string
 */
export const getRouterPath = (i18n, originalPath, targetLocale, isBaseRoute, params) => {
    const { path } = getLangAndPath(i18n, originalPath);

    const fallbackLng = i18n.options?.fallbackLng?.[0] || i18n.fallbackLng;
    const prefix = (targetLocale || i18n.language) === fallbackLng ? '' : `/${targetLocale || i18n.language}`;
    const sourceLocale = isBaseRoute ? defaultLanguage : i18n.language;
    const pattern = getLanguagePathPattern(path, sourceLocale, targetLocale);
    const pathname = getPathnameWithInterpolatedParams(pattern, params);
    return `${prefix}${pathname}`;
};

/**
 * Create path from given route and params
 *
 * @param i18n {i18n}
 * @param path {string}
 * @param params
 * @returns string
 */
export const getPath = (i18n, path, ...params) => {
    // Check path variables and params length in development
    if (process.env.NODE_ENV === 'development') {
        if (path === undefined) {
            console.warn('Param path not defined in getPath()/path() (path: %s)', path);
        }

        const maxParams = (path.match(/:/g) || []).length;
        const minParams = maxParams - (path.match(/\?/g) || []).length;
        if (params.length < minParams || params.length > maxParams) {
            console.warn('Params do not match path (path: %s, params: %s)', path, params.join(', '));
        }
    }

    // Build a parameter object from the array by getting the names from the path
    const paramsObj = {};
    const names = (path.match(/:\w+/g) || []);

    params.forEach((val, i) => {
        paramsObj[names[i].replace(':', '')] = val;
    });

    // Now let react-router generate the path
    return generatePath(getRouterPath(i18n, path, i18n.language, true), paramsObj);
};

export const getPagePath = (pages, key) => {
    const page = pages.find(currPage => currPage.key === key);

    return page ? page.path : `/p/${key}`;
};
