import PropTypes from 'prop-types';
import React from 'react';
import { Accordion } from '@athenagroup/components';
import Markdown from '../../markdown/Markdown';

function HomePageWidgetAccordion({ items, animation }) {
    return (
        <Accordion items={items.map(item => ({ ...item, description: <Markdown>{item.description}</Markdown> }))} animation={animation} />
    );
}

HomePageWidgetAccordion.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    animation: PropTypes.string,
};

export default HomePageWidgetAccordion;
