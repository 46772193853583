import {
    Alert,
    Button,
    Grid,
    InputLabel,
    TextField,
    Typography,
    outlinedInputClasses,
    formHelperTextClasses,
    styled,
    Box,
    darken,
    alpha,
} from '@mui/material';
import PropTypes from 'prop-types';
import { mergeAll } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { submitContactForm } from '../../../actions/contact';
import FormData, { errorRendererField } from '../../../hoc/FormData';
import SimpleState from '../../../hoc/SimpleState';
import Loader from '../../loader';
import WidgetAnimation from './WidgetAnimation';

const StyledForm = styled('form')(({ theme, widgetColor }) => ({
    [`& .${formHelperTextClasses.root}`]: {
        color: theme.palette.error.main,
    },
    [`& .${outlinedInputClasses.root}`]: {
        borderRadius: '0.25em',
        marginTop: theme.spacing(0.5),
        [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: widgetColor || theme.palette.primary.main,
        },
    },
}));

const StyledSubmitButton = styled(Button, {
    shouldForwardProp: propName => propName !== 'widgetColor',
})(({ theme, widgetColor }) => ({
    borderColor: widgetColor || theme.palette.primary.main,
    color: widgetColor || theme.palette.secondary.main,
    textTransform: 'unset',
    fontSize: '1rem',
    padding: theme.spacing(1),
    borderRadius: '0.25em',
    '&:hover, &:focus': {
        borderColor: darken(widgetColor || theme.palette.primary.main, 0.25),
        color: darken(widgetColor || theme.palette.primary.main, 0.25),
        backgroundColor: alpha(widgetColor || theme.palette.primary.main, 0.1),
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
}));

const validation = (t) => (data) => {
    const errors = [];

    if (data.firstName.length === 0) {
        errors.push({ firstName: t('home.widgets.contactForm.empty_field') });
    }

    // NOT USED FOR NOW
    // if (data.lastName.length === 0) {
    //     errors.push({ lastName: t('home.widgets.contactForm.empty_field') });
    // }

    if (data.schoolName.length === 0) {
        errors.push({ schoolName: t('home.widgets.contactForm.empty_field') });
    }

    // NOT USED FOR NOW
    // if (!data.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
    //     errors.push({ email: t('home.widgets.contactForm.email_incorrect') });
    // }

    if (!data.phoneNumber.match(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/g)) {
        errors.push({ phoneNumber: t('home.widgets.contactForm.phone_no_incorrect') });
    }

    // NOT USED FOR NOW
    // if (data.question.length === 0) {
    //     errors.push({ question: t('home.widgets.contactForm.empty_field') });
    // }

    if (errors.length > 0) return {
        errors: mergeAll(errors),
    };

    return false;
};

function ContactForm({ color, formTitle, fields, onChangeField, handleSubmit, saving, success, error }) {
    const { t } = useTranslation();

    if (success) {
        return (
            <Alert severity="success" icon={false}>
                {t('home.widgets.contactForm.formSentSuccessfully')}
            </Alert>
        );
    }

    return (
        <StyledForm onSubmit={handleSubmit(() => fields, submitContactForm, validation(t))} widgetColor={color}>
            {saving && (
                <Loader sheet>{t('home.widgets.contactForm.loading')}</Loader>
            )}
            <Typography variant="h6" sx={{ mb: 1 }}>
                {formTitle}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StyledInputLabel htmlFor="firstName">
                        {t('home.widgets.contactForm.form_firstName')}
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_firstName_placeholder')}
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        value={fields.firstName}
                        onChange={onChangeField}
                        helperText={errorRendererField(error, 'firstName')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledInputLabel htmlFor="phoneNumber">
                        {t('home.widgets.contactForm.form_tel')}
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_tel_placeholder')}
                        name="phoneNumber"
                        id="phoneNumber"
                        variant="outlined"
                        value={fields.phoneNumber}
                        onChange={onChangeField}
                        helperText={errorRendererField(error, 'phoneNumber')}
                    />
                </Grid>
                <Grid item xs={12} md={6} hidden>
                    {' '}
                    { /* NOT USED FOR NOW */ }
                    <StyledInputLabel htmlFor="lastName">
                        {t('home.widgets.contactForm.form_lastName')}
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_lastName_placeholder')}
                        name="lastName"
                        id="lastName"
                        variant="outlined"
                        value={fields.lastName}
                        onChange={onChangeField}
                        helperText={errorRendererField(error, 'lastName')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledInputLabel htmlFor="schoolName">
                        {t('home.widgets.contactForm.form_name_school')}
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_name_school_placeholder')}
                        name="schoolName"
                        id="schoolName"
                        variant="outlined"
                        value={fields.schoolName}
                        onChange={onChangeField}
                        helperText={errorRendererField(error, 'schoolName')}
                    />
                </Grid>
                <Grid item xs={12} md={6} hidden>
                    {' '}
                    { /* NOT USED FOR NOW */ }
                    <StyledInputLabel htmlFor="email">
                        {t('home.widgets.contactForm.form_mail')}
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_mail_placeholder')}
                        name="email"
                        id="email"
                        variant="outlined"
                        value={fields.email}
                        onChange={onChangeField}
                        helperText={errorRendererField(error, 'email')}
                    />
                </Grid>
                <Grid item xs={12} hidden>
                    {' '}
                    { /* NOT USED FOR NOW */ }
                    <StyledInputLabel htmlFor="question">
                        {t('home.widgets.contactForm.form_question')}
                        <Box component="span" sx={{ color: 'grey.500', fontWeight: 'regular' }}>
                            {` (${t('home.widgets.contactForm.character_limit')})`}
                        </Box>
                    </StyledInputLabel>
                    <TextField
                        fullWidth
                        placeholder={t('home.widgets.contactForm.form_question_placeholder')}
                        name="question"
                        id="question"
                        variant="outlined"
                        value={fields.question}
                        onChange={onChangeField}
                        multiline
                        helperText={errorRendererField(error, 'question')}
                        inputProps={{
                            maxLength: 1500,
                        }}
                    />
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <Alert severity="warning" icon={false} sx={{ mb: 0 }}>
                            {error.errorCode ? error.error : t('home.widgets.contactForm.error_with_fields')}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <StyledSubmitButton fullWidth variant="outlined" type="submit" widgetColor={color}>
                        {t('home.widgets.contactForm.form_send')}
                    </StyledSubmitButton>
                </Grid>
            </Grid>
        </StyledForm>
    );
}

export const ContactFormComponent = FormData(SimpleState(ContactForm, {
    fields: ['firstName', 'lastName', 'schoolName', 'phoneNumber', 'email', 'question'],
}), 'ContactForm', {});

function HomePageWidgetContactForm({ formSide, title, description, animation, color, formTitle, htmlId }) {
    return (
        <Grid container spacing={4} direction={formSide === 'left' ? 'row-reverse' : 'row'} {...(htmlId ? { id: htmlId } : {})}>
            <Grid item sm={12} xs={12}>
                <WidgetAnimation animation={animation} triggerOnce>
                    <ContactFormComponent color={color} formTitle={formTitle} />
                </WidgetAnimation>
            </Grid>
        </Grid>
    );
}

HomePageWidgetContactForm.propTypes = {
    formTitle: PropTypes.string.isRequired,
    formSide: PropTypes.oneOf(['left', 'right']),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    animation: PropTypes.string,
    color: PropTypes.string,
    htmlId: PropTypes.string,
};

HomePageWidgetContactForm.disableTitle = true;

export default HomePageWidgetContactForm;
