import { Grid } from "@mui/material";
import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import useAuth from '../hoc/hooks/useAuth'
import { useRedirectContext } from '../hoc/RedirectContextProvider'
import { path, routes } from '../routes'
import Content from './../components/content/Content'
import LoginForm from './../components/login/login-form'
import { getRouterPath } from '../helpers/path';

const LoginPage = () => {
	const auth = useAuth();
	const { i18n } = useTranslation();
	const navigate = useNavigate();

    useEffect(() => {
        const { loggedIn, user } = auth;
        if (loggedIn) {
            const targetLocale = user?.locale ? user.locale.substring(0, 2) : i18n.language;
            navigate(getRouterPath(i18n, routes.ACCOUNT, targetLocale, true), { replace: true });
        }
    }, [auth, navigate, i18n.language]);

    const redirectContext = useRedirectContext()
    if (redirectContext && auth.loggedIn) {
        redirectContext.url = path(i18n, routes.ACCOUNT);
    }

	return (
		<Content>
			<Content.Header>
				<h1>
					<Trans i18nKey="login.title"
									  defaults="Inloggen" />
				</h1>
			</Content.Header>
			<Grid container justifyContent="center">
				<Grid item xs={12} sm={6} md={4}>
					<LoginForm />
				</Grid>
			</Grid>
		</Content>
	);
}

export default LoginPage
