import React from 'react'
import {Helmet} from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import Markdown from '../components/markdown/Markdown'
import { getCustomLocaleMessage } from '../helpers/locale'
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties'
import Content from './../components/content/Content'
import Faq from './../components/faq/faq'
import FaqGroup from './../components/faq/faq-group'
import FaqItem from './../components/faq/faq-item'

const FaqPage = () => {
	const properties = useRootScopeProperties();
	const { t, i18n } = useTranslation();
	const meta = properties.meta || {};
	const faq = properties.faq || null;

	const email = meta.email || 'info@athenastudies.nl';
	const emailComplaints = meta.emailComplaints || 'klachten@athenastudies.nl';

	return (
		<Content>
			<Content.Header>
				<h1>{t('faq.title')}</h1>
			</Content.Header>
			<Helmet>
				<title>{t('faq.title')}</title>
			</Helmet>
			{faq ? (
				<Faq>
					{faq.map((group, i) => (
						<FaqGroup key={i} title={getCustomLocaleMessage(group.title, i18n)}>
							{group.items.map((item, i) => (
								<FaqItem key={i} title={getCustomLocaleMessage(item.title, i18n)}>
									<Markdown>
										{getCustomLocaleMessage(item.content, i18n)}
									</Markdown>
								</FaqItem>
							))}
						</FaqGroup>
					))}
				</Faq>
			) : (
				<Faq>
					<FaqGroup title={t('faq.courses')}>
						<FaqItem title={t('faq.1.title')}>
							{t('faq.1.text')}
						</FaqItem>
						<FaqItem title={t('faq.2.title')}>
							{t('faq.2.text')}
						</FaqItem>
						<FaqItem title={t('faq.3.title')}>
							{t('faq.3.text')}
						</FaqItem>
						<FaqItem title={t('faq.4.title')}>
							{t('faq.4.text')}
						</FaqItem>
					</FaqGroup>
					<FaqGroup title={t('faq.subscribe')}>
						<FaqItem title={t('faq.5.title')}>
							{t('faq.5.text')}
						</FaqItem>
						<FaqItem title={t('faq.6.title')}>
							<Trans i18nKey="faq.6.text" values={{ email }}>
								Ja, stuur hiervoor een e-mail naar <a href={`mailto:${email}`}>{email}</a> om te vragen of er nog plek is binnen een klas. Je zult alsnog het gehele bedrag moeten betalen voor de cursus, ongeacht vanaf wanneer je deel gaat nemen.
							</Trans>
						</FaqItem>
						<FaqItem title={t('faq.7.title')}>
							{t('faq.7.text')}
						</FaqItem>
					</FaqGroup>
					<FaqGroup title={t('faq.complaints')}>
						<FaqItem title={t('faq.8.title')}>
							<Trans i18nKey="faq.8.text" values={{ email: emailComplaints }}>
								Dit kan via e-mail naar <a href={`mailto:${emailComplaints}`}>{emailComplaints}</a>. Wij zullen onze uiterste best doen om zo snel mogelijk antwoord te geven.
							</Trans>
						</FaqItem>
					</FaqGroup>
				</Faq>
			)}
		</Content>
	);
}

export default FaqPage
