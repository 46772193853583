import * as React from 'react';
import StaticPage, { staticContentLoader } from './pages/static/StaticPage';
import staticPages from './pages/static/staticPages';

import NotFoundPage, { notFoundLoader } from './pages/general/NotFoundPage';

import HomePage from './pages/HomePage';
import FaqPage from './pages/FaqPage';

import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RegisterPage from './pages/RegisterPage';
import PasswordForgotPage from './pages/PasswordForgotPage';
import PasswordResetPage from './pages/PasswordResetPage';
import AccountPage from './pages/AccountPage';
import AccountEditPage from './pages/AccountEditPage';
import AccountEditAddressPage from './pages/AccountEditAddressPage';
import EvaluationPage from './pages/EvaluationPage';

import ScopeCompanyPage, { scopeCompanyPageLoader } from './pages/ScopeCompanyPage';
import ScopeInstitutePage, { scopeInstitutePageLoader } from './pages/ScopeInstitutePage';
import ScopeFacultyPage, { scopeFacultyPageLoader } from './pages/ScopeFacultyPage';
import ScopeStudyPage, { scopeStudyPageLoader } from './pages/ScopeStudyPage';
import ScopeCoursePage, { scopeCoursePageLoader } from './pages/ScopeCoursePage';

import PartnerPage from './pages/PartnerPage';

import CheckoutPage from './pages/CheckoutPage';
import PayPage from './pages/PayPage';

import VacanciesStaticPage from './pages/static/VacanciesStaticPage';
// import VacancyManagerStaticPage from './pages/static/VacancyManagerStaticPage';
// import VacancyBusinessStaticPage from './pages/static/VacancyBusinessStaticPage';
import Root from './Root';
import { baseRoutes } from './routeDefinitions';
import { getRouterPath } from './helpers/path';

const createLocaleRoutes = (store, i18n, locale, rootScope) => ({
    path: `/${i18n.fallbackLng !== locale ? locale : ''}`,
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
        { index: true, path: getRouterPath(i18n, baseRoutes.HOME, locale, true), element: <HomePage /> },
        { path: getRouterPath(i18n, baseRoutes.FAQ, locale, true), element: <FaqPage /> },

        { path: getRouterPath(i18n, baseRoutes.LOGIN, locale, true), element: <LoginPage /> },
        { path: getRouterPath(i18n, baseRoutes.LOGOUT, locale, true), element: <LogoutPage /> },
        { path: getRouterPath(i18n, baseRoutes.REGISTER, locale, true), element: <RegisterPage /> },
        { path: getRouterPath(i18n, baseRoutes.PASSWORD_FORGOT, locale, true), element: <PasswordForgotPage /> },
        { path: getRouterPath(i18n, baseRoutes.PASSWORD_RESET, locale, true), element: <PasswordResetPage /> },
        { path: getRouterPath(i18n, baseRoutes.ACCOUNT, locale, true), element: <AccountPage /> },
        { path: getRouterPath(i18n, baseRoutes.ACCOUNT_EDIT, locale, true), element: <AccountEditPage /> },
        { path: getRouterPath(i18n, baseRoutes.ACCOUNT_EDIT_ADDRESS, locale, true), element: <AccountEditAddressPage /> },

        { path: getRouterPath(i18n, baseRoutes.PARTNER, locale, true), element: <PartnerPage /> },

        { path: getRouterPath(i18n, baseRoutes.COURSES, locale, true), element: <ScopeCompanyPage />, loader: () => scopeCompanyPageLoader(store) },
        {
            path: getRouterPath(i18n, baseRoutes.INSTITUTE, locale, true),
            element: <ScopeInstitutePage />,
            loader: ({ params }) => scopeInstitutePageLoader(store, params),
        },
        {
            path: getRouterPath(i18n, baseRoutes.FACULTY, locale, true),
            element: <ScopeFacultyPage />,
            loader: ({ params }) => scopeFacultyPageLoader(store, params),
        },
        {
            path: getRouterPath(i18n, baseRoutes.STUDY, locale, true),
            element: <ScopeStudyPage />,
            loader: ({ params }) => scopeStudyPageLoader(store, params),
        },
        {
            path: getRouterPath(i18n, baseRoutes.COURSE, locale, true),
            element: <ScopeCoursePage />,
            loader: ({ params }) => scopeCoursePageLoader(store, params),
        },

        { path: getRouterPath(i18n, baseRoutes.COURSES_OLD, locale, true), element: <ScopeCompanyPage /> },
        { path: getRouterPath(i18n, baseRoutes.INSTITUTE_OLD, locale, true), element: <ScopeInstitutePage /> },
        { path: getRouterPath(i18n, baseRoutes.STUDY_OLD, locale, true), element: <ScopeStudyPage /> },

        { path: getRouterPath(i18n, baseRoutes.CHECKOUT, locale, true), element: <CheckoutPage /> },
        { path: getRouterPath(i18n, baseRoutes.PAY, locale, true), element: <PayPage /> },
        { path: getRouterPath(i18n, baseRoutes.PAY_METHOD_BASE, locale, true), element: <PayPage /> },
        { path: getRouterPath(i18n, baseRoutes.PAY_METHOD_SUB, locale, true), element: <PayPage /> },
        { path: getRouterPath(i18n, baseRoutes.RETURN, locale, true), element: <PayPage /> },
        { path: 'afronden', element: <PayPage /> }, // TODO: remove later, temporary backward compatibility
        { path: 'return', element: <PayPage /> }, // TODO: remove later, temporary backward compatibility

        // Map the build in static pages
        ...staticPages(i18n, locale).map((page) => {
            if (!rootScope) return false;

            const { abbreviation, properties } = rootScope;
            if (!page.path) return false;
            if (page.companyAbbr && !page.companyAbbr.includes(abbreviation)) return false;

            return ({
                path: page.path,
                element: <StaticPage />,
                loader: async ({ request }) => staticContentLoader(page, request, i18n, properties),
            });
        }).filter(Boolean),

        { path: getRouterPath(i18n, baseRoutes.STATIC.VACANCIES, locale, true), element: <VacanciesStaticPage /> },
        // { path: getRouterPath(i18n, baseRoutes.STATIC.VACANCIES_MANAGER, locale, true), element: <VacancyManagerStaticPage /> },
        // { path: getRouterPath(i18n, baseRoutes.STATIC.VACANCIES_BUSINESS, locale, true), element: <VacancyBusinessStaticPage /> },

        { path: getRouterPath(i18n, baseRoutes.EVALUATION, locale, true), element: <EvaluationPage />, noHeader: true },
        { path: '*', element: <NotFoundPage />, loader: notFoundLoader },
    ],
});

const createRoutes = (store, i18n, rootScope) => {
    const rootLocale = rootScope?.locale?.substring?.(0, 2) || 'nl';
    return [
        ...i18n.availableLocales
            .filter(currLocale => currLocale !== rootLocale)
            .map(currLocale => createLocaleRoutes(store, i18n, currLocale, rootScope)),
        createLocaleRoutes(store, i18n, rootLocale, rootScope),
    ];
};

export default createRoutes;
