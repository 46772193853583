import { KeyboardArrowRight } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { scopeAbbr } from '../../helpers/scope'
import StyledScopeListItem from "./StyledScopeListItem";

const ScopeListItem = ({ scope, pathCreator, letter, subTextCreator }) => {
	const redirect = scope.properties && scope.properties.redirect;
	const parent = useSelector(state => state.entities.scopes[scope.parent])

	const linkProps = redirect ? {
		href: redirect,
	} : {
		to: pathCreator(scope, parent),
	};

	{/* TODO: This could be a MUI <ListItemButton component="Link" ..> */}
	return (
		<StyledScopeListItem component={redirect ? 'a' : Link} {...linkProps}>
			<Grid container alignItems="center">
				{
					letter !== undefined &&
					<Grid item xs={1}>
						<div className="scope-list-item-letter">
							{letter ? letter : ''}
						</div>
					</Grid>
				}
				<Grid item xs={letter !== undefined ? 10 : 11} sm={letter !== undefined ? 10 : 11}>
					<div className="scope-list-item-name">
						{scope.name}
						<span className="scope-list-item-abbreviation">
						{subTextCreator ? subTextCreator(scope) : scopeAbbr(scope)}
					</span>
					</div>
				</Grid>
				<Grid item xs={1} sx={{ textAlign: 'right' }}>
					<KeyboardArrowRight fontSize="large" sx={{ color: 'info.main', my: -1.75 }} />
				</Grid>
			</Grid>
		</StyledScopeListItem>
	);
}

ScopeListItem.propTypes = {
	scope: PropTypes.object.isRequired,
	pathCreator: PropTypes.func.isRequired,
	letter: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	subTextCreator: PropTypes.func,
};

export default ScopeListItem
