import { useLayoutEffect } from 'react';

/**
 * @extends useLayoutEffect
 */
export default function useLayoutEffectClient(effect, deps) {
    if (import.meta.env.SSR) return;

    // Only needs to run on client, using an env var makes this hook non-conditional in runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    useLayoutEffect(effect, deps);
}
