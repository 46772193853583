
export function scopeHideAbbr(scope){
	return scope.properties && scope.properties.hideAbbr;
}

export function scopeAbbr(scope){
	return scope.properties && scope.properties.hideAbbr
		? ''
		: scope.abbreviation;
}

export function scopeUrlSegment(scope){
	return scope.abbreviation.toLowerCase();

	// Below method does not work anymore when we try to fetch scope by abbr list from url
	//  - if we do want to change it, we might want to introduce a slug system
	// return (scope.properties && scope.properties.hideAbbr
	// 	? scopeNameUrlFormat(scope.name)
	// 	: scope.abbreviation.toLowerCase()
	// );
}

export function getRootScopeTitle(rootScope) {
	const siteTitle = rootScope.properties.meta && rootScope.properties.meta.siteTitle;
	const scopeName = rootScope && rootScope.name;

	return siteTitle || scopeName || 'AthenaStudies';
}
