import { Button, TextField, Grid, Alert } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import SimpleState from './../../hoc/SimpleState'
import FormData from './../../hoc/FormData'
import { addDiscount } from './../../actions/basket'
import BasketDiscountList from './BasketDiscountList'

const getDiscountError = (error, t) => {
	if(error.errorCode === 404) return <Alert severity="warning" icon={false}>
		{t('checkout.basket.discount.error_not_found')}
	</Alert>;

	return <Alert severity="warning" icon={false}>
		{t('checkout.basket.discount.error_not_applicable')}
	</Alert>;
};

const BasketDiscount = ({ basket, fields, onChangeField, handleSubmit, saving, error, removeDiscount }) => {
	const { t } = useTranslation();
	return (
		<div>
			<BasketDiscountList codes={basket.appliedDiscountCodes}
								removeDiscount={removeDiscount} />
			{error && getDiscountError(error, t)}
			<form onSubmit={handleSubmit(() => fields, addDiscount)}>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={7} md={8}>
						<TextField
							placeholder={t('checkout.basket.discount.discount_code')}
							disabled={saving}
							name="discountCode"
							value={fields.discountCode}
							onChange={onChangeField}
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={12} sm={5} md={4}>
						<Button
							variant="contained"
							color="info"
							type="submit"
							disabled={saving || !fields.discountCode}
							fullWidth
							sx={{ height: '100%', mt: { xs: 1, sm: 0 }}}
						>
							{t('checkout.basket.discount.button_add')}
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

BasketDiscount.propTypes = {
	basket: PropTypes.object.isRequired,
	removeDiscount: PropTypes.func.isRequired,
};

export default FormData(SimpleState(BasketDiscount, {
	fields: ['discountCode'],
	cleanOnSuccess: true
}), 'discount-code')
