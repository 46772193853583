import { styled } from '@mui/material';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { methodImgUrl } from './CheckoutMethodsSection';

const PREFIX = 'CheckoutMethodTitle';
const classes = {
    title: `${PREFIX}-title`,
};

const CheckoutMethodTitle = styled(({ method, className }) => {
    const { t } = useTranslation();
    return (
        <div className={classnames(className, classes.title)}>
            {t('checkout.pay_with')}
            {' '}
            {Boolean(method) && method.visibleName}
            {Boolean(method) && (
                <img src={methodImgUrl(method)} alt={method.visibleName} />
            )}
        </div>
    );
})(({ theme }) => ({
    [`&.${classes.title}`]: {
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 600,
        color: theme.palette.grey[900],
        marginBottom: '25px',

        '& img': {
            height: '20px',
            width: 'auto',
            marginLeft: '6px',
            verticalAlign: '-5px',
        },
    },
}));

CheckoutMethodTitle.propTypes = {
    method: PropTypes.object,
};

export default CheckoutMethodTitle;
