import { Grid } from "@mui/material";
import React from 'react'
import { Trans, useTranslation, withTranslation } from 'react-i18next'
import useAuth from '../hoc/hooks/useAuth'
import { routes, path } from '../routes'
import IsAuthenticated from '../auth'
import Content from '../components/content/Content'
import Loader from '../components/loader'
import AccountEditAddressForm from '../components/account/AccountEditAddressForm'

const AccountEditAddressPage = () => {
	const auth = useAuth();
	const { i18n } = useTranslation();

	return (
		<Content padding>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={path(i18n, routes.ACCOUNT)} />
					</Grid>
					<Grid item xs={12} md={4} lg={6}>
						<h1>
							<Trans
								i18nKey="account.edit.my_address"
								defaults="Mijn adres"
							/>
						</h1>
					</Grid>
					<Grid item xs={0} md={4} lg={3} />
				</Grid>
			</Content.Header>
			{auth.user ? (
				<div>
					<AccountEditAddressForm
						defaultFields={{ ...auth.user }}
						cancelBackTo={path(i18n, routes.ACCOUNT)}
					/>
				</div>
			) : (
				<Loader/>
			)}
		</Content>
	);
}

export default IsAuthenticated(withTranslation()(AccountEditAddressPage))
