import React from 'react';

export const getPropertyByKey = (key, properties) => properties.find(choice => {
	if (choice?.key) return choice.key === key;
	return choice === key;
});

export const propertyLabel = (property, lang) => {
	if (property.options?.label?.[lang]) {
		return property.options?.label[lang];
	}
	return property.key;
};

export const choiceLabel = (choice, lang) => {
	if (choice?.label) {
		return choice?.label[lang] || choice.key;
	}
	return choice;
};

export const choiceLabelByKey = (key, options, lang) => {
	const item = options?.choices?.find(choice => {
		if (choice?.key) return choice.key === key;
		return choice === key;
	});
	return choiceLabel(item, lang);
};

export const propertyValue = (property, lang) => {
	if (property.options?.choices?.length) {
		if (Array.isArray(property.value)) {
			return property.value.map(item => choiceLabelByKey(item, property.options, lang)).join(', ');
		}
		return choiceLabelByKey(property.value, property.options, lang);
	}
	if (Array.isArray(property.value)) {
		return property.value.map(item => item).join(', ');
	}
	if (typeof property.value === 'boolean') {
		return property.value ? propertyLabel(property, lang) : null;
	}
	return property.value;
};
