import React, { useEffect, useMemo } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next'
import { connect } from 'react-redux'
import i18nServices from "../services/i18nServices";
import { defaultLanguage } from '../reducers/i18n';

const I18nProvider = connect((state) => ({
	init: state.init,
}))(({ init, children }) => {
	const { i18n, rootTranslations } = init;

	const i18nInstance = useMemo(() => {
		return i18next.createInstance({
			fallbackLng: i18n?.fallbackLng || defaultLanguage,
			lng: i18n?.language || defaultLanguage,
			ignoreJSONStructure: true,
			resources: i18nServices.generateResources(rootTranslations),
			debug: process.env.NODE_ENV !== 'production' && !import.meta.env.SSR,
			react: {
				useSuspense: true,
			},
		}, (err) => {
			if (err) return console.error('Failed initializing i18n instance', err);
		});
	}, []);

	const language = i18n?.language;
	useEffect(() => {
		language && i18nInstance.changeLanguage(language);
	}, [language, i18nInstance]);

	return (
		<I18nextProvider i18n={i18nInstance}>
			{ children }
		</I18nextProvider>
	);
});

export default I18nProvider
