import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { path, routes } from '../routes'

const partnerInitRedirect = (initState, location, navigate) => {
	// Check if there is a partner and scopes
	if(!initState.partner || !initState.partner.scopes) return;

	// Only redirect on /
	if(location.pathname !== '/') return;

	if(initState.partner.scopes.length > 1){
		navigate(path(initState.i18n, routes.PARTNER), { replace: true });
	}

	if(initState.partner.scopes.length === 1){
		const scope = initState.partner.scopes[0];
		const abbr = scope.abbreviation.toLowerCase();

		if(scope.class === 'Course'){
			navigate(path(initState.i18n, routes.STUDY, scope.parent.parent.abbreviation.toLowerCase(), abbr), { replace: true });
		}else if(scope.class === 'Faculty'){
			navigate(path(initState.i18n, routes.INSTITUTE, scope.parent.abbreviation.toLowerCase()), { replace: true });
		}else{
			navigate(path(initState.i18n, routes.INSTITUTE, abbr), { replace: true });
		}
	}
};

const selectInitState = (state) => state.init;

// Only render this component after the root is loaded already
const PartnerHandler = () => {
	const initState = useSelector(selectInitState);
	const location = useLocation();
	const navigate = useNavigate();

	// Attempt redirect on mount, as the root should be already loaded, this is enough
	useEffect(() => {
		partnerInitRedirect(initState, location, navigate);
	}, []);

    return null;
};

export default PartnerHandler
