import { Alert, Button, Grid, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import classNames from 'classnames'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { gtmEvent } from '../../helpers/gtm'
import { usePrevious } from '../../hoc/hooks/usePrevious'
import FormData, { errorRenderer } from './../../hoc/FormData'
import SimpleState from './../../hoc/SimpleState'
import { addToMailing } from '../../actions/mailing'
import Loader from './../loader'

const PREFIX = 'CourseNone';

const classes = {
    root: `${PREFIX}-root`,
    notify: `${PREFIX}-notify`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2, 2),
		fontSize: theme.typography.fontSize,
		background: theme.palette.grey[100],
		boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
		borderRadius: theme.shape.borderRadiusCards,
		'&.warning': {
			borderTop: `4px solid ${theme.palette.primarySubLight}`,
		},
	},

    [`& .${classes.notify}`]: {
		fontStyle: 'italic',
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(2),
	}
}));

const CourseNone = ({ scope, noWarning, fields, onChangeField, saving, error, success, handleSubmit }) => {

	const { t } = useTranslation();

	const getData = () => ({
		scopeId: scope.id,
		email: fields.email,
	});

	const prevSuccess = usePrevious(success);
	useEffect(() => {
		if(!prevSuccess && success){
			gtmEvent('courseNoneEmail');
		}
	}, [prevSuccess, success]);

	if(success) return (
		<Alert severity="success" icon={false}>
			{t('course.none.sign_up_success', {
				email: success.email,
				scope_name: success.scope.name
			})}
		</Alert>
	);

	return (
        <Root className={classNames(classes.root, {
			warning: !noWarning,
		})}>
			{noWarning ? (
				<p className="text-muted">{t('course.none.alert_request_sign_up')}</p>
			) : (
				<>
					<div className={classes.notify}>
						{t('course.none.notify_no_courses')}
					</div>
					<Alert severity="info" icon={false}>
						<p>{t('course.none.alert_request_sign_up')}</p>
					</Alert>
				</>
			)}
			{saving && (
				<Loader>{t('course.none.signing_up')}</Loader>
			)}
			{error && (
				errorRenderer({error: t('course.none.error_email_not_correct')})
			)}
			<form onSubmit={handleSubmit(getData, addToMailing)}>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={7} md={8}>
						<TextField
							placeholder={t('course.none.email')}
							disabled={saving}
							name="email"
							value={fields.email}
							onChange={onChangeField}
							variant="outlined"
							size="small"
							fullWidth
							sx={{ backgroundColor: 'common.white' }}
						/>
					</Grid>
					<Grid item xs={12} sm={5} md={4}>
						<Button
							type="submit"
							color="cta"
							variant="contained"
							disabled={saving}
							fullWidth
							sx={{ height: '100%', mt: { xs: 1, sm: 0 }}}
						>
							{t('course.none.sign_up')}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Root>
    );
}

CourseNone.propTypes = {
	scope: PropTypes.object.isRequired,
	noWarning: PropTypes.bool,
};

export default FormData(SimpleState(CourseNone, {
	fields: ['email']
}), 'mailingForm', {

})
