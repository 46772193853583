import {
	Checkbox,
	InputLabel,
	Grid,
	TextField,
	Button,
	Box,
	FormControlLabel,
	styled,
	formControlLabelClasses,
	Divider,
	Alert,
} from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { mergeAll } from 'ramda'
import { withTranslation, Trans } from 'react-i18next'
import PhoneField from "../general/PhoneField";
import { routes, path } from './../../routes'
import SimpleState from './../../hoc/SimpleState'
import FormData from './../../hoc/FormData'
import { doRegister } from './../../actions/auth'
import Loader from './../../components/loader'

const validation = (t) => (data) => {
	const errors = [];

	if(data.password !== data.password_repeat){
		errors.push({password_repeat: t('register.form.password_not_the_same')});
	}

	if(data.email !== data.email_repeat){
		errors.push({email_repeat: t('register.form.email_not_the_same')});
	}

	if(errors.length > 0) return {
		errors: mergeAll(errors)
	};

	return false;
};

const linkStyle = theme => ({
	'& a': {
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
	}
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	marginBottom: 0,
	[`& .${formControlLabelClasses.label}`]: {
		fontSize: '1rem',
		color: theme.palette.text.primary,
	},
	...linkStyle(theme),
}))

const StyledBox = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	marginTop: theme.spacing(2),
	color: theme.palette.text.primary,
	...linkStyle(theme),
}))

const RegisterForm = ({ fields, onChangeField, onChangeFieldBind, onChangeFieldCheckbox, saving, error, handleSubmit, t, i18n }) => (
	<form onSubmit={handleSubmit(() => fields, doRegister, validation(t))}>
		{ saving && (
			<Loader sheet>{t('register.form.loading')}</Loader>
		)}
		{ error?.errors?.['error'] && (
			<Alert severity="warning" icon={false}>
				{error?.errors?.['error']}
				{ error?.errors?.['error'].indexOf('already an account registered to this email') > -1 && (
					<p><Trans i18nKey="register.form.resetYourPassword">Perhaps you want to <Link to={path(i18n, routes.PASSWORD_FORGOT)}>reset your password</Link>?</Trans></p>
				)}
			</Alert>
		)}
		<Grid container spacing={2} columnSpacing={6}>
			<Grid item xs={12} md={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-name">
							{t('register.form.name')}
						</InputLabel>
						<TextField
							id="register-name"
							name="fname"
							autoComplete="given-name"
							fullWidth
							value={fields.name}
							onChange={onChangeFieldBind('name')}
							error={!!error?.errors?.['name']}
							helperText={error?.errors?.['name']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-surname">
							{t('register.form.surname')}
						</InputLabel>
						<TextField
							id="register-surname"
							fullWidth
							name="lname"
							autoComplete="family-name"
							value={fields.surname}
							onChange={onChangeFieldBind('surname')}
							error={!!error?.errors?.['surname']}
							helperText={error?.errors?.['surname']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-mobile">
							{t('register.form.mobile')}
						</InputLabel>
						<PhoneField
							value={fields.mobilePhone || '+31'}
							onChange={onChangeFieldBind('mobilePhone')}
							name="mobile"
							id="register-mobile"
							fullWidth
							error={!!error?.errors?.['mobilePhone']}
							helperText={error?.errors?.['mobilePhone']}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<Divider sx={{ my: 2, display: { md: 'none' } }} />
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-email">
							{t('register.form.email')}
						</InputLabel>
						<TextField
							id="register-email"
							fullWidth
							name="email"
							autoComplete="email"
							value={fields.email}
							onChange={onChangeField}
							error={!!error?.errors?.['email']}
							helperText={error?.errors?.['email']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-email-repeat">
							{t('register.form.email_repeat')}
						</InputLabel>
						<TextField
							id="register-email-repeat"
							fullWidth
							name="email_repeat"
							value={fields.email_repeat}
							onChange={onChangeField}
							error={!!error?.errors?.['email_repeat']}
							helperText={error?.errors?.['email_repeat']}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider sx={{ my: 2, display: { md: 'none' } }} />
						<InputLabel htmlFor="register-password">
							{t('register.form.password')}
						</InputLabel>
						<TextField type="password"
							id="register-password"
							fullWidth
							name="password"
							autoComplete="new-password"
							value={fields.password}
							onChange={onChangeField}
							error={!!error?.errors?.['password']}
							helperText={error?.errors?.['password']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="register-password-repeat">
							{t('register.form.password_repeat')}
						</InputLabel>
						<TextField type="password"
							id="register-password-repeat"
							fullWidth
							name="password_repeat"
							value={fields.password_repeat}
							onChange={onChangeField}
							error={!!error?.errors?.['password_repeat']}
							helperText={error?.errors?.['password_repeat']}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider sx={{ mt: 1, mb: { xs: 2, md: 1 }}} />
				<StyledFormControlLabel
					checked={fields.agree || false}
					onClick={onChangeFieldCheckbox}
					control={<Checkbox />}
					name="agree"
					label={(
						<Trans i18nKey="register.form.terms_text">
							Ga akkoord met onze <Link to={path(i18n, routes.STATIC.TERMS)} target="_blank">algemene voorwaarden</Link> en <Link to={path(i18n, routes.STATIC.PRIVACY)} target="_blank">privacyverklaring</Link> om een account te registreren.
						</Trans>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					type="submit"
					color="cta"
					variant="contained"
					disabled={!fields.agree}
					fullWidth
				>
					{t('register.form.register')}
				</Button>

				<StyledBox>
					{t('register.form.account_already')}
					{' '}
					<Link to={path(i18n, routes.LOGIN)}>{t('register.form.login')}</Link>
				</StyledBox>
			</Grid>
		</Grid>
	</form>
);

RegisterForm.propTypes = {
	redirectPath: PropTypes.string,
}

export default withTranslation()(FormData(SimpleState(RegisterForm, {
	fields: ['name', 'surname', 'mobilePhone', 'email', 'email_repeat', 'password', 'password_repeat', 'agree', 'marketingOptIn']
}), 'registerForm', {
	successRedirect: (props) => {
		return props.redirectPath || path(props.i18n, routes.ACCOUNT);
	},
	dataPick: ['name', 'surname', 'mobilePhone', 'email', 'password', 'marketingOptIn']
}))
