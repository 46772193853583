import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateLastScopePathStartAt } from '../../actions/lastPath'

function getScopeParents(scope){
	if(!scope) return [];
	if(scope.class === 'Company') return [];

	return [...getScopeParents(scope.parent), scope];
}

function getScopeIndex(scope){
	if(!scope) return false;

	switch (scope.class){
		case 'Institute':
			return 0;
		case 'Faculty':
			return 1;
		case 'Course':
			return 2;
		default:
			return false;
	}
}

export const useSetLastPath = (scope) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const scopes = getScopeParents(scope);
		const index = getScopeIndex(scopes[0]);

		if(index !== false){
			dispatch(updateLastScopePathStartAt(scopes, index));
		}
	}, [scope]);
}
