import PropTypes from 'prop-types';
import React from 'react';
import { AttentionSeeker, Bounce, Fade, JackInTheBox, Zoom } from 'react-awesome-reveal';

function WidgetAnimation({ animation, children, ...rest }) {
    let Component;

    switch (animation) {
        case 'JackInTheBox':
            Component = JackInTheBox;
            break;
        case 'AttentionSeeker':
            Component = AttentionSeeker;
            break;
        case 'Bounce':
            Component = Bounce;
            break;
        case 'Fade':
            Component = Fade;
            break;
        case 'Zoom':
            Component = Zoom;
            break;
        default:
            Component = false;
    }

    return Component ? (
        <Component cascade delay={200} fraction={0.3} duration={500} {...rest}>
            {children}
        </Component>
    ) : (
        children
    );
}

WidgetAnimation.propTypes = {
    animation: PropTypes.string.isRequired,
};

export default WidgetAnimation;
