import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight, LabelOutlined } from "@mui/icons-material";
import PropTypes from 'prop-types'
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { validProperties } from "../../actions/properties";
import { getHourPrice } from "../../helpers/course";
import { propertyValue } from "../../helpers/properties";
import { useFetchData } from "../../hoc/hooks/useFetchData";
import Loader from "../loader";
import CourseBundle from "./CourseBundle";
import CourseHoursTag from './CourseHoursTag';
import CourseListItemDescription from "./CourseListItemDescription";
import CourseListItemPrice from './CourseListItemPrice'
import CourseStockStatusChips from "./CourseStockStatusChips";
import CourseTimeTillStartTag from './CourseTimeTillStartTag';

const PREFIX = 'CourseListItem';

const classes = {
    courseWrapper: `${PREFIX}-courseWrapper`,
    course: `${PREFIX}-course`,
    courseTitle: `${PREFIX}-courseTitle`,
    courseDescBtn: `${PREFIX}-courseDescBtn`,
    courseInfo: `${PREFIX}-courseInfo`,
    labels: `${PREFIX}-labels`,
    label: `${PREFIX}-label`,
    courseTagline: `${PREFIX}-courseTagline`,
    priceWrapper: `${PREFIX}-priceWrapper`,
    orderNowBtn: `${PREFIX}-orderNowBtn`,
    endIcon: `${PREFIX}-endIcon`,
    priceAndTimeTag: `${PREFIX}-priceAndTimeTag`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.courseWrapper}`]: {
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.course}`]: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1.5, 2),
		fontSize: '0.9rem',
		color: theme.palette.text.secondary,
		background: theme.palette.grey[100],
		boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
		borderLeft: `4px solid ${theme.palette.primarySubLight}`,
		borderRadius: theme.shape.borderRadiusCards,
		position: 'relative',
		zIndex: 100,
		[theme.breakpoints.up('sm')]: {
			alignItems: 'flex-start',
		},
		'&:hover, &:focus': {
			textDecoration: 'none',
			color: theme.palette.text.secondary,
			background: theme.palette.grey[200],
			borderLeftColor: theme.palette.primarySubDark,
		}
	},

    [`& .${classes.courseTitle}`]: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '1.2rem',
		color: theme.palette.text.primary,
		'& > strong': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	},

    [`& .${classes.courseDescBtn}`]: {
		padding: theme.spacing(0.5),
		marginLeft: theme.spacing(0.5),
		'& svg': {
			width: '0.7em',
			height: '0.7em',
		},
	},

    [`& .${classes.courseInfo}`]: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		gap: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		}
	},

    [`& .${classes.labels}`]: {
		marginTop: theme.spacing(0.5),
		'& > span': {
			display: 'inline-flex',
			alignItems: 'center',
			marginRight: theme.spacing(2),
		},
	},

    [`& .${classes.label}`]: {
		color: infoBlue,
		fontSize: '1.25rem',
		marginRight: theme.spacing(0.5),
		transform: 'translateY(-1px)',
	},

    [`& .${classes.courseTagline}`]: {
		marginTop: theme.spacing(1.5),
	},

    [`& .${classes.priceWrapper}`]: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'right',
			flexDirection: 'column',
			alignItems: 'flex-end',
		}
	},

    [`& .${classes.orderNowBtn}`]: {
		borderRadius: theme.shape.borderRadiusCards,
		padding: theme.spacing(0.5, 1, 0.5, 1.5),
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.up('sm')]: {
			marginBottom: theme.spacing(1),
		}
	},

    [`& .${classes.endIcon}`]: {
		margin: 0,
	},

    [`& .${classes.priceAndTimeTag}`]: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('sm')]: {
			flex: 1,
			alignItems: 'flex-end',
		}
	}
}));

const infoBlue = '#4165ff';

const CourseListItem = ({ course, coursePathCreator, loading, filteredCourses, bundles, onSelectBundle, mode }) => {

	const { i18n, t } = useTranslation();
	const { data: scopeProperties } = useFetchData('ScopeProperties');
	const hourPrice = getHourPrice(course.price, course.subScopes);

	const properties = scopeProperties ? validProperties(course, scopeProperties, 'hiddenInCourseTagsList') : [];

	if(!filteredCourses || loading) return <Loader/>;

	return (
        <Root className={classes.courseWrapper} id={course.id}>
			<Link
				to={coursePathCreator(course.id)}
				className={classes.course}
			>
				<div className={classes.courseInfo}>
					<div style={{ flex: 1 }}>
						<div className={classes.courseTitle}>
							<strong>{course.name}</strong>
						</div>
						<div className={classes.labels}>
							{properties
								.map((prop, index) => (
									<span key={`prop_${index}`}>
										<LabelOutlined className={classes.label} />
										{propertyValue(prop, i18n.language)}
									</span>
								))
							}
							<CourseHoursTag course={course} as="span" />
							<CourseStockStatusChips course={course} />
						</div>
						<CourseListItemDescription course={course} />
					</div>
					<div className={classes.priceWrapper}>
						<div className={classes.priceAndTimeTag}>
							<CourseListItemPrice price={course.price} hourPrice={hourPrice} />
							<CourseTimeTillStartTag groups={course.subScopes} as="div" />
						</div>
						<Button
							variant="contained"
                            color={mode === 'list' ? 'cta' : 'info'}
							className={classes.orderNowBtn}
							classes={{ endIcon: classes.endIcon }}
							endIcon={<KeyboardArrowRight />}
						>
							{t('course.list.orderNow')}
						</Button>
					</div>
				</div>
			</Link>
			{mode === 'list' && (
				<CourseBundle
					courseId={course.id}
					bundles={bundles}
					onSelectBundle={onSelectBundle}
					mode={mode}
				/>
			)}
		</Root>
    );
}

CourseListItem.propTypes = {
	course: PropTypes.object.isRequired,
	study: PropTypes.object.isRequired,
	coursePathCreator: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(['list']),
};

export default CourseListItem;
