import moment from 'moment'

moment.updateLocale('nl', { ...moment.localeData('nl'),
	monthsShort: 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_'),
});

/** Abbr day | day | abbr month */
export function shortDateAbr(d){
	return moment(d).format("dd DD MMM");
}

/** day | abbr month | year */
export function dateNormal(d){
	return moment(d).format("DD MMM YYYY");
}

/** hour:minute */
export function time(d){
	return moment(d).format("HH:mm")
}

/** iso8601 */
export function dateIso(d){
	return moment(d).format('YYYY-MM-DDTHH:mm:ss');
}
