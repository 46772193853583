import { forEachObjIndexed, mapObjIndexed } from 'ramda'

function setParentIdForAllScopes(scopes){
	forEachObjIndexed((scope) => {
		if(!scope.subScopes) return;

		scope.subScopes.forEach(subScopeId => {
			if(!scopes[subScopeId]) return;

			scopes[subScopeId].parent = scope.id;
		});
	}, scopes);
}

export default function entities(state = {}, action){
	if(action.response && action.response.entities){
		setParentIdForAllScopes(action.response.entities.scopes)
		state = { ...state, ...mapObjIndexed((entityResponseData, key) => {
			// entityResponseData: object with ids and for example s copes
			// key: for example 'scopes'

			// old merger: return merge(state[key] || {}, entity);
			// new merges deeper to solve subScopes overwrite bug, related to WEBSTORE-155
			return {
				...state[key],
				...mapObjIndexed((entityItem, entityId) => {
					return {
						// The old data for one entity item
						...(state[key] ? state[key][entityId] : null),
						...entityItem,
					}
				}, entityResponseData),
			}
		}, action.response.entities)};

		state = addParent(state, action.addParent, action.response.result);
	}

	return state;
}

function addParent(state, props, result){
	if(!props || !result) return state;

	const { entity, id } = props;
	if(!entity || !id) {
		console.log('action.addParent should be an object with entity and id, now found: ', props);
		return state;
	}

	if(!state[entity][id]) return state;

	state = {
		...state,
		[entity]: {
			...state[entity],
			[id]: {
				...state[entity][id],
				subScopes: result,
			},
		},
	};

	return state;
}
