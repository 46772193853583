import { Grid } from "@mui/material";
import React from 'react'
import { Trans } from 'react-i18next'
import { useParams } from "react-router-dom";
import Content from './../components/content/Content'
import PasswordResetForm from './../components/password/PasswordResetForm'

const PasswordResetPage = () => {
	const params = useParams();
	return (
		<Content>
			<Content.Header>
				<h1>
					<Trans i18nKey="password.reset.title"
						   defaults="Wachtwoord instellen" />
				</h1>
			</Content.Header>
			<Grid container justifyContent="center">
				<Grid item xs={10} md={6}>
					<PasswordResetForm token={params.token} />
				</Grid>
			</Grid>
		</Content>
	);
}

export default PasswordResetPage
