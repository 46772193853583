import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { moneyComposer } from '../../helpers/composers'

const StyledDiv = styled('div')(({ theme }) => ({
	margin: theme.spacing(3, 0),
	[theme.breakpoints.down('sm')]: {
		lineHeight: '1.5rem',
		margin: theme.spacing(0, 0, 0.25),
	},
}));

const StyledPriceDiv = styled('div')(({ theme }) => ({
	fontSize: '1.2rem',
	fontWeight: theme.typography.fontWeightMedium,
	color: theme.palette.text.primary,
}));

const CourseListItemPrice = ({ price, hourPrice }) => {
	const { t } = useTranslation();

	if(hourPrice) return (
        <StyledDiv>
			<StyledPriceDiv>
				{t('course.price.per_hour', {
					hourPrice: moneyComposer(hourPrice)
				})}
			</StyledPriceDiv>
			<div>
				{t('course.price.total', {
					price: moneyComposer(price)
				})}
			</div>
		</StyledDiv>
    );

	if(price?.amount === 0) return (
		<StyledDiv>
			<StyledPriceDiv>
				{t('course.price.free')}
			</StyledPriceDiv>
		</StyledDiv>
	);

	return (
		<StyledDiv>
			<StyledPriceDiv>
				{moneyComposer(price)}
			</StyledPriceDiv>
		</StyledDiv>
	);
};

CourseListItemPrice.propTypes = {
	price: PropTypes.object.isRequired,
	hourPrice: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default CourseListItemPrice;
