import React, { memo } from 'react'
import moment from 'moment'
import useRootScope from '../../../hoc/hooks/useRootScope'
import ImageRain, { createImageHeart } from './ImageRain'
import Snow from './Snow'

import confettiGoldStreamer from './confetti-gold-streamer.png';
import gayPrideFlag from './gay-pride-flag.png';

function betweenDate(month1, day1, month2, day2){
	const today = moment();

	const a = moment().year(today.year()).month(month1 - 1).date(day1);
	const b = moment().year(today.year()).month(month2 - 1).date(day2);

	return today.isBetween(a, b, 'day', '[]');
}

function betweenDateDuration(startMonth, startDay, days){
	const today = moment();

	const start = moment().year(today.year()).month(startMonth - 1).date(startDay);
	const end = moment(start).add(days, 'days');

	return today.isBetween(start, end, 'day', '[]');
}

export function isValentine(){
	return betweenDate(2, 11, 2, 15);
}

export function isWinter(){
	return betweenDateDuration(12, 1, 40);
}

export function isKoningsDay(){
	const today = moment();

	if(today.month() !== 3) return false;

	// Kingsday is 27th of April, except on Sundays, in that case the day before
	return (
		(today.date() === 27 && today.day() !== 0) || // Today is 27 and not Sunday
		(today.date() === 26 && today.day() === 6) // Today is day before 27 and Saturday
	);
}

export function isGayPride(){
	const today = moment();

	return today.isBetween('2021-07-31', '2021-08-08', 'day', '[]');
}

// Date specific global theming
const SiteTheme = memo(() => {
	const rootScope = useRootScope();

	if(!rootScope) return null;

	if(isValentine()) return (
		<ImageRain image={createImageHeart()} amountOverwrite={20} whirl />
	);

	if((rootScope.abbreviation === 'ASTU' || rootScope.abbreviation === 'MS') && isKoningsDay()) return (
		<ImageRain image={{
			url: confettiGoldStreamer,
		}} amountOverwrite={40} whirl />
	);

	if(isGayPride()) return (
		<ImageRain image={{
			url: gayPrideFlag,
		}} amountOverwrite={20} whirl />
	);

	if(isWinter()) return (
		<Snow />
	);

	return null;
});

export default SiteTheme
