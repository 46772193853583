import React, { Component } from 'react'
import { connect } from 'react-redux'
import { denormalize } from 'normalizr'
import { CALL_API } from '../../setup/api'
import { fetchDataInternalActions } from './fetchDataActions'
import PropTypes from 'prop-types'

const defaultOptions = {
	customId: undefined,
	keepData: false,
	autoRefreshSeconds: false,
	mapEntities: false,
	mapStateToProps: () => ({}),
	pagination: false,
	paginationPageSize: 25,
	denormalize: undefined,
};

/**
 * fetchData HOC, provides tools to fetch
 * Injects: {loading, fetchError, data, refresh, load, pagination, onPaginationChangePage, onPaginationChangePageSize}
 *
 * @class withFetchData
 * @param action func(props)
 * @param extraOptions object {customId: func, keepData bool, autoRefreshSeconds int, mapEntities string|false, mapStateToProps func, pagination bool, paginationPageSize int, denormalize schema}
 * @returns function(Component):Component
 */
const withFetchData = (action = null, extraOptions = {}) => ComposedComponent => {
	const options = {...defaultOptions, ...extraOptions};

	const fetchDataId = options.customId
		? options.customId
		: () => (ComposedComponent.displayName || ComposedComponent.name);

	const defaultPagination = {
		page: 0,
		pageSize: options.paginationPageSize,
		count: 0,
	}

	function actionWithPagination(actionObj, page, pageSize){
		// Check if it is an object indeed
		if(!actionObj || typeof actionObj !== 'object' || !actionObj[CALL_API]){
			throw new Error(`When using pagination property with FetchData, the action can only be an object with CALL_API key.`);
		}

		return {
			...actionObj,
			[CALL_API]: {
				...actionObj[CALL_API],
				pagination: {
					page: page || 0,
					pageSize: pageSize || options.paginationPageSize,
				}
			}
		};
	}

	return connect((state, props) => {
		const generatedId = fetchDataId(props);
		return (Object.assign({},
				options.mapStateToProps(state, props), {
					fetchData: state.fetchData[generatedId] && Object.assign(
						{},
						state.fetchData[generatedId],
						options.mapEntities ? {
							success: state.fetchData[generatedId].success && (Array.isArray(state.fetchData[generatedId].success) ? state.fetchData[generatedId].success.map(
								id => state.entities[options.mapEntities][id]
							) : state.entities[options.mapEntities][state.fetchData[generatedId].success]) // TODO: clean up
						} : {},
						options.denormalize ? {
							success: state.fetchData[generatedId].success &&
							denormalize(state.fetchData[generatedId].success, options.denormalize, state.entities)
						} : {}
					)
				})
		)
	})(class extends Component {
		static displayName = 'withFetchData(' + (ComposedComponent.displayName || ComposedComponent.name || 'Unknown') + ')';

		render() {
			const { fetchData, dispatch, ...rest } = this.props;

			return (
				<ComposedComponent
					{...rest}
					loading={fetchData && fetchData.loading}
					error={fetchData && fetchData.error}
					fetchError={fetchData && fetchData.error}
					data={fetchData && fetchData.success}
					pagination={(fetchData && fetchData.pagination) ? fetchData.pagination : defaultPagination}
					onPaginationChangePage={this.onPaginationChangePage}
					onPaginationChangePageSize={this.onPaginationChangePageSize}
					refresh={this.loadData}
					load={this.loadData}
				/>
			);
		}

		componentDidMount(){
			// Only call when there is an action already
			if(action) this.loadData();

			if(options.autoRefreshSeconds){
				this.timer = setTimeout(
					this.refreshTimer, options.autoRefreshSeconds * 1000
				);
			}
		}

		componentWillUnmount(){
			// Make sure to clear the data
			if(!options.keepData){
				this.props.dispatch(fetchDataInternalActions.fetchDataClear(fetchDataId(this.props)));
			}

			if(this.timer){
				clearTimeout(this.timer);
			}
		}

		loadData = (customAction = undefined) => {
			const generatedId = fetchDataId(this.props);
			let finalAction = customAction ? customAction : action ? action(this.props) : null;
			if(!finalAction){
				if(!customAction && !action){
					throw new Error('No action defined for fetchData with id ' + generatedId);
				}
				return false;
			}

			const { fetchData, dispatch } = this.props;

			// Inject the pagination options
			if(options.pagination){
				finalAction = actionWithPagination(
					finalAction,
					fetchData && fetchData.pagination && fetchData.pagination.page,
					fetchData && fetchData.pagination && fetchData.pagination.pageSize
				);
			}

			const promise = dispatch(finalAction);
			if(!promise) return false;

			dispatch(fetchDataInternalActions.fetchDataLoading(generatedId));

			promise.then((res) => {
				// Handle the error
				if(res && res.errorCode){
					// Exclude 401 error and let other systems handle it
					if(res.errorCode !== 401){
						return dispatch(fetchDataInternalActions.fetchDataError(generatedId, res));
					}
				}else{
					const pagination = res && res.response && res.response.pagination;
					const result = res && res.response && res.response.result;
					return dispatch(fetchDataInternalActions.fetchDataSuccess(generatedId, result || true, pagination || false));
				}
			});
		};

		refreshTimer = () => {
			this.loadData();

			if(options.autoRefreshSeconds){
				this.timer = setTimeout(
					this.refreshTimer, options.autoRefreshSeconds * 1000
				);
			}
		};

		onPaginationChangePage = (e, page) => {
			const { dispatch } = this.props;
			return dispatch(fetchDataInternalActions.fetchDataPage(fetchDataId(this.props), page));
		};

		onPaginationChangePageSize = (e) => {
			const { dispatch } = this.props;
			return dispatch(fetchDataInternalActions.fetchDataPageSize(fetchDataId(this.props), e.target.value));
		};
	});
};

export default withFetchData
