import { Alert, Button, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles';
import { AddShoppingCart } from '@mui/icons-material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { addBasketMultiple } from '../../actions/basket'
import { fetchSubSubScopes } from "../../actions/scopes";
import FormData from '../../hoc/FormData'
import { SortScopesOnName } from '../../helpers/lists'
import { path, routes } from '../../routes'
import { subSubScopeNestedSelector } from "../../selectors/scope";
import SimpleDialog from '../content/dialog/SimpleDialog'
import Loader from '../loader'
import CourseBundleSelectGroup from './CourseBundleSelectGroup'

const PREFIX = 'CourseBundleSelectDialog';

const classes = {
    course: `${PREFIX}-course`,
    bundleTitle: `${PREFIX}-bundleTitle`,
    courseTitle: `${PREFIX}-courseTitle`,
    groupGrid: `${PREFIX}-groupGrid`,
    iconAdd: `${PREFIX}-iconAdd`
};

const StyledSimpleDialog = styled(SimpleDialog)(({ theme }) => ({
    [`& .${classes.course}`]: {
		marginBottom: theme.spacing(5),
	},

    [`& .${classes.bundleTitle}`]: {
		display: 'inline',
		backgroundColor: theme.palette.info.main,
		color: theme.palette.common.white,
		fontSize: '0.9em',
		fontWeight: theme.typography.fontWeightRegular,
		borderRadius: 4,
		padding: theme.spacing(0.5, 1),
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			marginLeft: 0,
		},
	},

    [`& .${classes.courseTitle}`]: {
		...theme.typography.h5,
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(1),
	},

    [`& .${classes.groupGrid}`]: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr 1fr',
		gridGap: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr 1fr',
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr',
		},
	},

    [`& .${classes.iconAdd}`]: {
		marginRight: theme.spacing(1),
		fontSize: '1.4em',
		verticalAlign: '-0.3em',
	}
}));

const CourseBundleSelectDialog = ({ bundle, onClose, fetchSubSubScopes, courses, study, saving, error, success, watchSubmit }) => {

	const { i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const dialogRef = useRef();

	const coursesInBundle = useMemo(() => (
		bundle.applicableInCourses.map(c1 => courses?.find(c2 => c1.id === c2.id))
	), [bundle, courses]);

	const [picked, setPicked] = useState({});

	useEffect(() => {
		if (study && !courses) {
			fetchSubSubScopes(study.id);
		}
	}, [courses, study])

	const bindHandleAdd = useCallback((courseId) => (groupId) => {
		setPicked((picked) => ({
			...picked,
			[courseId]: groupId,
		}));
	}, [setPicked]);

	const allAdded = Object.values(picked).filter(Boolean).length === coursesInBundle.length;

	const handleAddBundle = useCallback(() => {
		const ids = Object.values(picked);

		watchSubmit(addBasketMultiple(ids), true);

		dialogRef.current?.getElementsByClassName('MuiDialog-scrollBody')?.[0]?.scrollTo(0, 0)
	}, [setPicked, watchSubmit, picked]);

	return (
        <StyledSimpleDialog
			open
			onClose={onClose}
			ref={dialogRef}
			title={(
				<>
					<Trans
						i18nKey="course.bundle.select.title"
						defaults="Kies je groepen"
					/>
					<div className={classes.bundleTitle}>
						<Trans
							i18nKey="course.bundle.select.bundle"
							defaults="Bundel: {{bundleTitle}}"
							values={{
								bundleTitle: bundle.description,
							}}
						/>
					</div>
				</>
			)}
			maxWidth="md"
			fullScreen={fullScreen}
		>
			{(!courses || saving) && (
				<Loader sheet />
			)}
			{error && (
				<Alert severity="warning" icon={false}>
					{error.error}
				</Alert>
			)}
			{success && (
				<>
					<Alert severity="success" icon={false}>
						<Trans
							i18nKey="course.bundle.select.bundle_added"
							defaults="Bundel is toegevoegd!"
						/>
					</Alert>
					<Button component={Link} color="cta" fullWidth to={path(i18n, routes.CHECKOUT)}>
						<Trans
							i18nKey="course.bundle.select.visit_shopping_basket"
							defaults="Bekijk je winkelmandje"
						/>
					</Button>
				</>
			)}
			{!success && courses && (
				<>
					{coursesInBundle.map(course => (
						<div key={course.id} className={classes.course}>
							<div className={classes.courseTitle}>{course.name}</div>
							<div className={classes.groupGrid}>
								{SortScopesOnName(course.subScopes).map(group => (
									<CourseBundleSelectGroup
										key={group.id}
										group={group}
										selected={Boolean(picked[course.id] === group.id)}
										onSelect={bindHandleAdd(course.id)}
									/>
								))}
							</div>
						</div>
					))}
					<Button
						disabled={!allAdded}
						onClick={handleAddBundle}
						color={allAdded ? 'info' : 'default'}
						sx={{ mb: 2 }}
						variant="contained"
						fullWidth
					>
						{allAdded ? (
							<>
								<AddShoppingCart className={classes.iconAdd} />
								<Trans
									i18nKey="course.bundle.select.add_to_basket"
									defaults="Voeg de bundel toe aan je winkelmandje"
								/>
							</>
						) : (
							<Trans
								i18nKey="course.bundle.select.pick_groups_first"
								defaults="Kies eerst van elke cursus een groep"
							/>
						)}
					</Button>
				</>
			)}
		</StyledSimpleDialog>
    );
};

CourseBundleSelectDialog.propTypes = {
	study: PropTypes.object.isRequired,
	bundle: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	courses: PropTypes.array,
};

export default  connect((state, props) => ({
	courses: props.study && subSubScopeNestedSelector(state, props.study),
}), {
	fetchSubSubScopes,
})(FormData(CourseBundleSelectDialog, 'CourseBundleSelectDialog'))
