import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import { storeUtmQueryString } from '../helpers/basket'
import { parseSearch, encodeSearch } from '../helpers/query'

// Only render this component after the root is loaded already
const QueryHandler = () => {
	const [ cookies, setCookie ] = useCookies();
	const navigate = useNavigate();
	const location = useLocation();

	// Only on mount check for the init code and remove it
	useEffect(() => {
		const query = parseSearch(location.search);

		if(!query.initCode) return;

		// Remove the init code
		navigate({
			pathname: location.pathname,
			search: encodeSearch({
				...query,
				initCode: undefined,
			}),
		}, { replace: true });
	}, []);

	// Only store UTM query parameters on mount
	useEffect(() => {
		const query = parseSearch(location.search);

		// We are only storing and not removing, as removing makes Google analytics not read them
		storeUtmQueryString(query, setCookie);
	}, []);

    return null;
};

export default QueryHandler
