import { Container, Grid, Box } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { path, routes } from '../../routes'
import SectionTitle from '../general/section-title'

const AccountSimpleOverview = ({ user, t, i18n }) => {
	return (
		<Box sx={{ pt: 0.75, pb: 2.5 }}>
			<SectionTitle>{t('account.overview.my_details')}</SectionTitle>
			<Container>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={6}>
						<Box sx={{ fontSize: 13 }}>{t('account.overview.name')}</Box>
						<Box sx={{ mb: 2 }}>{user.fullName}</Box>

						<Box sx={{ fontSize: 13 }}>{t('account.overview.email')}</Box>
						<Box sx={{ mb: 2 }}>{user.email}</Box>

						{
							user.mobilePhone && (<>
								<Box sx={{ fontSize: 13 }}>{t('account.overview.mobile')}</Box>
								<Box sx={{ mb: 2 }}>{user.mobilePhone}</Box>
							</>)
						}
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box sx={(theme) => ({
							border: '1px solid',
							borderColor: 'grey.400',
							borderRadius: `${theme.shape.borderRadiusCards}px`,
							p: 2.5,
						})}>
							<Box sx={{ fontSize: 13 }}>
								{t('account.overview.postal_address')} (<Link to={path(i18n, routes.ACCOUNT_EDIT_ADDRESS)}>{t('account.overview.edit')}</Link>)
							</Box>
							<Box>
								{user.street} {user.houseNumber}{user.houseNumberExtension}<br/>
								{user.zipcode}, {user.city}, {user.country}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
};

AccountSimpleOverview.propTypes = {
	user: PropTypes.object.isRequired,
};

export default withTranslation()(AccountSimpleOverview)
