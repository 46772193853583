import { Schemas, CALL_API } from './../schemas'
import { getApiTypeSuccess } from './../helpers/types'
import { GetAccountLocaleFromLanguage } from '../helpers/locale'
import { changeLocaleAuto, I18N_SOURCE_ACCOUNT_LOGIN, I18N_SOURCE_ACCOUNT_ME } from '../reducers/i18n'

export const LOGIN = 'login';

const postAuth = (data) => ({
	[CALL_API]: {
		type: LOGIN,
		endpoint: 'auth',
		body: {
			username: data.username,
			password: data.password
		}
	}
});

export const doLogin = (data) => {
	return (dispatch) => {
		return dispatch(postAuth(data)).then((res) => {
			// Dispatch a me to fetch user
			if(res.type === getApiTypeSuccess(LOGIN)){
				dispatch(getMe(I18N_SOURCE_ACCOUNT_LOGIN));
			}

			return res;
		});
	}
};

export const LOGOUT = 'logout';
export const doLogout = () => ({
	type: LOGOUT
});

export const ME = 'me';
export const getMe = (source = undefined) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: ME,
			endpoint: 'my-athena/me',
			schema: Schemas.USER
		}
	}).then(res => {
		// Dispatch a locale change if user has one set
		if(res.type === getApiTypeSuccess(ME)){
			const user = res.response.entities.users[res.response.result];
			if(user.locale){
				dispatch(changeLocaleAuto(
					user.locale,
					source || I18N_SOURCE_ACCOUNT_ME
				));
			}
		}

		return res;
	});
};

export const REGISTER = 'register';
export const doRegister = (data) => (dispatch, getState) => {
	data.marketingOptIn = true;

	// Build the register locale for the user
	// Consists of: current language + domain language/locale
	const initState = getState().init;
	data.locale = GetAccountLocaleFromLanguage(initState.i18n.language, initState.rootLocale);

	return dispatch({
		[CALL_API]: {
			type: REGISTER,
			endpoint: 'client',
			schema: Schemas.USER,
			body: data
		}
	});
};

export const PAYMENT_METHODS = 'payment_methods';
export const getPaymentMethods = () => {
	return {
		[CALL_API]: {
			type: PAYMENT_METHODS,
			endpoint: 'available-payment-methods/v2'
		}
	};
};

export const PAY = 'pay';
export const payOrder = (orderId, methodId, methodSubId, returnUri) => {
	return {
		[CALL_API]: {
			type: PAY,
			endpoint: `order/${orderId}/pay`,
			body: {
				id: methodId,
				subId: methodSubId,
				finishUri: returnUri
			}
		}
	}
};

// Note: this is the same endpoint as below, but we need another type for the reducer
//  later this could be simplified by merging the logic based on the order state
//  but that is currently not within the scope of the current change
export const ORDER_RETURN = 'order_return';
export const getOrderReturn = (orderId) => {
	return {
		[CALL_API]: {
			type: ORDER_RETURN,
			endpoint: `my-athena/order/${orderId}`
		}
	}
};

export const ORDER = 'order';
export const getOrder = (orderId) => {
	return {
		[CALL_API]: {
			type: ORDER,
			endpoint: `my-athena/order/${orderId}`
		}
	}
};

export const PASSWORD = 'password';

export const sendResetPassword = (email) => {
	return {
		[CALL_API]: {
			type: PASSWORD,
			endpoint: `client/lost-password`,
			body: {
				email: email
			}
		}
	}
};

export const doResetPassword = (code, password) => {
	return {
		[CALL_API]: {
			type: PASSWORD,
			endpoint: `client/reset-password?resetCode=${code}`,
			body: {
				password: password
			}
		}
	}
};

export const CLIENT_COURSES = 'client_courses';
export const getCourses = () => {
	return {
		[CALL_API]: {
			type: CLIENT_COURSES,
			endpoint: `my-athena/courses`
		},
		forList: 'courses'
	};
};

export const CLIENT_EDIT = 'client_edit';
export const editClient = (data) => {
	return {
		[CALL_API]: {
			type: CLIENT_EDIT,
			endpoint: 'client',
			schema: Schemas.USER,
			body: data,
			method: 'PUT'
		}
	};
};

export const CLIENT_EDIT_ADDRESS = 'client_edit_address';
export const editClientAddress = (data) => {
	return {
		[CALL_API]: {
			type: CLIENT_EDIT_ADDRESS,
			endpoint: 'client',
			schema: Schemas.USER,
			body: {
				address: data,
			},
			method: 'PUT',
		},
	};
};
