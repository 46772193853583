import { Alert } from "@mui/material";
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Loader from '../loader'
import ScopeListItem from './ScopeListItem'
import StyledScopeList from "./StyledScopeList";

const ScopeList = ({ scopes, pathCreator, group, noScopesMessage, subTextCreator, filterUnlisted }) => {
	const listedScopes = useMemo(() => (
		filterUnlisted && scopes ? scopes.filter(scope => !scope?.properties?.unlisted) : scopes
	), [scopes, filterUnlisted]);

	if(!scopes) return (
		<Loader />
	);

	if(scopes.length === 0) return (
		<Alert severity="warning" icon={false}>
			{noScopesMessage || 'Kon niks laten zien. Probeer het later opnieuw.'}
		</Alert>
	);

	let last = null;

	return (
		<StyledScopeList>
			{listedScopes.map(scope => {
				let first = null;
				let letter = undefined;

				if(group){
					first = scope.name.substring(0, 1).toLowerCase();
					letter = false;

					if(last !== first){
						last = first;
						letter = first;
					}
				}

				return (
					<ScopeListItem
						key={scope.id}
						scope={scope}
						pathCreator={pathCreator}
						letter={letter}
						subTextCreator={subTextCreator}
					/>
				);
			})}
		</StyledScopeList>
	);
};

ScopeList.propTypes = {
	scopes: PropTypes.array,
	pathCreator: PropTypes.func,
	group: PropTypes.bool,
	noScopesMessage: PropTypes.string,
	subTextCreator: PropTypes.func,
	filterUnlisted: PropTypes.bool,
};

export default ScopeList
