import { Button, Collapse, Grid, Link, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { LabelOutlined } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from "react-youtube";
import { DEFAULT_DESC_STRING, getDescription } from "../../helpers/description";
import { gtmEvent } from '../../helpers/gtm';
import { propertyValue } from "../../helpers/properties";
import useLayoutEffectClient from '../../hoc/hooks/useLayoutEffectClient';
import CourseHoursTag from '../course/CourseHoursTag';
import CourseTimeTillStartTag from '../course/CourseTimeTillStartTag';

const PREFIX = 'ScopeDescription';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    description: `${PREFIX}-description`,
    seeMoreBtn: `${PREFIX}-seeMoreBtn`,
    closed: `${PREFIX}-closed`,
    youtubePlayer: `${PREFIX}-youtubePlayer`,
    youtubePlayerIframe: `${PREFIX}-youtubePlayerIframe`,
    propertiesWrapper: `${PREFIX}-propertiesWrapper`,
    propertiesList: `${PREFIX}-propertiesList`,
    svg: `${PREFIX}-svg`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		background: theme.palette.grey[100],
		marginBottom: theme.spacing(2),
		// boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
		'&:after': {
			content: '""',
			clear: 'both',
			display: 'table',
		}
	},

    [`& .${classes.description}`]: {
		position: 'relative',
		padding: theme.spacing(2),
		'&.has-collapse-btn': {
			paddingBottom: theme.spacing(6),
		}
	},

    [`& .${classes.seeMoreBtn}`]: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		width: '100%',
		justifyContent: 'left',
		padding: theme.spacing(0, 2),
		marginBottom: theme.spacing(1.5),
		fontSize: '1em',
		'&:hover, &:focus': {
			outline: 'none',
		},
	},

    [`& .${classes.closed}`]: {
		'&:before': {
			position: 'absolute',
			content: '""',
			width: '100%',
			left: 0,
			bottom: '100%',
			height: '4rem',
			backgroundImage: `linear-gradient(0deg, ${theme.palette.grey[100]} 0%, rgba(255,255,255,0) 100%)`,
		},
	},

    [`& .${classes.youtubePlayer}`]: {
		position: 'relative',
		margin: theme.spacing(2),
		aspectRatio: '9 / 6',
		width: `calc(100% - ${theme.spacing(4)})`,
		overflow: 'hidden',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},

    [`& .${classes.youtubePlayerIframe}`]: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: `calc(100% - ${theme.spacing(3)})`,
		margin: '0 -1px',
		height: 'calc(100% + 1px)',
		'& .ytp-chrome-top-buttons': {
			display: 'none',
		},
	},

    [`& .${classes.propertiesWrapper}`]: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(0, 2),
		[theme.breakpoints.up('md')]: {
			borderLeft: `2px solid ${theme.palette.grey[300]}`,
		},
	},

    [`& .${classes.propertiesList}`]: {
		listStyle: 'none',
		paddingLeft: 0,
		marginTop: theme.spacing(0.5),
		'& li': {
			display: 'flex',
			alignItems: 'flex-start',
			lineHeight: '1.4em',
			marginBottom: theme.spacing(1),
		},
	},

    [`& .${classes.svg}`]: {
		color: infoBlue,
		fontSize: '1.25em',
		lineHeight: '2em',
		marginRight: theme.spacing(0.5),
		transform: 'translateY(-1px)',
	}
}));

const playersOptions = {
	playerVars: {
		modestbranding: 1,
	},
};
const infoBlue = '#4165ff';

const DEFAULT_COLLAPSED_HEIGHT = 220;

const ScopeDescription = ({ scopeDescription, scopeProperties, scope }) => {
	const [ description, youtube ] = getDescription(scopeDescription);
	const descContentRef = useRef();
	const [enableCollapse, setEnableCollapse] = useState(false);
	const isASCourse = scope?.class === 'AthenaStudiesCourse';

	const { t, i18n } = useTranslation();

    useLayoutEffectClient(() => {
        if (scopeDescription && descContentRef.current) {
            setEnableCollapse(descContentRef.current.getBoundingClientRect().height > DEFAULT_COLLAPSED_HEIGHT);
        }
    }, [scopeDescription]);

	useEffect(() => {
		if (typeof enableCollapse === 'boolean') {
			setOpened(!enableCollapse);
		}
	}, [enableCollapse]);

	const [opened, setOpened] = useState(enableCollapse);

	const toggleOpenend = () => {
		setOpened(opened => !opened);

		if(!opened){
			gtmEvent(isASCourse ? 'readMoreCourse' : 'readMoreStudy');
		}
	};

	const hasScopeProperties = !!scopeProperties?.length;

	if ((description === DEFAULT_DESC_STRING || !description) && !hasScopeProperties) {
		return null;
	}

	let gridWidth = {};
	if (youtube && hasScopeProperties) {
		gridWidth = { sm: 4, md: 6 };
	} else if (youtube || hasScopeProperties) {
		gridWidth = { sm: 8, md: 9 };
	}

	return (
        <Root className={classes.wrapper}>
			<Grid container columnSpacing={2}>
				{youtube && (
					<Grid item xs={12} sm={4} md={3}>
						<YouTube
							videoId={youtube}
							opts={playersOptions}
							containerClassName={classes.youtubePlayer}
							className={classes.youtubePlayerIframe}
						/>
					</Grid>
				)}
				<Grid item xs={12} {...gridWidth}>
					<div className={`${classes.description} ${enableCollapse ? 'has-collapse-btn' : ''}`}>
						{isASCourse && (<Typography variant="h6">{t('course.description.about_this_course')}</Typography>)}
						<Collapse in={opened} collapsedSize={enableCollapse ? DEFAULT_COLLAPSED_HEIGHT : 'auto' }>
							<div
								dangerouslySetInnerHTML={{
									__html: description
								}}
								ref={descContentRef}
								className="editor"
							/>
							{ enableCollapse && (
								<Button component={Link} className={`${classes.seeMoreBtn} ${opened ? '' : classes.closed}`} onClick={toggleOpenend} variant="text">
									{opened
										? t('course.description.see_less')
										: t('course.description.see_more')
									}
								</Button>
							)}
						</Collapse>
					</div>
				</Grid>
				{!!scopeProperties?.length && (
					<Grid item xs={12} sm={3} md={3}>
						<div className={classes.propertiesWrapper}>
							<Typography variant="h6">{t('course.description.in_short')}</Typography>
							<ul className={classes.propertiesList}>
								{scopeProperties
									.map((prop, index) => (
										<li key={`prop_${index}`}>
											<LabelOutlined className={classes.svg} />
											{propertyValue(prop, i18n.language)}
										</li>
									))
								}
								<CourseHoursTag course={scope} as="li" />
								<CourseTimeTillStartTag groups={scope.subScopes} as="li" />
							</ul>
						</div>
					</Grid>
				)}
			</Grid>
		</Root>
    );
}

export default ScopeDescription;
