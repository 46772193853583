import { Grid } from "@mui/material";
import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import useAuth from '../hoc/hooks/useAuth'
import { routes, path } from '../routes'
import IsAuthenticated from '../auth'
import Content from './../components/content/Content'
import AccountEditForm from '../components/account/AccountEditForm'
import Loader from './../components/loader'

const AccountEditPage = ({ i18n }) => {
	const auth = useAuth();

	return (
		<Content padding>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={path(i18n, routes.ACCOUNT)} />
					</Grid>
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<h1>
							<Trans i18nKey="account.edit.my_athenastudies" defaults="Mijn AthenaStudies" />
						</h1>
					</Grid>
					<Grid item xs={0} md={4} lg={3} />
				</Grid>
			</Content.Header>
			{auth.user && <div>
				<AccountEditForm defaultFields={{ ...auth.user,
					email_repeat: auth.user.email,
				}} />
			</div> || <Loader/>}
		</Content>
	);
}

export default IsAuthenticated(withTranslation()(AccountEditPage))
