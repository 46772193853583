import { Button, Divider, Grid, InputLabel, TextField, Alert } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Countries from "../../constants/Countries";
import SimpleState from '../../hoc/SimpleState'
import FormData from '../../hoc/FormData'
import { editClientAddress } from '../../actions/auth'
import Loader from '../../components/loader'

const filterOptions = createFilterOptions({
	stringify: o => `${o.code} ${o.name} ${o.nameLocal} ${o.prefix}`,
});

function getOptionLabel(option) {
	const country = typeof option === 'string' ? Countries.find(f => f.code === option) : option;
	return `${country.name} / ${country.nameLocal} (${country.code})`;
}

const AccountEditAddressForm = ({ cancelBackTo, saveAndConfirmText, onSuccess, fields, onChangeField, onChangeFieldDirect, saving, error, success, handleSubmit, t }) => {
	useEffect(() => {
		if(success && onSuccess){
			onSuccess();
		}
	}, [success, onSuccess]);

	const handleChangeCountry = (e, item) => {
		onChangeFieldDirect('country', item.code);
	};

	return (
		<form onSubmit={handleSubmit(() => fields, editClientAddress)}>
			{saving && (
				<Loader sheet={true}>{t('account.edit.address_form.loading')}</Loader>
			)}
			{error && (
				<Alert severity="warning" icon={false}>
					{error.errors ? t('account.edit.address_form.error_with_fields') : error.error}
				</Alert>
			)}

			{success ? (
				<Alert severity="success" icon={false}>
					{t('account.edit.address_form.success_message')} {cancelBackTo && (<span><Link to={cancelBackTo}>{t('account.edit.address_form.go_back')}</Link>.</span>)}
				</Alert>
			) : (
				<>
					<Grid container spacing={2}>
						{!saveAndConfirmText && (
							<Grid item xs={12}>
								<p>{t('account.edit.address_form.description')}</p>
							</Grid>
						)}
						<Grid item xs={12} md={6}>
							<InputLabel htmlFor="edit-street">
								{t('account.edit.address_form.street')}
							</InputLabel>
							<TextField
								id="edit-street"
								fullWidth
								name="street"
								value={fields.street || ''}
								onChange={onChangeField}
								error={!!error?.errors?.['address.street']}
								helperText={error?.errors?.['address.street']}
							/>
						</Grid>
						<Grid item xs={6} sm={4} md={3}>
							<InputLabel htmlFor="edit-houseNumber">
								{t('account.edit.address_form.house_number')}
							</InputLabel>
							<TextField
								id="edit-houseNumber"
								fullWidth
								name="houseNumber"
								value={fields.houseNumber || ''}
								onChange={onChangeField}
								error={!!error?.errors?.['address.houseNumber']}
								helperText={error?.errors?.['address.houseNumber']}
							/>
						</Grid>
						<Grid item xs={6} sm={4} md={3}>
							<InputLabel htmlFor="edit-houseNumberExtension">
								{t('account.edit.address_form.house_number_extension')}
							</InputLabel>
							<TextField
								id="edit-houseNumberExtension"
								fullWidth
								name="houseNumberExtension"
								value={fields.houseNumberExtension || ''}
								onChange={onChangeField}
								error={!!error?.errors?.['address.houseNumberExtension']}
								helperText={error?.errors?.['address.houseNumberExtension']}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<InputLabel htmlFor="edit-zipcode">
								{t('account.edit.address_form.zipcode')}
							</InputLabel>
							<TextField
								id="edit-zipcode"
								fullWidth
								name="zipcode"
								value={fields.zipcode || ''}
								onChange={onChangeField}
								error={!!error?.errors?.['address.zipcode']}
								helperText={error?.errors?.['address.zipcode']}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<InputLabel htmlFor="edit-city">
								{t('account.edit.address_form.city')}
							</InputLabel>
							<TextField
								id="edit-city"
								fullWidth
								name="city"
								value={fields.city || ''}
								onChange={onChangeField}
								error={!!error?.errors?.['address.city']}
								helperText={error?.errors?.['address.city']}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<InputLabel htmlFor="edit-country">
								{t('account.edit.address_form.country')}
							</InputLabel>
							<Autocomplete
								onChange={handleChangeCountry}
								value={fields.country || null}
								options={Countries}
								getOptionLabel={getOptionLabel}
								isOptionEqualToValue={(option, val) => option.code === val}
								renderOption={(props, option) => (
									<li {...props} key={option.code}>
										{`${option.name} / ${option.nameLocal} (${option.code})`}
									</li>
								)}
								filterOptions={filterOptions}
								disableClearable
								noOptionsText={t('account.edit.address_form.country_not_found')}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder={t('account.edit.address_form.country')}
										error={!!error?.errors?.['address.country']}
										helperText={error?.errors?.['address.country']}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Divider sx={{ my: 2 }} />

					<Grid container spacing={2}>
						{cancelBackTo && (
							<Grid item xs={12} sm={6}>
								<Button component={Link} to={cancelBackTo} variant="contained" color="default" fullWidth>
									{t('account.edit.address_form.button_cancel')}
								</Button>
							</Grid>
						)}
						<Grid
							item
							{...cancelBackTo
								? { xs: 12, sm: 6, sx: { mb: 2 } }
								: { xs: 12, sm: 6, md: 4, sx: { mx: 'auto', mb: 2 } }
							}
						>
							<Button
								type="submit"
								color="cta"
								variant="contained"
								disabled={saving}
								fullWidth
							>
								{saveAndConfirmText
									? t('account.edit.address_form.button_save_and_confirm')
									: t('account.edit.address_form.button_save')
								}
							</Button>
						</Grid>
					</Grid>
				</>
			)}
		</form>
	);
};

AccountEditAddressForm.propTypes = {
	cancelBackTo: PropTypes.string,
	saveAndConfirmText: PropTypes.bool,
	onSuccess: PropTypes.func,
};

export default withTranslation()(FormData(SimpleState(AccountEditAddressForm, {
	fields: ['street', 'houseNumber', 'houseNumberExtension', 'zipcode', 'city', 'country'],
}), 'AccountEditAddressForm', {
	dataPick: ['street', 'houseNumber', 'houseNumberExtension', 'zipcode', 'city', 'country'],
}))
