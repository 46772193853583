import React, { PureComponent } from 'react'

// Do change
const startAtTop = true;
const radiusFactor = 0.8;
const amountFactor = 0.6;

const debug = false;

// Probably don't change
const maxWeight = 5;
const maxSpeed = 3;

let canvas = null,
	ctx = null,
	windowW = 0,
	windowH = 0,
	numFlakes = 100 * amountFactor,
	flakes = [];

function Flake(x, y) {
	this.x = x;
	this.y = y;
	this.r = randomBetween(0, 1);
	this.a = randomBetween(0, Math.PI);
	this.aStep = 0.01;
	this.visible = !startAtTop;

	this.weight = randomBetween(2, maxWeight);
	this.alpha = (this.weight / maxWeight);
	this.speed = (this.weight / maxWeight) * maxSpeed;

	this.update = function() {
		if(!this.visible){
			this.visible = randomBetween(0, 1) < (1 / numFlakes)
			return;
		}

		this.x += Math.cos(this.a) * this.r;
		this.a += this.aStep;

		this.y += this.speed;
	}
}

function amountFactorFromWidth(width){
	// Screens are generally roughly from 350 - 2000
	// 2000 will be factor 1, which is the max

	let factor = width / 2000;

	if(debug) console.log(`[SNOW] Flake factor: ${factor}`);

	if(factor < 0.2) return 0.2
	if(factor > 1) return 1;

	return factor;
}

function init() {
	if(typeof window === 'undefined') return;

	windowW = window.innerWidth;
	windowH = window.innerHeight;

	canvas = document.querySelector('.snow');
	ctx = canvas.getContext('2d');

	let i = parseInt(amountFactorFromWidth(windowW) * numFlakes), flake, x, y;

	while (i--) {
		x = randomBetween(0, windowW, true);
		y = startAtTop ? -10 : randomBetween(0, windowH, true);

		flake = new Flake(x, y);
		flakes.push(flake);
	}

	scaleCanvas();
	loop();
}

function scaleCanvas() {
	canvas.width = windowW;
	canvas.height = windowH;
}

let times = [];
function log(){
	if(times.length % 60 === 0){
		const total = times.reduce((acc, c) => acc + c, 0);
		const avgSeconds = total / times.length / 1000;

		console.log(`[SNOW] Average loop time: ${avgSeconds.toFixed(4)}s`);
	}
}

function loop() {
	let i = flakes.length, flake, start;

	if(debug){
		start = performance.now();
	}

	// Clear canvas first
	ctx.save();
	ctx.setTransform(1, 0, 0, 1, 0, 0);
	ctx.clearRect(0, 0, windowW, windowH);
	ctx.restore();

	// Update and render again
	while (i--) {
		flake = flakes[i];
		flake.update();

		ctx.beginPath();
		ctx.arc(flake.x, flake.y, flake.weight * radiusFactor, 0, 2 * Math.PI, false);
		ctx.fillStyle = 'rgba(255, 255, 255, ' + flake.alpha + ')';
		ctx.fill();

		if (flake.y >= windowH) {
			flake.y = -flake.weight;
		}
	}

	if(debug){
		times.push(performance.now() - start);
		log();
	}

	requestAnimationFrame(loop);
}

function randomBetween(min, max, round) {
	const num = Math.random() * (max - min + 1) + min;

	if (round) {
		return Math.floor(num);
	} else {
		return num;
	}
}

const styles = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
};

class Snow extends PureComponent {
	render(){
		if(debug) console.log('[SNOW] Rendering');

		return (
			<canvas className="snow" style={styles} />
		);
	}

	componentDidMount(){
		init();
	}

	componentWillUnmount(){
		if(ctx){
			ctx.clearRect(0, 0, windowW, windowH);
		}
	}
}

export default Snow
