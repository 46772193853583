import {
	Alert,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	formControlLabelClasses,
	Grid,
	InputLabel,
	styled,
	TextField,
} from "@mui/material";
import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { mergeAll } from 'ramda'
import PhoneField from "../general/PhoneField";
import { routes, path } from './../../routes'
import SimpleState from './../../hoc/SimpleState'
import FormData from './../../hoc/FormData'
import { editClient } from './../../actions/auth'
import Loader from './../../components/loader'

const validation = (t) => (data) => {
	const errors = [];

	if(data.email !== data.email_repeat) {
		errors.push({email_repeat: t('account.edit.form.email_not_the_same')});
	}

	if(errors.length > 0) return {
		errors: mergeAll(errors)
	};

	return false;
};

const StyledFormControlLabel = styled(FormControlLabel)({
	[`& .${formControlLabelClasses.label}`]: {
		fontSize: '1rem',
	},
});

const AccountEditForm = ({ fields, onChangeField, onChangeFieldCheckbox, saving, error, handleSubmit, t, i18n }) => (
	<form onSubmit={handleSubmit(() => fields, editClient, validation(t))}>
		{ saving && (
			<Loader sheet>{t('account.edit.form.loading')}</Loader>
		)}
		{ error?.errors?.['error'] && (
			<Alert severity="warning" icon={false}>{error.errors['error']}</Alert>
		)}

		<Grid container spacing={2} columnSpacing={6}>
			<Grid item xs={12} md={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<InputLabel htmlFor="edit-name">
							{t('account.edit.form.name')}
						</InputLabel>
						<TextField
							id="edit-name"
							name="name"
							autoComplete="given-name"
							fullWidth
							value={fields.name}
							onChange={onChangeField}
							error={!!error?.errors?.['name']}
							helperText={error?.errors?.['name']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="edit-surname">
							{t('account.edit.form.surname')}
						</InputLabel>
						<TextField
							id="edit-surname"
							fullWidth
							name="surname"
							autoComplete="family-name"
							value={fields.surname}
							onChange={onChangeField}
							error={!!error?.errors?.['surname']}
							helperText={error?.errors?.['surname']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="edit-mobile">
							{t('account.edit.form.mobile')}
						</InputLabel>
						<PhoneField
							value={fields.mobilePhone}
							onChange={onChangeField}
							name="mobilePhone"
							id="edit-mobile"
							fullWidth
							error={!!error?.errors?.['mobilePhone']}
							helperText={error?.errors?.['mobilePhone']}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<InputLabel htmlFor="edit-email">
							{t('account.edit.form.email')}
						</InputLabel>
						<TextField
							id="edit-email"
							fullWidth
							name="email"
							autoComplete="email"
							value={fields.email}
							onChange={onChangeField}
							error={!!error?.errors?.['email']}
							helperText={error?.errors?.['email']}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="edit-email-repeat">
							{t('account.edit.form.email_repeat')}
						</InputLabel>
						<TextField
							id="edit-email-repeat"
							fullWidth
							name="email_repeat"
							value={fields.email_repeat}
							onChange={onChangeField}
							error={!!error?.errors?.['email_repeat']}
							helperText={error?.errors?.['email_repeat']}
						/>
					</Grid>
				</Grid>
				<Alert severity="warning" icon={false}>
					{t('account.edit.form.email_changes_username')}
				</Alert>
			</Grid>
		</Grid>

		<Divider sx={{ my: 2 }} />

		<Grid item xs={12}>
			<StyledFormControlLabel
				checked={fields.marketingOptIn || false}
				onChange={onChangeFieldCheckbox}
				control={<Checkbox id="marketingCheck" />}
				htmlFor="marketingCheck"
				name="marketingOptIn"
				label={t('account.edit.form.agree_marketing')}
			/>
		</Grid>

		<Divider sx={{ mt: 1, mb: 3 }} />

		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Button component={Link} variant="contained" to={path(i18n, routes.ACCOUNT)} color="default" fullWidth>
					{t('account.edit.form.button_cancel')}
				</Button>
			</Grid>
			<Grid item xs={6}>
				<Button
					type="submit"
					color="cta"
					variant="contained"
					fullWidth
				>
					{t('account.edit.form.button_save')}
				</Button>
			</Grid>
		</Grid>
	</form>
);

export default withTranslation()(FormData(SimpleState(AccountEditForm, {
	fields: ['name', 'surname', 'mobilePhone', 'email', 'email_repeat', 'marketingOptIn']
}), 'AccountEditForm', {
	successRedirect: (props) => path(props.i18n, routes.ACCOUNT),
	dataPick: ['name', 'surname', 'mobilePhone', 'email', 'marketingOptIn']
}))
