import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function ScopeSearch({ q, onChange }) {
	const { t } = useTranslation();

	return (
		<TextField
			placeholder={t('select.scope.search')}
			name="q"
			value={q}
			variant="standard"
			fullWidth
			onChange={(e) => onChange(e.target.value)}
			InputProps={{
				startAdornment: (<Search sx={{ mr: 1 }} color="disabled" />)
			}}
			sx={{ my: 1, pl: { xs: 0, sm: 3, md: 6 } }}
		/>
	);
}

ScopeSearch.propTypes = {
	q: PropTypes.string,
	onChange: PropTypes.func.isRequired
};

export default ScopeSearch;
