import moment from 'moment'
import { gtmEvent } from '../helpers/gtm'
import { CALL_API } from './../schemas'
import { getApiTypeSuccess } from '../helpers/types'
import { getUtmQueryString } from '../helpers/basket'
import { BasketCookieDays, BasketFirstAddedCookieName } from '../reducers/init'

export const BASKET = 'basket';

export const createBasket = () => (dispatch, getState, cookies) => {
	const rootScopeId = getState().init.rootScope;

	return dispatch({
		[CALL_API]: {
			type: BASKET,
			endpoint: `basket?rootScopeId=${rootScopeId || ''}&${getUtmQueryString(cookies)}`,
			method: 'POST'
		}
	});
}

export const getBasket = (basketId) => ({
	[CALL_API]: {
		type: BASKET,
		endpoint: `basket/${basketId}`
	}
});

const postBasketItem = (basketId, scopeId) => ({
	[CALL_API]: {
		type: BASKET,
		endpoint: `basket/${basketId}/product/${scopeId}`,
		method: 'POST'
	}
});

export const addBasketItem = (scopeId) => (dispatch, getState, cookies) => {
	let basket = getState().init.shoppingBasket;

	gtmEvent('basket', {
		basketAction: 'add item',
		basketItemId: scopeId,
	});

	if(!cookies.get(BasketFirstAddedCookieName)){
		cookies.set(BasketFirstAddedCookieName, moment().toISOString(), {
			days: BasketCookieDays,
		});
	}

	if(!basket){
		return dispatch(createBasket()).then(res => {
			if(res.type == getApiTypeSuccess(BASKET)){
				return dispatch(postBasketItem(res.response.result.id, scopeId));
			}else{
				return res;
			}
		})
	}else{
		return dispatch(postBasketItem(basket.id, scopeId));
	}
};

export const addBasketMultiple = (scopeIds) => (dispatch) => {
	return dispatch(addBasketItem(scopeIds[0])).then(res => {
		if(res.type === getApiTypeSuccess(BASKET) && scopeIds.length > 1){
			return dispatch(addBasketMultiple(scopeIds.slice(1)));
		}

		return res;
	});
}

export const removeBasketItem = (scopeId) => (dispatch, getState) => {
	let basket = getState().init.shoppingBasket;
	if(!basket) return null;

	gtmEvent('basket', {
		basketAction: 'remove item',
		basketItemId: scopeId,
	});

	return dispatch({
		[CALL_API]: {
			type: BASKET,
			endpoint: `basket/${basket.id}/product/${scopeId}`,
			method: 'DELETE'
		}
	})
};

export const addDiscount = ({discountCode}) => (dispatch, getState) => {
	if(!discountCode) return null;

	let basket = getState().init.shoppingBasket;
	if(!basket) return null;

	if(typeof discountCode === 'string'){
		discountCode = discountCode.trim();
	}

	gtmEvent('basket', {
		basketAction: 'add discount',
		basketDiscountCode: discountCode,
	});

	return dispatch({
		[CALL_API]: {
			type: BASKET,
			endpoint: `basket/${basket.id}/discountcode`,
			body: {
				discountCode: discountCode
			}
		}
	})
};

export const removeDiscount = (discountCode) => (dispatch, getState) => {
	if(!discountCode) return null;

	let basket = getState().init.shoppingBasket;
	if(!basket) return null;

	if(typeof discountCode === 'string'){
		discountCode = discountCode.trim();
	}

	gtmEvent('basket', {
		basketAction: 'remove discount',
		basketDiscountCode: discountCode,
	});

	return dispatch({
		[CALL_API]: {
			type: BASKET,
			endpoint: `basket/${basket.id}/discountcode/${discountCode}`,
			method: 'DELETE'
		}
	})
};

export const ORDER_BASKET = 'order_basket';
export const orderBasket = () => (dispatch, getState) => {
	let basket = getState().init.shoppingBasket;
	if(!basket) return null;

	let me = getState().init.me;
	if(!me) return null;

	return dispatch({
		[CALL_API]: {
			type: ORDER_BASKET,
			endpoint: `order/${basket.id}`,
			method: 'POST'
		}
	});
};

export const CLEAR_ACTIVE_ORDER = 'clear_active_order';
export const clearActiveOrder = () => ({
	type: CLEAR_ACTIVE_ORDER
});
